import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';

import { InputText } from 'primereact/inputtext';
import { Loader } from '../../components/Loader/Loader';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DisptachViewColumns } from '../../appconfig/DatatableSetting';
import { defaultRowOptions, } from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

export const ViewDispatch = () => {

    //Navigate Another Route
    const navigate = useNavigate();

    const pageService = new PageService();
    const location = useLocation();

    const [pageLoad, setPageLoad] = useState(false);

    const toast = useRef<any>(null);
    const [dispatchData, setDispatchData] = useState<any>(null);
    const [dispatchDetails, setDispatchDetails] = useState<any>([]);
    const [lotNoDetails, setLotNoDetails] = useState<any>([[]]);

    const [returnId, setReturnId] = useState<any>(null);
    const [returnDialog, setReturnDialog] = useState<boolean>(false);
    const [dispatchRows, setDispatchRows] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            getDispatchDataFromAPI(state);
        }
    }, []);

    // Get Dispatch Data from API
    const getDispatchDataFromAPI = async (state: any) => {
        setPageLoad(true);
        // call api
        pageService
            .getDispatchDataById(state.id)
            .then((response) => {
                // get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setDispatchData(null);
                        setLotNoDetails(null);
                    } else {
                        setDispatchData(DataList);
                        setDispatchDetails(DataList.dispatch_details)
                        setPageLoad(false);
                    }
                } else {
                    setDispatchData(null);
                    setDispatchDetails(null);
                    setLotNoDetails(null);
                }
            });
    };

    // Get Dispatch Data from API
    const onClickReturn = async (id: any) => {
        setPageLoad(true);
        // call api
        pageService
            .onClickReturn(id)
            .then((response) => {
                // get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setDispatchRows(null);
                    } else {
                        setDispatchRows(DataList);
                        setPageLoad(false);
                    }
                } else {
                    setDispatchRows(null);
                }
            });
    };

    // open return dialog
    const returnOrder = (data: any) => {
        onClickReturn(data.id)
        setReturnId(data.id);
        setReturnDialog(true);
    };

    //close return dialog
    const hideReturnDialog = () => {
        setDispatchRows(null);
        setReturnDialog(false);
        setReturnId(null);
    };

    const onHandleChange = (e: any, name: string, rowData: any) => {
        let data = dispatchRows

        data[rowData.i][name] = e.target.value

        setDispatchRows(data);
    }

    // for column action
    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <div className="actions">
                    <InputText
                        keyfilter="num"
                        value={!window.cn(dispatchRows) ? dispatchRows[rowData.i]["quantity"] : ""}
                        name="quantity"
                        autoComplete="off"
                        onChange={(e) => onHandleChange(e, 'quantity', rowData)}
                        disabled={rowData.disable}
                        placeholder='Enetr Quantity'
                    />
                </div>
            </>
        );
    };

    //on submit
    const onSubmit = () => {
        setLoading(true);

        let formData = new FormData();

        formData.append('dispatch_id', returnId);
        if (!window.cn(dispatchRows) && dispatchRows.length > 0) {
            formData.append('return_line_items', JSON.stringify(dispatchRows));
        }

        pageService.returnOrder(formData).then((result: any) => {
            setLoading(false);
            setReturnDialog(false);
            setDispatchRows(null);
            toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: result.message
            });
        }).catch(error => {
            setReturnDialog(false);
            setDispatchRows(null);
            setLoading(false);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response.data.error,
            });
        });

    };

    return (<>

        <h3> <Button
            //label="Back"
            icon="pi pi-arrow-left"
            className="p-button-secondary mr-2"
            onClick={() => navigate('/product/dispatch')}
        />    Dispatch Details</h3>
        <Toast ref={toast} />
        <div className='card' style={{ position: "relative" }}>
            <div className="field col">
                <div className="grid">
                    <div className="field col-4 flex flex-column">
                        <p> <b>Product Dispatch Type</b> : {!window.cn(dispatchData) ? dispatchData.dispatch_type : ""}</p>
                    </div>

                    {!window.cn(dispatchData) && (dispatchData?.dispatch_type == 'SEED DISPATCH' || dispatchData?.dispatch_type == 'LOOSE PACKET') ?
                        <>
                            <div className="field col-4 flex flex-column">
                                <p> <b>Dispatch Type</b> : {!window.cn(dispatchData.seed_dispatch_type) ? dispatchData.seed_dispatch_type : ""}</p>
                            </div>

                            <div className="field col-4 flex flex-column">
                                <p> <b>Dealer Name</b> : {!window.cn(dispatchData.dealer_name) ? dispatchData.dealer_name : ""}</p>
                            </div>
                        </>
                        : !window.cn(dispatchData) && dispatchData?.dispatch_type == 'PACKING MATERIAL' ?
                            <>
                                <div className="field col-4 flex flex-column">
                                    <p> <b>Name Of Organizer</b> : {!window.cn(dispatchData.name_of_organizer) ? dispatchData.name_of_organizer : ""}</p>
                                </div>
                                <div className="field col-4 flex flex-column">
                                    <p> <b>Bag Type</b> : {!window.cn(dispatchData.bag_type) ? dispatchData.bag_type : ""}</p>
                                </div>
                            </>
                            : !window.cn(dispatchData) && dispatchData?.dispatch_type == 'PROMOTIONAL MATERIAL' ?
                                <>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Dealer</b> : {!window.cn(dispatchData.dealer_name) ? dispatchData.dealer_name : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column"></div>
                                </> : ""}
                    <div className="field col-4 flex flex-column">
                        <p> <b>Bill To</b> : {!window.cn(dispatchData) ? dispatchData.bill_to : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Billing Address</b> : {!window.cn(dispatchData) ? dispatchData.billing_address : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>GSTIN No.</b> : {!window.cn(dispatchData) ? dispatchData.gst_no : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Delivery At</b> : {!window.cn(dispatchData) ? dispatchData.delivery_at : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Delivery Address</b> : {!window.cn(dispatchData) ? dispatchData.delivery_address : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Mobile</b> : {!window.cn(dispatchData) ? dispatchData.mobile : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Dispatch Data Added By</b> : {!window.cn(dispatchData) ? dispatchData.data_added_by_user : ""}</p>
                    </div>
                    {!window.cn(dispatchData) && !window.cn(dispatchData.data_edited_by_user) ?
                        <div className="field col-4 flex flex-column">
                            <p> <b> Data Edited By</b> : {!window.cn(dispatchData.data_edited_by_user) ? dispatchData.data_edited_by_user : ""}</p>
                        </div> : ""}
                </div>
                <hr />
                {dispatchDetails.map((item: any, index: number) => (
                    <div className="formgrid grid dispatch">
                        {!window.cn(dispatchData) && dispatchData?.dispatch_type == 'SEED DISPATCH' || dispatchData?.dispatch_type == 'PACKING MATERIAL' || dispatchData?.dispatch_type == 'LOOSE PACKET' ?
                            <>
                                <div className="field col-4 flex flex-column">
                                    <p> <b>Crop</b> : {!window.cn(item) ? item.crop_obj?.name : ""}</p>
                                </div>
                                <div className="field col-4 flex flex-column">
                                    <p> <b>Variety</b> : {!window.cn(item) ? item.variety_obj?.name : ""}</p>
                                </div>

                                {!window.cn(item["detail_array"][0]["lot_no"]) ?
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Lot No</b> : {!window.cn(item["detail_array"][0]["lot_no"]["name"]) ? item["detail_array"][0]["lot_no"]["name"] : ""}</p>
                                    </div>
                                    : ""}
                                {!window.cn(item["detail_array"][0]["packing_size"]["name"]) ?
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Packing Size</b> : {!window.cn(item["detail_array"][0]["packing_size"]["name"]) ? item["detail_array"][0]["packing_size"]["name"] : ""}</p>
                                    </div> : ""}

                                {!window.cn(item["detail_array"][0]["class"]) ?
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Class</b> : {!window.cn(item["detail_array"][0]["class"]) ? item["detail_array"][0]["class"] : ""}</p>
                                    </div> : ""}

                                {!window.cn(item["detail_array"][0]["no_of_bag"]) ?
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No Of Outer bag</b> : {!window.cn(item["detail_array"][0]["no_of_bag"]) ? item["detail_array"][0]["no_of_bag"] : ""}</p>
                                    </div> : ""}
                                {!window.cn(item["detail_array"][0]["no_of_inner_bag"]) ?
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No Of Packets</b> : {!window.cn(item["detail_array"][0]["no_of_inner_bag"]) ? item["detail_array"][0]["no_of_inner_bag"] : ""}</p>
                                    </div> : ""}

                                {!window.cn(item.total_amount) ?
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Amount</b> : {!window.cn(item.total_amount) ? item.total_amount : ""}</p>
                                    </div> : ""
                                }
                            </>
                            : !window.cn(dispatchData) && dispatchData?.dispatch_type == 'PACKING MATERIAL' ?
                                <>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Crop</b> : {!window.cn(item) ? item.crop_obj?.name : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Variety</b> : {!window.cn(item) ? item.variety_obj?.name : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Packing Size</b> : {!window.cn(item.packing_size) ? item.packing_size : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No Of Inner bag</b> : {!window.cn(item) ? item.no_of_inner_bag : ""}</p>
                                    </div>
                                    <div className="field col-6 flex flex-column">
                                        <p> <b>No Of Bag</b> : {!window.cn(item) ? item.no_of_bag : ""}</p>
                                    </div>
                                </>
                                : !window.cn(dispatchData) && dispatchData?.dispatch_type == 'PROMOTIONAL MATERIAL' ?
                                    <>
                                        <div className="field col-6 flex flex-column">
                                            <p> <b>Particulars</b> : {!window.cn(item) ? item.particulars : ""}</p>
                                        </div>
                                        <div className="field col-6 flex flex-column">
                                            <p> <b>No Of Bag</b> : {!window.cn(item) ? item.no_of_bag : ""}</p>
                                        </div>
                                    </>
                                    : !window.cn(dispatchData) && dispatchData?.dispatch_type == 'SAMPLING MATERIAL' ?
                                        <>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Crop</b> : {!window.cn(item) ? item.crop_obj?.name : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>No Of Inner Cloth Bag</b> : {!window.cn(item) ? item.no_of_inner_bag : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Rate Per Bag</b> : {!window.cn(item) ? item.sampling_rate_per_inner_bag : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>No Of Outer Cloth Bag</b> : {!window.cn(item) ? item.no_of_bag : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Rate Per Bag</b> : {!window.cn(item) ? item.sampling_rate_per_outer_bag : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Wax Qty.</b> : {!window.cn(item) ? item.wax_qty : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Rate Per KG.</b> : {!window.cn(item) ? item.rate_per_wax : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Twine(Sutali)</b> : {!window.cn(item) ? item.sutali : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Rate Per KG.</b> : {!window.cn(item) ? item.rate_per_sutali : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Total Amount</b> : {!window.cn(item) ? item.total_amount : ""}</p>
                                            </div>

                                        </>
                                        : !window.cn(dispatchData) && dispatchData?.dispatch_type == 'SEED REMENANT DISPATCH' ?
                                            <>
                                                <div className="field col-4 flex flex-column">
                                                    <p> <b>Crop</b> : {!window.cn(item) ? item.crop_obj?.name : ""}</p>
                                                </div>
                                                <div className="field col-4 flex flex-column">
                                                    <p> <b>Variety</b> : {!window.cn(item) ? item.variety_obj?.name : ""}</p>
                                                </div>
                                                <div className="field col-4 flex flex-column">
                                                    <p> <b>Remenant</b> : {!window.cn(item) ? item.remenant : ""}</p>
                                                </div>
                                            </> : ""}
                    </div>
                ))}


                <hr />
                <h5> Transportation Details</h5>
                <>
                    <div className="formgrid grid dispatch">
                        <div className="field col-4 flex flex-column">
                            <p> <b>Transportation Type</b> : {!window.cn(dispatchData) ? dispatchData.transport_type : ""}</p>
                        </div>
                    </div>

                    {
                        !window.cn(dispatchData) && dispatchData.transport_type == "PART LOAD" ?
                            <>
                                <div className="formgrid grid">
                                    <div className="field col flex flex-column ">
                                        <p> <b>Lr. No.</b> : {!window.cn(dispatchData.lr_no) ? dispatchData.lr_no : ""}</p>

                                    </div>
                                    <div className="field col flex flex-column">
                                        <p> <b>Date Of Supply</b> : {!window.cn(dispatchData.date_of_supply) ? dispatchData.date_of_supply : ""}</p>

                                    </div>
                                    <div className="field col flex flex-column">
                                        <p> <b>Transport Name</b> : {!window.cn(dispatchData.transport_name) ? dispatchData.transport_name : ""}</p>
                                    </div>
                                </div>

                                <div className="formgrid grid ">
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Payment PAID Or Not</b> : {!window.cn(dispatchData.payment_type) ? dispatchData.payment_type : ""}</p>
                                    </div>

                                    {!window.cn(dispatchData) && dispatchData.amount ?
                                        <>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Amount</b> : {!window.cn(dispatchData.amount) ? dispatchData.amount : ""}</p>
                                            </div>
                                        </> : <>
                                        </>
                                    }

                                    {!window.cn(dispatchData) && dispatchData.advance_amount ?
                                        <>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Advance Amount</b> : {!window.cn(dispatchData.advance_amount) ? dispatchData.advance_amount : ""}</p>
                                            </div>
                                        </> : <>
                                        </>
                                    }
                                    {!window.cn(dispatchData.lr_image) && dispatchData.lr_image != null ?

                                        <div className="field col-6 flex flex-column">
                                            <p> <b>LR Image</b>
                                                <Button
                                                    icon="pi pi-eye"
                                                    className="p-button-rounded p-button-primary ml-3"
                                                    style={{ marginRight: '17px' }}
                                                    onClick={() => window.open(dispatchData.lr_image, '_blank')}
                                                /> </p>
                                        </div> : ""}
                                </div>
                                <hr />
                            </>
                            : !window.cn(dispatchData) && dispatchData.transport_type == "FULL LOAD" ?
                                <>
                                    <div className='formgrid grid'>
                                        <div className="field col-4 flex flex-column">
                                            <p> <b>LR No.</b> : {!window.cn(dispatchData.lr_no) ? dispatchData.lr_no : ""}</p>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <p> <b>Transport Name</b> : {!window.cn(dispatchData.transport_name) ? dispatchData.transport_name : ""}</p>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <p> <b>Vehicle No.</b> : {!window.cn(dispatchData.car_no) ? dispatchData.car_no : ""}</p>
                                        </div>
                                    </div>
                                    <div className='formgrid grid'>
                                        <div className="field col-4 flex flex-column">
                                            <p> <b>Driver Name</b> : {!window.cn(dispatchData.driver_name) ? dispatchData.driver_name : ""}</p>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <p> <b>Driver Mobile No.</b> : {!window.cn(dispatchData.driver_mobile) ? dispatchData.driver_mobile : ""}</p>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <p> <b>License No.</b> : {!window.cn(dispatchData.license_no) ? dispatchData.license_no : ""}</p>
                                        </div>
                                    </div>
                                    <div className='formgrid grid'>
                                        <div className="field col-4 flex flex-column">
                                            <p> <b>License State</b> : {!window.cn(dispatchData.license_state) ? dispatchData.license_state : ""}</p>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <p> <b>Payment PAID Or Not</b> : {!window.cn(dispatchData.payment_type) ? dispatchData.payment_type : ""}</p>
                                        </div>

                                        {!window.cn(dispatchData.payment_type) && dispatchData.payment_type == 'PAID' ?
                                            <>
                                                <div className="field col-4 flex flex-column">
                                                    <p> <b>Amount</b> : {!window.cn(dispatchData.amount) ? dispatchData.amount : ""}</p>
                                                </div>
                                            </> : <>
                                            </>
                                        }
                                    </div>

                                    {!window.cn(dispatchData.photo) && dispatchData.photo != null ?
                                        <div className='formgrid grid mt-2'>
                                            <div className="field col-6 flex flex-column">
                                                <div className="field col-12 md:col-6 editImage" style={{ display: "contents" }}>
                                                    <label htmlFor="photo">Driver Image</label>
                                                    <img src={dispatchData.photo}></img>
                                                </div>
                                            </div>
                                        </div> : ""}
                                    <hr />
                                </>

                                : !window.cn(dispatchData) && dispatchData.transport_type == "PRIVATE" ?
                                    <>
                                        <div className='formgrid grid'>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Transport Name</b> : {!window.cn(dispatchData.transport_name) ? dispatchData.transport_name : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Vehicle No.</b> : {!window.cn(dispatchData.car_no) ? dispatchData.car_no : ""}</p>
                                            </div>

                                            <div className="field col-4 flex flex-column">
                                                <p> <b>No. Of Bags</b> : {!window.cn(dispatchData.no_of_bags) ? dispatchData.no_of_bags : ""}</p>

                                            </div>
                                        </div>
                                        <div className='formgrid grid'>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Amount</b> : {!window.cn(dispatchData.amount) ? dispatchData.amount : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Driver Mobile No.</b> : {!window.cn(dispatchData.driver_mobile) ? dispatchData.driver_mobile : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>From Location</b> : {!window.cn(dispatchData.from_location) ? dispatchData.from_location : ""}</p>
                                            </div>
                                        </div>
                                        <div className='formgrid grid'>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>To Location</b> : {!window.cn(dispatchData.to_location) ? dispatchData.to_location : ""}</p>
                                            </div>
                                        </div>
                                        <hr />

                                    </>

                                    : !window.cn(dispatchData) && dispatchData.transport_type == "COURIER" ?
                                        <>
                                            <div className='formgrid grid'>
                                                <div className="field col-4 flex flex-column">
                                                    <p> <b>Tracking ID</b> : {!window.cn(dispatchData.tracking_id) ? dispatchData.tracking_id : ""}</p>
                                                </div>
                                                <div className="field col-4 flex flex-column">
                                                    <p> <b>Courier Name</b> : {!window.cn(dispatchData.courier_name) ? dispatchData.courier_name : ""}</p>
                                                </div>
                                            </div>
                                            <hr />
                                        </>

                                        : !window.cn(dispatchData) && dispatchData.transport_type == "PARCEL" ?
                                            <>
                                                <div className='formgrid grid'>
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>Tracking ID</b> : {!window.cn(dispatchData.tracking_id) ? dispatchData.tracking_id : ""}</p>
                                                    </div>
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>Courier Name</b> : {!window.cn(dispatchData.courier_name) ? dispatchData.courier_name : ""}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                            </>

                                            :
                                            ""
                    }
                </>
                {/* <div className="field col-12 md:col-12">
                    <div style={{ float: "right" }}>
                        <Button
                            label="Return Order"
                            icon="pi pi-replay"
                            onClick={() => returnOrder(dispatchData)}
                        />
                    </div>
                </div> */}
            </div>

        </div>

        {/* Loader Start */}
        {
            pageLoad && <Loader />
        }
        {/* Loader End */}


        {/* return order modal start*/}
        <Dialog
            visible={returnDialog}
            style={{ width: '450px' }}
            header="Return Order"
            modal
            footer={
                <>
                    <Button
                        label="Cancel"
                        icon="pi pi-times"
                        className="p-button-secondary"
                        onClick={hideReturnDialog}
                    />
                    <Button
                        label="Save"
                        icon="pi pi-check"
                        className="p-button-primary"
                        onClick={onSubmit}
                        loading={loading}
                    />
                </>
            }
            onHide={hideReturnDialog}
        >
            <DataTable
                className="datatable-responsive"
                value={dispatchRows}
                rows={defaultRowOptions}
                emptyMessage="No Dispatch Data Found"
            >
                {DisptachViewColumns.map((col, i) => {
                    if (col.field === 'sr_no') {
                        return (
                            <Column
                                key={col.field}
                                header={col.header}
                                body={(_, { rowIndex }) => rowIndex + 1}
                            />
                        );
                    } else if (col.field === 'action') {
                        return (
                            <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                body={actionBodyTemplate}
                            />
                        );
                    } else {
                        return (
                            <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                sortable
                            />
                        );
                    }
                })}
            </DataTable>
        </Dialog>
        {/* return order modal End*/}
    </>)
};