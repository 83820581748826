import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppTopbar from './layouts/AppTopbar';
import AppFooter from './layouts/AppFooter';
import AppBreadcrumb from './components/CustomeBreadcrumb/AppBreadcrumb';
import AppMenu from './layouts/AppMenu';
import Dashboard from './pages/dashboard/Dashboard';
import { Tooltip } from 'primereact/tooltip';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import { Product } from './pages/product/Product';
import { Process } from './pages/product/Process';
import { Packaging } from './pages/product/Packaging';
import FormsInput from './pages/FormInputs';
import { DeleteMultipleData } from './pages/product/DeleteMultipleData';
import Agent from './pages/agent/Agent';
import { LeaveManagement } from './pages/leaveManagement/LeaveManagement';
import { Attendance } from './pages/attendance/Attendance';
import { Dealer } from './pages/dealer/Dealer';
import { LeadDealer } from './pages/dealer/LeadDealer';
import { AddDealer } from './pages/dealer/AddDealer';
import { Farmer } from './pages/farmer/Farmer';
import { Expense } from './pages/expense/Expense';
import { Crops } from './pages/crops/crops';
import { Order } from './pages/order/Order';
import { Employee } from './pages/employee/Employee';
import { ChangePassword } from './pages/user-profile/ChangePassword';
import { AddCrop } from './pages/crops/AddCrop';
import { Dispatch } from './pages/order/Dispatch';
import { ProductDispatch } from './pages/product/ProductDispatch';
import { ViewDispatch } from './pages/product/ViewDispatch';
import { PackagingMaterial } from './pages/crops/PackagingMaterial';
import { Production } from './pages/product/Production';
import { AddProduction } from './pages/product/AddProduction';
import { ViewProduction } from './pages/product/ViewProduction';
import { FieldInspection } from './pages/product/FieldInspection';
import { SamplingReport } from './pages/report/SamplingReport';
import { InspectionReport } from './pages/report/InspectionReport';
import { CropWisePackagingMaterial } from './pages/crops/CropWisePackagingMaterial';
import { TestingReport } from './pages/report/TestingReport';
import { GrowerReport } from './pages/report/GrowerReport';
import { LotNoStock } from './pages/crops/LotNoWiseStock';
import { LoadingUnloadingReport } from './pages/report/LoadingUnloadingReport';
import { PackingMaterialReport } from './pages/report/PackingMaterialReport';
import { GlobalCrops } from './pages/crops/GlobalCrops';
import { AddGlobalCrop } from './pages/crops/AddGlobalCrop';
import { ViewFarmer } from './pages/farmer/ViewFarmer';
import { ViewDealer } from './pages/dealer/ViewDealer';
import { ViewExpense } from './pages/expense/ViewExpense';
import { ViewLeadDealer } from './pages/dealer/ViewLeadDealer';
import { Gtm } from './pages/order/Gtm';
import { OrderDispatch } from './pages/order/OrderDispatch';
import { Testing } from './pages/doctor/Testing';
import { AddGermination } from './pages/doctor/AddGermination';
import { AddGeneticPurity } from './pages/doctor/AddGeneticPurity';
import { AddFieldEmergence } from './pages/doctor/AddFieldEmergence';
import { LocationTracker } from './pages/location/LocationTracker';
import { ViewLocation } from './pages/location/ViewLocation';
import { ReturnOrder } from './pages/order/ReturnOrder';
import { Remenant } from './pages/product/Remenant';
import { RemenantDispatch } from './pages/product/RemenantDispatch';
import { OrderTransfer } from './pages/order/OrderTransfer';

const App = (props: any) => {
  const [menuMode, setMenuMode] = useState('sidebar');
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [sidebarStatic, setSidebarStatic] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] =
    useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [pinActive, setPinActive] = useState(false);
  const [activeInlineProfile, setActiveInlineProfile] = useState(false);
  const [resetActiveIndex, setResetActiveIndex] = useState<boolean>(false);
  const [assignedActions, setAssignedActions] = useState<any>([]);
  const [newMenu, setNewMenu] = useState<any>([]);
  const copyTooltipRef = useRef<any>();
  const location = useLocation();

  // use effect method
  useEffect(() => {
    let tempAssignedActions = localStorage.getItem('assigned_actions')?.split(',');
    setAssignedActions(tempAssignedActions);
    assignedMenu(tempAssignedActions);
  }, []);

  let menu = [
    {
      label: 'Dashboard',
      icon: 'pi pi-home',
      to: '/dashboard',
      status: "0"
    },
    {
      label: 'Users',
      icon: 'pi pi-users',
      items: [
        { label: 'Dealer', icon: 'pi pi-minus', to: '/user/dealer/list' },
        { label: 'Lead Dealer', icon: 'pi pi-minus', to: '/user/dealer/dealer-lead' },
        { label: 'Organizer', icon: 'pi pi-minus', to: '/user/agent' },
        // { label: 'Farmer', icon: 'pi pi-minus', to: '/user/farmer' },
        { label: 'Employee', icon: 'pi pi-minus', to: '/user/employee' },
      ],
    },
    {
      label: 'Manage Product',
      icon: 'pi pi-shopping-cart',
      items: [{ label: 'Production', icon: 'pi pi-minus', to: '/product/production' },
      { label: 'Raw Material', icon: 'pi pi-minus', to: '/product/raw-material' },
      { label: 'Testing', icon: 'pi pi-minus', to: '/product/testing' },
      { label: 'Processing', icon: 'pi pi-minus', to: '/product/processing' },
      { label: 'Packaging', icon: 'pi pi-minus', to: '/product/packaging' },
      { label: 'Dispatch', icon: 'pi pi-minus', to: '/product/dispatch' },
      { label: 'Seed Remenant', icon: 'pi pi-minus', to: '/product/remenant' },],
    },
    {
      label: 'Stock',
      icon: 'pi pi-chart-line',
      items: [
        { label: 'Global Crops', icon: 'pi pi-minus', to: '/stock/global-crop' },
        { label: 'Crop', icon: 'pi pi-minus', to: '/stock/list' },
        { label: 'Lot No Stock', icon: 'pi pi-minus', to: '/stock/lotnowise-stock' },
        { label: 'Packaging Material', icon: 'pi pi-minus', to: '/stock/packaging-material' },
        { label: 'Cropwise Packaging Material', icon: 'pi pi-minus', to: '/stock/cropwise-packaging-material' },
      ],
    },
    {
      label: 'Order',
      icon: 'pi pi-shopping-bag',
      to: '/order',
    },
    {
      label: 'Sales Transfer/Return',
      icon: 'pi pi-book',
      to: '/order-transfer',
    },
    
    {
      label: 'GTM',
      icon: 'pi pi-truck',
      to: '/gtm',
    },
    {
      label: 'Expense',
      icon: 'pi pi-wallet',
      to: '/expense',
    },
    {
      label: 'Attendance',
      icon: 'pi pi-calendar',
      to: '/attendance',
    },
    {
      label: 'Leave Management',
      icon: 'pi pi-file-export',
      to: '/leave-management',
    },
    {
      label: 'Location Tracker',
      icon: 'pi pi-map-marker',
      to: '/location-tracker',
    },
    {
      label: 'Reports',
      icon: 'pi pi-database',
      items: [{ label: 'Grower', icon: 'pi pi-minus', to: '/report/grower' },
      { label: 'Sampling', icon: 'pi pi-minus', to: '/report/sampling' },
      { label: 'Inspection', icon: 'pi pi-minus', to: '/report/inspection' },
      // { label: 'Testing', icon: 'pi pi-minus', to: '/report/testing'},
      { label: 'Loading/Unloading', icon: 'pi pi-minus', to: '/report/loading-unloading' },
      { label: 'Packing Material', icon: 'pi pi-minus', to: '/report/packing-material' },],
    }
  ];

  // Make roles menu
  const assignedMenu = (assignedActions: any) => {
    let newMenu = menu;
    //check for assigned roles
    for (let i = 0; i < assignedActions.length; i++) {
      for (let j = 0; j < newMenu.length; j++) {
        //check if inner roles exists
        if (newMenu[j].hasOwnProperty('items')) {
          let items: any = newMenu[j].items;
          //check for inner roles
          for (let k = 0; k < items.length; k++) {
            //if role is assigned change status to 1
            if (items[k].label == assignedActions[i]) {
              items[k].status = "1";
            }
          }
        } else {
          //if role is assigned change status to 1
          if (newMenu[j].label == assignedActions[i]) {
            newMenu[j]["status"] = "1";
          }
        }
      }
    }

    //Create new array for assisgned roles to display in sidebar
    let newAssignedRoles = [];
    for (let j = 0; j < newMenu.length; j++) {
      if (newMenu[j].hasOwnProperty('items')) {
        //check for inner roles
        let items: any = newMenu[j].items;
        for (let k = 0; k < items.length; k++) {
          if (items[k]["status"] != undefined) {
            //check if value exists in array of object or not
            let valueExists = newAssignedRoles.some((el: any) => el.label == newMenu[j]["label"]);
            //if value exists in array of object the push in items array
            if (valueExists) {
              for (let x = 0; x < newAssignedRoles.length; x++) {
                if (newAssignedRoles[x]["label"] == newMenu[j]["label"]) {
                  newAssignedRoles[x]["items"]?.push(items[k]);
                }
              }
            } else {
              //if value does not exusts in array object then push new object in array
              newAssignedRoles.push({
                "label": newMenu[j]["label"],
                "icon": newMenu[j]["icon"],
                "items": [items[k]]
              });
            }
          }
        }
      } else {
        if (newMenu[j]["status"] != undefined) {
          newAssignedRoles.push(newMenu[j]);
        }
      }
    }
    setAssignedActions(newAssignedRoles);
  };

  const routes = [
    { parent: 'Dashboard', label: 'Sales Dashboard' },
    { parent: 'Pages', label: 'Empty' },
    { parent: 'Pages', label: 'Access' },
    { parent: 'Pages', label: 'Multiple Delete' },
    { parent: 'Start', label: 'Documentation' },
    { parent: 'Form Layout ', label: 'Form Inputs' },
  ];

  let menuClick: any;

  let topbarItemClick: any;

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  useEffect(() => {
    setResetActiveIndex(true);
    setMenuActive(false);
  }, [menuMode]);

  const onDocumentClick = () => {
    if (!topbarItemClick) {
      setTopbarMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
        setResetActiveIndex(true);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
      }

      hideOverlayMenu();
      unblockBodyScroll();
    }

    topbarItemClick = false;
    menuClick = false;
  };

  const onMenuButtonClick = (event: any) => {
    menuClick = true;

    if (isOverlay()) {
      setOverlayMenuActive((prevState) => !prevState);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive((prevState) => !prevState);
    } else {
      setStaticMenuMobileActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const onTopbarItemClick = (event: any) => {
    topbarItemClick = true;
    setTopbarMenuActive((prevState) => !prevState);
    hideOverlayMenu();
    event.preventDefault();
  };

  const onToggleMenu = (event: any) => {
    menuClick = true;

    if (overlayMenuActive) {
      setOverlayMenuActive(false);
    }

    if (sidebarActive) {
      setSidebarStatic((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarMouseOver = () => {
    if (menuMode === 'sidebar' && !sidebarStatic) {
      setSidebarActive(isDesktop());
      setTimeout(() => {
        setPinActive(isDesktop());
      }, 200);
    }
  };

  const onSidebarMouseLeave = () => {
    if (menuMode === 'sidebar' && !sidebarStatic) {
      setTimeout(() => {
        setSidebarActive(false);
        setPinActive(false);
      }, 250);
    }
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onChangeActiveInlineMenu = (event: any) => {
    setActiveInlineProfile((prevState) => !prevState);
    event.preventDefault();
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevState) => !prevState);
  };

  const onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      hideOverlayMenu();
      setResetActiveIndex(true);
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const isHorizontal = () => {
    return menuMode === 'horizontal';
  };

  const isSlim = () => {
    return menuMode === 'slim';
  };

  const isOverlay = () => {
    return menuMode === 'overlay';
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  };

  const layoutClassName = classNames('layout-wrapper', {
    'layout-static': menuMode === 'static',
    'layout-overlay': menuMode === 'overlay',
    'layout-overlay-active': overlayMenuActive,
    'layout-slim': menuMode === 'slim',
    'layout-horizontal': menuMode === 'horizontal',
    'layout-active': menuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-sidebar': menuMode === 'sidebar',
    'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
    'layout-static-inactive':
      staticMenuDesktopInactive && menuMode === 'static',
  });

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />
      <div className="layout-main">
        <AppTopbar
          items={assignedActions}
          menuMode={menuMode}
          colorScheme={props.colorScheme}
          menuActive={menuActive}
          topbarMenuActive={topbarMenuActive}
          activeInlineProfile={activeInlineProfile}
          onTopbarItemClick={onTopbarItemClick}
          onMenuButtonClick={onMenuButtonClick}
          onSidebarMouseOver={onSidebarMouseOver}
          onSidebarMouseLeave={onSidebarMouseLeave}
          onToggleMenu={onToggleMenu}
          onChangeActiveInlineMenu={onChangeActiveInlineMenu}
          onMenuClick={onMenuClick}
          onMenuItemClick={onMenuItemClick}
          onRootMenuItemClick={onRootMenuItemClick}
          resetActiveIndex={resetActiveIndex}
        />

        <AppMenu
          changeColorScheme={props.onColorSchemeChange}
          model={assignedActions}
          onRootMenuItemClick={onRootMenuItemClick}
          onMenuItemClick={onMenuItemClick}
          onToggleMenu={onToggleMenu}
          onMenuClick={onMenuClick}
          menuMode={menuMode}
          colorScheme={props.colorScheme}
          menuActive={menuActive}
          sidebarActive={sidebarActive}
          sidebarStatic={sidebarStatic}
          pinActive={pinActive}
          onSidebarMouseLeave={onSidebarMouseLeave}
          onSidebarMouseOver={onSidebarMouseOver}
          activeInlineProfile={activeInlineProfile}
          onChangeActiveInlineMenu={onChangeActiveInlineMenu}
          resetActiveIndex={resetActiveIndex}
        />

        <AppBreadcrumb
          routes={routes}
          colorScheme={props.colorScheme}
          changeColorScheme={props.onColorSchemeChange}
          onMenuButtonClick={onMenuButtonClick}
          menuMode={menuMode}
        />

        <div className="layout-main-content">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/forminputs" element={<FormsInput />} />
            <Route path="/agent" element={<Agent />} />
            <Route path="/deletemultipledata" element={<DeleteMultipleData />} />

            <Route path="/attendance" element={<Attendance />} />
            <Route path="/leave-management" element={<LeaveManagement />} />

            <Route path="/order" element={<Order />} />
            <Route path="/dispatch/add-dispatch-details" element={<Dispatch />} />
            <Route path="/dispatch/view-dispatch-details" element={<ViewDispatch />} />
            <Route path="/dispatch/add-order-dispatch-details" element={<OrderDispatch />} />
            <Route path="/return-order" element={<ReturnOrder />} />
            <Route path="/order-transfer" element={<OrderTransfer />} />

            <Route path="/expense" element={<Expense />} />
            <Route path="/expense/view-expense" element={<ViewExpense />} />

            <Route path="/stock/global-crop" element={<GlobalCrops />} />
            <Route path="/stock/add-global-crop" element={<AddGlobalCrop />} />
            <Route path="/stock/list" element={<Crops />} />
            <Route path="/crop/add-crop" element={<AddCrop />} />
            <Route path="/stock/lotnowise-stock" element={<LotNoStock />} />
            <Route path="/stock/packaging-material" element={<PackagingMaterial />} />
            <Route path="/stock/cropwise-packaging-material" element={<CropWisePackagingMaterial />} />

            <Route path="/user/dealer/list" element={<Dealer />} />
            <Route path="/user/dealer/dealer-lead" element={<LeadDealer />} />
            <Route path="/user/dealer/add-dealer" element={<AddDealer />} />
            <Route path="/user/dealer/view-dealer" element={<ViewDealer />} />
            <Route path="/user/dealer/view-lead-dealer" element={<ViewLeadDealer />} />
            <Route path="/user/agent" element={<Agent />} />
            {/* <Route path="/user/farmer" element={<Farmer />} />
            <Route path="/user/farmer-view" element={<ViewFarmer />} /> */}
            <Route path="/user/employee" element={<Employee />} />

            <Route path="/product/production" element={<Production />} />
            <Route path="/product/add-production" element={<AddProduction />} />
            <Route path="/product/view-production-details" element={<ViewProduction />} />
            <Route path="/product/field-inspection" element={<FieldInspection />} />
            <Route path="/product/raw-material" element={<Product />} />
            <Route path="/product/processing" element={<Process />} />
            <Route path="/product/packaging" element={<Packaging />} />
            <Route path="/product/dispatch" element={<ProductDispatch />} />
            <Route path="/product/testing" element={<Testing />} />
            <Route path="/product/testing/add-germination" element={<AddGermination />} />
            <Route path="/product/testing/add-genetic-purity" element={<AddGeneticPurity />} />
            <Route path="/product/testing/add-field-emergence" element={<AddFieldEmergence />} />
            <Route path="/product/remenant" element={<Remenant />} />
            <Route path="/product/add-remenant-dispatch" element={<RemenantDispatch />} />

            <Route path="/report/grower" element={<GrowerReport />} />
            <Route path="/report/sampling" element={<SamplingReport />} />
            <Route path="/report/inspection" element={<InspectionReport />} />
            {/* <Route path="/report/testing" element={<TestingReport />} /> */}
            <Route path="/report/loading-unloading" element={<LoadingUnloadingReport />} />
            <Route path="/report/packing-material" element={<PackingMaterialReport />} />

            <Route path="/gtm" element={<Gtm />} />

            {/* User Profile Routes */}
            <Route path="/change-password" element={<ChangePassword />} />
            {/* <Route path="/forgot-password" element={<ChangePassword />} /> */}

            <Route path="/location-tracker" element={<LocationTracker />} />
            <Route path="/location-tracker/view-location" element={<ViewLocation />} />
            {/* User Profile Routes End */}

          </Routes>
        </div>

        <AppFooter colorScheme={props.colorScheme} />
      </div>
    </div>
  );
};

export default App;
