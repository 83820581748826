import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from 'primereact/checkbox';

//Services
import PageService from '../../service/PageService';

// Data table
import {TransportType, PaymentPAIDOrNot, DispatchType, SeedDispatchType, packingTypeDropdown, particularsDropdown, partLoadTranportNameDropdown } from '../../appconfig/Settings';

import { RadioButton } from 'primereact/radiobutton';

//File Uplaod Components
import { chooseOptions, emptyTemplate, headerTemplate,} from '../../components/ImageUploadComponent/ImageUploadSetting';

import { DispatchValidate } from '../../config/Validate';

export const Dispatch = () => {
    document.title = 'Dispatch | Swarnim Farms';

    // File Upload Details
    const fileUploadRef = useRef(null);
    const [image, setImage] = useState<any>(null);
    const [lrImage, setLrImage] = useState<any>(null);
    const removeFile = useRef(null);

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    //Product Crud Object
    const toast = useRef<any>(null);
    const [editId, setEditId] = useState<any>(null);
    const [dispatchData, setDispatchData] = useState<any>(null);
    const [uiUpdated, setIsUiUpdated] = useState<boolean>(false);
    const [seedUiUpdated, setIsSeedUiUpdated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dispatchType, setDispatchType] = useState<any>(null);
    const [bagType, setBagType] = useState<any>(null);
    const [seedDispatchType, setSeedDispatchType] = useState<any>(null);
    const [seedDispatchData, setSeedDispatchData] = useState<any>([{
        crop: '',
        variety: '',
        packing_size: '',
        no_of_bag: '',
        no_of_inner_bag: '',
        stage: '',
        amount: '',
        particulars: '',
        sampling_rate_per_inner_bag: '',
        sampling_rate_per_outer_bag: '',
        wax_qty: '',
        rate_per_wax: '',
        sutali: '',
        rate_per_sutali: ''
    }]);

    const [checkboxValue, setCheckboxValue] = useState<any>([]);
    const [partLoadTransportName, setPartTransportName] = useState<any>(null);
    const [fullLotArr, setFullLotArr] = useState<any>([]);

    // crop and variety
    const [cropData, setCropData] = useState<any>([]);
    const [dispatchMasterData, setDispatchMasterData] = useState<any>({
        dealer: "",
        bill_to: "",
        billing_address: "",
        gst_no: "",
        mobile: "",
        no_of_outer_bags: "",
        transport_name: "",
        car_no: "",
        driver_name: "",
        driver_mobile: "",
        license_no: "",
        license_state: "",
        payment_type: "",
        is_advance: "",
        amount: "",
        lr_no: "",
        date_of_supply: "",
        no_of_bags: "",
        from_location: "",
        to_location: "",
        tracking_id: "",
        courier_name: "",
        delivery_at: "",
        delivery_address: "",
        delivery_mobile: "",
    });
    const [varietyDropdown, setVarietyDropdown] = useState<any>([]);
    const [lotNoList, setLotNoList] = useState<any>([]);
    const [dealerDropdown, setDealerDropdown] = useState<any>([]);
    const [selectedDealer, setSelectedDealer] = useState<any>(null);
    const [weightDropdown, setWeightDropdown] = useState<any>([[]]);
    const [paymentType, setPaymentType] = useState<any>(null);
    const [packingSizeDropdown, setPackingSizeDropdown] = useState<any>([]);

    const [lotNoArr, setLotNoArr] = useState<any>([[{
        lot_no: '',
        packing_size: '',
        no_of_bag: '',
        class: ''
    }]])

    const pageService = new PageService();

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    // use effect method
    useEffect(() => {
        if (location.state) {
            const state = location.state;
            if (state != null) {
                setEditId(state);
                getDispatchDataByIDFromAPI(state);
            }
            getCropList();
            getDealerList();
        }
    }, []);

    useEffect(() => {
        if (uiUpdated) {
            setIsUiUpdated(false);
            getTransportUi();
        }
    }, [dispatchData, uiUpdated]);

    useEffect(() => {
        if (seedUiUpdated) {
            setIsSeedUiUpdated(false);
            getSeedDispatchArrayUI();
        }
    }, [seedUiUpdated, seedDispatchData]);

    const onCheckboxChange = (e: any) => {
        let selectedValue: any[] = [...checkboxValue];
        if (e.checked) selectedValue.push(e.value);
        else selectedValue.splice(selectedValue.indexOf(e.value), 1);
        if (selectedValue.indexOf('1') != -1) {
            // setDispatchMasterData({
            //     ...dispatchMasterData,
            //     'delivery_at': dispatchMasterData.bill_to,
            //     'delivery_address': dispatchMasterData.billing_address,
            //     'delivery_mobile': dispatchMasterData.mobile,
            // });
            let tempDispatchMasterDataObj = dispatchMasterData;
            tempDispatchMasterDataObj["delivery_at"] = dispatchMasterData.bill_to;
            tempDispatchMasterDataObj["delivery_address"] = dispatchMasterData.billing_address;
            tempDispatchMasterDataObj["delivery_mobile"] = dispatchMasterData.mobile;
            setDispatchMasterData(tempDispatchMasterDataObj);
        } else if (selectedValue.indexOf('1') == -1) {
            // setDispatchMasterData({
            //     ...dispatchMasterData,
            //     'delivery_at': '',
            //     'delivery_address': '',
            //     'delivery_mobile': '',
            // });
            let tempDispatchMasterDataObj = dispatchMasterData;
            tempDispatchMasterDataObj["delivery_at"] = "";
            tempDispatchMasterDataObj["delivery_address"] = "";
            tempDispatchMasterDataObj["delivery_mobile"] = "";
            setDispatchMasterData(tempDispatchMasterDataObj);
        }

        if (selectedValue.indexOf('3') != -1) {
            setDispatchData({ name: "PART LOAD", code: "PART LOAD" });
            setIsUiUpdated(true);
        } else if (selectedValue.indexOf('3') == -1) {
            setDispatchData(null);
            setIsUiUpdated(true);
        }

        if (selectedValue.indexOf('3') == -1) {
            setDispatchMasterData({
                ...dispatchMasterData,
                'local_transport_name': '',
                'local_from': '',
                'local_to': '',
                'local_no_of_bags': '',
                'local_weight_per_bags': '',
                'local_rate_per_bags': '',
                'local_dispatch_date': '',
                'local_driver_name': '',
                'local_driver_mobile_no': '',
            });
        }

        if (selectedValue.indexOf('4') == -1) {
            setDispatchMasterData({
                ...dispatchMasterData,
                'warehouse_amount': '',
                'warehouse_transport_name': '',
                'warehouse_charges_per_bag': '',
                'warehouse_no_of_bags': '',
            });
        }
        setCheckboxValue(selectedValue);
        setIsUiUpdated(true);
    };

    // Get Dispatch Data from API
    const getDispatchDataByIDFromAPI = async (state: any) => {
        if (!window.cn(state) && state.id != null) {
            // call api
            pageService.getDispatchDataById(state.id).then((response) => {
                // get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setDispatchMasterData({ ...dispatchMasterData });
                    } else {
                        var date = null;
                        if (DataList.date_of_supply != null) {
                            date = new Date(DataList.date_of_supply);
                        }
                        var Payment: any = {
                            name: DataList.payment_type,
                            code: DataList.payment_type,
                        }
                        setPaymentType(Payment);

                        var transportName: any = {
                            name: DataList.transport_name,
                            code: DataList.transport_name,
                        }

                        setPartTransportName(transportName);

                        setDispatchMasterData({
                            ...dispatchMasterData,
                            date_of_supply: date,
                            other_transport_name: DataList.other_transport_name,
                            is_advance: DataList.is_advance,
                            advance_amount: DataList.advance_amount,
                            amount: DataList.amount,
                        });
                        setIsUiUpdated(true);
                    }
                }
            });
        }
    };

    // Get crops
    const getCropList = async () => {
        try {
            const cropData: any = [];

            pageService.cropList().then((response) => {
                if (response) {
                    const cropDataList = response;
                    for (let i = 0; i < cropDataList.length; i++) {
                        const userObj: any = {};
                        userObj['name'] = cropDataList[i].name;
                        userObj['code'] = cropDataList[i].id;
                        cropData.push(userObj);
                    }
                    setCropData(cropData);
                }
            });

        } catch (error) { }
    };

    //delete dispatch detail
    const deleteSeedDispatchData = (index: number) => {
        seedDispatchData.splice(index, 1);
        lotNoArr.splice(index, 1);
        setIsUiUpdated(true);
    }

    const getVarietyList = async (id: any, index: number) => {

        pageService.varietyList(id.code).then((response) => {
            // get response
            if (response) {
                const DataList = response;
                if (DataList.length == 0) {
                    setVarietyDropdown([]);
                } else {
                    if (response) {
                        const tempVarietyArr = response;
                        const varietyData: any = [];
                        for (let i = 0; i < tempVarietyArr.length; i++) {
                            const varietyObj: any = {};
                            varietyObj['name'] = tempVarietyArr[i].name;
                            varietyObj['code'] = tempVarietyArr[i].id;
                            varietyData.push(varietyObj);
                        }

                        if (varietyDropdown[index] != undefined) {
                            varietyDropdown.splice(index, 1, varietyData);
                        } else {
                            varietyDropdown.splice(index, 0, varietyData);
                        }
                        setIsSeedUiUpdated(true);
                    }
                }

            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });

    }

    const getLotNoList = async (crop_id: any, variety_id: any, index: number) => {

        pageService.getLotNoList(crop_id.code, variety_id.code).then((response) => {
            // get response
            if (response) {
                
                const DataList = response;
                if (DataList.length == 0) {
                    setLotNoList([]);
                } else {
                    if (DataList) {
                        const tempArr = DataList;
                        const lotNoData: any = [];
                        for (let i = 0; i < tempArr.length; i++) {
                            const lotNoObj: any = {};
                            lotNoObj['name'] = tempArr[i].lot_no;
                            lotNoObj['code'] = tempArr[i].id;
                            lotNoData.push(lotNoObj);
                        }

                        if (lotNoList[index] != undefined) {
                            lotNoList.splice(index, 1, lotNoData);
                        } else {
                            lotNoList.splice(index, 0, lotNoData);
                        }
                        setFullLotArr(DataList);
                    }
                }
                setIsSeedUiUpdated(true);
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    }

    const getPackingSizeList = async (id: any, i: number, index: number) => {

        pageService.getLotNoWisePackingSizeList(id.code).then((response) => {
            // get response
            if (response) {
                const DataList = response;
                if (DataList.length == 0) {
                    setWeightDropdown([[]]);
                } else {
                    if (response) {
                        const DataList = response;
                        let tempWeightArray: any = [];
                        for (let i = 0; i < DataList.length; i++) {
                            let weightObj: any = {};
                            weightObj['name'] = DataList[i].packing_size;
                            weightObj['code'] = DataList[i].packing_size;
                            tempWeightArray.push(weightObj);
                        }
                        if (weightDropdown[i][index] != undefined) {
                            weightDropdown[i].splice(index, 1, tempWeightArray);
                        } else {
                            weightDropdown[i].splice(index, 0, tempWeightArray);
                        }
                        setIsSeedUiUpdated(true);
                    }
                }
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    }

    const getWeightList = async (id: any, index: number) => {

        pageService.getWeightListByVariety(id.code).then((response) => {
            // get response
            if (response) {
                const DataList = response;
                if (DataList.length == 0) {
                    setPackingSizeDropdown([]);
                } else {
                    if (response) {
                        const DataList = response;
                        let tempWeightArray: any = [];
                        for (let i = 0; i < DataList.length; i++) {
                            let weightObj: any = {};
                            weightObj['name'] = DataList[i].name;
                            weightObj['code'] = DataList[i].name;
                            tempWeightArray.push(weightObj);
                        }

                        if (packingSizeDropdown[index] != undefined) {
                            packingSizeDropdown.splice(index, 1, tempWeightArray);
                        } else {
                            packingSizeDropdown.splice(index, 0, tempWeightArray);
                        }
                        setIsSeedUiUpdated(true);
                    }
                }
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    }

    // dropdown handle change crop
    const handleCropDropdown = (id: any, index: number) => {
        let tempArray = seedDispatchData;
        if (tempArray[index] == undefined) {
            tempArray.push({
                crop: id,
                variety: '',
                packing_size: '',
                no_of_bag: '',
                no_of_inner_bag: '',
                stage: '',
                amount: '',
                particulars: '',
                sampling_rate_per_inner_bag: '',
                sampling_rate_per_outer_bag: '',
                wax_qty: '',
                rate_per_wax: '',
                sutali: '',
                rate_per_sutali: ''
            });
        } else {
            tempArray[index]["crop"] = id;
        }
        setSeedDispatchData(tempArray);
        getVarietyList(id, index);
        setIsSeedUiUpdated(true);
    };

    // dropdown handle change variety
    const handleVarietyDropdown = (id: any, index: number) => {
        let tempArray = seedDispatchData;
        if (tempArray[index] == undefined) {
            tempArray.push({
                crop: seedDispatchData[index]["crop"],
                variety: id,
                packing_size: '',
                no_of_inner_bag: '',
                stage: '',
                amount: '',
                particulars: '',
                sampling_rate_per_inner_bag: '',
                sampling_rate_per_outer_bag: '',
                wax_qty: '',
                rate_per_wax: '',
                sutali: '',
                rate_per_sutali: ''
            });
        } else {
            tempArray[index]["variety"] = id;
        }
        setSeedDispatchData(tempArray);
        getLotNoList(seedDispatchData[index]["crop"], id, index);
        getWeightList(id, index);
        setIsSeedUiUpdated(true);
    };

    // dropdown handle change lotno
    const handleLotNo = (id: any, i: number, index: number) => {
        let tempArray = lotNoArr;
        if (tempArray[i][index] == undefined) {
            tempArray.push({
                lot_no: id,
                no_of_bag: '',
                packing_size: '',
                class: ''
            });
        } else {
            tempArray[i][index]["lot_no"] = id;
            let classArr = fullLotArr.filter((el: any) => el.lot_no == id.name);
            tempArray[i][index]["class"] = classArr[0]["raw_material"]["crop_division"];
        }
        setLotNoArr(tempArray);
        setIsSeedUiUpdated(true);
        getPackingSizeList(id, i, index);
    };

    // dropdown handle change lotno
    const handlePackingSize = (id: any, i: number, index: number) => {
        let tempArray = lotNoArr;
        tempArray[i][index]["packing_size"] = id;
        setLotNoArr(tempArray);
        setIsSeedUiUpdated(true);
    };

    //delete dispatch detail
    const DeleteLotNoObj = (i: number, index: number) => {
        lotNoArr[i].splice(index, 1);
        setIsUiUpdated(true);
    }

    const onHandleChangeBag = (e: any, i: number, index: number, name: string) => {
        let tempArray = lotNoArr;
        tempArray[i][index][name] = e.target.value.toUpperCase();
        setLotNoArr(tempArray);
        setIsSeedUiUpdated(true);
    }

    // dropdown handle change 
    const handleDropdown = (id: any, index: number, name: any) => {
        let tempArray = seedDispatchData;
        tempArray[index][name] = id;
        setSeedDispatchData(tempArray);
        setIsSeedUiUpdated(true);
    };

    const onHandleChangeDealer = (e: any) => {
        setSelectedDealer(e);
        pageService.getDealerDataById(e.code).then((result) => {

            setDispatchMasterData({
                ...dispatchMasterData,
                'bill_to': result.firm_name,
                'billing_address': result.business_address,
                'gst_no': result.gst_no,
                'mobile': result.mobile
            });
            setIsUiUpdated(true);
        });
    }

    //handle change transportation type dropdown
    const handleTransportationType = (value: any) => {

        setDispatchData(value);
        setDispatchMasterData({
            ...dispatchMasterData,
            transport_name: "",
            car_no: "",
            driver_name: "",
            driver_mobile: "",
            license_no: "",
            license_state: "",
            payment_type: "",
            is_advance: "",
            amount: "",
            lr_no: "",
            date_of_supply: "",
            no_of_bags: "",
            from_location: "",
            to_location: "",
            tracking_id: "",
            courier_name: ""
        });
        setIsUiUpdated(true);
    }

    //handle change dispatch Master details
    const onHandleChangeDispatchMasterDetails = (e: any, name: string) => {
        let val;
        if (name == 'date_of_supply' || name == 'is_advance' || name == 'local_dispatch_date') {
            val = e || '';
        } else {
            val = (e.target && e.target.value) || '';
            val = val.toUpperCase();
        }
        setDispatchMasterData({ ...dispatchMasterData, [name]: val });
        setIsUiUpdated(true);

    }

    //handle change dispatch Master details
    const onHandleChangePatLoadTransportName = (e: any) => {
        setPartTransportName(e);

        setDispatchMasterData({ ...dispatchMasterData, other_transport_name: '' });
        setIsUiUpdated(true);
    }

    const onHandleChangeSeedDispatchText = (e: any, index: number, name: string) => {
        let tempArray = seedDispatchData;
        tempArray[index][name] = e.target.value.toUpperCase();
        setSeedDispatchData(tempArray);
        setIsSeedUiUpdated(true);
    }

    //handle add more
    const handleAddMoreSeedDispatchDeta = () => {
        setSeedDispatchData([...seedDispatchData, {
            crop: '',
            variety: '',
            packing_size: '',
            no_of_bag: '',
            no_of_inner_bag: '',
            stage: '',
            amount: '',
            particulars: '',
            sampling_rate_per_inner_bag: '',
            sampling_rate_per_outer_bag: '',
            wax_qty: '',
            rate_per_wax: '',
            sutali: '',
            rate_per_sutali: ''
        }]);
        setLotNoArr([...lotNoArr, [{
            lot_no: '',
            packing_size: '',
            no_of_bag: '',
            class: ''
        }]]);
        setWeightDropdown([...weightDropdown, []])
    }

    //handle add more
    const handleLotNoAddMore = (i: number) => {
        let tempLotNoArr = lotNoArr;
        tempLotNoArr[i].push({
            lot_no: '',
            packing_size: '',
            no_of_bag: '',
            class: ''
        });
        setLotNoArr(tempLotNoArr);
        setIsSeedUiUpdated(true);
    }

    // Get dealer Data from API
    const getDealerList = async () => {
        pageService.dealerList().then((response) => {
            // get response
            if (response) {
                const DataList = response;
                if (DataList.length == 0) {
                    setDealerDropdown([]);
                } else {
                    if (response) {
                        const DataList = response;
                        let tempUserArr: any = [];
                        for (let i = 0; i < DataList.length; i++) {
                            let userObj: any = {};
                            userObj['name'] = DataList[i].firm_name;
                            userObj['code'] = DataList[i].id;
                            tempUserArr.push(userObj);
                        }
                        setDealerDropdown(tempUserArr);
                    }
                }
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    }

    const clearData = async () => {
        setSeedDispatchData([{
            crop: '',
            variety: '',
            packing_size: '',
            no_of_bag: '',
            no_of_inner_bag: '',
            stage: '',
            amount: '',
            particulars: '',
            sampling_rate_per_inner_bag: '',
            sampling_rate_per_outer_bag: '',
            wax_qty: '',
            rate_per_wax: '',
            sutali: '',
            rate_per_sutali: ''
        }]);
        setDispatchType({});
        setSelectedDealer({});
        setSeedDispatchType({});
        setVarietyDropdown([]);
        setWeightDropdown([]);
        setPackingSizeDropdown([]);
        setLotNoList([]);
        setEditId(null);
    }

    // Template for image upload
    const itemImageTemplate = (file: any, props: any) => {
        setImage(file);
        removeFile.current = props.onRemove;
        return (
            <>
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        <img
                            alt={file.name}
                            role="presentation"
                            src={file.objectURL}
                            width={100}
                        />
                        <span className="flex flex-column text-left ml-3">{file.name}</span>
                        <Button
                            type="button"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                            onClick={() => onTemplateRemove(props.onRemove)}
                        />
                    </div>
                </div>
            </>
        );
    };

    // remove image template
    const onTemplateRemove = (callback: any) => {
        setImage(null);
        callback();
    };

    // Template for image upload
    const lrImageTemplate = (file: any, props: any) => {
        setLrImage(file);
        removeFile.current = props.onRemove;
        return (
            <>
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        <img
                            alt={file.name}
                            role="presentation"
                            src={file.objectURL}
                            width={100}
                        />
                        <span className="flex flex-column text-left ml-3">{file.name}</span>
                        <Button
                            type="button"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                            onClick={() => onLrImageTemplateRemove(props.onRemove)}
                        />
                    </div>
                </div>
            </>
        );
    };

    // remove image template
    const onLrImageTemplateRemove = (callback: any) => {
        setLrImage(null);
        callback();
    };

    const getTransportUi = () => {
        return (
            <>
                <div className="formgrid grid dispatch">
                    <div className="field col-4 flex flex-column">
                        <label htmlFor="dispatch_type">Select Transport Dispatch Type *</label>
                        <Dropdown
                            filter
                            value={!window.cn(dispatchData) ? dispatchData : ""}
                            onChange={(e) => handleTransportationType(e.value)}
                            options={TransportType}
                            optionLabel="name"
                            placeholder="Select One"
                            name="transport_type"
                            className='dispatch-drp'
                        ></Dropdown>
                        <small className="p-invalid-txt">{errors['transport_type']}</small>
                    </div>
                </div>

                {
                    !window.cn(dispatchData) && dispatchData.code == "PART LOAD" ?
                        <>
                            <div className="formgrid grid">
                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Lr. No.</label>
                                    <InputText
                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.lr_no : ""}
                                        name="lr_no"
                                        autoComplete="off"
                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'lr_no')}
                                    />
                                </div>

                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="date_of_supply">Date Of Supply</label>
                                    <Calendar
                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.date_of_supply : ""}
                                        dateFormat="dd/mm/yy"
                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, 'date_of_supply')}
                                        name="date_of_supply"
                                        showIcon
                                    />
                                </div>
                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="transport_name">Transport Name *</label>
                                    <Dropdown
                                        filter
                                        value={partLoadTransportName}
                                        onChange={(e) => onHandleChangePatLoadTransportName(e.value)}
                                        options={partLoadTranportNameDropdown}
                                        optionLabel="name"
                                        placeholder="Select One"
                                        name="transport_name"
                                    ></Dropdown>
                                    <small className="p-invalid-txt">{errors['transport_name']}</small>
                                </div>
                                {!window.cn(partLoadTransportName) && partLoadTransportName.code === 'Other' ?
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="other_transport_name">Other</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.other_transport_name : ""}
                                            name="other_transport_name"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'other_transport_name')}
                                        />
                                    </div> : ""}

                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Payment PAID Or Not</label>
                                    <Dropdown
                                        id="payment_type"
                                        value={paymentType}
                                        onChange={(e) => setPaymentType(e.value)}
                                        options={PaymentPAIDOrNot}
                                        optionLabel="name"
                                        placeholder="Select One"
                                        name="payment_type"
                                    ></Dropdown>
                                </div>

                                <div className="field col-4">
                                    <div className="col-12 mb-3">
                                        <label htmlFor="gtm">Advance Payment</label>
                                    </div>
                                    <div className='grid'>
                                        <div className="col-12 md:col-4 lg:col-4">
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="option1" name="option" value="Yes" checked={dispatchMasterData.is_advance === 'Yes'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                <label htmlFor="option1">Yes</label>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4">
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="option2" name="option" value="No" checked={dispatchMasterData.is_advance === 'No'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                <label htmlFor="option2">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!window.cn(dispatchMasterData) && dispatchMasterData.is_advance === 'Yes' ?
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="advance_amount">Advance Amount</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.advance_amount : ""}
                                            name="advance_amount"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'advance_amount')}
                                        />
                                    </div> : ""}

                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Amount</label>
                                    <InputText
                                        keyfilter="num"
                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.amount : ""}
                                        name="amount"
                                        autoComplete="off"
                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'amount')}
                                    />
                                </div>
                            </div>
                            <div className='formgrid grid'>
                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Lr. Image</label>
                                    <FileUpload
                                        ref={fileUploadRef}
                                        accept="image/*"
                                        name="lr_image[]"
                                        className="imageupload"
                                        chooseOptions={chooseOptions}
                                        emptyTemplate={emptyTemplate}
                                        headerTemplate={headerTemplate}
                                        itemTemplate={lrImageTemplate}
                                    ></FileUpload>
                                    <small className="p-invalid-txt">{errors['lr_image']}</small>
                                </div>
                            </div>
                        </>
                        : !window.cn(dispatchData) && dispatchData.code == "FULL LOAD" ?
                            <>
                                <div className='formgrid grid'>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Lr. No. *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.lr_no : ""}
                                            name="lr_no"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'lr_no')}
                                            className={errors['lr_no'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['lr_no']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Transport Name *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchData.transport_name : ""}
                                            name="transport_name"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'transport_name')}
                                            className={errors['transport_name'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['transport_name']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Vehicle No. *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.car_no : ""}
                                            name="car_no"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'car_no')}
                                            className={errors['car_no'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['car_no']}</small>
                                    </div>
                                </div>
                                <div className='formgrid grid'>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Driver Name *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.driver_name : ""}
                                            name="driver_name"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'driver_name')}
                                            className={errors['driver_name'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['driver_name']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Driver Mobile No. *</label>
                                        <InputText
                                             keyfilter="num"
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.driver_mobile : ""}
                                            name="driver_mobile"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'driver_mobile')}
                                            className={errors['driver_mobile'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['driver_mobile']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">License No. *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.license_no : ""}
                                            name="license_no"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'license_no')}
                                            className={errors['license_no'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['license_no']}</small>
                                    </div>
                                </div>
                                <div className='formgrid grid'>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">License Issuing State *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.license_state : ""}
                                            name="license_state"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'license_state')}
                                            className={errors['license_state'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['license_state']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Payment PAID Or Not *</label>
                                        <Dropdown
                                            id="payment_type"
                                            filter
                                            value={paymentType}
                                            onChange={(e) => setPaymentType(e.value)}
                                            options={PaymentPAIDOrNot}
                                            optionLabel="name"
                                            placeholder="Select One"
                                            name="payment_type"
                                            className={errors['payment_type'] && 'p-invalid'}
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['payment_type']}</small>
                                    </div>

                                    <div className="field col-4">
                                        <div className="col-12 mb-3">
                                            <label htmlFor="is_advance">Advance Payment</label>
                                        </div>
                                        <div className='grid'>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <div className="field-radiobutton">
                                                    <RadioButton inputId="option1" name="option" value="Yes" checked={dispatchMasterData.is_advance === 'Yes'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                    <label htmlFor="option1">Yes</label>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <div className="field-radiobutton">
                                                    <RadioButton inputId="option2" name="option" value="No" checked={dispatchMasterData.is_advance === 'No'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                    <label htmlFor="option2">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <small className="p-invalid-txt">{errors['is_advance']}</small>
                                    </div>

                                    {!window.cn(dispatchMasterData) && dispatchMasterData.is_advance === 'Yes' ?
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="advance_amount">Advance Amount</label>
                                            <InputText
                                                keyfilter="num"
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.advance_amount : ""}
                                                name="advance_amount"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'advance_amount')}
                                            />
                                            <small className="p-invalid-txt">{errors['advance_amount']}</small>
                                        </div> : ""}

                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Amount *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.amount : ""}
                                            name="amount"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'amount')}
                                            className={errors['amount'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['amount']}</small>
                                    </div>
                                </div>
                                <div className='formgrid grid'>
                                    <div className="field col-6 flex flex-column">
                                        <label htmlFor="photo">Driver Image</label>
                                        <FileUpload
                                            ref={fileUploadRef}
                                            accept="image/*"
                                            name="photo[]"
                                            className="imageupload"
                                            chooseOptions={chooseOptions}
                                            emptyTemplate={emptyTemplate}
                                            headerTemplate={headerTemplate}
                                            itemTemplate={itemImageTemplate}
                                        ></FileUpload>
                                        <small className="p-invalid-txt">{errors['photo']}</small>
                                    </div>
                                    <div className="field col-6 flex flex-column">
                                        <label htmlFor="name">Lr. Image *</label>
                                        <FileUpload
                                            ref={fileUploadRef}
                                            accept="image/*"
                                            name="lr_image[]"
                                            className="imageupload"
                                            chooseOptions={chooseOptions}
                                            emptyTemplate={emptyTemplate}
                                            headerTemplate={headerTemplate}
                                            itemTemplate={lrImageTemplate}
                                        ></FileUpload>
                                        <small className="p-invalid-txt">{errors['lr_image']}</small>
                                    </div>

                                </div>
                            </>
                            : !window.cn(dispatchData) && dispatchData.code == "PRIVATE" ?
                                <>
                                    <div className='formgrid grid'>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">Transport Name *</label>
                                            <InputText
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.transport_name : ""}
                                                name="transport_name"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'transport_name')}
                                                className={errors['transport_name'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['transport_name']}</small>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">Vehicle No. *</label>
                                            <InputText
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.car_no : ""}
                                                name="car_no"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'car_no')}
                                                className={errors['car_no'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['car_no']}</small>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">No. of Bags *</label>
                                            <InputText
                                                keyfilter="num"
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.no_of_bags : ""}
                                                name="no_of_bags"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'no_of_bags')}
                                                className={errors['no_of_bags'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['no_of_bags']}</small>
                                        </div>
                                    </div>
                                    <div className='formgrid grid'>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">Driver Mobile No. *</label>
                                            <InputText
                                                 keyfilter="num"
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.driver_mobile : ""}
                                                name="driver_mobile"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'driver_mobile')}
                                                className={errors['driver_mobile'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['driver_mobile']}</small>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">From Location *</label>
                                            <InputText
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.from_location : ""}
                                                name="from_location"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'from_location')}
                                                className={errors['from_location'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['from_location']}</small>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">To Location *</label>
                                            <InputText
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.to_location : ""}
                                                name="to_location"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'to_location')}
                                                className={errors['to_location'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['to_location']}</small>
                                        </div>
                                    </div>
                                    <div className="formgrid grid ">
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">Payment PAID Or Not *</label>
                                            <Dropdown
                                                id="payment_type"
                                                filter
                                                value={paymentType}
                                                onChange={(e) => setPaymentType(e.value)}
                                                options={PaymentPAIDOrNot}
                                                optionLabel="name"
                                                placeholder="Select One"
                                                name="payment_type"
                                                className={errors['payment_type'] && 'p-invalid'}
                                            ></Dropdown>
                                            <small className="p-invalid-txt">{errors['payment_type']}</small>
                                        </div>

                                        <div className="field col-4">
                                            <div className="col-12 mb-3">
                                                <label htmlFor="gtm">Advance Payment</label>
                                            </div>
                                            <div className='grid'>
                                                <div className="col-12 md:col-4 lg:col-4">
                                                    <div className="field-radiobutton">
                                                        <RadioButton inputId="option1" name="option" value="Yes" checked={dispatchMasterData.is_advance === 'Yes'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                        <label htmlFor="option1">Yes</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-4 lg:col-4">
                                                    <div className="field-radiobutton">
                                                        <RadioButton inputId="option2" name="option" value="No" checked={dispatchMasterData.is_advance === 'No'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                        <label htmlFor="option2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <small className="p-invalid-txt">{errors['is_advance']}</small>
                                        </div>

                                        {!window.cn(dispatchMasterData) && dispatchMasterData.is_advance === 'Yes' ?
                                            <div className="field col-4 flex flex-column">
                                                <label htmlFor="advance_amount">Advance Amount</label>
                                                <InputText
                                                    keyfilter="num"
                                                    value={!window.cn(dispatchMasterData) ? dispatchMasterData.advance_amount : ""}
                                                    name="advance_amount"
                                                    autoComplete="off"
                                                    onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'advance_amount')}
                                                />
                                                <small className="p-invalid-txt">{errors['advance_amount']}</small>
                                            </div> : ""}

                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">Amount *</label>
                                            <InputText
                                                keyfilter="num"
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.amount : ""}
                                                name="amount"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'amount')}
                                                className={errors['amount'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['amount']}</small>
                                        </div>
                                    </div>
                                </>
                                : !window.cn(dispatchData) && dispatchData.code == "COURIER" ?
                                    <>
                                        <div className='formgrid grid'>
                                            <div className="field col-4 flex flex-column">
                                                <label htmlFor="name">Tracking ID *</label>
                                                <InputText
                                                    value={!window.cn(dispatchMasterData) ? dispatchMasterData.tracking_id : ""}
                                                    name="tracking_id"
                                                    autoComplete="off"
                                                    onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'tracking_id')}
                                                    className={errors['tracking_id'] && 'p-invalid'}
                                                />
                                                <small className="p-invalid-txt">{errors['tracking_id']}</small>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <label htmlFor="name">Courier Name *</label>
                                                <InputText
                                                    value={!window.cn(dispatchMasterData) ? dispatchMasterData.courier_name : ""}
                                                    name="courier_name"
                                                    autoComplete="off"
                                                    onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'courier_name')}
                                                    className={errors['courier_name'] && 'p-invalid'}
                                                />
                                                <small className="p-invalid-txt">{errors['courier_name']}</small>
                                            </div>
                                        </div>
                                    </>
                                    : !window.cn(dispatchData) && dispatchData.code == "PARCEL" ?
                                        <>
                                            <div className='formgrid grid'>
                                                <div className="field col-4 flex flex-column">
                                                    <label htmlFor="name">Tracking ID *</label>
                                                    <InputText
                                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.tracking_id : ""}
                                                        name="tracking_id"
                                                        autoComplete="off"
                                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'tracking_id')}
                                                        className={errors['tracking_id'] && 'p-invalid'}
                                                    />
                                                    <small className="p-invalid-txt">{errors['tracking_id']}</small>
                                                </div>
                                                <div className="field col-4 flex flex-column">
                                                    <label htmlFor="name">Courier Name *</label>
                                                    <InputText
                                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.courier_name : ""}
                                                        name="courier_name"
                                                        autoComplete="off"
                                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'courier_name')}
                                                        className={errors['courier_name'] && 'p-invalid'}
                                                    />
                                                    <small className="p-invalid-txt">{errors['courier_name']}</small>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        ""
                }
            </>
        );
    };

    const lotNoArrUi = (i: number) => {
        return (
            <>
                {!window.cn(lotNoArr) && lotNoArr[i].map((item: any, index: number) => (
                    <><div className="field col-12 md:col-4">
                        <label htmlFor="lot_no">Lot No. *</label>
                        <Dropdown
                            filter
                            value={lotNoArr[i][index]["lot_no"]}
                            defaultValue={lotNoArr[i][index]["lot_no"]}
                            onChange={(e) => handleLotNo(e.value, i, index)}
                            options={lotNoList[i]}
                            optionLabel="name"
                            name="lot_no"
                            placeholder="Select Lot No"
                        ></Dropdown>
                    </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="packing_size">Packing Size *</label>
                            <Dropdown
                                value={lotNoArr[i][index]["packing_size"]}
                                defaultValue={lotNoArr[i][index]["packing_size"]}
                                onChange={(e) => handlePackingSize(e.value, i, index)}
                                options={weightDropdown[i][index]}
                                optionLabel="name"
                                name="packing_size"
                                placeholder="Select Packing Size"
                            ></Dropdown>
                        </div>
                        { !window.cn(dispatchType) && dispatchType.code == 'SEED DISPATCH' ?
                        <div className="field col-12 md:col-2">
                            <label htmlFor="no_of_bag">No Of Bag *</label>
                            <InputText
                                keyfilter="num"
                                value={!window.cn(lotNoArr[i][index]["no_of_bag"]) ? lotNoArr[i][index]["no_of_bag"] : ""}
                                defaultValue={!window.cn(lotNoArr[i][index]["no_of_bag"]) ? lotNoArr[i][index]["no_of_bag"] : ""}
                                name="no_of_bag"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeBag(e, i, index, "no_of_bag")}
                            />
                        </div>: <div className="field col-12 md:col-2">
                            <label htmlFor="no_of_inner_bag">No Of Packets *</label>
                            <InputText
                                keyfilter="num"
                                value={!window.cn(lotNoArr[i][index]["no_of_inner_bag"]) ? lotNoArr[i][index]["no_of_inner_bag"] : ""}
                                defaultValue={!window.cn(lotNoArr[i][index]["no_of_inner_bag"]) ? lotNoArr[i][index]["no_of_inner_bag"] : ""}
                                name="no_of_inner_bag"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeBag(e, i, index, "no_of_inner_bag")}
                            />
                        </div> }
                        <div className="field col-4 flex flex-column">
                            <label htmlFor="stage">Class</label>
                            <InputText
                                value={!window.cn(lotNoArr[i][index]["class"]) ? lotNoArr[i][index]["class"] : ""}
                                defaultValue={!window.cn(lotNoArr[i][index]["class"]) ? lotNoArr[i][index]["class"] : ""}
                                name="stage"
                                disabled
                            />
                        </div>

                        {index > 0 ?
                            <div className="field col-12 md:col-1">
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger mt-4 "
                                    onClick={() => DeleteLotNoObj(i, index)}
                                />
                            </div> : ""}
                    </>
                ))}
                <div className="field col-12 md:col-1">
                    <Button
                        icon="pi pi-plus"
                        className="p-button-rounded mt-4"
                        onClick={() => handleLotNoAddMore(i)}
                    />
                </div>
            </>
        )
    }

    const getSeedDispatchArrayUI = () => {
        return (
            <>
                {!window.cn(dispatchType) && dispatchType.code == 'SEED DISPATCH' || !window.cn(dispatchType) && dispatchType.code == 'LOOSE PACKET'?
                    <>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="seed_dispatch_type">Seed Dispatch Type *</label>
                            <Dropdown
                                id="seed_dispatch_type"
                                filter
                                value={seedDispatchType}
                                onChange={(e) => setSeedDispatchType(e.value)}
                                options={SeedDispatchType}
                                optionLabel="name"
                                placeholder="Select One"
                                name="seed_dispatch_type"
                                className='dispatch-drp'
                            ></Dropdown>
                            <small className="p-invalid-txt">{errors['seed_dispatch_type']}</small>
                        </div>

                        {!window.cn(seedDispatchType) && seedDispatchType.code == 'DEALER' ?
                            <>
                                <div className="field col-12 md:col-4">
                                    <label htmlFor="dealer">Select Dealer *</label>
                                    <Dropdown
                                        id="dealer"
                                        filter
                                        value={selectedDealer}
                                        onChange={(e) => onHandleChangeDealer(e.value)}
                                        options={dealerDropdown}
                                        optionLabel="name"
                                        placeholder="Select One"
                                        name="dealer"
                                        className='dispatch-drp'
                                    ></Dropdown>
                                    <small className="p-invalid-txt">{errors['dealer']}</small>
                                </div>
                            </> : <div className="field col-12 md:col-4"></div>}
                    </>
                    : !window.cn(dispatchType) && dispatchType.code == 'PACKING MATERIAL' ?
                        <>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="bag_type">Bag Type *</label>
                                <Dropdown
                                    id="bag_type"
                                    filter
                                    value={bagType}
                                    onChange={(e) => setBagType(e.value)}
                                    options={packingTypeDropdown}
                                    optionLabel="name"
                                    placeholder="Select One"
                                    name="bag_type"
                                    className='dispatch-drp'
                                ></Dropdown>
                                <small className="p-invalid-txt">{errors['bag_type']}</small>
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="name_of_organizer">Name Of Organizer *</label>
                                <InputText
                                    value={!window.cn(dispatchMasterData["name_of_organizer"]) ? dispatchMasterData["name_of_organizer"] : ""}
                                    name="name_of_organizer"
                                    autoComplete="off"
                                    onChange={(e) => onHandleChangeDispatchMasterDetails(e, "name_of_organizer")}
                                />
                                <small className="p-invalid-txt">{errors['name_of_organizer']}</small>
                            </div>
                        </>
                        : !window.cn(dispatchType) && dispatchType.code == 'PROMOTIONAL MATERIAL' ?
                            <>
                                <div className="field col-12 md:col-4">
                                    <label htmlFor="dealer">Select Dealer *</label>
                                    <Dropdown
                                        id="dealer"
                                        filter
                                        value={selectedDealer}
                                        onChange={(e) => onHandleChangeDealer(e.value)}
                                        options={dealerDropdown}
                                        optionLabel="name"
                                        placeholder="Select One"
                                        name="dealer"
                                        className='dispatch-drp'
                                    ></Dropdown>
                                    <small className="p-invalid-txt">{errors['dealer']}</small>
                                </div>
                                <div className="field col-12 md:col-4">
                                </div>
                            </>
                            : <div className="field col-12 md:col-8"> </div>
                }

                <div className="field col-12 md:col-4">
                    <label htmlFor="bill_to">Bill To *</label>
                    <InputText
                        value={!window.cn(dispatchMasterData.bill_to) ? dispatchMasterData.bill_to : ""}
                        name="bill_to"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "bill_to")}
                        className={errors['bill_to'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['bill_to']}</small>
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="billing_address">Billing Address *</label>
                    <InputText
                        value={!window.cn(dispatchMasterData.billing_address) ? dispatchMasterData.billing_address : ""}
                        name="billing_address"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "billing_address")}
                        className={errors['billing_address'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['billing_address']}</small>
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="gst_no">GSTIN No. </label>
                    <InputText
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.gst_no : ""}
                        name="gst_no"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "gst_no")}
                        className={errors['gst_no'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['gst_no']}</small>
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="mobile">Mobile *</label>
                    <InputText
                         keyfilter="num"
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.mobile : ""}
                        name="mobile"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "mobile")}
                        className={errors['mobile'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['mobile']}</small>
                </div>

                <div className="field col-12 md:col-8"></div>

                <div className="field col-12">
                    <div className="field-checkbox">
                        <Checkbox inputId="checkOption1" name="option" value="1" checked={checkboxValue.indexOf('1') !== -1} onChange={onCheckboxChange} />
                        <label htmlFor="checkOption1">Delivery Address Same as Billing Address</label>
                    </div>
                </div>

                <div className="field col-12 md:col-4">
                    <label htmlFor="delivery_at">Delivery At *</label>
                    <InputText
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.delivery_at : ""}
                        name="delivery_at"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "delivery_at")}
                        className={errors['delivery_at'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['delivery_at']}</small>
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="delivery_address">Delivery Address *</label>
                    <InputText
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.delivery_address : ""}
                        name="delivery_address"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "delivery_address")}
                        className={errors['delivery_address'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['delivery_address']}</small>
                </div>
                <div className="field col-12 md:col-4 flex flex-column">
                    <label htmlFor="delivery_mobile">Mobile *</label>
                    <InputText
                         keyfilter="num"
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.delivery_mobile : ""}
                        name="delivery_mobile"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "delivery_mobile")}
                        className={errors['delivery_mobile'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['delivery_mobile']}</small>
                </div>

                <div className="field col-4 flex flex-column">
                    <label htmlFor="delivery_station">Delivery Station</label>
                    <InputText
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.delivery_station : ""}
                        name="delivery_station"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "delivery_station")}
                        className={errors['delivery_station'] && 'p-invalid'}
                    />
                </div>
                <div className="field col-8 flex flex-column"></div>

                {!window.cn(seedDispatchType) && seedDispatchType.code == 'DEALER' ?
                    <div className="field col-12">
                        <div className="field-checkbox">
                            <Checkbox inputId="checkOption1" name="option" value="2" checked={checkboxValue.indexOf('2') !== -1} onChange={onCheckboxChange} />
                            <label htmlFor="checkOption1">Add Amount Manually</label>
                        </div>
                    </div> : ""
                }

                {seedDispatchData.map((item: any, index: number) => (
                    <>
                        {!window.cn(dispatchType) && dispatchType.code == 'SEED DISPATCH' || !window.cn(dispatchType) && dispatchType.code == 'LOOSE PACKET' ?
                            <>
                                <div className="field col-12 md:col-4">
                                    <label htmlFor="crop">Crop *</label>
                                    <Dropdown
                                        filter
                                        value={seedDispatchData[index]["crop"]}
                                        defaultValue={seedDispatchData[index]["crop"]}
                                        onChange={(e) => handleCropDropdown(e.value, index)}
                                        options={cropData}
                                        optionLabel="name"
                                        name="crop"
                                        placeholder="Select Crop"
                                        className={errors['crop'] && 'p-invalid'}
                                    ></Dropdown>
                                    <small className="p-invalid-txt">{errors['crop']}</small>
                                </div>
                                <div className="field col-12 md:col-4">
                                    <label htmlFor="variety">Variety *</label>
                                    <Dropdown
                                        filter
                                        value={seedDispatchData[index]["variety"]}
                                        defaultValue={seedDispatchData[index]["variety"]}
                                        onChange={(e) => handleVarietyDropdown(e.value, index)}
                                        options={varietyDropdown[index]}
                                        optionLabel="name"
                                        name="variety"
                                        placeholder="Select Variety"
                                        className={errors['variety'] && 'p-invalid'}
                                    ></Dropdown>
                                    <small className="p-invalid-txt">{errors['variety']}</small>
                                </div>

                                {lotNoArrUi(index)}

                                {(!window.cn(seedDispatchType) && seedDispatchType.code != 'DEALER') || (!window.cn(checkboxValue) && checkboxValue.indexOf('2') !== -1) ?
                                    <>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="amount">Amount</label>
                                            <InputText
                                                keyfilter="num"
                                                value={!window.cn(seedDispatchData[index]["amount"]) ? seedDispatchData[index]["amount"] : ""}
                                                defaultValue={!window.cn(seedDispatchData[index]["amount"]) ? seedDispatchData[index]["amount"] : ""}
                                                name="amount"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeSeedDispatchText(e, index, "amount")}

                                            />
                                            <small className="p-invalid-txt">{errors['amount']}</small>
                                        </div>
                                        <div className="field col-12 md:col-8"></div>
                                    </> :
                                    <div></div>
                                }
                            </> : !window.cn(dispatchType) && dispatchType.code == 'PACKING MATERIAL' ?
                                <>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="crop">Crop *</label>
                                        <Dropdown
                                            filter
                                            value={seedDispatchData[index]["crop"]}
                                            onChange={(e) => handleCropDropdown(e.value, index)}
                                            options={cropData}
                                            optionLabel="name"
                                            name="crop"
                                            placeholder="Select Crop"
                                            className={errors['crop'] && 'p-invalid'}
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['crop']}</small>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="variety">Variety *</label>
                                        <Dropdown
                                            filter
                                            value={seedDispatchData[index]["variety"]}
                                            onChange={(e) => handleVarietyDropdown(e.value, index)}
                                            options={varietyDropdown[index]}
                                            optionLabel="name"
                                            name="variety"
                                            placeholder="Select Variety"
                                            className={errors['variety'] && 'p-invalid'}
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['variety']}</small>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="packing_size">Packing Size *</label>
                                        <Dropdown
                                            value={seedDispatchData[index]["packing_size"]}
                                            onChange={(e) => handleDropdown(e.value, index, "packing_size")}
                                            options={packingSizeDropdown[index]}
                                            optionLabel="name"
                                            name="packing_size"
                                            placeholder="Select Packing Size"
                                            className={errors['packing_size'] && 'p-invalid'}
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['packing_size']}</small>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="no_of_bag">No Of Bag *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(seedDispatchData[index]["no_of_bag"]) ? seedDispatchData[index]["no_of_bag"] : ""}
                                            name="no_of_bag"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeSeedDispatchText(e, index, "no_of_bag")}
                                            className={errors['no_of_bag'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['no_of_bag']}</small>
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label htmlFor="no_of_inner_bag">No Of Inner Bag *</label>
                                        <InputText
                                            value={!window.cn(seedDispatchData[index]["no_of_inner_bag"]) ? seedDispatchData[index]["no_of_inner_bag"] : ""}
                                            name="no_of_inner_bag"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeSeedDispatchText(e, index, "no_of_inner_bag")}
                                            className={errors['no_of_inner_bag'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['no_of_inner_bag']}</small>
                                    </div>
                                </> : !window.cn(dispatchType) && dispatchType.code == 'SAMPLING MATERIAL' ?
                                    <>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="crop">Crop *</label>
                                            <Dropdown
                                                filter
                                                value={seedDispatchData[index]["crop"]}
                                                onChange={(e) => handleCropDropdown(e.value, index)}
                                                options={cropData}
                                                optionLabel="name"
                                                name="crop"
                                                placeholder="Select Crop"
                                                className={errors['crop'] && 'p-invalid'}
                                            ></Dropdown>
                                            <small className="p-invalid-txt">{errors['crop']}</small>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="no_of_inner_bag">No Of Inner Clothing Bag *</label>
                                            <InputText
                                                keyfilter="num"
                                                value={!window.cn(seedDispatchData[index]["no_of_inner_bag"]) ? seedDispatchData[index]["no_of_inner_bag"] : ""}
                                                name="no_of_inner_bag"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeSeedDispatchText(e, index, "no_of_inner_bag")}
                                                className={errors['no_of_inner_bag'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['no_of_inner_bag']}</small>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="sampling_rate_per_inner_bag">Rate Per Inner Bag *</label>
                                            <InputText
                                                keyfilter="num"
                                                value={!window.cn(seedDispatchData[index]["sampling_rate_per_inner_bag"]) ? seedDispatchData[index]["sampling_rate_per_inner_bag"] : ""}
                                                name="sampling_rate_per_inner_bag"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeSeedDispatchText(e, index, "sampling_rate_per_inner_bag")}
                                                className={errors['sampling_rate_per_inner_bag'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['sampling_rate_per_inner_bag']}</small>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="no_of_bag">No Of Outer CLothing Bag *</label>
                                            <InputText
                                                keyfilter="num"
                                                value={!window.cn(seedDispatchData[index]["no_of_bag"]) ? seedDispatchData[index]["no_of_bag"] : ""}
                                                name="no_of_bag"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeSeedDispatchText(e, index, "no_of_bag")}
                                                className={errors['no_of_bag'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['no_of_bag']}</small>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="sampling_rate_per_outer_bag">Rate Per Outer Bag *</label>
                                            <InputText
                                                 keyfilter="num"
                                                value={!window.cn(seedDispatchData[index]["sampling_rate_per_outer_bag"]) ? seedDispatchData[index]["sampling_rate_per_outer_bag"] : ""}
                                                name="sampling_rate_per_outer_bag"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeSeedDispatchText(e, index, "sampling_rate_per_outer_bag")}
                                                className={errors['sampling_rate_per_outer_bag'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['sampling_rate_per_outer_bag']}</small>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="wax_qty">Wax Qty *</label>
                                            <InputText
                                                 keyfilter="num"
                                                value={!window.cn(seedDispatchData[index]["wax_qty"]) ? seedDispatchData[index]["wax_qty"] : ""}
                                                name="wax_qty"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeSeedDispatchText(e, index, "wax_qty")}
                                                className={errors['wax_qty'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['wax_qty']}</small>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="rate_per_wax">RATE PER WAX (KG) *</label>
                                            <InputText
                                                 keyfilter="num"
                                                value={!window.cn(seedDispatchData[index]["rate_per_wax"]) ? seedDispatchData[index]["rate_per_wax"] : ""}
                                                name="rate_per_wax"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeSeedDispatchText(e, index, "rate_per_wax")}
                                                className={errors['rate_per_wax'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['rate_per_wax']}</small>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="sutali">TWINE (SUTALI) *</label>
                                            <InputText
                                                 keyfilter="num"
                                                value={!window.cn(seedDispatchData[index]["sutali"]) ? seedDispatchData[index]["sutali"] : ""}
                                                name="sutali"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeSeedDispatchText(e, index, "sutali")}
                                                className={errors['sutali'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['sutali']}</small>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="rate_per_sutali">RATE PER SUTALI (KG) *</label>
                                            <InputText
                                                 keyfilter="num"
                                                value={!window.cn(seedDispatchData[index]["rate_per_sutali"]) ? seedDispatchData[index]["rate_per_sutali"] : ""}
                                                name="rate_per_sutali"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeSeedDispatchText(e, index, "rate_per_sutali")}
                                                className={errors['rate_per_sutali'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['rate_per_sutali']}</small>
                                        </div>
                                        <div className="field col-12 md:col-4">
                                            <label htmlFor="total_amount">TOTAL AMOUNT *</label>
                                            <InputText
                                                 keyfilter="num"
                                                value={!window.cn(seedDispatchData[index]["total_amount"]) ? seedDispatchData[index]["total_amount"] : ""}
                                                name="total_amount"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeSeedDispatchText(e, index, "total_amount")}
                                                className={errors['total_amount'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['total_amount']}</small>
                                        </div>
                                        <div className="field col-12 md:col-8"></div>
                                    </>
                                    : !window.cn(dispatchType) && dispatchType.code == 'PROMOTIONAL MATERIAL' ?
                                        <>
                                            <div className="field col-12 md:col-4">
                                                <label htmlFor="particulars">Particulars *</label>
                                                <Dropdown
                                                    filter
                                                    value={seedDispatchData[index]["particulars"]}
                                                    onChange={(e) => handleDropdown(e.value, index, "particulars")}
                                                    options={particularsDropdown}
                                                    optionLabel="name"
                                                    name="particulars"
                                                    placeholder="Select Particulars"
                                                    className={errors['particulars'] && 'p-invalid'}
                                                ></Dropdown>
                                                <small className="p-invalid-txt">{errors['particulars']}</small>
                                            </div>
                                            <div className="field col-12 md:col-4">
                                                <label htmlFor="no_of_bag">No Of Bag *</label>
                                                <InputText
                                                     keyfilter="num"
                                                    value={!window.cn(seedDispatchData[index]["no_of_bag"]) ? seedDispatchData[index]["no_of_bag"] : ""}
                                                    name="no_of_bag"
                                                    autoComplete="off"
                                                    onChange={(e) => onHandleChangeSeedDispatchText(e, index, "no_of_bag")}
                                                    className={errors['no_of_bag'] && 'p-invalid'}
                                                />
                                                <small className="p-invalid-txt">{errors['no_of_bag']}</small>
                                            </div>
                                            <div className="field col-12 md:col-4"></div>
                                        </> : ""}

                        {index > 0 ?
                            <div className="field col-12 md:col-12">
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger mt-2 "
                                    onClick={() => deleteSeedDispatchData(index)}
                                />
                            </div>

                            : ""
                        }

                    </>
                ))}
                <small className="p-invalid-txt">{errors['all_lots']}</small>
                <br />
                <br />
                {!window.cn(dispatchType) && (dispatchType.code != null) ?
                    <div className="field col-12 md:col-12">
                        <Button
                            style={{ width: 'fit-content' }}
                            label="Add More"
                            icon="pi pi-plus"
                            className="p-button"
                            onClick={handleAddMoreSeedDispatchDeta}
                        />
                    </div> : ""}

                <div className="field col-12 mt-3">
                    <div className="field-checkbox">
                        <Checkbox inputId="checkOption1" name="option" value="3" checked={checkboxValue.indexOf('3') !== -1} onChange={onCheckboxChange} />
                        <label htmlFor="checkOption1">Warehouse To Local Dealer Transfer</label>
                    </div>
                </div>

                {(!window.cn(checkboxValue) && checkboxValue.indexOf('3') !== -1) ?
                    <>
                        <div className="field col-12 mt-3">
                            <h5>Warehouse To Local Dealer Transfer</h5>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="local_trans port_name">Name Of Transporter</label>
                            <InputText
                                value={!window.cn(dispatchMasterData["local_transport_name"]) ? dispatchMasterData["local_transport_name"] : ""}
                                name="local_transport_name"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "local_transport_name")}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="local_from">From</label>
                            <InputText
                                value={!window.cn(dispatchMasterData["local_from"]) ? dispatchMasterData["local_from"] : ""}
                                name="local_from"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "local_from")}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="local_to">To</label>
                            <InputText
                                value={!window.cn(dispatchMasterData["local_to"]) ? dispatchMasterData["local_to"] : ""}
                                name="local_to"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "local_to")}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="local_no_of_bags">No. Of Bags</label>
                            <InputText
                                 keyfilter="num"
                                value={!window.cn(dispatchMasterData["local_no_of_bags"]) ? dispatchMasterData["local_no_of_bags"] : ""}
                                name="local_no_of_bags"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "local_no_of_bags")}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="local_weight_per_bags">Weight Per Bag</label>
                            <InputText
                                value={!window.cn(dispatchMasterData["local_weight_per_bags"]) ? dispatchMasterData["local_weight_per_bags"] : ""}
                                name="local_weight_per_bags"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "local_weight_per_bags")}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="local_rate_per_bags">Rate Per Bag</label>
                            <InputText
                                value={!window.cn(dispatchMasterData["local_rate_per_bags"]) ? dispatchMasterData["local_rate_per_bags"] : ""}
                                name="local_rate_per_bags"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "local_rate_per_bags")}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="local_dispatch_date">Dispatch Date</label>
                            <Calendar
                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.local_dispatch_date : ""}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, 'local_dispatch_date')}
                                name="local_dispatch_date"
                                showIcon
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="local_driver_name">Driver Name</label>
                            <InputText
                                value={!window.cn(dispatchMasterData["local_driver_name"]) ? dispatchMasterData["local_driver_name"] : ""}
                                name="local_driver_name"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "local_driver_name")}
                            />
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="local_driver_mobile_no">Mobile No.</label>
                            <InputText
                                 keyfilter="num"
                                value={!window.cn(dispatchMasterData["local_driver_mobile_no"]) ? dispatchMasterData["local_driver_mobile_no"] : ""}
                                name="local_driver_mobile_no"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "local_driver_mobile_no")}
                            />
                        </div>
                    </> : ""}

                <div className="field col-12 mt-3">
                    <div className="field-checkbox">
                        <Checkbox inputId="checkOption1" name="option" value="4" checked={checkboxValue.indexOf('4') !== -1} onChange={onCheckboxChange} />
                        <label htmlFor="checkOption1">Warehouse To Transport</label>
                    </div>
                </div>

                {(!window.cn(checkboxValue) && checkboxValue.indexOf('4') !== -1) ?
                    <div className="formgrid grid ">
                        <div className="field col-12 md:col-4">
                            <label htmlFor="warehouse_transport_name">Transport Name</label>
                            <InputText
                                value={!window.cn(dispatchMasterData["warehouse_transport_name"]) ? dispatchMasterData["warehouse_transport_name"] : ""}
                                name="warehouse_transport_name"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "warehouse_transport_name")}
                            />
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="warehouse_charges_per_bag">Charges per bag</label>
                            <InputText
                                 keyfilter="num"
                                value={!window.cn(dispatchMasterData["warehouse_charges_per_bag"]) ? dispatchMasterData["warehouse_charges_per_bag"] : ""}
                                name="warehouse_charges_per_bag"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "warehouse_charges_per_bag")}
                            />
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="warehouse_no_of_bags">No. Of Bags</label>
                            <InputText
                                 keyfilter="num"
                                value={!window.cn(dispatchMasterData["warehouse_no_of_bags"]) ? dispatchMasterData["warehouse_no_of_bags"] : ""}
                                name="warehouse_no_of_bags"
                                autoComplete="off"
                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, "warehouse_no_of_bags")}
                            />
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="warehouse_amount">Total Freight Charges</label>
                            <InputText
                                 keyfilter="num"
                                value={!window.cn(dispatchMasterData["warehouse_no_of_bags"]) ? dispatchMasterData["warehouse_charges_per_bag"] * dispatchMasterData["warehouse_no_of_bags"] : ""}
                                name="warehouse_amount"
                                autoComplete="off"
                                disabled
                            />
                        </div>
                    </div> : ""}
            </>
        );
    };

    //on submit
    const onSubmitDispatchData = () => {

        const { errors, isError } = DispatchValidate(dispatchMasterData, dispatchData, dispatchType, paymentType, image, editId, seedDispatchType, bagType, selectedDealer, seedDispatchData, lotNoArr, partLoadTransportName, lrImage);
 
        setErrors(errors);
        setIsError(isError);
        //Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            if (!window.cn(dispatchType) && (dispatchType.code == 'SEED DISPATCH' || dispatchType.code == 'LOOSE PACKET')) {
          
                seedDispatchData.map((item: any, i: number) => {
                    item["details"] = lotNoArr[i]
                });
            }

            let formData = new FormData();

            if (!window.cn(dispatchType)) {
                formData.append('dispatch_type', dispatchType?.code);
            }

            if (!window.cn(seedDispatchType) && seedDispatchType.code) {
                formData.append('seed_dispatch_type', seedDispatchType?.code);
            }

            if (!window.cn(bagType) && bagType.code) {
                formData.append('bag_type', bagType?.code);
            }

            if (!window.cn(seedDispatchData) && !window.cn(editId) && editId.id == null) {
                formData.append('dispatch_details', JSON.stringify(seedDispatchData));
            }

            if (!window.cn(partLoadTransportName) && partLoadTransportName != null) {
                formData.append('transport_name', partLoadTransportName.code);

                if (!window.cn(dispatchMasterData.other_transport_name)) {
                    formData.append('other_transport_name', dispatchMasterData.other_transport_name);
                }
            }

            if (!window.cn(selectedDealer)) {
                formData.append('dealer_id', selectedDealer?.code);
            }

            if (!window.cn(dispatchMasterData.bill_to)) {
                formData.append('bill_to', dispatchMasterData.bill_to);
            }

            if (!window.cn(dispatchMasterData.billing_address)) {
                formData.append('billing_address', dispatchMasterData.billing_address);
            }

            if (!window.cn(dispatchMasterData.gst_no)) {
                formData.append('gst_no', dispatchMasterData.gst_no);
            }

            if (!window.cn(dispatchMasterData.mobile)) {
                formData.append('mobile', dispatchMasterData.mobile);
            }

            if (!window.cn(dispatchMasterData.delivery_station)) {
                formData.append('delivery_station', dispatchMasterData.delivery_station);
            }

            if (!window.cn(dispatchMasterData.delivery_at)) {
                formData.append('delivery_at', dispatchMasterData.delivery_at);
            }
            if (!window.cn(dispatchMasterData.delivery_address)) {
                formData.append('delivery_address', dispatchMasterData.delivery_address);
            }
            if (!window.cn(dispatchMasterData.delivery_mobile)) {
                formData.append('delivery_mobile', dispatchMasterData.delivery_mobile);
            }

            if (!window.cn(dispatchData?.code)) {
                formData.append('transport_type', dispatchData?.code);
            }

            if (!window.cn(dispatchMasterData.transport_name)) {
                formData.append('transport_name', dispatchMasterData.transport_name);
            }

            if (!window.cn(dispatchMasterData.driver_name)) {
                formData.append('driver_name', dispatchMasterData.driver_name);
            }

            if (!window.cn(dispatchMasterData.driver_mobile)) {
                formData.append('driver_mobile', dispatchMasterData.driver_mobile);
            }

            if (!window.cn(dispatchMasterData.from_location)) {
                formData.append('from_location', dispatchMasterData.from_location);
            }

            if (!window.cn(dispatchMasterData.to_location)) {
                formData.append('to_location', dispatchMasterData.to_location);
            }

            if (!window.cn(dispatchMasterData.date_of_supply)) {
                var date = new Date(dispatchMasterData.date_of_supply);
                let supplyDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                formData.append('date_of_supply', supplyDate);
            }

            if (!window.cn(dispatchMasterData.no_of_bags)) {
                formData.append('no_of_bags', dispatchMasterData.no_of_bags);
            }

            if (!window.cn(paymentType?.code)) {
                formData.append('payment_type', paymentType?.code);
            }

            if (!window.cn(dispatchMasterData.amount)) {
                formData.append('amount', dispatchMasterData.amount);
            }

            if (!window.cn(dispatchMasterData.advance_amount)) {
                formData.append('advance_amount', dispatchMasterData.advance_amount);
            }

            if (!window.cn(dispatchMasterData.lr_no)) {
                formData.append('lr_no', dispatchMasterData.lr_no);
            }

            if (!window.cn(dispatchMasterData.license_no)) {
                formData.append('license_no', dispatchMasterData.license_no);
            }

            if (!window.cn(dispatchMasterData.license_state)) {
                formData.append('license_state', dispatchMasterData.license_state);
            }

            if (!window.cn(dispatchMasterData.is_advance)) {
                formData.append('is_advance', dispatchMasterData.is_advance);
            }

            if (!window.cn(dispatchMasterData.car_no)) {
                formData.append('car_no', dispatchMasterData.car_no);
            }

            if (!window.cn(editId) && editId.id) {
                formData.append('id', editId.id);
            }

            if (image != undefined && image != null && image != "") {
                formData.append('photo', image);
            }

            if (lrImage != undefined && lrImage != null && lrImage != "") {
                formData.append('lr_image', lrImage);
            }

            if (!window.cn(dispatchMasterData.warehouse_transport_name)) {
                formData.append('warehouse_transport_name', dispatchMasterData.warehouse_transport_name);
            }

            if (!window.cn(dispatchMasterData.warehouse_charges_per_bag)) {
                formData.append('warehouse_charges_per_bag', dispatchMasterData.warehouse_charges_per_bag);
            }

            if (!window.cn(dispatchMasterData.warehouse_no_of_bags)) {
                formData.append('warehouse_no_of_bags', dispatchMasterData.warehouse_no_of_bags);
                dispatchMasterData["warehouse_amount"] = dispatchMasterData["warehouse_charges_per_bag"] * dispatchMasterData["warehouse_no_of_bags"];
            }

            if (!window.cn(dispatchMasterData) && dispatchMasterData.warehouse_amount) {
                formData.append('warehouse_amount', dispatchMasterData.warehouse_amount);
            }

            if ((!window.cn(checkboxValue) && checkboxValue.indexOf('3') !== -1)) {
                if (!window.cn(dispatchMasterData.local_transport_name)) {
                    formData.append('local_transport_name', dispatchMasterData.local_transport_name);
                }

                if (!window.cn(dispatchMasterData.local_from)) {
                    formData.append('local_from', dispatchMasterData.local_from);
                }

                if (!window.cn(dispatchMasterData.local_to)) {
                    formData.append('local_to', dispatchMasterData.local_to);
                }

                if (!window.cn(dispatchMasterData.local_no_of_bags)) {
                    formData.append('local_no_of_bags', dispatchMasterData.local_no_of_bags);
                }

                if (!window.cn(dispatchMasterData.local_weight_per_bags)) {
                    formData.append('local_weight_per_bags', dispatchMasterData.local_weight_per_bags);
                }

                if (!window.cn(dispatchMasterData.local_rate_per_bags)) {
                    formData.append('local_rate_per_bags', dispatchMasterData.local_rate_per_bags);
                }

                if (!window.cn(dispatchMasterData.local_dispatch_date)) {
                    var date = new Date(dispatchMasterData.local_dispatch_date);
                    let dispatchDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                    formData.append('local_dispatch_date', dispatchDate);
                }

                if (!window.cn(dispatchMasterData.local_driver_name)) {
                    formData.append('local_driver_name', dispatchMasterData.local_driver_name);
                }

                if (!window.cn(dispatchMasterData.local_driver_mobile_no)) {
                    formData.append('local_driver_mobile_no', dispatchMasterData.local_driver_mobile_no);
                }
            }

            if (!window.cn(dispatchData) && (dispatchData.code == 'PARCEL' || dispatchData.code == 'COURIER')) {
                if (!window.cn(dispatchMasterData.tracking_id)) {
                    formData.append('tracking_id', dispatchMasterData.tracking_id);
                }

                if (!window.cn(dispatchMasterData.courier_name)) {
                    formData.append('courier_name', dispatchMasterData.courier_name);
                }
            }

            pageService.addDispatchData(formData).then(result => {
                setLoading(false);
                clearData();
                navigate('/product/dispatch');
                if (result.data != "") {
                    window.open(result.data, '_blank')
                }
                setCheckboxValue([]);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: result.message
                });
            }).catch(error => {
                setLoading(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.error,
                });
            });
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <h4> <Button
                //label="Back"
                icon="pi pi-arrow-left"
                className="p-button-secondary mr-2"
                onClick={() => navigate('/product/dispatch')}
            />    Add Dispatch Data</h4>

            <div className="col-12">
                <div className="card">
                    {(!window.cn(editId) && editId.id == null) || editId == null ?
                        <>
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-4">
                                    <label htmlFor="dispatch_type">Select Product Dispatch Type *</label>
                                    <Dropdown
                                        id="dispatch_type"
                                        filter
                                        value={dispatchType}
                                        onChange={(e) => setDispatchType(e.value)}
                                        options={DispatchType}
                                        optionLabel="name"
                                        placeholder="Select One"
                                        name="dispatch_type"
                                        className='dispatch-drp'
                                    ></Dropdown>
                                    <small className="p-invalid-txt">{errors['dispatch_type']}</small>
                                </div>

                                {getSeedDispatchArrayUI()}
                            </div>
                            <hr></hr>

                            <h5>Transport Details</h5>
                            {getTransportUi()}
                        </> : <>
                            <div className="formgrid grid">
                                <div className="field col-4 flex flex-column ">
                                    <label htmlFor="name">Lr. No.</label>
                                    <InputText
                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.lr_no : ""}
                                        name="lr_no"
                                        autoComplete="off"
                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'lr_no')}
                                    />
                                    <small className="p-invalid-txt">{errors['lr_no']}</small>
                                </div>
                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="date_of_supply">Date Of Supply</label>
                                    <Calendar
                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.date_of_supply : ""}
                                        dateFormat="dd/mm/yy"
                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, 'date_of_supply')}
                                        name="date_of_supply"
                                        showIcon
                                    />
                                    <small className="p-invalid-txt">{errors['date_of_supply']}</small>
                                </div>
                                <div className="field col flex flex-column">
                                    <label htmlFor="transport_name">Transport Name *</label>
                                    <Dropdown
                                        filter
                                        value={partLoadTransportName}
                                        onChange={(e) => onHandleChangePatLoadTransportName(e.value)}
                                        options={partLoadTranportNameDropdown}
                                        optionLabel="name"
                                        placeholder="Select One"
                                        name="transport_name"
                                    ></Dropdown>
                                    <small className="p-invalid-txt">{errors['transport_name']}</small>
                                </div>
                                {!window.cn(partLoadTransportName) && partLoadTransportName.code === 'Other' ?
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="other_transport_name">Other</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.other_transport_name : ""}
                                            name="other_transport_name"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'other_transport_name')}
                                        />
                                    </div> : ""}
                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Payment PAID Or Not</label>
                                    <Dropdown
                                        id="payment_type"
                                        value={paymentType}
                                        onChange={(e) => setPaymentType(e.value)}
                                        options={PaymentPAIDOrNot}
                                        optionLabel="name"
                                        placeholder="Select One"
                                        name="payment_type"
                                    ></Dropdown>
                                    <small className="p-invalid-txt">{errors['payment_type']}</small>
                                </div>

                                <div className="field col-4">
                                    <div className="col-12 mb-3">
                                        <label htmlFor="gtm">Advance Payment</label>
                                    </div>
                                    <div className='grid'>
                                        <div className="col-12 md:col-4 lg:col-4">
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="option1" name="option" value="Yes" checked={dispatchMasterData.is_advance === 'Yes'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                <label htmlFor="option1">Yes</label>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4">
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="option2" name="option" value="No" checked={dispatchMasterData.is_advance === 'No'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                <label htmlFor="option2">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <small className="p-invalid-txt">{errors['is_advance']}</small>
                                </div>

                                {!window.cn(dispatchMasterData) && dispatchMasterData.is_advance === 'Yes' ?
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="advance_amount">Advance Amount</label>
                                        <InputText
                                             keyfilter="num"
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.advance_amount : ""}
                                            name="advance_amount"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'advance_amount')}
                                        />
                                        <small className="p-invalid-txt">{errors['advance_amount']}</small>
                                    </div> : ""}

                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Amount *</label>
                                    <InputText
                                         keyfilter="num"
                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.amount : ""}
                                        name="amount"
                                        autoComplete="off"
                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'amount')}
                                    />
                                    <small className="p-invalid-txt">{errors['amount']}</small>
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Lr. Image *</label>
                                    <FileUpload
                                        ref={fileUploadRef}
                                        accept="image/*"
                                        name="lr_image[]"
                                        className="imageupload"
                                        chooseOptions={chooseOptions}
                                        emptyTemplate={emptyTemplate}
                                        headerTemplate={headerTemplate}
                                        itemTemplate={lrImageTemplate}
                                    ></FileUpload>
                                    <small className="p-invalid-txt">{errors['lr_image']}</small>
                                </div>
                            </div>
                        </>}

                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12">
                            <div style={{ float: "right" }}>
                                <Button
                                    label="Save"
                                    icon="pi pi-check"
                                    onClick={onSubmitDispatchData}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
