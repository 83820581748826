import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../appconfig/Settings';

// Column
import {
    CropWisePackagingColumns
} from '../../appconfig/DatatableSetting';

//Services
import PageService from '../../service/PageService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { addPackingBagCountValidate } from '../../config/Validate';

export const CropWisePackagingMaterial = () => {
    document.title = 'Packaging Material | Swarnim Farms';

    // Page service
    const pageService = new PageService();

    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);

    //Stock Crud Object
    const [packingMaterialList, setPackingMaterialList] = useState<any>([]);
    const [stockData, setstockData] = useState<any>({});
    const [editId, setEditId] = useState<any>();

    const [stockDialog, setStockDialog] = useState<any>(false);

    const [spinner, setSpinner] = useState<boolean>(false);

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    // use effect method
    useEffect(() => {
        getDataFromApi();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    //onChange stockData
    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        setstockData({ ...stockData, [name]: val });
    };

    // Get packaging material stock Data from API
    const getDataFromApi = async () => {
        // api call
        pageService.getCropWisePackagingMaterial().then((response) => {
            // get response
            if (response) {
                setPackingMaterialList(response);
                setPageLoad(true);
            } else {
                setPageLoad(false);
                setPackingMaterialList([]);
            }
        });
    };

    //open edit dialog
    const editData = async (data: any) => {
        setSpinner(true);
        setStockDialog(true);
        setEditId(data.id);
        setstockData({});
        setSpinner(false);
    }

    const hideEditDialog = () => {
        setStockDialog(false);
        setstockData({});
        setIsError(false);
        setErrors({});
        setEditId(null);
        setSpinner(false);
    };

    //edit packing material Data
    const onSubmit = async () => {
        const { errors, isError } = addPackingBagCountValidate(stockData);
        setErrors(errors);
        setIsError(isError);

        if (!isError) {
            setSubmitLoading(true);

            //Request object
            let formRequestData = new FormData();

            formRequestData.append('packing_bag_count', stockData.packing_bag_count);

            if (editId !== undefined && editId !== null) {
                formRequestData.append('id', editId);
            }

            pageService.editCropWisePackagingMaterial(formRequestData).then((response) => {
                setStockDialog(false);
                setstockData({ stockData });
                setErrors({});
                setIsError(false);
                setSubmitLoading(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Data Updated Sucessfully',
                });
                getDataFromApi();
            }).catch(error => {
                setSubmitLoading(false);
                toast.current?.show({
                    severity: 'error', summary: 'Error', detail: error.response.data.error
                });
            });
        }
    }

    // for column action
    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-success mr-3"
                    onClick={() => editData(rowData)}
                />
            </div>
        );
    };

    const leftToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
                        placeholder="Search..."
                    />
                </span>
            </>
        );
    };

    return (
        <>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h4>Crop Wise Packaging Material Data</h4>
                        </div>

                        <Toast ref={toast} />
                        <Toolbar
                            className="mb-4"
                            left={leftToolbarTemplate}
                        ></Toolbar>
                        <Toast ref={toast} />
                        {/* Datatable */}
                        {pageLoad ? (
                            <>
                                <DataTable
                                    className="datatable-responsive"
                                    value={packingMaterialList}
                                    paginator={packingMaterialList.length > 0 && true}
                                    globalFilter={globalFilter}
                                    rows={defaultRowOptions}
                                    rowsPerPageOptions={defaultPageRowOptions}
                                    paginatorTemplate={paginatorLinks}
                                    currentPageReportTemplate={showingEntries}
                                    emptyMessage="No Crops Data Found"
                                >
                                    {CropWisePackagingColumns.map((col, i) => {
                                        if (col.field === 'action') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={actionBodyTemplate}
                                                />
                                            );
                                        } else if (col.field === 'sr_no') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    header={col.header}
                                                    body={(_, { rowIndex }) => rowIndex + 1} />
                                            );
                                        } else {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    sortable
                                                    filter
                                                />
                                            );
                                        }
                                    })}
                                </DataTable>
                            </>
                        ) : (
                            <>
                                {/* Skeleton Data table */}
                                <DataTable value={Skeletonitems}>
                                    {CropWisePackagingColumns.map((col, i) => (
                                        <Column
                                            key={col.field}
                                            field={col.field}
                                            header={col.header}
                                            body={SkeletonbodyTemplate}
                                        />
                                    ))}
                                </DataTable>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Edit Dialog Start*/}
            <Dialog
                visible={stockDialog}
                className="p-fluid"
                onHide={hideEditDialog}
                header='Edit Packaging Materail Stock Data'
                footer={
                    <>
                        <Button
                            label="Cancel"
                            icon="pi pi-times"
                            className="p-button-text"
                            onClick={hideEditDialog}
                        />
                        <Button
                            label="Save"
                            icon="pi pi-check"
                            className="p-button-text"
                            loading={submitLoading}
                            onClick={onSubmit}
                        />
                    </>
                }
            >
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="packing_bag_count">Bag Count *</label>
                        <InputText
                             keyfilter="num"
                            value={!window.cn(stockData) ? stockData.packing_bag_count : ""}
                            name="packing_bag_count"
                            autoComplete="off"
                            onChange={(e) => onInputChange(e, 'packing_bag_count')}
                            className={errors['packing_bag_count'] && 'p-invalid'}
                        />
                         <small className="p-invalid-txt">{errors['packing_bag_count']}</small>
                    </div>
                </div>
                {
                    spinner && <div className="loading flex justify-content-center">
                        <ProgressSpinner />
                    </div>
                }
            </Dialog>
            {/* Edit Dialog End*/}
        </>
    )

};