import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useNavigate, useLocation } from 'react-router-dom';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';

//File Uplaod Components
import {
  chooseOptions,
  emptyTemplate,
  headerTemplate,
} from '../../components/ImageUploadComponent/ImageUploadSetting';

//Services
import PageService from '../../service/PageService';

//Loader
import { Loader } from '../../components/Loader/Loader';


// Validatetion
import { CropDataValidate } from '../../config/Validate';

export const AddCrop = () => {
  document.title = 'Crops  | Swarnim Farms';

  //Navigate Another Route
  const navigate = useNavigate();
  const location = useLocation();

  //Crops Crud Object
  const [cropsData, setCropsData] = useState<any>({});
  const [cropArray, setCropArray] = useState<any>([
    {
      state: '',
      amount: '',
    },
  ]);
  const [isCropArrayUpdate, setIsCropArrayUpdate] = useState(false);

  // Error object
  const [isError, setIsError] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  //Set Toast/ Filter Properties
  const toast = useRef<any>(null);

  // Edit, View and Delete Model/ Dialog
  const [cropsDialog, setCropsDialog] = useState<boolean>(false);

  // Edit and Delete Data Details
  const [editId, setEditId] = useState<any>(null);
  const [varietyEditId, setVarietyEditId] = useState<any>(null);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // File Upload Details
  const fileUploadRef = useRef(null);
  const removeFile = useRef(null);
  const [cropImage, setCropImage] = useState<any>();

  // crop and variety
  const [cropData, setCropData] = useState<any>([]);
  const [selectedCropId, setSelectedCropId] = useState<any>();
  const [selectedVarietyId, setSelectedVarietyId] = useState<any>();
  const [varietyData, setVarietyData] = useState<any>([]);
  const [packingsize, setPackingSize] = useState<any>();
  const [packingSizeData, setPackingSizeData] = useState<any>([]);

  const [stateData, setStateData] = useState<any>([]);

  // use effect method
  useEffect(() => {
    if (location.state) {
      const state = location.state;
      setEditId(state);
      editCrops(state);
    } else {
      getStateDataFromAPI();
    }
    getCropList();

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [dates]);

  useEffect(() => {
    if (isCropArrayUpdate) {
      setIsCropArrayUpdate(false);
      getCropArrayUI();
    }
  }, [isCropArrayUpdate]);

  useEffect(() => {
    getCropArrayUI();
  }, [stateData, cropArray]);

  // Get States
  const getStateDataFromAPI = async () => {
    try {
      const tempStateData: any = [];

      pageService.getState().then((response) => {
        if (response) {
          const stateDataList = response;
          for (let i = 0; i < stateDataList.length; i++) {
            const userObj: any = {};
            userObj['name'] = stateDataList[i].name;
            userObj['code'] = stateDataList[i].id;
            tempStateData.push(userObj);
          }
          setStateData(tempStateData);
        }
      });

    } catch (error) { }
  };

  // Get crops
  const getCropList = async () => {
    try {
      const cropData: any = [];

      pageService.cropList().then((response) => {
        if (response) {
          const cropDataList = response;
          for (let i = 0; i < cropDataList.length; i++) {
            const userObj: any = {};
            userObj['name'] = cropDataList[i].name;
            userObj['code'] = cropDataList[i].id;
            cropData.push(userObj);
          }
          setCropData(cropData);
        }
      });

    } catch (error) { }
  };

  //Get variety list
  const varietyList = async (selectedCropId: any, extra: string) => {
    try {
      let cropId = !window.cn(selectedCropId) ? selectedCropId.code : '';
      const varietyData: any = [];
      pageService.varietyList(cropId).then((response) => {
        if (response) {
          const varietyDataList = response;
          for (let i = 0; i < varietyDataList.length; i++) {
            const varietyObj: any = {};
            varietyObj['name'] = varietyDataList[i].name;
            varietyObj['code'] = varietyDataList[i].id;
            varietyData.push(varietyObj);
          }
          setVarietyData(varietyData);
        }
      });
    } catch (error) { }
  };

  // get packing size list
  const getpackingSizeList = async (crop_id: any, variety_id: any) => {
    pageService.getCropsPackingSizeById(crop_id, variety_id).then((response) => {
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setPackingSizeData([]);
        } else {
          const DataList = response;
          let tempWeightArray: any = [];
          for (let i = 0; i < DataList.length; i++) {
            const packingSizeObj: any = {};
            packingSizeObj['name'] = DataList[i].weight;
            packingSizeObj['code'] = DataList[i].id;
            tempWeightArray.push(packingSizeObj);
          }
          setPackingSizeData(tempWeightArray);
        }
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }
  
  // Handle Select Crop
  const handleSelectedCrop = (value: any) => {
    if (!window.cn(value)) {
      setSelectedCropId(value);
      varietyList(value, 'null');
    } else {
      setSelectedCropId('');
      setVarietyData([]);
    }
  };

  // Handle Select Crop
  const handleSelectedVariety = (value: any) => {
    if (!window.cn(value)) {
      setSelectedVarietyId(value);
      getpackingSizeList(selectedCropId?.code, value?.code);
    } else {
      setSelectedVarietyId('');
      setPackingSizeData([]);
    }
  };

  // Handle crops Data
  const handleCropArray = () => {
    const defaultObject = {
      state: '',
      amount: '',
    };
    let array = cropArray;
    array.push(defaultObject);
    setCropArray(array);
    setIsCropArrayUpdate(true);
  };

  // Variant UI
  const getCropArrayUI = () => {
    return (
      <>
        {cropArray.map((item: any, index: number) => (
          <>
            <div className="field col-12 md:col-6 mb-0">
              <label htmlFor="state">State *</label>
              <Dropdown
                filter
                value={item.state}
                onChange={(e) => onHandleChangeCrop(e.value, 'state', index)}
                options={stateData}
                optionLabel="name"
                name="state"
                placeholder="Select State"
                className={errors['state'] && 'p-invalid'}
              ></Dropdown>
              <small className="p-invalid-txt">{errors['state']}</small>
            </div>
            <div className="field col-12 md:col-6 mb-0">
              <label htmlFor="amount">Amount *</label>
              <InputText
                 keyfilter="num"
                value={item.amount}
                name="amount"
                autoComplete="off"
                onChange={(e) => onHandleChangeCrop(e, 'amount', index)}
                className={errors['amount'] && 'p-invalid'}
              />
              <small className="p-invalid-txt">{errors['amount']}</small>
            </div>
            {index > 0 ? (
              <>
                <div className="field-col-12 md:col-12 mb-2">
                  {/* <div style={{ marginTop: '25px' }}> */}
                  {item.id != null ? (
                    <Button
                      icon="pi pi-trash"
                      className="p-button-rounded p-button-danger mt-2"
                      onClick={() => deleteCropDetail(item.id, index)}
                    />
                  ) : (
                    <Button
                      icon="pi pi-trash"
                      className="p-button-rounded p-button-danger mt-2"
                      onClick={() => deleteCrop(index)} />
                  )}
                  {/* </div> */}
                </div>

              </>
            ) : (
              <>
                <div className="field-col-12 md:col-12"></div>
              </>
            )}
          </>
        ))}
      </>
    );
  };

  // onHandleChange for crops
  const onHandleChangeCrop = (e: any, name: string, index: number) => {
    let tempCropArray = cropArray;

    if (name === 'state') {
      tempCropArray[index][name] = e;

    } else {
      tempCropArray[index][name] = e.target.value;
    }

    // Check IsValid
    if (
      tempCropArray[index][name] !== '' ||
      tempCropArray[index][name] !== undefined
    ) {
      setValidation({ ...validation, crops: false });
    }

    setCropArray(tempCropArray);
    setIsCropArrayUpdate(true);
  };

  // onHandleChange for crops
  const onHandleChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    setCropsData({ ...cropsData, [name]: val });
  };

  // Delete crop
  const deleteCrop = (index: number) => {
    let deleteCropArray = cropArray;
    deleteCropArray.splice(index, 1);
    setCropArray(deleteCropArray);
    setIsCropArrayUpdate(true);
  };

  //check field validation
  const [validation, setValidation] = useState({
    name: false,
    variety: false,
    crops: false,
  });

  // for upload image adhar
  const itemProfileTemplate = (file: any, props: any) => {
    setCropImage(file);
    removeFile.current = props.onRemove;
    return (
      <>
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img
              alt={file.name}
              role="presentation"
              src={file.objectURL}
              width={100}
            />
            <span className="flex flex-column text-left ml-3">{file.name}</span>
            <Button
              type="button"
              icon="pi pi-times"
              className="p-button-outlined p-button-rounded p-button-danger ml-auto"
              onClick={() => onCropTemplateRemove(props.onRemove)}
            />
          </div>
        </div>
      </>
    );
  };

  // for remove profile image
  const onCropTemplateRemove = (callback: any) => {
    setCropImage({});
    callback();
  };

  // for add and edit crop api call on submit
  const onSubmit = async () => {
    const { errors, isError } = CropDataValidate(cropArray, cropImage, selectedCropId, selectedVarietyId, packingsize ,editId, cropsData);
    setErrors(errors);
    setIsError(isError);

    if (!isError) {
      setLoading(true);
      // variety object on the basis of condition
      if (varietyEditId) {
        cropArray.append('id', varietyEditId);
        cropArray.remove('created_at');
        cropArray.remove('updated_at');
      }

      //Request object
      let formRequestData = new FormData();

      formRequestData.append('quantity', cropsData.quantity);
      formRequestData.append('crop_details', JSON.stringify(cropArray));

      // set image if it is uploaded
      if (cropImage && cropImage.objectURL) {
        formRequestData.append('photo', cropImage);
      }

      if (editId !== undefined && editId !== null) {
        formRequestData.append('id', editId.id);
      } else {
        formRequestData.append('crop', selectedCropId.code);
        formRequestData.append('variety', selectedVarietyId.code);
        formRequestData.append('weight', packingsize?.name);
      }

      // api call
      pageService.addEditCropData(formRequestData).then((result) => {
        setCropsDialog(false);
        setCropsData({});
        setSelectedCropId({});
        setVarietyData([]);
        setCropArray([
          {
            state: '',
            amount: '',
          },
        ]);
        setIsCropArrayUpdate(false);
        setEditId(null);
        setVarietyEditId(null);
        setErrors({});
        setErrors(false);
        setLoading(false);
        navigate('/stock/list');
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: result.message,
        });
      }).catch(error => {
        setCropsDialog(true);
        setCropsData({ cropsData });
        setErrors({});
        setErrors(false);
        setLoading(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error,
        });
      }
      );
    }
  };

  // call delete api
  const deleteCropDetail = async (id: any, index: any) => {
    setPageLoad(true);
    let deleteCropArray = cropArray;
    deleteCropArray.splice(index, 1);
    setCropArray(deleteCropArray);
    setIsCropArrayUpdate(true);

    // request data
    let requestData = {
      id: id,
    };

    // call api
    pageService.deleteCropDetailById(requestData).then((response) => {
      // get response

      setPageLoad(false);

    });
  };

  //open edit dialog and set data
  const editCrops = async (id: any) => {
    setPageLoad(true)
    const tempStateData: any = [];

    await pageService.getState().then((response) => {
      if (response) {
        const stateDataList = response;
        for (let i = 0; i < stateDataList.length; i++) {
          const userObj: any = {};
          userObj['name'] = stateDataList[i].name;
          userObj['code'] = stateDataList[i].id;
          tempStateData.push(userObj);
        }
        setStateData(tempStateData);
      }
    });

    await pageService.getCropsDataById(id.id).then((result) => {
      let cropDetails = result.varieties;

      let varieryArray: {
        id: any;
        state: any;
        amount: any;
      }[] = [];

      cropDetails.map((item: any, index: number) => {

        const stateArr = tempStateData.filter((data: any) => {
          return data.code == item.state.id;
        });

        varieryArray.push({
          id: item.id,
          amount: item.amount,
          state: stateArr[0],
        });
      });

      if (cropDetails && cropDetails.id) {
        setVarietyEditId(cropDetails.id);
      } else {
        setVarietyEditId(null);
      }

      setCropImage(result.photo);
      setCropsDialog(true);
      setCropsData(result);
      setCropArray(varieryArray);
      setIsCropArrayUpdate(true);
      setPageLoad(false);
    }
    )
  }

  return (
    <>
      <h4> <Button
        //label="Back"
        icon="pi pi-arrow-left"
        className="p-button-secondary mr-2"
        onClick={() => navigate('/stock/list')}
      />   {editId !== null ? "Edit Crop Details" : "Add Crop Details"}</h4>

      <div className="card" style={{ position: "relative" }}>
        <div className="p-fluid formgrid grid">
          {editId != null ? (
            <>
              <div className="field col-12 md:col-6">
                <label htmlFor="crop">Crop</label>
                <InputText
                  value={cropsData?.crop?.name}
                  name="crop"
                  autoComplete="off"
                  className={errors['crop'] && 'p-invalid'}
                  readOnly
                  disabled
                />
              </div>

              <div className="field col-12 md:col-6">
                <label htmlFor="variety">Variety</label>
                <InputText
                  value={cropsData?.variety?.name}
                  name="variety"
                  autoComplete="off"
                  className={errors['variety'] && 'p-invalid'}
                  readOnly
                  disabled
                />
              </div>

              <div className="field col-12 md:col-6">
                <label htmlFor="weight">Packing Size</label>
                <InputText
                  value={cropsData?.weight}
                  name="weight"
                  autoComplete="off"
                  readOnly
                  disabled
                />
              </div>
            </>
          ) : (
            <>
              <div className="field col-12 md:col-6">
                <label htmlFor="crop">Crop *</label>
                <Dropdown
                  filter
                  value={selectedCropId}
                  onChange={(e) => handleSelectedCrop(e.value)}
                  options={cropData}
                  optionLabel="name"
                  name="crop"
                  placeholder="Select Crop"
                  className={errors['crop'] && 'p-invalid'}
                ></Dropdown>
                <small className="p-invalid-txt">{errors['crop']}</small>
              </div>

              <div className="field col-12 md:col-6">
                <label htmlFor="variety">Variety *</label>
                <Dropdown
                  filter
                  value={selectedVarietyId}
                  onChange={(e) => handleSelectedVariety(e.value)}
                  options={varietyData}
                  optionLabel="name"
                  name="variety"
                  placeholder="Select Variety"
                  className={errors['variety'] && 'p-invalid'}
                ></Dropdown>
                <small className="p-invalid-txt">{errors['variety']}</small>
              </div>

              <div className="field col-12 md:col-6">
                <label htmlFor="weight">Packing Size *</label>
                <Dropdown
                  filter
                  value={packingsize}
                  onChange={(e) => setPackingSize(e.value)}
                  options={packingSizeData}
                  optionLabel="name"
                  name="weight"
                  placeholder="Select Packing Size"
                  className={errors['weight'] && 'p-invalid'}
                ></Dropdown>
                <small className="p-invalid-txt">{errors['weight']}</small>
              </div>
            </>
          )}

          <div className="field col-12 md:col-6">
            <label htmlFor="name">Quantity *</label>
            <InputText
              keyfilter="num"
              value={cropsData.quantity}
              name="quantity"
              autoComplete="off"
              onChange={(e) => onHandleChange(e, 'quantity')}
              className={errors['quantity'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['quantity']}</small>
          </div>

          {getCropArrayUI()}

          <div className="field col-12 md:col-12">
            <Button
              style={{ width: 'fit-content' }}
              label="Add More"
              icon="pi pi-plus"
              className="p-button"
              onClick={handleCropArray}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="photo">Crop Image</label>
            <FileUpload
              ref={fileUploadRef}
              accept="image/*"
              name="photo[]"
              className="imageupload"
              chooseOptions={chooseOptions}
              emptyTemplate={emptyTemplate}
              headerTemplate={headerTemplate}
              itemTemplate={itemProfileTemplate}
            ></FileUpload>
            <small className="p-invalid-txt">{errors['photo']}</small>
          </div>

          {editId != null && (
            <div className="field col-12 md:col-6 editImage">
              {typeof (cropImage) === 'object' ? (
                <></>
              ) : (
                <>
                  <label htmlFor="photo">Old Image</label>
                  <img src={cropImage}></img>
                </>
              )}
            </div>
          )}

          <div className="card-footer">
            <div className="button-group">
              <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-4" onClick={() => navigate('/stock/list')} />
              <Button label="Save" icon="pi pi-check" loading={loading} onClick={onSubmit} />
            </div>
          </div>

          {
            editId != null && pageLoad && <Loader />
          }
        </div>
      </div>
    </>
  );
};
