import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

// Column
import { ExpenseColumns } from '../../appconfig/DatatableSetting';

// Data table
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

export const Expense = () => {
  document.title = 'Expense | Swarnim Farms';

  //Navigate Another Route
  const navigate = useNavigate();

  //Expense Crud Object
  const [expenseList, setExpenseList] = useState<any>([]);
  const [UserList, setUserList] = useState<any>([]);

  const [pdfData, setPdfData] = useState(null);

  const [status, setStatus] = useState<any>({ name: 'All', code: 'all' });
  const [user, setUser] = useState<any>({ name: 'All', code: 'All' });

  // Approve / Reject Leave Model/ Dialog
  const [approveConfirmModal, setApproveConfirmModal] = useState(false);

  // Approve / Reject Leave Data Details
  const [approveId, setApproveId] = useState(null);
  const [approveStatus, setApproveStatus] = useState(null);

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  // drop down status
  const statusDropdownItems = [
    { name: 'All', code: 'all' },
    { name: 'Pending', code: '0' },
    { name: 'Approved', code: '1' }
  ];

  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  // use effect method
  useEffect(() => {
    getExpenseDataFromAPI();
    getUserList();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [dates]);

  useEffect(() => {
    if (status) {
      getExpenseDataFromAPI();
    }
  }, [status, user]);

  // Get Expense Data from API
  const getExpenseDataFromAPI = async () => {
    setPageLoad(false);
 
    // api call
    pageService
      .getExpenseData(!window.cn(dates) && dates[0], !window.cn(dates) && dates[1], status.code, user.code)
      .then((response) => {
        // get response
        if (response) {
          const DataList = response.data;
          if (DataList.length == 0) {
            setExpenseList([]);
          } else {
            setExpenseList(DataList);
          }
          setPageLoad(true);
        } else {
          setPageLoad(true);
          setExpenseList([]);
        }
      });
  };

  // Get User Data from API
  const getUserList = async () => {
    // api call
    pageService
      .userList()
      .then((response) => {
        // get response
        if (response) {
          const DataList = response;
          if (DataList.length == 0) {
            setUserList([]);
          } else {
            let tempUserArr: any = [];
            for (let i = 0; i < DataList.length; i++) {
              let userObj: any = {};
              userObj['name'] = DataList[i].name;
              userObj['code'] = DataList[i].id;
              tempUserArr.push(userObj);
            }
            setUserList(tempUserArr);
          }
          setPageLoad(true);
        } else {
          setPageLoad(false);
          setUserList([]);
        }
      });
  };

  // On Date Change
  const onDateChange = (e: any) => {
    setDates(e.value);
  };

  //Approve Expense Data
  const approveData = (approveId: any, e: any) => {
    setApproveId(approveId);
    setApproveStatus(e.target.checked);
    setApproveConfirmModal(true);
  };

  //Approve Modal close
  const hideApproveConfirmModal = () => {
    setApproveId(null);
    setApproveStatus(null);
    setApproveConfirmModal(false);
  };

  // approve the Expense by id
  const approveExpense = async () => {
    setPageLoad(true);
    setLoading(true);

    // request data
    const requestData = {
      id: approveId,
    };

    // call api
    pageService.approveExpenseData(requestData).then((response) => {
      // Get response
      if (response) {
        setPageLoad(false);
        setLoading(false);
        setApproveConfirmModal(false);
        toast.current?.show({ severity: 'Success', summary: 'Success', detail: 'Expense Approved Successfully.' });
        getExpenseDataFromAPI();
      } else {
        setPageLoad(false);
        setLoading(false);
        setApproveConfirmModal(true);
        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong, Please try again.' });
      }
    });
  };

  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // right part of toolbar
  const rightToolbarTemplate = () => {
    return (
      <>
        <div className='mr-3'>
          <label>User :</label>
          <Dropdown
            filter
            value={user}
            onChange={(e) => setUser(e.value)}
            options={UserList}
            optionLabel="name"
            placeholder="All"
            className='order-drp'
          ></Dropdown>
        </div>
        <label>Payment Status :</label>
        <Dropdown
          value={status}
          onChange={(e) => setStatus(e.value)}
          options={statusDropdownItems}
          optionLabel="name"
          placeholder="Status"
        ></Dropdown>
        <div style={{ marginLeft: '15px' }}></div>
        <Calendar
          value={dates}
          dateFormat="dd/mm/yy"
          onChange={(e) => onDateChange(e)}
          selectionMode="range"
          showIcon
        />
        <div style={{ marginLeft: '15px' }}></div>
        <Button
          label='Export'
          icon="pi pi-file-export"
          className="p-button-success mr-2"
          loading={pdfLoading}
          onClick={exportPdf} 
          disabled={user.code === 'All'}
          />
      </>
    );
  };

  const exportPdf = async () => {
    setPdfLoading(true);
    //Validation Chec
    pageService.getExpenseData(!window.cn(dates) && dates[0], !window.cn(dates) && dates[1], status.code, user.code).then((response) => {
        // get response
        if (response) {
            let PdfDetails = response.pdf_data;
            if (PdfDetails !== null) {
              setPdfData(PdfDetails); 
              window.open(PdfDetails, '_blank')
              setPdfLoading(false);
            }
        } else {
          setPdfData(null);
        }
    });
}

  // for column action
  const statusBodyTemplate = (rowData: any) => {
    let statusClass = rowData.status === 1 ? "status status-active" : "status status-inactive";
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputSwitch
            checked={rowData.status === 1 ? true : false}
            onChange={(e) => approveData(rowData.id, e)}
            disabled={rowData.status === 1 ? true : false}
          />
          <span className={statusClass}>{rowData.status === 1 ? "Approved" : "Pending"}</span>
        </div>
      </>
    );
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary mt-2"
          style={{ marginRight: '17px' }}
          onClick={() => navigate('/expense/view-expense', { state: { id: rowData.id } })}
        />
      </div>
    );
  };

  //Start Date Time Conversion Template
  const dateFormatStartTimeTemplate = (rowData: any, rowIndex: any) => {
    return (
      <>
        {rowData.from_date === rowData[rowIndex.field] && rowData.from_date !== null ? moment.utc(rowData.from_date).format('MMM DD, YYYY') : rowData[rowIndex.field]}
      </>
    );
  };

  //End Date Time Conversion Template
  const dateFormatEndTimeTemplate = (rowData: any, rowIndex: any) => {
    return (
      <>
        {rowData.to_date === rowData[rowIndex.field] && rowData.to_date !== null ? moment.utc(rowData.to_date).format('MMM DD, YYYY') : rowData[rowIndex.field]}
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo main-div-sec ">
        <div className="col-12">
          <div className="card">
            <h4>Expense List</h4>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            {/* Datatable Start */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive"
                  value={expenseList}
                  paginator={expenseList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Expense Record Found"
                >
                  {ExpenseColumns.map((col, i) => {
                    if (col.field === 'status') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={statusBodyTemplate}
                        />
                      );
                    } else if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else if (col.field === 'from_date') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          field={col.field}
                          body={dateFormatStartTimeTemplate}
                          sortable
                        />
                      );
                    } else if (col.field === 'to_date') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={dateFormatEndTimeTemplate}
                          sortable
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {ExpenseColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
            {/* Datatable End */}
          </div>
        </div>
      </div>

      {/* Approve Confirmation Modal */}
      <Dialog
        visible={approveConfirmModal}
        style={{ width: '450px' }}
        header="Approve Expense"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={hideApproveConfirmModal}
            />
            <Button
              label="Approve"
              icon="pi pi-check"
              className="p-button-success"
              onClick={approveExpense}
              loading={loading}
            />
          </>
        }
        onHide={hideApproveConfirmModal}
      >
        <div className="flex align-items-center justify-content-start">
          <i
            className="pi pi-exclamation-triangle mr-3 delete-triangle"
            style={{ fontSize: '2rem' }}
          />
          <span className="delete-dialog-note">
            Note: Once you Approve, This will count as accepted.
          </span>
        </div>
      </Dialog>
      {/* Approve Confirmation Modal End */}
    </>
  );
};
