import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputSwitch } from 'primereact/inputswitch';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import {
  OrderColumns, ordersViewColumns
} from '../../appconfig/DatatableSetting';
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';

import { OrderDataValidate } from '../../config/Validate';

//Services
import PageService from '../../service/PageService';
import CommonService from '../../service/CommonService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { formatDateTime } from '../../appconfig/AppHelper';

export const Order = () => {
  document.title = 'Order | Swarnim Farms';
  const [ordersList, setOrdersList] = useState<any>([]);

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);
  const [pageLoad, setPageLoad] = useState(false);
  const [ordersDialog, setOrdersDialog] = useState<boolean>(false);
  const [editId, setEditId] = useState<any>(null);
  const [isError, setIsError] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});
  const [ordersData, setOrdersData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [deleteordersDialog, setDeleteOrdersDialog] = useState<boolean>(false);
  const [viewOrderDataDialog, setViewOrderDataDialog] =
    useState<boolean>(false);
  const [viewordersData, setViewordersData] = useState<any>({});
  const [orderArray, setOrderArray] = useState<any>([
    {
      crop: '',
      variety: '',
      packing_size: '',
      quantity: ''
    },
  ]);

  const [cropNameDropdownItems, setCropNameDropdwownItems] = useState<any>([]);
  const [isOrderArrayUpdate, setIsOrderArrayUpdate] = useState(false);
  const [weightDropdown, setWeightDropdown] = useState<any>([]);
  const [dealerDropdown, setDealerDropdown] = useState<any>([]);
  const [selectedDealer, setSelectedDealer] = useState<any>(null);
  
  const [varietyDropdown, setVarietyDropdown] = useState<any>([]);

  // Approve / Reject order Model/ Dialog
  const [approveConfirmModal, setApproveConfirmModal] = useState(false);

  // Approve / Reject order Data Details
  const [approveId, setApproveId] = useState(null);
  const [approveStatus, setApproveStatus] = useState(null);

  const [status, setStatus] = useState<any>({ name: 'All', code: 'all' });
  const [dealer, setDealer] = useState<any>({ name: 'All', code: 'All' });

  // drop down status
  const statusDropdownItems = [
    { name: 'All', code: 'all' },
    { name: 'Approved', code: '2' },
    { name: 'Pending', code: '1' }
  ];

  const commonService = new CommonService();
  const pageService = new PageService();

  //Navigate Another Route
  const navigate = useNavigate();

  // use effect method
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getDealerList();
  }, []);

  useEffect(() => {
    getordersDataFromAPI();
  }, [status, dates, dealer]);

  useEffect(() => {
    if (isOrderArrayUpdate) {
      setIsOrderArrayUpdate(false);
      getOrderArrayUI();
    }
  }, [isOrderArrayUpdate]);

  //OnChange Switch
  const inputSwitchChanges = async () => {
    //Set Loading Status
    setLoading(true);

    // request data
    const formRequestData = {
      id: approveId,
      role: localStorage.getItem('role'),
    };

    pageService.approveOrder(formRequestData).then((response) => {
        // Work here with the result
        setLoading(false);
        setApproveConfirmModal(false);
        setApproveId(null);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
        });
        getordersDataFromAPI();
      }).catch(error => {
        setLoading(false);
        setPageLoad(false);
        setApproveConfirmModal(true);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error,
        });
        getordersDataFromAPI();
      });
  }

  // Handle orders Data
  const handleOrderArray = () => {
    const defaultObject = {
      crop: '',
      variety: '',
      packing_size: '',
      quantity: ''
    };
    let array = orderArray;
    array.push(defaultObject);
    setOrderArray(array);
    setIsOrderArrayUpdate(true);
  };

  // Handle orders Data
  const handledealerDropdown = (e:any) => {
    setSelectedDealer(e);
    getCropsDataFromAPI(e.code);
  };

  // dropdown handle change crop
  const handleCropDropdown = (id: any, index: number, dealer: any) => {
    let tempOrderArray = orderArray;
    if (tempOrderArray[index] == undefined) {
      tempOrderArray.push({
        crop: id,
        variety: '',
        packing_size: '',
        quantity: ''
      });
    } else {
      tempOrderArray[index]["crop"] = id;
    }
    setOrderArray(tempOrderArray);
    getVarietyList(id, index, dealer);
  };

  // dropdown handle change crop
  const handleVarietyDropdown = (id: any, index: number) => {
    let tempOrderArray = orderArray;
    if (tempOrderArray[index] == undefined) {
      tempOrderArray.push({
        crop: orderArray[index]["crop"],
        variety: id,
        packing_size: '',
        quantity: ''
      });
    } else {
      tempOrderArray[index]["variety"] = id;
    }
    setOrderArray(tempOrderArray);
    getWeightList(id,index);
  };

  // dropdown handle change weight
  const handleWeightDropdown = (id: any, index: number) => {
    let tempWeightArray = orderArray;
    tempWeightArray[index]["packing_size"] = id;
    setOrderArray(tempWeightArray);
    setIsOrderArrayUpdate(true);
  };

  // dropdown handle change
  const handleStage = (id: any, index: number) => {
    let tempArray = orderArray;
    tempArray[index]["stage"] = id;
    setOrderArray(tempArray);
    setIsOrderArrayUpdate(true);
  };

  // Get Crops Data from API
  const getCropsDataFromAPI = async (dealerId : any) => {
    pageService.getCropList(dealerId).then((response) => {
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setCropNameDropdwownItems([]);
        } else {
          if (response) {
            setCropNameDropdwownItems(response);
          }
        }
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }

  const getVarietyList = async (id: any, index: number, dealer: any) => {
    pageService.getVarietyListByCrop(id.code,dealer?.code).then((response) => {
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setVarietyDropdown([]);
        } else {
          if (response) {
            const tempVarietyArr = response;
            if (varietyDropdown[index] != undefined) {
              varietyDropdown.splice(index, 1, tempVarietyArr);
            } else {
              varietyDropdown.splice(index, 0, tempVarietyArr);
            }
            setIsOrderArrayUpdate(true);
          }
        }
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }

  const getWeightList = async (id: any, index: number) => {

    pageService.getWeightListByVariety(id.code).then((response) => {
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setWeightDropdown([]);
        } else {
          if (response) {
            const DataList = response;
            let tempWeightArray: any = [];
            for (let i = 0; i < DataList.length; i++) {
              let weightObj: any = {};
              weightObj['name'] = DataList[i].name;
              weightObj['code'] = DataList[i].name;
              tempWeightArray.push(weightObj);
            }

            if (weightDropdown[index] != undefined) {
              weightDropdown.splice(index, 1, tempWeightArray);
            } else {
              weightDropdown.splice(index, 0, tempWeightArray);
            }
            setIsOrderArrayUpdate(true);
          }
        }
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }

  // Get Crops Data from API
  const getDealerList = async () => {

    pageService.dealerList().then((response) => {
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setDealerDropdown([]);
        } else {
          if (response) {
            const DataList = response;
            let tempUserArr: any = [];
            for (let i = 0; i < DataList.length; i++) {
              let userObj: any = {};
              userObj['name'] = DataList[i].firm_name;
              userObj['code'] = DataList[i].id;
              tempUserArr.push(userObj);
            }

            let dealer: any = {
              'name': 'All',
              'code': 'All'
            }

            tempUserArr.unshift(dealer);
            setDealerDropdown(tempUserArr);
          }
        }
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }

  // Variant UI
  const getOrderArrayUI = () => {
    return (
      <>
        <div className="formgrid grid">
          <div className="field col-12 md:col-12">
            <label htmlFor="dealer_id">Dealer *</label>
            <Dropdown
              id="dealer_id"
              filter
              value={selectedDealer}
              onChange={(e) => handledealerDropdown(e.value)}
              options={dealerDropdown}
              optionLabel="name"
              placeholder="Select One"
              name="dealer"
              className={errors['dealer'] && 'p-invalid'}
            ></Dropdown>
            <small className="p-invalid-txt">{errors['dealer']}</small>
          </div>
        </div>
        {orderArray.map((item: any, index: number) => (
          <>
            <div className="formgrid grid">
              <div className="field col-12 md:col-6">
                <label htmlFor="crop">Crop *</label>
                <Dropdown
                  id="crop"
                  filter
                  value={orderArray[index]["crop"]}
                  onChange={(e) => handleCropDropdown(e.value, index, selectedDealer)}
                  options={cropNameDropdownItems}
                  optionLabel="name"
                  placeholder="Select One"
                  name="crop"
                  className={errors['crop'] && 'p-invalid'}
                  disabled={editId != null}
                ></Dropdown>
                <small className="p-invalid-txt">{errors['crop']}</small>
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="variety">Variety *</label>
                <Dropdown
                  id="variety"
                  filter
                  value={orderArray[index]["variety"]}
                  onChange={(e) => handleVarietyDropdown(e.value, index)}
                  options={varietyDropdown[index]}
                  optionLabel="name"
                  placeholder="Select One"
                  name="variety"
                  className={errors['variety'] && 'p-invalid'}
                  disabled={editId != null}
                ></Dropdown>
                <small className="p-invalid-txt">{errors['variety']}</small>
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="weight">Packing Size *</label>
                 <Dropdown
                    filter
                    value={orderArray[index]["packing_size"]}
                    onChange={(e) => handleWeightDropdown(e.value, index)}
                    options={weightDropdown[index]}
                    optionLabel="name"
                    name="packing_size"
                    placeholder="Select One"
                    className={errors['packing_size'] && 'p-invalid'}
                    disabled={editId != null}
                  ></Dropdown> 
                <small className="p-invalid-txt">{errors['packing_size']}</small>
              </div>
              <div className="field col-12 md:col-6 mb-2">
                <label htmlFor="name">No. of Bags *</label>
                <InputText
                   keyfilter="num"
                  value={item.quantity}
                  name="quantity"
                  autoComplete="off"
                  onChange={(e) => onHandleChangeOrder(e, 'quantity', index)}
                  className={errors['quantity'] && 'p-invalid'}
                />
                <small className="p-invalid-txt">{errors['quantity']}</small>
              </div>
              {localStorage.hasOwnProperty('role') && localStorage.getItem('role') == '1' && editId != null ?  <div className="field col-12 md:col-6 mb-2">
                <label htmlFor="amount_per_packet">Amount Per Packet</label>
                <InputText
                  keyfilter="num"
                  value={item.amount_per_packet}
                  name="amount_per_packet"
                  autoComplete="off"
                  onChange={(e) => onHandleChangeOrder(e, 'amount_per_packet', index)}
                  className={errors['amount_per_packet'] && 'p-invalid'}
                />
              </div> :''} 
              {index > 0 ? (
                <>
                  <div className='field mb-4'>
                    <Button
                      icon="pi pi-trash"
                      className="p-button-rounded p-button-danger mt-2"
                      onClick={() => deleteOrder(index)}
                    />
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
          </>
        ))}
      </>
    );
  };

  // onHandleChange for orders
  const onHandleChangeOrder = (e: any, name: string, index: number) => {
    let tempOrderArray = orderArray;
    tempOrderArray[index][name] = e.target.value;
    // Check IsValid
    if (
      tempOrderArray[index][name] !== '' ||
      tempOrderArray[index][name] !== undefined
    ) {
      setValidation({ ...validation, orders: false });
    }

    setOrderArray(tempOrderArray);
    setIsOrderArrayUpdate(true);
  };

  // Delete Order
  const deleteOrder = (index: number) => {
    let deleteOrderArray = orderArray;
    deleteOrderArray.splice(index, 1);
    setOrderArray(deleteOrderArray);

    let deleteWeightArray = weightDropdown;
    deleteWeightArray.splice(index, 1);
    setWeightDropdown(deleteWeightArray);

    setIsOrderArrayUpdate(true);
  };

  //check field validation
  const [validation, setValidation] = useState({
    name: false,
    variety: false,
    orders: false,
  });

  // Get orders Data from API
  const getordersDataFromAPI = async () => {

    pageService.getOrderData(!window.cn(dates) && dates[0], !window.cn(dates) && dates[1], status.code, !window.cn(dealer.code) ? dealer.code : "",localStorage.getItem('role')).then((response) => {
      setPageLoad(false);
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setOrdersList([]);
        } else {
          setOrdersList(DataList);
        }
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }

  // On Date Change
  const onDateChange = (e: any) => {
    setDates(e.value);
  };

  // open add order dialog
  const addDialog = () => {
    setOrdersDialog(true);
  };

  // close add order dialog
  const hideAddDialog = () => {
    setOrdersDialog(false);
    setOrdersData({});
    setOrderArray([
      {
        crop: '',
        variety: '',
        packing_size: '',
        quantity: ''
      },
    ]);
    setIsOrderArrayUpdate(false);
    setEditId(null);
    setErrors({});  
    setSelectedDealer(null);
    setVarietyDropdown([]);
    setWeightDropdown([]);
    setLoading(false);
  };

  // for adding dealer data
  const onSubmit = async () => {

    const { errors, isError } = OrderDataValidate(selectedDealer, orderArray, editId);
    setErrors(errors);
    setIsError(isError);

    if (!isError) {
      setLoading(true);

      //Request object
      let formRequestData = new FormData();

      orderArray.map((item: any, index: number) => {
        item['crop'] = item['crop']['code'];
        item['variety'] = item['variety']['code'];
        item['packing_size'] = item['packing_size']['code'];
      });

      formRequestData.append('line_items', JSON.stringify(orderArray));

      if (editId !== undefined && editId !== null) {
        formRequestData.append('id', editId);
      } else {
        formRequestData.append('dealer_id', selectedDealer?.code);
      }

      pageService.addUpdateOrder(formRequestData).then((response) => {
          setOrdersDialog(false);
          setOrdersData({ ordersData });
          setOrderArray([
            {
              crop: '',
              variety: '',
              packing_size: '',
              quantity: ''
            },
          ]);
          setErrors(false);
          setLoading(false);
          toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: response.message,
          });
          setOrdersDialog(false);
          setVarietyDropdown([]);
          setWeightDropdown([]);
          getordersDataFromAPI();
          setSelectedDealer(null);
          hideAddDialog();
        }).catch(error => {
          setOrdersDialog(true);
          setOrdersData({ ordersData });
          setOrderArray(orderArray);
          setErrors(false);
          setLoading(false);
          toast.current?.show({
            severity: 'error',
            summary: 'Error',
            detail: error.response.data.error,
          });
        });
    }
  };

  // open view orders data dialog
  const viewOrderDetails = (rowData: any) => {
    setSpinner(true);
    setViewOrderDataDialog(true);
    setViewordersData(rowData.lineitems);
    setSpinner(false);
  };

  // close view orders data dialog
  const hideOrderDataDialog = () => {
    setViewOrderDataDialog(false);
    setViewordersData([]);
    setSpinner(false);
  };

  // open delete dialog
  const confirmDeleteOrder = (deleteID: any) => {
    setDeleteId(deleteID);
    setDeleteOrdersDialog(true);
  };

  //close delete dialog
  const hideDeleteOrderDialog = () => {
    setDeleteOrdersDialog(false);
    setDeleteId(null);
  };

  // call delete api
  const deleteorders = async () => {
    setLoading(true);
    // request data
    let requestData = {
      id: deleteId,
    };

    pageService.deleteOrder(requestData).then((response) => {
      // get response
      if (response) {
        setLoading(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Order Deleted Successfully.',
        });
        getordersDataFromAPI();
        setPageLoad(true);
      } else {
        setPageLoad(false);
        setLoading(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
      setDeleteId(null);
      setDeleteOrdersDialog(false);
    });
  }

  //open edit dialog
  const editorders = async (orders: any) => {
    setEditId(orders.id);

    let ordersData = orders;

    let OrderDetails = ordersData.lineitems;

    let detailArr: { id: any; crop: any; packing_size: any; quantity: any; variety: any; amount_per_packet : any}[] =
      [];

      const dealer: any = {
        "name": ordersData.dealer.firm_name,
        "code": ordersData.dealer_id
      }

      setSelectedDealer(dealer);
      setIsOrderArrayUpdate(true);
      getCropsDataFromAPI(ordersData.dealer_id);

      OrderDetails.map((item: any, index: number) => {

        let crop: any = {
          "name": item.crop_obj.name,
          "code": item.crop_obj.id
        }

        let varietyCode = item.variety_obj.id;
        let variety: any = {
          "name": item.variety_obj.name,
          "code": varietyCode.toString()
        }
        
        let packingSize: any = {
          "name": item.packing_size,
          "code": item.packing_size
        }

        detailArr.push({
          id: item.id,
          crop: crop,
          variety: variety,
          packing_size: packingSize,
          quantity: item.quantity,
          amount_per_packet: item.amount_per_packet
        });
        handleCropDropdown(crop, index, dealer);
        handleVarietyDropdown(variety, index);
        setIsOrderArrayUpdate(true);
      });

    setOrderArray(detailArr);
    setOrdersDialog(true);
    setIsOrderArrayUpdate(true);

  }

  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // right part of toolbar
  const rightToolbarTemplate = () => {
    return (
      <>
        <div className='mr-3'>
          <label>Dealer :</label>
          <Dropdown
          filter
            value={dealer}
            onChange={(e) => setDealer(e.value)}
            options={dealerDropdown}
            optionLabel="name"
            placeholder="Dealer"
            className='order-drp'
          ></Dropdown>
        </div>
        <div className='mr-3'>
          <label>Order Status :</label>
          <Dropdown
            value={status}
            onChange={(e) => setStatus(e.value)}
            options={statusDropdownItems}
            optionLabel="name"  
            placeholder="Status"
            className='order-drp'
          ></Dropdown>
        </div>
        <div>
          <Calendar
            value={dates}
            className='order-cle'
            dateFormat="dd/mm/yy"
            onChange={(e) => onDateChange(e)}
            selectionMode="range"
            showIcon
          />
        </div>
      </>
    );
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions action-btn">
        <Button
          tooltip='View'
          tooltipOptions={{ position: 'bottom' }}
          icon="pi pi-eye"
          className="p-button-primary mt-2 mr-2 icn-btn"
          onClick={() => viewOrderDetails(rowData)}
        />
        <Button
          tooltip='Edit'
          tooltipOptions={{ position: 'bottom' }}
          icon="pi pi-pencil"
          className="p-button-success mr-2"
          onClick={() => editorders(rowData)}
          disabled={rowData.status == 2}
        />
        <Button
          icon="pi pi-trash"
          tooltip='Delete'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-danger mr-2 mt-2 icn-btn"
          onClick={() => confirmDeleteOrder(rowData.id)}
          disabled={rowData.dispatch_status == 1}
        />
        <Button
          style={{ width: 'fit-content' }}
          label="Dispatch"
          className="p-button-warning mr-2 mt-3"
          onClick={() => navigate('/dispatch/add-order-dispatch-details', { state: { id: rowData.id } })}
          disabled={(!window.cn(rowData.dispatch) && rowData.dispatch != null && rowData.dispatch_status == 1 || (rowData.status == 0 || rowData.status == 1) || rowData.order_transfered == 1) ? true : false}
        />
      </div>
    );
  };

  const dealerBodyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData?.dealer?.firm_name}</div>
      </>
    );
  }

  //Approve Data
  const approveData = (approveId: any, e: any) => {
    setApproveId(approveId);
    setApproveStatus(e.target.checked);
    setApproveConfirmModal(true);
  };

  // const statusBodyTemplate = (rowData: any) => {
  //   let statusClass = rowData.status === 1 ||  rowData.status === 2 ? "status status-active" : "status status-inactive";
  //   return (
  //     <>
  //       <div style={{ display: "flex", flexDirection: "column" }}>
  //       <InputSwitch
  //         checked={(localStorage.getItem('role') == '7' || localStorage.getItem('role') == '33' || localStorage.getItem('role') == '43' || localStorage.getItem('role') == '44') &&  rowData.status === 1 ? true : ((localStorage.getItem('role') != '7' && localStorage.getItem('role') != '33' && localStorage.getItem('role') != '43' && localStorage.getItem('role') != '44') &&  rowData.status === 2) ? true : false}
  //         onChange={(e) => approveData(rowData.id, e)}
  //         disabled={(localStorage.getItem('role') == '7' || localStorage.getItem('role') == '33' || localStorage.getItem('role') == '43' || localStorage.getItem('role') == '44') &&  rowData.status === 1 ? true : ((localStorage.getItem('role') != '7' && localStorage.getItem('role') != '33' && localStorage.getItem('role') != '43' && localStorage.getItem('role') != '44') &&  rowData.status === 2) ? true : false }
  //       />
  //       <span className={statusClass}>{rowData.status === 1 ? "Approved By Exec." : rowData.status === 2 ? "Approved" : "Pending"}</span>
  //       </div>
  //     </>
  //   );
  // };

  const statusBodyTemplate = (rowData: any) => {
    let statusClass = rowData.status === 1 ||  rowData.status === 2 ? "status status-active" : "status status-inactive";
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column" }}>
        <InputSwitch
          checked={(localStorage.getItem('role') != '1' && rowData.order_placed_by == 'Dealer' && rowData.status === 1) || (rowData.status === 2) }
          onChange={(e) => approveData(rowData.id, e)}
          disabled={(localStorage.getItem('role') == '1' && rowData.order_placed_by == 'Dealer' && rowData.status === 0) || (rowData.status === 2) || (localStorage.getItem('role') != '1' && rowData.order_placed_by == 'Salesman' && rowData.status === 0) || (localStorage.getItem('role') != '1' && rowData.order_placed_by == 'Dealer' && rowData.status === 1)}
        />
        </div>
      </>
    );
  }

  const orderStatus = (rowData: any) => {
    return (
      <>
     
        <div>{rowData.status === 1 ? "Approved By Exec." : rowData.status === 2 ? "Approved" : (rowData.order_placed_by == 'Dealer' && rowData.status === 0) ? "Approval Pending From Exec." : "Pending"}</div>
      
      </>
    );
  }

  //Approve Modal close
  const hideApproveConfirmModal = () => {
    setApproveId(null);
    setApproveStatus(null);
    setApproveConfirmModal(false);
    setLoading(false);
  };

  const cropBodyTemplate = (rowData: any) => {
    return (<>
      <div>{rowData?.crop_obj?.name}</div>
    </>);
  };

  const varietyBodyTemplate = (rowData: any) => {
    return (<>
      <div>{rowData?.variety_obj?.name}</div>
    </>);
  };

  const orderDateTemplate = (rowData: any) => {
    return (<>
      <div>{formatDateTime(rowData?.created_at)}</div>
    </>);
  };

  const orderApproveBy = (rowData: any) => {
    return (<>
      <div>{ !window.cn(rowData.admin_approve) ? rowData.admin_approve.full_name : !window.cn(rowData.executive_approve) ? rowData.executive_approve.full_name : '-'}</div>
    </>);
  };

  const orderAddedBy = (rowData: any) => {
    return (<>
      <div>{ !window.cn(rowData.user_id) ? rowData?.user?.full_name : "-"}</div>
    </>);
  };

  return (
    <>
      <div className="grid crud-demo main-div-sec ">
        <div className="col-12">
          <div className="card">
            <div className='main-header'>
              <h4 className='header'> Order List </h4>
              {localStorage.getItem('role') == '1' ? 
              <Button
                label="Add New Order"
                icon="pi pi-plus"
                className="p-button-success mr-2"
                onClick={addDialog}
              /> : ""}
            </div>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4" 
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            {/* Datatable */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive p-datatable-sm"
                  value={ordersList}
                  paginator={ordersList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Orders Found"
                >
                  {OrderColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else if (col.field === 'order_date') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={orderDateTemplate}
                        />
                      )
                    } else if (col.field === 'user_id') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={orderAddedBy}
                        />
                      )
                    } else if (col.field === 'executive_approve') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={orderApproveBy}
                        />
                      );
                    } else if (col.field === 'status') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={statusBodyTemplate}
                        />
                      );
                    } else if (col.field === 'admin_approve_id') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={orderStatus}
                        />
                      );
                    }else if (col.field === 'dealer_id') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={dealerBodyTemplate}
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {OrderColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Add Dialog Start*/}
      <Dialog
        visible={ordersDialog}
        className="p-fluid"
        header="Add Order"
        onHide={hideAddDialog}
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideAddDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              className="p-button-text"
              onClick={onSubmit}
              loading={loading}
            />
          </>
        }
      >
        {getOrderArrayUI()}
        { editId == null ? 
        <div className="field">
          <div className="">
            <Button
              style={{ width: 'fit-content' }}
              label="Add More"
              icon="pi pi-plus"
              className="p-button"
              onClick={handleOrderArray}
            />
          </div>
        </div> : ""}
      </Dialog>
      {/* Add Dialog End*/}

      {/* deleteDialog start*/}
      <Dialog
        visible={deleteordersDialog}
        style={{ width: '450px' }}
        header="Delete"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-secondary"
              onClick={hideDeleteOrderDialog}
            />
            <Button
              label="Delete"
              icon="pi pi-trash"
              className="p-button-danger"
              onClick={deleteorders}
              loading={loading}
            />
          </>
        }
        onHide={hideDeleteOrderDialog}
      >
        <div className="flex align-items-center justify-content-start">
          <i
            className="pi pi-exclamation-triangle mr-3 delete-triangle"
            style={{ fontSize: '2rem' }}
          />
          <span className="delete-dialog-note">
            Are you sure you want to delete ?
          </span>
        </div>
      </Dialog>
      {/* deleteDialog End*/}

      {/* View Dialog start*/}
      <Dialog
        visible={viewOrderDataDialog}
        style={{ width: '1000px' }}
        header="View Order Data"
        className='crud-demo'
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-secondary"
              onClick={hideOrderDataDialog}
            />
          </>
        }
        onHide={hideOrderDataDialog}
      >
        <DataTable
          className="datatable-responsive"
          value={viewordersData}
          rows={defaultRowOptions}
          currentPageReportTemplate={showingEntries}
          emptyMessage="No orders Varieties Data Found"
        >

          {ordersViewColumns.map((col, i) => {
            if (col.field === 'crop') {
              return <Column key={col.field} field={col.field} header={col.header} body={cropBodyTemplate} />;
            } else if (col.field === 'variety') {
              return <Column key={col.field} field={col.field} header={col.header} body={varietyBodyTemplate} />;
            } else if (col.field === 'sr_no') {
              return (
                <Column key={col.field} header={col.header} body={(_, { rowIndex }) => rowIndex + 1} />
              );
            } else {
              return <Column key={col.field} field={col.field} header={col.header} />
            }
          })}

          {
            spinner && <div className="loading flex justify-content-center">
              <ProgressSpinner />
            </div>
          }

        </DataTable>
      </Dialog>
      {/* View Dialog End*/}

      {/* Approve / Reject Confirmation Modal */}
      <Dialog
        visible={approveConfirmModal}
        style={{ width: '450px' }}
        header="Approve Order"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={hideApproveConfirmModal}
            />
            <Button
              label="Approve"
              icon="pi pi-check"
              className="p-button-success"
              onClick={inputSwitchChanges}
              loading={loading}
            />
          </>
        }
        onHide={hideApproveConfirmModal}
      >
        <div className="flex align-items-center justify-content-start">
          <i
            className="pi pi-exclamation-triangle mr-3 delete-triangle"
            style={{ fontSize: '2rem' }}
          />
          <span className="delete-dialog-note">
            Note: Once you Approve, This will count as accepted.
          </span>
        </div>
      </Dialog>
      {/* Approve / Reject Confirmation Modal End */}
    </>
  );
};
