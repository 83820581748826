import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../appconfig/AppHelper';

import moment from "moment/moment";

// Column
import { ProductionColumns } from '../../appconfig/DatatableSetting';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';


export const Production = () => {

    document.title = 'Production | Swarnim Farms';

    const pageService = new PageService();
    //Navigate Another Route
    const navigate = useNavigate();

    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // Date Object
    let today = new Date();
    const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);

    const [production, setProduction] = useState<any>([]);

    // use effect method
    useEffect(() => {
        getProductionData();
    }
        , []);

    //get dispatch data from api
    const getProductionData = () => {
        pageService.getProductionData().then((response) => {
            setPageLoad(false);
            // get response
            if (response) {
                const DataList = response;
                if (DataList.length == 0) {
                    setProduction([]);
                } else {
                    setProduction(DataList);
                }
                setPageLoad(true);
            } else {
                setPageLoad(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    };

    // on date change
    const onDateChange = (e: any) => {
        setDates(e.value);
    };

    //open edit dialog
    const editProduction = async (production: any) => {
        navigate('/product/add-production', { state: { id: production.id } });
    };

    // for left toolbar
    const leftToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
                        placeholder="Search..."
                    />
                </span>
            </>
        );
    };

    // for column action
    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-eye"
                    tooltip='View'
                    tooltipOptions={{ position: 'bottom' }}
                    className="p-button-rounded p-button-primary mt-2 mr-3 mb-2"
                    onClick={() => navigate('/product/view-production-details', { state: { id: rowData.id } })}
                />
                <Button
                    icon="pi pi-pencil"
                    tooltip='Edit'
                    tooltipOptions={{ position: 'bottom' }}
                    className="p-button-rounded p-button-success mr-3 mb-2"
                    onClick={() => editProduction(rowData)}
                />
            </div>
        );
    };

    const organizerTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData?.organizer?.first_name + " " + rowData?.organizer?.last_name}</div>
            </>
        )
    };

     //Start Date Time Conversion Template
  const dateFormatStartTimeTemplate = (rowData: any) => {
    return (
      <>
        <div>{formatDateTime(rowData?.created_at)}</div>
      </>
    );
  };

    return (<>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='main-header'>
                        <h4 className='header'> Production List </h4>
                        <Button
                            label="Add Production Data"
                            icon="pi pi-plus"
                            className="p-button-success mr-2"
                            onClick={() => navigate('/product/add-production')}
                        />
                    </div>
                    <Toast ref={toast} />
                    <Toolbar
                        className="mb-4"
                        left={leftToolbarTemplate}
                    ></Toolbar>

                    {/* Datatable Start */}
                    {pageLoad ? (
                        <>
                            <DataTable
                                value={production}
                                loading={loading}
                                paginator={production.length > 0 && true}
                                globalFilter={globalFilter}
                                rows={defaultRowOptions}
                                rowsPerPageOptions={defaultPageRowOptions}
                                className="datatable-responsive p-datatable-sm"
                                paginatorTemplate={paginatorLinks}
                                currentPageReportTemplate={showingEntries}
                                emptyMessage="No Production Data Found"
                            >
                                {ProductionColumns.map((col, i) => {
                                    if (col.field === 'action') {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={actionBodyTemplate}
                                            />
                                        );
                                    } else if (col.field === 'sr_no') {
                                        return (
                                            <Column
                                                key={col.field}
                                                header={col.header}
                                                body={(_, { rowIndex }) => rowIndex + 1} />
                                        );
                                    } else if (col.field === 'created_at') {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={dateFormatStartTimeTemplate}
                                                sortable
                                            />
                                        );
                                    } else if (col.field === 'organizer') {
                                        return (
                                            <Column
                                                key={col.field}
                                                header={col.header}
                                                body={organizerTemplate} />
                                        );
                                    } else {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                sortable
                                                filter
                                            />
                                        );
                                    }
                                })}
                            </DataTable>
                        </>
                    ) : (
                        <>
                            {/* Skeleton Data table */}
                            <DataTable value={Skeletonitems}>
                                {ProductionColumns.map((col, i) => (
                                    <Column
                                        key={col.field}
                                        field={col.field}
                                        header={col.header}
                                        body={SkeletonbodyTemplate}
                                    />
                                ))}
                            </DataTable>
                        </>
                    )}
                    {/* Datatable End */}
                </div>
            </div>
        </div>
    </>)
};