import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';

//Services
import PageService from '../../service/PageService';
import { Loader } from '../../components/Loader/Loader';

export const ViewExpense = () => {

    //Navigate Another Route
    const navigate = useNavigate();

    const pageService = new PageService();
    const location = useLocation();

    const [pageLoad, setPageLoad] = useState(false);
    const [loading, setLoading] = useState(false);

    const toast = useRef<any>(null);
    const [expenseId, setExpenseId] = useState<any>();
    const [expenseData, setExpenseData] = useState<any>(null);
    const [otherExpenseData, setOtherExpenseData] = useState<any>([]);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setExpenseId(state);
            getExpenseDataFromApi(state);
        }
    }, []);

    // Get Expense Data from API
    const getExpenseDataFromApi = async (state: any) => {
        setPageLoad(true);
        // call api
        pageService
            .getExpenseDataById(state.id)
            .then((response) => {
                // get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setExpenseData(null);
                    } else {
                        setOtherExpenseData(JSON.parse(DataList?.other_expense));
                        setExpenseData(DataList);
                        setPageLoad(false);
                    }
                } else {
                    setExpenseData(null);
                }
            });
    };

    return (<>
        <h3> <Button
            icon="pi pi-arrow-left"
            className="p-button-secondary mr-2"
            onClick={() => navigate('/expense')}
        />    Expense Details</h3>

        <div className='card' style={{ position: "relative" }}>
            <div className="field col">
                <div className="grid">
                    <div className="field col-12 flex flex-column">
                        <p> <b>Name</b> : {!window.cn(expenseData) ? expenseData.user?.first_name + " " + expenseData.user?.last_name : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Paid Type</b> : {!window.cn(expenseData) ? expenseData.paid_type : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Tour Type</b> : {!window.cn(expenseData) ? expenseData.tour_type : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Vehicle Type</b> : {!window.cn(expenseData) ? expenseData.vehicle_type : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Payment Type</b> : {!window.cn(expenseData) ? expenseData.payment_type : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Start Date</b> : {!window.cn(expenseData) ? moment.utc(expenseData.from_date).format('DD MMM YYYY') : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>End Date</b> : {!window.cn(expenseData) ? moment.utc(expenseData.to_date).format('DD MMM YYYY') : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Distance Travelled (in km)</b> : {!window.cn(expenseData) ? expenseData.distance : ""}</p>
                    </div>
                </div>

                <div className="grid">
                    {otherExpenseData.map((item: any, index: number) => {
                        return (
                            <>{!window.cn(item) && item.Expense == true ?
                                <div className="field col-4 flex flex-column">
                                    <p> <b>{!window.cn(item) ? item.title +" Amount" : ""}</b> : {!window.cn(item) ? item.Amount : ""} &nbsp;&nbsp; 
                                    
                                    {!window.cn(item) && item.title == "Fare" && expenseData.fare_img != null ? <>
                                    <Button
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-primary ml-3"
                                        style={{ marginRight: '17px' }}
                                        onClick={() => window.open(expenseData.fare_img, '_blank')}
                                    /></> : ""}

                                    {!window.cn(item) && item.title == "Food"  && expenseData.food_img != null ? <>
                                    <Button
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-primary ml-3"
                                        style={{ marginRight: '17px' }}
                                        onClick={() => window.open(expenseData.food_img, '_blank')}
                                    /></> : ""}

                                    {!window.cn(item) && item.title == "Fuel"  && expenseData.fuel_img != null ? <>
                                    <Button
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-primary ml-3"
                                        style={{ marginRight: '17px' }}
                                        onClick={() => window.open(expenseData.fuel_img, '_blank')}
                                    /></> : ""}

                                    {!window.cn(item) && item.title == "Repairing"  && expenseData.repairing_img != null ? <>
                                    <Button
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-primary ml-3"
                                        style={{ marginRight: '17px' }}
                                        onClick={() => window.open(expenseData.repairing_img, '_blank')}
                                    /></> : ""}

                                    {!window.cn(item) && item.title == "Lodging"  && expenseData.lodging_img != null ? <>
                                    <Button
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-primary ml-3"
                                        style={{ marginRight: '17px' }}
                                        onClick={() => window.open(expenseData.lodging_img, '_blank')}
                                    /></> : ""}

                                    {!window.cn(item) && item.title == "Other"  && expenseData.other_img != null ? <>
                                    <Button
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-primary ml-3"
                                        style={{ marginRight: '17px' }}
                                        onClick={() => window.open(expenseData.other_img, '_blank')}
                                    /></> : ""}
                                    </p>
                                </div>
                                : ""
                            }</>
                        )
                    }
                    )}
                </div>
            </div>
        </div>

        {
            pageLoad && <Loader />
        }
    </>);
}