/********************  START APP API SLUG ************* */

// Prefixed App URL

//Athencation
export const LOGIN = '/login';
const APP = '/app/';
const ADMIN = '/api/admin/';

export const GET_ALL_ROLES = '/api/get-all-roles';

// Agent
export const GET_AGENT = ADMIN + 'agent-list';
export const ADD_UPDATE_AGENT = ADMIN + 'add-update-agent-details';
export const DELETE_AGENT = ADMIN + 'delete-agent-details';
export const GET_AGENT_BY_ID = ADMIN + 'get-agent-by-id';

// State and city
export const GET_STATE = '/api/state-list';
export const GET_CITY = '/api/city-list';

// global crud
export const ADD_GLOBAL_CROP =  ADMIN + 'add-global-crop-details';
export const GLOBAL_CROP_VARIETY =  ADMIN + 'global-crop-variety';
export const ADD_GLOBAL_PACKING_SIZE =  ADMIN + 'add-global-packing-size-details';

// Crop
export const GET_CROP = '/api/swarnim-crop-list';
// Variety
export const GET_VARIETY = '/api/swarnim-variety-list';

// project list
export const PROJECT_LIST = '/api/project-list';

// Raw Material 
export const RAW_MATERIAL_SLIP = ADMIN + 'raw-material-slip';
export const GET_RAW_MATERIAL_LIST = ADMIN + 'raw-material-list';
export const ADD_AFTER_RAWMATERIAL_DATA = ADMIN + 'add-after-raw-material';
export const GET_DATA_BY_LOT_NO = ADMIN + 'get-data-by-lot-no';
export const DELETE_RAW_MATERIAL = ADMIN + 'delete-raw-material';

//Attendance Data
export const GET_ATTENDANCE_DATA = ADMIN + 'get-all-attendance-details';
export const UPDATE_ATTENDANCE_STATUS = ADMIN + 'change-attendance-status';

// Location
export const GET_ALL_SALESMAN = '/api/get-all-salesman';
export const GET_USER_LOCATION = ADMIN + 'get-location-details';

//Leave 
export const GET_LEAVE_DATA = ADMIN + 'get-all-leave';
export const CHANGE_LEAVE_STATUS = ADMIN + 'change-leave-status';

//Dealer
export const GET_DEALER_DATA = ADMIN + 'get-dealer-details';
export const ADD_DEALER_DATA = ADMIN + 'add-dealer-lead';
export const GET_DEALER_BY_ID = ADMIN + 'get-dealer-by-id';
export const GET_DEALER_LEAD_DATA = ADMIN + 'dealer-lead-list';
export const APPROVE_DEALER_LEAD = ADMIN + 'approve-dealer-lead';
export const DELETE_DEALER_DATA = ADMIN + 'delete-dealer-details';
export const EDIT_DEALER_DATA = ADMIN + 'update-dealer-data';
export const GET_DEALER_PDF = ADMIN + 'dealer-pdf';

//Farmer 
export const GET_FARMER_DATA = ADMIN + 'get-all-farmer';
export const ADD_EDIT_FARMER_DATA = ADMIN + 'add-update-farmer';
export const GET_FARMER_DATA_BY_ID = ADMIN + 'get-farmer-by-id';
export const DELETE_FARMER_DATA = ADMIN + 'delete-farmer-details';
export const GET_FARMER_DATA_ORGANIZER = ADMIN + 'get-farmer-by-organizer-id';

// Crops
export const GET_CROP_DATA = ADMIN + 'crop-list';
export const ADD_EDIT_CROP_DATA = ADMIN + 'add-update-crop';
export const GET_CROP_DATA_BY_ID = ADMIN + 'crop-by-id';
export const GET_CROP_PACKING_SIZE_BY_ID = ADMIN + 'get-crop-packing-size-by-id';
export const DELETE_CROP_DATA = ADMIN + 'delete-crop';
export const DELETE_CROP_DETAIL_BY_ID = ADMIN + 'delete-crop-detail-by-id';
export const VARIETY_LIST = ADMIN + 'variety-list';
export const WEIGHT_LIST = ADMIN + 'weight-list';
export const LOT_NO_LIST = ADMIN + 'get-lot-wise-data';
export const GET_LOTNO_BY_ID = ADMIN + 'get-lot-no-by-id';
export const GET_CROP_STOCK = ADMIN + 'crop-stock';
export const LOT_NO_LIST_BY_PACKING_SIZE = ADMIN + 'lot-no-list-by-packing-size';

// Packaging Material Stock
export const GET_PACKAGING_STOCK_DATA = ADMIN + 'get-packing-stock-data';
export const EDIT_PACKAGING_STOCK_DATA = ADMIN + 'edit-packing-stock-data';
export const EDIT_LOT_NO_STOCK = ADMIN + 'edit-lot-no-stock';

// Get Expense Data
export const GET_EXPENSE_DATA = ADMIN + 'get-expense-details';
export const APPROVE_EXPENSE_DATA = ADMIN + 'approve-expense';
export const GET_EXPENSE_DATA_BY_ID = ADMIN + 'get-expense-data-by-id';

// ORDER
export const ADD_UPDATE_ORDER = ADMIN + 'add-update-order-details';
export const GET_ORDER_LIST = ADMIN + 'get-order-list';
export const GET_ORDER_BY_ID = ADMIN + 'get-order-by-id';
export const APPROVE_ORDER = ADMIN + 'change-order-status';
export const DELETE_ORDER = ADMIN + 'delete-order';
export const ORDER_TRANSFER_LIST = ADMIN + 'order-transfer-list';
export const ORDER_TRANSFER_APPROVE = ADMIN +'order-transfer-approve';
export const ORDER_RETURN = ADMIN +'order-return-qty';

// user
export const ADD_EMPLOYEE = ADMIN + 'register';
export const GET_ALL_USERS = '/api/get-all-users';
export const CHANGE_PASSWORD = ADMIN + 'change-admin-password';
export const USER_DATA = '/api/get-user-by-id';

// processing
export const PROCESS_LIST = ADMIN + 'process-list';
export const CHANGE_RAW_MATERIAL_STATUS = ADMIN + 'change-raw-material-status'; 
export const UPDATE_PROCESS_DATA = ADMIN + 'update-process-data';

// packaging
export const PACKING_LIST = ADMIN + 'packing-data';
export const ADD_PACKING_DATA = ADMIN + 'add-packaging-material';
export const GET_PACKING_DATA_BY_ID = ADMIN + 'get-packing-data-by-id';
export const PACKING_MATERIAL_LIST = ADMIN + 'packing-material-list';

//Dispatch
export const ADD_DISPATCH_DATA = ADMIN + 'add-dispatch-data';
export const ADD_ORDER_DISPATCH_DATA = ADMIN + 'add-order-dispatch-data';
export const GET_DISPATCH_DATA = ADMIN + 'get-dispatch-data';
export const DELETE_DISPATCH = ADMIN + 'delete-dispatch';

export const RETURN_ORDER = ADMIN + 'return-order';
export const RETURN_ORDER_LIST = ADMIN + 'return-order-list';
export const ON_RETURN = ADMIN + 'on-click-return';

// Production
export const ADD_UPDATE_PRODUCTION = ADMIN + 'add-update-production-data'; 
export const GET_PRODUCTION_DATA =ADMIN + 'get-production-data';
export const GET_PROODUCTION_DETAIL_BY_ID = ADMIN + 'get-production-detail-by-id';
export const ADD_SAMPLING_DATA = ADMIN + 'add-sampling-data'; 
export const ADD_INSPECTION_DATA = ADMIN + 'add-field-inspection-data'; 
export const GET_PRODUCTION_DATA_BY_ID =ADMIN + 'get-production-data-by-id';
export const DELETE_PRODUCTION_DETAIL_BY_ID = ADMIN + 'delete-production-detail-by-id';

// report
export const GET_SAMPLING_DATA = ADMIN + 'get-sampling-list';
export const GET_INSPECTION_DATA = ADMIN + 'get-inspection-list';
export const GET_TESTING_DATA = ADMIN + 'get-testing-list';

export const EDIT_CROPWISE_PACKANGING_MATERIAL = ADMIN + 'edit-crop-packaging-data';
export const GET_CROPWISE_PACKANGING_MATERIAL = ADMIN + 'get-crop-packaging-data';
export const GET_PACKING_SIZE_DATA = ADMIN + 'get-packing-size-data';

//GTM
export const GET_GTM_DATA = ADMIN + 'get-gtm-data';
export const ADD_UPDATE_GTM_DATA = ADMIN + 'add-update-gtm-data';
export const DELETE_GTM_DATA = ADMIN + 'delete-gtm-by-id';

// doctor
export const ADD_TEST_DETAILS = ADMIN + 'add-test-details';
export const FETCH_TEST_DETAILS_BY_LOT_NO = ADMIN +'fetch-test-data-by-lot-no';
export const UPDATE_TEST_RESULT = ADMIN + 'update-test-result';

export const DELETE_EMPLOYEE = ADMIN + 'delete-user';

// Remenant
export const REMENANT_LIST = ADMIN + 'remenant-list';
export const ADD_REMENANT_DISPATCH = ADMIN + 'add-remenant-dispatch-data';

// Split Raw Data
export const ADD_SPLIT_RAW_DATA = ADMIN + 'add-split-raw-data';

// merge lot no
export const LOT_NO_MERGE = ADMIN + 'lot-no-merge-data';
export const LOT_NO_LIST_FOR_MERGE = ADMIN + 'lot-no-list-for-merge';
export const FETCH_PROCESSED_QTY_BY_LOTNO = ADMIN + 'fetch-processed-qty-by-lotno';

// revrt lot no stock
export const REVERT_DATA = ADMIN + 'revert-data';