import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';

// Data table
import { inspectionTypeDropdown, isolationDistanceDropdown, cropConditionDropdown, roughingStatusDropdown2, roughingStatusDropdown3, cropStageDropdown, weightDropdown, wasteAreaDropdown } from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';
import { InspectionValidate } from '../../config/Validate';
import { Loader } from '../../components/Loader/Loader';

export const FieldInspection = () => {

    document.title = 'Field Inspection | Swarnim Farms';

    const pageService = new PageService();

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    const toast = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageLoad, setPageLoad] = useState(false);

    const [productionDetailId, setProductionDetailId] = useState<any>();
    const [productionDetail, setProductionDetail] = useState<any>(null);
    const [inspectionData, setInspectionData] = useState<any>({
        weight : { name: 'kg', code: 'kg' }
    });
    const [inspectionType, setInspectionType] = useState<any>(null);
    const [productionId, setProductionId] = useState<any>(null);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setProductionDetailId(state);
            getProductionDataFromApi(state);
        }
    }, []);

    // Get Production detail Data from API
    const getProductionDataFromApi = async (state: any) => {
        setPageLoad(true);
        // call api
        pageService.getProductionDetailById(state.id).then((response) => {
            // get response
            if (response) {
                const DataList = response;
                setProductionId(DataList.production_id)
                if (DataList.length == 0) {
                    setProductionDetail(null);
                } else {
                    setProductionDetail(DataList);
                    setPageLoad(false);
                }
            } else {
                setProductionDetail(null);
            }
        });
    };

    //onChange inspection Data
    const onInputChange = (e: any, name: string) => {
        let val;
        if (name == 'crop_condition' || name == 'isolation' || name == 'roughing_status' || name == 'crop_stage' || name == 'expected_harvesting_date' || name == 'inspection_date' || name == 'weight' || name == 'wasted_due_to') {
            val = e;
        } else {
            val = (e.target && e.target.value) || '';
            val = val.toUpperCase();
        }

        setInspectionData({ ...inspectionData, [name]: val });
    };

    // add inspection data
    const onSubmit = async () => {
        const { errors, isError } = InspectionValidate(inspectionData, productionDetail, inspectionType);
        setErrors(errors);
        setIsError(isError);

        //Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            let formData = new FormData();

            //form data for 1st inspection
            if (!window.cn(productionDetail) && productionDetail?.status == 0) {
                formData.append('inspection', '1');
                formData.append('isolation', inspectionData?.isolation?.code);
                formData.append('germination_status', inspectionData?.germination_status);
                if (!window.cn(inspectionType)) {
                    formData.append('inspection_type', inspectionType?.code);
                }
                formData.append('actual_sowing_area', inspectionData?.actual_sowing_area);
            } else if (!window.cn(productionDetail) && productionDetail?.status == 1) {
                //form data for 2nd inspection
                formData.append('inspection', '2');
                formData.append('off_type', inspectionData.off_type);
                formData.append('roughing_status', inspectionData?.roughing_status?.code);
            } else if (!window.cn(productionDetail) && productionDetail?.status == 2) {
                //form data for 3rd inspection
                formData.append('inspection', '3');
                var date = new Date(inspectionData?.expected_harvesting_date);
                let harvestingDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                formData.append('expected_harvesting_date', harvestingDate);
                formData.append('roughing_status', inspectionData.roughing_status.code);
                formData.append('expected_yield', inspectionData?.expected_yield);
                formData.append('weight', inspectionData?.weight?.code);
            }

            var inspectiondt = new Date(inspectionData?.inspection_date);
            let inspectionDate = inspectiondt.getFullYear() + "-" + (inspectiondt.getMonth() + 1) + "-" + inspectiondt.getDate();
            formData.append('inspection_date', inspectionDate);
            formData.append('crop_stage', inspectionData?.crop_stage?.code);
            formData.append('crop_condition', inspectionData?.crop_condition?.code);
            formData.append('recommendation', inspectionData.recommendation);
            formData.append('remarks', inspectionData.remarks);
            formData.append('insect_disease', inspectionData.insect_disease);

            if (!window.cn(inspectionData) && inspectionData.wasted_due_to) {
                formData.append('wasted_due_to', inspectionData?.wasted_due_to?.code);
            }
            if (!window.cn(inspectionData) && inspectionData.wasted_area) {
                formData.append('wasted_area', inspectionData.wasted_area);
            }

            formData.append('production_detail_id', productionDetailId.id);

            // Api call
            pageService.addFieldInspectionData(formData).then((response) => {
                // Get Response
                setLoading(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: response.message,
                });
                setTimeout(() => {
                    navigate('/product/view-production-details', { state: { id: productionDetail.production_id } });
                }, 1000)
            }).catch(error => {
                setLoading(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.error,
                });
            }
            );
        }
    };

    return (
        <>
            <div className="col-12">
                <h4> <Button
                    //label="Back"
                    icon="pi pi-arrow-left"
                    className="p-button-secondary mr-2"
                    onClick={() => navigate('/product/view-production-details', { state: { id: productionId } })}
                />    Field Inspection Details</h4>

                {pageLoad == false ?
                    <div className="card">

                        <Toast ref={toast} />
                        <div className="p-fluid formgrid grid">
                            <div className="field col-4 flex flex-column">
                                <p> <b>Name Of Grower</b> : {!window.cn(productionDetail) ? productionDetail?.farmer_name : ""}</p>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <p> <b>Crop</b> : {!window.cn(productionDetail) ? productionDetail?.crop_obj?.name : ""}</p>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <p> <b>Variety</b> : {!window.cn(productionDetail) ? productionDetail?.variety_obj?.name : ""}</p>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <p> <b>Village</b> : {!window.cn(productionDetail) ? productionDetail?.village : ""}</p>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <p> <b>Taluka</b> : {!window.cn(productionDetail) ? productionDetail?.taluka : ""}</p>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <p> <b>District</b> : {!window.cn(productionDetail) ? productionDetail?.district : ""}</p>
                            </div>
                            <div className="field col-4 flex flex-column">
                                <p> <b>Distributed Area (in acres)</b> : {!window.cn(productionDetail) ? productionDetail?.area : ""}</p>
                            </div>
                        </div>

                        <hr />

                        {!window.cn(productionDetail) && productionDetail.inspection.length > 0 ?
                            <>
                                {!window.cn(productionDetail.status) && productionDetail.status > 0 ?
                                    <>
                                        <div className="p-fluid formgrid grid">
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Inspection Type</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].inspection_type : ""}</p>
                                            </div>
                                            {!window.cn(productionDetail) && productionDetail.inspection.length > 0 ?
                                                <div className="field col-4 flex flex-column">
                                                    <p> <b>Final Sowing Area (in acres)</b> : 
                                                        {!window.cn(productionDetail) && productionDetail.inspection.length == 2 ? productionDetail.inspection[0].actual_sowing_area - productionDetail?.inspection[0]?.wasted_area - productionDetail?.inspection[1]?.wasted_area
                                                            : !window.cn(productionDetail) && productionDetail.inspection.length == 3 ? productionDetail.inspection[0].actual_sowing_area - productionDetail?.inspection[0]?.wasted_area - productionDetail?.inspection[1]?.wasted_area - productionDetail?.inspection[2]?.wasted_area : productionDetail.inspection[0].actual_sowing_area - productionDetail?.inspection[0]?.wasted_area
                                                        }
                                                    </p>
                                                </div> : ""}
                                            <div className="field col-12 flex flex-column"></div>
                                            <div className="field col-12 flex flex-column"><b>1st Inspection</b></div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Isolation</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].isolation : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Germination Status (In %)</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].germination_status : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Actual Sowing Area (in acres)</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].actual_sowing_area : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Date Of Inspection</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].inspection_date : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Crop Stage</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].crop_stage : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Crop Condition</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].crop_condition : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Insect/Disease</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].insect_disease : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Recommendations</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].recommendation : ""}</p>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>Remarks</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].remarks : ""}</p>
                                            </div>
                                            {!window.cn(productionDetail) && productionDetail.inspection[0].wasted_due_to ?
                                                <div className="field col-4 flex flex-column">
                                                    <p> <b>{productionDetail.inspection[0].wasted_due_to} (in acres)</b> : {productionDetail.inspection[0].wasted_area}</p>
                                                </div> : ""}
                                            <div className="field col-4 flex flex-column">
                                                <p> <b>1st Inspection Data Added By</b> : {!window.cn(productionDetail) ? productionDetail.inspection[0].data_added_by_user : ""}</p>
                                            </div>
                                        </div>
                                        <hr />

                                        {!window.cn(productionDetail.status) && productionDetail.status > 1 ?
                                            <>
                                                <div className="p-fluid formgrid grid">
                                                    <div className="field col-12 flex flex-column"><b>2nd Inspection</b></div>
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>Off Type (In %)</b> : {!window.cn(productionDetail) ? productionDetail.inspection[1].off_type : ""}</p>
                                                    </div>
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>Roughing Status</b> : {!window.cn(productionDetail) ? productionDetail.inspection[1].roughing_status : ""}</p>
                                                    </div>
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>Date Of Inspection</b> : {!window.cn(productionDetail) ? productionDetail.inspection[1].inspection_date : ""}</p>
                                                    </div>
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>Crop Stage</b> : {!window.cn(productionDetail) ? productionDetail.inspection[1].crop_stage : ""}</p>
                                                    </div>
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>Crop Condition</b> : {!window.cn(productionDetail) ? productionDetail.inspection[1].crop_condition : ""}</p>
                                                    </div>
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>Insect/Disease</b> : {!window.cn(productionDetail) ? productionDetail.inspection[1].insect_disease : ""}</p>
                                                    </div>
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>Recommendations</b> : {!window.cn(productionDetail) ? productionDetail.inspection[1].recommendation : ""}</p>
                                                    </div>
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>Remarks</b> : {!window.cn(productionDetail) ? productionDetail.inspection[1].remarks : ""}</p>
                                                    </div>
                                                    {!window.cn(productionDetail) && productionDetail.inspection[1].wasted_due_to ?
                                                        <div className="field col-4 flex flex-column">
                                                            <p> <b>{productionDetail.inspection[1].wasted_due_to} (in acres)</b> : {productionDetail.inspection[1].wasted_area}</p>
                                                        </div> : ""}
                                                    <div className="field col-4 flex flex-column">
                                                        <p> <b>2nd Inspection Data Added By</b> : {!window.cn(productionDetail) ? productionDetail.inspection[1].data_added_by_user : ""}</p>
                                                    </div>
                                                </div>
                                                <hr />

                                                {!window.cn(productionDetail.status) && productionDetail.status > 2 ?
                                                    <>
                                                        <div className="p-fluid formgrid grid">
                                                            <div className="field col-12 flex flex-column"><b>3rd Inspection</b></div>
                                                            <div className="field col-4 flex flex-column">
                                                                <p> <b>Roughing Status</b> : {!window.cn(productionDetail) ? productionDetail.inspection[2].roughing_status : ""}</p>
                                                            </div>
                                                            <div className="field col-4 flex flex-column">
                                                                <p> <b>Expected Harvesting Date</b> : {!window.cn(productionDetail) ? productionDetail.inspection[2].expected_harvesting_date : ""}</p>
                                                            </div>
                                                            <div className="field col-4 flex flex-column">
                                                                <p> <b>Expected Yield</b> : {!window.cn(productionDetail) ? productionDetail.inspection[2].expected_yield + " " + productionDetail.inspection[2].weight : ""}</p>
                                                            </div>
                                                            <div className="field col-4 flex flex-column">
                                                                <p> <b>Date Of Inspection</b> : {!window.cn(productionDetail) ? productionDetail.inspection[2].inspection_date : ""}</p>
                                                            </div>
                                                            <div className="field col-4 flex flex-column">
                                                                <p> <b>Crop Stage</b> : {!window.cn(productionDetail) ? productionDetail.inspection[2].crop_stage : ""}</p>
                                                            </div>
                                                            <div className="field col-4 flex flex-column">
                                                                <p> <b>Crop Condition</b> : {!window.cn(productionDetail) ? productionDetail.inspection[2].crop_condition : ""}</p>
                                                            </div>
                                                            <div className="field col-4 flex flex-column">
                                                                <p> <b>Insect/Disease</b> : {!window.cn(productionDetail) ? productionDetail.inspection[2].insect_disease : ""}</p>
                                                            </div>
                                                            <div className="field col-4 flex flex-column">
                                                                <p> <b>Recommendations</b> : {!window.cn(productionDetail) ? productionDetail.inspection[2].recommendation : ""}</p>
                                                            </div>
                                                            <div className="field col-4 flex flex-column">
                                                                <p> <b>Remarks</b> : {!window.cn(productionDetail) ? productionDetail.inspection[2].remarks : ""}</p>
                                                            </div>
                                                            {!window.cn(productionDetail) && productionDetail.inspection[2].wasted_due_to ?
                                                                <div className="field col-4 flex flex-column">
                                                                    <p> <b>{productionDetail.inspection[2].wasted_due_to} (in acres)</b> : {productionDetail.inspection[2].wasted_area}</p>
                                                                </div> : ""}
                                                            <div className="field col-4 flex flex-column">
                                                                <p> <b>3rd Inspection Data Added By</b> : {!window.cn(productionDetail) ? productionDetail.inspection[2].data_added_by_user : ""}</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>
                                                    : ""
                                                }
                                            </>
                                            : ""
                                        }
                                    </>
                                    : ""
                                }
                            </>
                            : ""
                        }

                        {!window.cn(productionDetail) && productionDetail?.status == 0 ?
                            <>
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12 flex flex-column">
                                        <label htmlFor="inspection_type">Inspection Type *</label>
                                        <Dropdown
                                            filter
                                            value={inspectionType}
                                            onChange={(e) => setInspectionType(e.value)}
                                            options={inspectionTypeDropdown}
                                            optionLabel="name"
                                            name="inspection_type"
                                            placeholder="Select Inspection Type"
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['inspection_type']}</small>
                                    </div>
                                    <div className="field col-12 flex flex-column"></div>
                                    <div className="field col-12 flex flex-column"><b>1st Inspection</b></div>

                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="isolation">Isolation *</label>
                                        <Dropdown
                                            filter
                                            value={inspectionData?.isolation}
                                            onChange={(e) => onInputChange(e.value, "isolation")}
                                            options={isolationDistanceDropdown}
                                            optionLabel="name"
                                            name="isolation"
                                            placeholder="Select Isolation"
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['isolation']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="germination_status">Germination Status (In %) *</label>
                                        <InputText
                                             keyfilter="num"
                                            value={!window.cn(inspectionData) ? inspectionData.germination_status : ""}
                                            name="germination_status"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "germination_status")}
                                        />
                                        <small className="p-invalid-txt">{errors['germination_status']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="actual_sowing_area">Actual Sowing Area (in acres) *</label>
                                        <InputText
                                             keyfilter="num"
                                            value={!window.cn(inspectionData) ? inspectionData.actual_sowing_area : ""}
                                            name="actual_sowing_area"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "actual_sowing_area")}
                                        />
                                        <small className="p-invalid-txt">{errors['actual_sowing_area']}</small>
                                    </div>
                                </div>
                            </>
                            : !window.cn(productionDetail) && productionDetail?.status == 1 ?
                                <>
                                    <div className="p-fluid formgrid grid">
                                        <div className="field col-12 flex flex-column"><b>2nd Inspection</b></div>

                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="off_type">Off Type (In %) *</label>
                                            <InputText
                                                 keyfilter="num"
                                                value={!window.cn(inspectionData) ? inspectionData.off_type : ""}
                                                name="off_type"
                                                autoComplete="off"
                                                onChange={(e) => onInputChange(e, "off_type")}
                                            />
                                            <small className="p-invalid-txt">{errors['off_type']}</small>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="roughing_status">Roughing Status *</label>
                                            <Dropdown
                                                filter
                                                value={inspectionData?.roughing_status}
                                                onChange={(e) => onInputChange(e.value, "roughing_status")}
                                                options={roughingStatusDropdown2}
                                                optionLabel="name"
                                                name="roughing_status"
                                                placeholder="Select Roughing Status"
                                            ></Dropdown>
                                            <small className="p-invalid-txt">{errors['roughing_status']}</small>
                                        </div>
                                    </div>

                                </>
                                : !window.cn(productionDetail) && productionDetail?.status == 2 ?
                                    <>
                                        <div className="p-fluid formgrid grid">
                                            <div className="field col-12 flex flex-column"><b>3rd Inspection</b></div>

                                            <div className="field col-4 flex flex-column">
                                                <label htmlFor="expected_harvesting_date">Expected Date Of Harvesting *</label>
                                                <Calendar
                                                    value={!window.cn(inspectionData) ? inspectionData.expected_harvesting_date : ""}
                                                    dateFormat="dd/mm/yy"
                                                    onChange={(e) => onInputChange(e.value, 'expected_harvesting_date')}
                                                    name="expected_harvesting_date"
                                                    showIcon
                                                />
                                                <small className="p-invalid-txt">{errors['expected_harvesting_date']}</small>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <label htmlFor="roughing_status">Roughing Status *</label>
                                                <Dropdown
                                                    filter
                                                    value={!window.cn(inspectionData) ? inspectionData.roughing_status : ""}
                                                    onChange={(e) => onInputChange(e.value, "roughing_status")}
                                                    options={roughingStatusDropdown3}
                                                    optionLabel="name"
                                                    name="roughing_status"
                                                    placeholder="Select Roughing Status"
                                                ></Dropdown>
                                                <small className="p-invalid-txt">{errors['roughing_status']}</small>
                                            </div>
                                            <div className="field col-2 flex flex-column">
                                                <label htmlFor="expected_yield">Expected Yield *</label>
                                                <InputText
                                                     keyfilter="num"
                                                    value={!window.cn(inspectionData) ? inspectionData.expected_yield : ""}
                                                    name="expected_yield"
                                                    autoComplete="off"
                                                    onChange={(e) => onInputChange(e, "expected_yield")}
                                                />
                                                <small className="p-invalid-txt">{errors['expected_yield']}</small>
                                            </div>
                                            <div className="field col-2 flex flex-column" >
                                            <label htmlFor="expected_yield"> Weight *</label>
                                                <Dropdown
                                                    filter
                                                    value={inspectionData?.weight}
                                                    onChange={(e) => onInputChange(e.value, "weight")}
                                                    options={weightDropdown}
                                                    optionLabel="name"
                                                    placeholder="Select Weight"
                                                ></Dropdown>
                                            </div>
                                        </div>
                                    </> : ""
                        }

                        {!window.cn(productionDetail) && productionDetail.status < 3 ?
                            <>
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="inspection_date">Date Of Inspection *</label>
                                        <Calendar
                                            value={!window.cn(inspectionData) ? inspectionData.inspection_date : ""}
                                            dateFormat="dd/mm/yy"
                                            onChange={(e) => onInputChange(e.value, 'inspection_date')}
                                            name="inspection_date"
                                            showIcon
                                        />
                                        <small className="p-invalid-txt">{errors['inspection_date']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="crop_stage">Crop Stage *</label>
                                        <Dropdown
                                            filter
                                            value={inspectionData?.crop_stage}
                                            onChange={(e) => onInputChange(e.value, "crop_stage")}
                                            options={cropStageDropdown}
                                            optionLabel="name"
                                            name="crop_stage"
                                            placeholder="Select Crop Stage"
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['crop_stage']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="crop_condition">Crop Condition *</label>
                                        <Dropdown
                                            filter
                                            value={inspectionData?.crop_condition}
                                            onChange={(e) => onInputChange(e.value, "crop_condition")}
                                            options={cropConditionDropdown}
                                            optionLabel="name"
                                            name="inspection_type"
                                            placeholder="Select Crop Condition"
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['crop_condition']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="insect_disease">Insect/Disease *</label>
                                        <InputText
                                            value={!window.cn(inspectionData) ? inspectionData.insect_disease : ""}
                                            name="insect_disease"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "insect_disease")}
                                        />
                                        <small className="p-invalid-txt">{errors['insect_disease']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="recommendation">Recommendations *</label>
                                        <InputText
                                            value={!window.cn(inspectionData) ? inspectionData.recommendation : ""}
                                            name="recommendation"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "recommendation")}
                                        />
                                        <small className="p-invalid-txt">{errors['recommendation']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="remarks">Remarks *</label>
                                        <InputText
                                            value={!window.cn(inspectionData) ? inspectionData.remarks : ""}
                                            name="remarks"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "remarks")}
                                        />
                                        <small className="p-invalid-txt">{errors['remarks']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="wasted_due_to">Select</label>
                                        <Dropdown
                                            filter
                                            value={inspectionData?.wasted_due_to}
                                            onChange={(e) => onInputChange(e.value, "wasted_due_to")}
                                            options={wasteAreaDropdown}
                                            optionLabel="name"
                                            name="wasted_due_to"
                                            placeholder="Select"
                                        ></Dropdown>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="wasted_area">Rejected/PloughDown Area </label>
                                        <InputText
                                             keyfilter="num"
                                            value={!window.cn(inspectionData) ? inspectionData.wasted_area : ""}
                                            name="wasted_area"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "wasted_area")}
                                        />
                                    </div>
                                </div>

                            </> : ""}

                        <div className="card-footer">
                            <div className="button-group">
                                {!window.cn(productionDetail) && productionDetail?.status < 3 ?
                                    <>
                                        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-4" onClick={() => navigate('/product/view-production-details', { state: { id: productionDetail.production_id } })} />
                                        <Button label="Save" icon="pi pi-check" loading={loading} onClick={onSubmit} />
                                    </> : ""}
                            </div>
                        </div>
                    </div> : <>
                        <Loader />
                    </>}
            </div>
        </>
    )
}