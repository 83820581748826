import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';

//Services
import PageService from '../../service/PageService';
import { fieldEmergenceValidate } from '../../config/Validate';
import { Loader } from '../../components/Loader/Loader';
import { formatDateTime } from '../../appconfig/AppHelper';
import { Badge } from 'primereact/badge';

export const AddFieldEmergence = () => {

    document.title = 'Field Emergence | Swarnim Farms';

    const pageService = new PageService();

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    const toast = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageLoad, setPageLoad] = useState(false);

    const [codeNo, setCodeNo] = useState<any>(null);
    const [testData, setTestData] = useState<any>({});
    const [emergenceData, setEmergenceData] = useState<any>(null);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setCodeNo(state);
            const stateLength = Object.keys(state).length;
            if (stateLength == 2) {
                getTestingDataFromApi(state);
            }
        }
    }, []);

    // Get Production detail Data from API
    const getTestingDataFromApi = async (state: any) => {
        setPageLoad(true);
        // call api
        pageService.fetchTestDetails(state.lot_no).then((response) => {
            // get response
            if (response) {
                const DataList = response;
                setEmergenceData(DataList.field_emergence)
                setPageLoad(false);
            }
        });
    };


    //onChange inspection Data
    const onInputChange = (e: any, name: string) => {
        let val;
        if (name == 'field_emergence_date_of_observation' || name == 'date_of_sowing') {
            val = e;
        } else {
            val = (e.target && e.target.value) || '';
        }

        setTestData({ ...testData, [name]: val });
    };

    // add inspection data
    const onSubmit = async () => {
        const { errors, isError } = fieldEmergenceValidate(testData);
        setErrors(errors);
        setIsError(isError);

        //Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            let formData = new FormData();

            if (!window.cn(testData) && testData.field_emergence_date_of_observation) {
                var dateOfObs = new Date(testData.field_emergence_date_of_observation);
                let obsDate = dateOfObs.getFullYear() + "-" + (dateOfObs.getMonth() + 1) + "-" + dateOfObs.getDate();
                formData.append('field_emergence_date_of_observation', obsDate);
            }
            var dateOfSowing = new Date(testData?.date_of_sowing);
            let sowingDt = dateOfSowing.getFullYear() + "-" + (dateOfSowing.getMonth() + 1) + "-" + dateOfSowing.getDate();
            formData.append('date_of_sowing', sowingDt);

            formData.append('seeds_planted', testData.seeds_planted);
            formData.append('seeds_emerged', testData.seeds_emerged);
            formData.append('field_emergence', ((testData.seeds_emerged / testData.seeds_planted) * 100).toFixed(2));
            formData.append('seeds_planted_1', testData.seeds_planted_1);
            formData.append('seeds_emerged_1', testData.seeds_emerged_1);
            formData.append('field_emergence_1', ((testData.seeds_emerged_1 / testData.seeds_planted_1) * 100).toFixed(2));
            let totalSeeds = parseInt(testData.seeds_planted) + parseInt(testData.seeds_planted_1);
            var totalEmerged = parseInt(testData.seeds_emerged_1) + parseInt(testData.seeds_emerged);
            formData.append('total_seeds_planted', totalSeeds.toString());
            formData.append('total_seeds_emerged', totalEmerged.toString());
            formData.append('total_field_emergence', (((parseInt(testData.seeds_emerged) + parseInt(testData.seeds_emerged_1)) / (parseInt(testData.seeds_planted) + parseInt(testData.seeds_planted_1))) * 100).toFixed(2));

            if (!window.cn(testData) && testData.remark) {
                formData.append('remark', testData.remark);
            }
            formData.append('test_type', 'Field Emergence');
            formData.append('lot_no', codeNo.code_no);

            // Api call
            pageService.addTestData(formData).then((response) => {
                // Get Response
                setLoading(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: response.message,
                });
                setTimeout(() => {
                    navigate('/product/testing');
                }, 1000)
            }).catch(error => {
                setLoading(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.error,
                });
            }
            );
        }
    };

    return (
        <>
            {!window.cn(emergenceData) && emergenceData != null ?
                <>
                    <div className="col-12">
                        <h4> <Button
                            //label="Back"
                            icon="pi pi-arrow-left"
                            className="p-button-secondary mr-2"
                            onClick={() => navigate('/product/testing')}
                        />  Field Emergence Data</h4>

                        {pageLoad == false ?
                            <div className="card">
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Date Of Sowing</b> : {!window.cn(emergenceData) ? formatDateTime(emergenceData?.date_of_sowing) : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Date Of Observation</b> : {!window.cn(emergenceData) ? formatDateTime(emergenceData?.field_emergence_date_of_observation) : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Date</b> : {!window.cn(emergenceData) ? formatDateTime(emergenceData?.created_at) : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                    </div>
                                    <div className="field col-12 flex flex-column">
                                        <p> <b>Replication - 1</b></p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No. Of Sown Seeds (A)</b> : {!window.cn(emergenceData) ? emergenceData?.seeds_planted : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No. Of Emerged Seeds (B)</b> : {!window.cn(emergenceData) ? emergenceData?.seeds_emerged : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Field Emergence</b> : {!window.cn(emergenceData) ? emergenceData?.field_emergence : ""}</p>
                                    </div>
                                    <div className="field col-12 flex flex-column">
                                        <p> <b>Replication - 2</b></p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No. Of Sown Seeds (C)</b> : {!window.cn(emergenceData) ? emergenceData?.seeds_planted_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No. Of Emerged Seeds (D)</b> : {!window.cn(emergenceData) ? emergenceData?.seeds_emerged_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Field Emergence</b> : {!window.cn(emergenceData) ? emergenceData?.field_emergence_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Total No. Seeds Putted (A + C)</b> : {!window.cn(emergenceData) ? emergenceData?.total_seeds_planted : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Total No. Seeds Putted (B + D)</b> : {!window.cn(emergenceData) ? emergenceData?.total_seeds_emerged : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Total Field Emergence %</b> : {!window.cn(emergenceData) ? emergenceData?.total_field_emergence : ""}</p>
                                    </div>
                            
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Result</b> : {!window.cn(emergenceData) && emergenceData.result == 'Pass' ?
                                            <Badge severity="success" value={emergenceData.result}></Badge> :
                                            <Badge severity="warning" value={emergenceData.result}></Badge>
                                        }</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Remark</b> : {!window.cn(emergenceData) ? emergenceData?.remark : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Data Added By</b> : {!window.cn(emergenceData) ? emergenceData?.data_added_by_user : ""}</p>
                                    </div>
                                </div>
                            </div> : <>
                                <Loader />
                            </>}
                    </div>
                </> :
                <div className="col-12">
                    <h4> <Button
                        //label="Back"
                        icon="pi pi-arrow-left"
                        className="p-button-secondary mr-2"
                        onClick={() => navigate('/product/testing')}
                    />    Add Germination Data</h4>

                    {pageLoad == false ?
                        <div className="card">

                            <Toast ref={toast} />
                            <>
                                <div className="p-fluid formgrid grid">

                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="code_no">Code No</label>
                                        <InputText
                                            value={!window.cn(codeNo) ? codeNo.code_no : ""}
                                            name="code_no"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                        <small className="p-invalid-txt">{errors['field_emergence_status']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="date_of_sowing">Date Of Sowing </label>
                                        <Calendar
                                            value={!window.cn(testData) ? testData.date_of_sowing : ""}
                                            dateFormat="dd/mm/yy"
                                            onChange={(e) => onInputChange(e.value, 'date_of_sowing')}
                                            name="date_of_sowing"
                                            showIcon
                                        />
                                        <small className="p-invalid-txt">{errors['date_of_sowing']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="field_emergence_date_of_observation">Date Of Observation</label>
                                        <Calendar
                                            value={!window.cn(testData) ? testData.field_emergence_date_of_observation : ""}
                                            dateFormat="dd/mm/yy"
                                            onChange={(e) => onInputChange(e.value, 'field_emergence_date_of_observation')}
                                            name="field_emergence_date_of_observation"
                                            showIcon
                                        />
                                    </div>
                                    <div className="field col-12 flex flex-column"> <b> Replication-1</b></div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="seeds_planted">No Of Sown Seeds (A) *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.seeds_planted : ""}
                                            name="seeds_planted"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "seeds_planted")}
                                        />
                                        <small className="p-invalid-txt">{errors['seeds_planted']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="seeds_emerged">No. Of Emerged Seeds (B) *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.seeds_emerged : ""}
                                            name="seeds_emerged"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "seeds_emerged")}
                                        />
                                        <small className="p-invalid-txt">{errors['seeds_emerged']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="field_emergence">Field Emergence</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.seeds_emerged && testData.seeds_planted ? ((testData.seeds_emerged / testData.seeds_planted) * 100).toFixed(2) : ""}
                                            name="field_emergence"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-12 flex flex-column"> <b> Replication-2</b></div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="seeds_planted_1">No Of Sown Seeds (C) *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.seeds_planted_1 : ""}
                                            name="seeds_planted_1"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "seeds_planted_1")}
                                        />
                                        <small className="p-invalid-txt">{errors['seeds_planted_1']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="seeds_emerged_1">No. Of Emerged Seeds (D) *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.seeds_emerged_1 : ""}
                                            name="seeds_emerged_1"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "seeds_emerged_1")}
                                        />
                                        <small className="p-invalid-txt">{errors['seeds_emerged_1']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="field_emergence_1">Field Emergence</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.seeds_emerged_1 && testData.seeds_planted_1 ? ((testData.seeds_emerged_1 / testData.seeds_planted_1) * 100).toFixed(2) : ""}
                                            name="field_emergence_1"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_seeds_planted">Total No. Of Seeds Putted (A+C)</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.seeds_planted_1 && testData.seeds_planted ? (parseInt(testData.seeds_planted) + parseInt(testData.seeds_planted_1)) : ""}
                                            name="total_seeds_planted"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_seeds_emerged">Total No. Of Seeds Putted (B+D)</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.seeds_emerged_1 && testData.seeds_emerged ? (parseInt(testData.seeds_emerged_1) + parseInt(testData.seeds_emerged)) : ""}
                                            name="total_seeds_emerged"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_field_emergence">Total Field Emergence %</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.seeds_planted_1 && testData.seeds_planted ? (((parseInt(testData.seeds_emerged) + parseInt(testData.seeds_emerged_1)) / (parseInt(testData.seeds_planted) + parseInt(testData.seeds_planted_1))) * 100).toFixed(2) : ""}
                                            name="total_field_emergence"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="remark">Remark</label>
                                        <InputText
                                            value={!window.cn(testData) ? testData.remark : ""}
                                            name="remark"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "remark")}
                                        />
                                    </div>
                                </div>
                            </>

                            <div className="card-footer">
                                <div className="button-group">
                                    <>
                                        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-4" onClick={() => navigate('/product/testing')} />
                                        <Button label="Save" icon="pi pi-check" loading={loading} onClick={onSubmit} />
                                    </>
                                </div>
                            </div>
                        </div> : <>
                            <Loader />
                        </>}
                </div>}
        </>
    )
}