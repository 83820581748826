import React from 'react';

const AppFooter = (props: any) => {
    return (
        <div className="layout-footer">
            <div>
                <span>Designed &amp; Developed By&nbsp;</span>
                <a
                    href="https://helicoreinfo.com"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                >
                    <img className='footer-logo'
                        src="https://gujaratpharmacycouncil.co.in/front/favicon-helicore.png"
                        width="32"
                        height="32"
                        alt="NO Image"
                    />
                    <span className={`${props.colorScheme === "light" ? "black-txt" : "white-txt"}`}>&nbsp;Helicore Info Pvt. Ltd.</span>
                </a>
            </div>

        </div>
    );
};

export default AppFooter;
