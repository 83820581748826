import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';

//Services
import PageService from '../../service/PageService';

// Validatetion
import { globalCropDataValidate } from '../../config/Validate';

export const AddGlobalCrop = () => {
  document.title = 'Crops  | Swarnim Farms';

  //Navigate Another Route
  const navigate = useNavigate();
  const location = useLocation();

  //Crops Crud Object
  const [cropsData, setCropsData] = useState<any>({});
  const [cropArray, setCropArray] = useState<any>([{ name: '' }]);
  const [isCropArrayUpdate, setIsCropArrayUpdate] = useState(false);

  // Error object
  const [isError, setIsError] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});

  //Set Toast/ Filter Properties
  const toast = useRef<any>(null);

  // Edit and Delete Data Details
  const [varietyEditId, setVarietyEditId] = useState<any>(null);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // crop and variety
  const [crop, setCrop] = useState<any>();

  // use effect method
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (isCropArrayUpdate) {
      setIsCropArrayUpdate(false);
      getCropArrayUI();
    }
  }, [isCropArrayUpdate]);

  useEffect(() => {
    getCropArrayUI();
  }, [cropArray]);

  // Handle crops Data
  const handleCropArray = () => {
    const defaultObject = {
      name: '',
    };
    let array = cropArray;
    array.push(defaultObject);
    setCropArray(array);
    setIsCropArrayUpdate(true);
  };

  // Variant UI
  const getCropArrayUI = () => {
    return (
      <>
        {cropArray.map((item: any, index: number) => (
          <>
            <div className="field col-12 mb-0">
              <label htmlFor="name">Variety *</label>
              <InputText
                value={item.variety}
                name="name"
                autoComplete="off"
                onChange={(e) => onHandleChangeCrop(e, 'name', index)}
                className={errors['name'] && 'p-invalid'}
              />
              <small className="p-invalid-txt">{errors['name']}</small>
            </div>
            {index > 0 ? (
              <>
                <div className="field-col-12 md:col-12 mb-2">
                  {/* <div style={{ marginTop: '25px' }}> */}
                  {item.id != null ? (
                    <Button
                      icon="pi pi-trash"
                      className="p-button-rounded p-button-danger mt-2"
                      onClick={() => deleteCropDetail(item.id, index)}
                    />
                  ) : (
                    <Button
                      icon="pi pi-trash"
                      className="p-button-rounded p-button-danger mt-2"
                      onClick={() => deleteCrop(index)} />
                  )}
                  {/* </div> */}
                </div>

              </>
            ) : (
              <>
                <div className="field-col-12 md:col-12"></div>
              </>
            )}
          </>
        ))}
      </>
    );
  };

  // onHandleChange for crops
  const onHandleChangeCrop = (e: any, name: string, index: number) => {
    let tempCropArray = cropArray;
    tempCropArray[index][name] = e.target.value;
    setCropArray(tempCropArray);
    setIsCropArrayUpdate(true);
  };

  // Delete crop
  const deleteCrop = (index: number) => {
    let deleteCropArray = cropArray;
    deleteCropArray.splice(index, 1);
    setCropArray(deleteCropArray);
    setIsCropArrayUpdate(true);
  };

  // for add and edit crop api call on submit
  const onSubmit = async () => {
    const { errors, isError } = globalCropDataValidate(cropArray, crop);
    setErrors(errors);
    setIsError(isError);

    if (!isError) {
      setLoading(true);
      // variety object on the basis of condition
      if (varietyEditId) {
        cropArray.append('id', varietyEditId);
        cropArray.remove('created_at');
        cropArray.remove('updated_at');
      }

      //Request object
      let formRequestData = new FormData();

      formRequestData.append('varieties', JSON.stringify(cropArray));
      formRequestData.append('name', crop);

      // api call
      pageService.addGlobalCrop(formRequestData).then((result) => {
        setLoading(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: result.message,
        });
        setTimeout(() => {
          navigate('/stock/global-crop');
        }, 1000)
      }).catch(error => {
        setCrop({ crop });
        setErrors({});
        setErrors(false);
        setLoading(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error,
        });
      }
      );
    }
  };

  // call delete api
  const deleteCropDetail = async (id: any, index: any) => {
    setPageLoad(true);
    let deleteCropArray = cropArray;
    deleteCropArray.splice(index, 1);
    setCropArray(deleteCropArray);
    setIsCropArrayUpdate(true);

    // request data
    let requestData = {
      id: id,
    };

    // call api
    pageService.deleteCropDetailById(requestData).then((response) => {
      // get response
      setPageLoad(false);
    });
  };

  //open edit dialog and set data
  const editCrops = async (id: any) => {
    setPageLoad(true);

    await pageService.getCropsDataById(id.id).then((result) => {
      let cropDetails = result.varieties;

      let varieryArray: {
        id: any;
        variety: any;
      }[] = [];

      cropDetails.map((item: any, index: number) => {

        varieryArray.push({
          id: item.id,
          variety: item.variety,
        });
      });

      if (cropDetails && cropDetails.id) {
        setVarietyEditId(cropDetails.id);
      } else {
        setVarietyEditId(null);
      }

      setCropsData(result);
      setCropArray(varieryArray);
      setIsCropArrayUpdate(true);
      setPageLoad(false);
    }
    )
  }

  return (
    <>
      <h4> <Button
        //label="Back"
        icon="pi pi-arrow-left"
        className="p-button-secondary mr-2"
        onClick={() => navigate('/stock/global-crop')}
      />   Add Crop Details</h4>
      <Toast ref={toast} />
      <div className="card" style={{ position: "relative" }}>
        <div className="p-fluid formgrid grid">

          <div className="field col-12 ">
            <label htmlFor="crop">Crop *</label>
            <InputText
              value={cropsData?.crop}
              onChange={(e) => setCrop(e.target.value)}
              name="crop"
              autoComplete="off"
              className={errors['crop'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['crop']}</small>
          </div>


          {getCropArrayUI()}

          <div className="field col-12 md:col-12">
            <Button
              style={{ width: 'fit-content' }}
              label="Add More"
              icon="pi pi-plus"
              className="p-button"
              onClick={handleCropArray}
            />
          </div>

          <div className="card-footer">
            <div className="button-group">
              <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-4" onClick={() => navigate('/stock/list')} />
              <Button label="Save" icon="pi pi-check" loading={loading} onClick={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
