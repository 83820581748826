import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';

// Column
import { RemenantColumns } from '../../appconfig/DatatableSetting';

// Data table
import { defaultPageRowOptions, defaultRowOptions, paginatorLinks, showingEntries, SkeletonbodyTemplate, Skeletonitems } from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

export const Remenant = () => {
  document.title = 'Seed Remenant | Swarnim Farms';

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [products, setProducts] = useState<any>([]);

  //Navigate Another Route
  const navigate = useNavigate();

  // Page service
  const pageService = new PageService();

  // use effect method
  useEffect(() => {
    getData();
  }, []);

  // get raw matrail product data
  const getData = async () => {
    setLoading(true);

    // Api call
    pageService.remenantList()
      .then((response) => {
        // Get response
        if (response) {
          const productData = response;
          if (productData.length == 0) {
            setProducts([]);
            setLoading(false);
            setPageLoad(true);
          } else {
            setProducts(productData);
            setLoading(false);
            setPageLoad(true);
          }
        } else {
          setProducts([]);
          setLoading(false);
          setPageLoad(false);
        }
      });
  };

  // for left toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  return (<>
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='main-header'>
            <h4 className='header'> Remanant List </h4>
            <Button
              label="Add Remenant Dispatch"
              icon="pi pi-plus"
              className="p-button-success mr-2"
              onClick={() => navigate('/product/add-remenant-dispatch')}
            />
          </div>
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
          ></Toolbar>

          {/* Datatable Start */}
          {pageLoad ? (
            <>
              <DataTable
                value={products}
                loading={loading}
                paginator={products.length > 0 && true}
                globalFilter={globalFilter}
                rows={defaultRowOptions}
                rowsPerPageOptions={defaultPageRowOptions}
                className="datatable-responsive"
                paginatorTemplate={paginatorLinks}
                currentPageReportTemplate={showingEntries}
                emptyMessage="No Remenant Data Found"
              >
                {RemenantColumns.map((col, i) => {
                  if (col.field === 'sr_no') {
                    return (
                      <Column
                        key={col.field}
                        header={col.header}
                        body={(_, { rowIndex }) => rowIndex + 1} />
                    );
                  } else {
                    return (
                      <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                        sortable
                        filter
                      />
                    );
                  }
                })}
              </DataTable>
            </>
          ) : (
            <>
              {/* Skeleton Data table */}
              <DataTable value={Skeletonitems}>
                {RemenantColumns.map((col, i) => (
                  <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    body={SkeletonbodyTemplate}
                  />
                ))}
              </DataTable>
            </>
          )}
          {/* Datatable End */}
        </div>
      </div>
    </div>
  </>);
}