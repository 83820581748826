import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useRef, useState } from 'react';
import {
  GTMColumns
} from '../../appconfig/DatatableSetting';
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
  weightDropdown,
} from '../../appconfig/Settings';

import { GTMValidate } from '../../config/Validate';

//Services
import PageService from '../../service/PageService';
import { formatDateTime } from '../../appconfig/AppHelper';

export const Gtm = () => {
  document.title = 'GTM | Swarnim Farms';

  // Page service
  const pageService = new PageService();

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  const [cropData, setCropData] = useState<any>([]);
  const [varietyData, setVarietyData] = useState<any>([]);
  const [gtmList, setGTMList] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);
  const [pageLoad, setPageLoad] = useState(false);
  const [gtmDialog, setGTMDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editId, setEditId] = useState<any>(null);
  const [gtmData, setGTMData] = useState<any>({});
  const [selectedCropId, setSelectedCropId] = useState<any>();
  const [selectedVarietyId, setSelectedVarietyId] = useState<any>();
  const [weight, setWeight] = useState<any>({ name: "kg", code: "kg" });
  const [isError, setIsError] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});
  const [viewGTMData, setViewGTMData] = useState<any>({});
  const [gtmViewDialog, setGTMViewDialog] = useState(false);
  const [gtmDeleteDialog, setGTMDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // use effect method
  useEffect(() => {
    getDataFromAPI();
    getCropList();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // Get crops
  const getCropList = async () => {
    try {
      const cropData: any = [];

      pageService.cropList().then((response) => {
        if (response) {
          const cropDataList = response;
          for (let i = 0; i < cropDataList.length; i++) {
            const userObj: any = {};
            userObj['name'] = cropDataList[i].name;
            userObj['code'] = cropDataList[i].id;
            cropData.push(userObj);
          }
          setCropData(cropData);
        }
      });

    } catch (error) { }
  };

  //Get variety list
  const varietyList = async (selectedCropId: any, extra: string) => {
    try {
      let cropId = !window.cn(selectedCropId) ? selectedCropId.code : '';
      const varietyData: any = [];
      pageService.varietyList(cropId).then((response) => {
        if (response) {
          const varietyDataList = response;
          for (let i = 0; i < varietyDataList.length; i++) {
            const varietyObj: any = {};
            varietyObj['name'] = varietyDataList[i].name;
            varietyObj['code'] = varietyDataList[i].id;
            varietyData.push(varietyObj);
          }
          setVarietyData(varietyData);
        }
      });
    } catch (error) { }
  };

  // Get orders Data from API
  const getDataFromAPI = async () => {

    pageService.getGTMData(!window.cn(dates) && dates[0], !window.cn(dates) && dates[1]).then((response) => {
      setPageLoad(false);
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setGTMList([]);
        } else {
          setGTMList(DataList);
        }
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }

  // Handle Select Crop
  const handleSelectedCrop = (value: any) => {
    if (!window.cn(value)) {
      setSelectedCropId(value);
      varietyList(value, 'null');
    } else {
      setSelectedCropId('');
      setVarietyData([]);
    }
  };

  // Handle Select Crop
  const handleSelectedVariety = (value: any) => {
    if (!window.cn(value)) {
      setSelectedVarietyId(value);
    } else {
      setSelectedVarietyId('');
    }
  };

  //onChange proccessing data
  const onInputChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    setGTMData({ ...gtmData, [name]: val.toUpperCase() });
  };

  // open add gtm dialog
  const addDialog = () => {
    setGTMDialog(true);
  };

  const hideAddDialog = () => {
    setGTMDialog(false);
    setLoading(false);
    setSelectedCropId(null);
    setSelectedVarietyId(null);
    setWeight(null);
    setGTMData({});
    setErrors({});
  };

  // On Date Change
  const onDateChange = (e: any) => {
    setDates(e.value);
  };

  // on submit
  const onSubmit = () => {
    try{
      const { errors, isError } = GTMValidate(gtmData, selectedCropId, selectedVarietyId, weight);
      setErrors(errors);
      setIsError(isError);
      if (!isError) {
        setLoading(true);

        //Request object
        let formRequestData = new FormData();

        formRequestData.append('crop', selectedCropId.code);
        formRequestData.append('variety', selectedVarietyId.code);
        formRequestData.append('weight', weight.code);
        formRequestData.append('unit', gtmData.unit);
        formRequestData.append('storage', gtmData.storage);
        formRequestData.append('processing', gtmData.processing);
        formRequestData.append('name_of_carrier', gtmData.carrier);
        formRequestData.append('amount', gtmData.amount);
        formRequestData.append('vehicle_no', gtmData.vehicle_no);
        formRequestData.append('remark', gtmData.remark);
        formRequestData.append('quantity', gtmData.quantity);

        if (editId !== undefined && editId !== null) {
          formRequestData.append('id', editId);
        }

        pageService.addUpdateGTMData(formRequestData).then((response) => {
          if (response) {
            setGTMDialog(false);
            setGTMData({});
            setErrors(false);
            setLoading(false);
            setSelectedCropId(null);
            setSelectedVarietyId(null);
            setWeight(null);
            window.open(response.data, '_blank');
            toast.current?.show({
              severity: 'success',
              summary: 'Success',
              detail: 'GTM Created.',
            });
            getDataFromAPI();
          } else {
            setLoading(false);
            setGTMDialog(true);
            setGTMData(gtmData);
            setErrors(false);
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'GTM Deatils not Added.' });
          }
        });
      }
    } catch (error: any) {
      if (error.response) {
        setLoading(false);
        toast.current?.show({
          severity: 'error', summary: 'Error', detail: error.response.data.error
        });
      }
    }
  };

  // View GTM
  const viewGTM = (rowData: any) => {
    setGTMViewDialog(true);
    setViewGTMData(rowData);
  };

  const hideViewDialog = () => {
    setGTMViewDialog(false);
    setViewGTMData({});
  };

  // Edit gtm
  const editGTM = (rowData: any) => {
    setEditId(rowData.id);
    varietyList({ name: rowData.crop_obj.name, code: rowData.crop_obj.id }, 'null');
    setWeight({ name: rowData.weight, code: rowData.weight});
    setGTMData({
      storage: rowData.storage,
      processing: rowData.processing,
      unit: rowData.unit,
      amount: rowData.amount,
      vehicle_no: rowData.vehicle_no,
      carrier: rowData.name_of_carrier,
      quantity: rowData.quantity,
      remark: rowData.remark,
    });
    setSelectedCropId({ name: rowData.crop_obj.name, code: rowData.crop_obj.id });
    setSelectedVarietyId({ name: rowData.variety_obj.name, code: rowData.variety_obj.id });
    setGTMDialog(true);
  };

  // Open delete modal
  const showDeleteDialog = (id: any) => {
    setGTMDeleteDialog(true);
    setDeleteId(id);
  }

  // Hide Delete GTM Dialog
  const hideDeleteGTMDialog = () => {
    setGTMDeleteDialog(false);
    setDeleteId(null);
  }

  // Delete GTM
  const deleteGtm = () => {
    setLoading(true);
    pageService.deleteGTMData(deleteId).then((response) => {
      if (response) {
        setGTMDeleteDialog(false);
        setDeleteId(null);
        setLoading(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'GTM Created Deleted.',
        });
        getDataFromAPI();
      } else {
        setLoading(false);
        setGTMDeleteDialog(true);
        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'GTM Deatils not Added.' });
      }
    });
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary mt-2"
          style={{ marginRight: '17px' }}
          onClick={() => viewGTM(rowData)}
        />
        <Button
          icon="pi pi-file-pdf"
          className="p-button-primary mt-2"
          style={{ marginRight: '17px' }}
          onClick={() => window.open(rowData?.gtm_url, '_blank')}
          disabled={rowData?.gtm_url == null ? true : false}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-3"
          onClick={() => editGTM(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger "
          onClick={() => showDeleteDialog(rowData.id)}
        />
      </div>
    );
  };

  const cropBodyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData?.crop_obj?.name}</div>
      </>
    );
  };

  const varietyBodyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData?.variety_obj?.name}</div>
      </>
    );
  };

  const dateTemplate = (rowData: any) => {
    return (<>
      <div>{formatDateTime(rowData?.created_at)}</div>
    </>);
  };

  // right part of toolbar
  const rightToolbarTemplate = () => {
    return (
      <>
        <div>
          <Calendar
            value={dates}
            className='order-cle'
            dateFormat="dd/mm/yy"
            onChange={(e) => onDateChange(e)}
            selectionMode="range"
            showIcon
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo main-div-sec ">
        <div className="col-12">
          <div className="card">
            <div className='main-header'>
              <h4 className='header'> GTM List </h4>
              {localStorage.getItem('role') == '1' ?
                <Button
                  label="Create GTM"
                  icon="pi pi-plus"
                  className="p-button-success mr-2"
                  onClick={addDialog}
                /> : ""}
            </div>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              right={rightToolbarTemplate}
            ></Toolbar>
            {/* Datatable */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive"
                  value={gtmList}
                  paginator={gtmList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No GTM Found"
                >
                  {GTMColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    }else if (col.field === 'crop') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={cropBodyTemplate}
                        />
                      );
                    }else if (col.field === 'variety') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={varietyBodyTemplate}
                        />
                      );
                    }else if (col.field === 'created_at') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={dateTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {GTMColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Add Dialog Start*/}
      <Dialog
        visible={gtmDialog}
        className="p-fluid"
        header="Create GTM"
        onHide={hideAddDialog}
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideAddDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              className="p-button-text"
              onClick={onSubmit}
              loading={loading}
            />
          </>
        }
      >
        <div className="p-fluid formgrid grid">
          <div className="field col f-col">
            <label htmlFor="crop">Crop *</label>
            <Dropdown
              filter
              value={selectedCropId}
              onChange={(e) => handleSelectedCrop(e.value)}
              options={cropData}
              optionLabel="name"
              name="crop"
              placeholder="Select Crop"
              className={errors['crop'] && 'p-invalid'}
            ></Dropdown>
            <small className="p-invalid-txt">{ errors['crop'] }</small>
          </div>

          <div className="field col f-col">
            <label htmlFor="variety">Variety *</label>
            <Dropdown
              filter
              value={selectedVarietyId}
              onChange={(e) => handleSelectedVariety(e.value)}
              options={varietyData}
              optionLabel="name"
              name="variety"
              placeholder="Select Variety"
              className={errors['variety'] && 'p-invalid'}
            ></Dropdown>
            <small className="p-invalid-txt">{ errors['variety'] }</small>
          </div>
          <div className="field col f-col">
            <label htmlFor="unit">Unit *</label>
            <InputText
               keyfilter="num"
              value={!window.cn(gtmData) ? gtmData.unit : ""}
              name="unit"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'unit')}
              className={errors['unit'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['unit']}</small>
          </div>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field col f-col">
            <label htmlFor="storage">Storage (From) *</label>
            <InputText
              value={!window.cn(gtmData) ? gtmData.storage : ""}
              name="storage"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'storage')}
              className={errors['storage'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['storage']}</small>
          </div>
          <div className="field col f-col">
            <label htmlFor="processing">Processing (To) *</label>
            <InputText
              value={!window.cn(gtmData) ? gtmData.processing : ""}
              name="processing"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'processing')}
              className={errors['processing'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['processing']}</small>
          </div>
          <div className="field col f-col">
            <label htmlFor="carrier">Name Of Carrier/Driver Name *</label>
            <InputText
              value={!window.cn(gtmData) ? gtmData.carrier : ""}
              name="carrier"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'carrier')}
              className={errors['carrier'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['carrier']}</small>
          </div>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field col f-col">
            <label htmlFor="amount">Amount *</label>
            <InputText
               keyfilter="num"
              value={!window.cn(gtmData) ? gtmData.amount : ""}
              name="amount"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'amount')}
              className={errors['amount'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['amount']}</small>
          </div>
          <div className="field col f-col">
            <label htmlFor="vehicle_no">Vehicle No. *</label>
            <InputText
              value={!window.cn(gtmData) ? gtmData.vehicle_no : ""}
              name="vehicle_no"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'vehicle_no')}
              className={errors['carrier'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['vehicle_no']}</small>
          </div>
          <div className="field col f-col">
            <label htmlFor="remark">Remark *</label>
            <InputText
              value={!window.cn(gtmData) ? gtmData.remark : ""}
              name="remark"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'remark')}
              className={errors['remark'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['remark']}</small>
          </div>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field col f-col">
            <label htmlFor="quantity">Quantity *</label>
            <InputText
               keyfilter="num"
              value={!window.cn(gtmData) ? gtmData.quantity : ""}
              name="quantity"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'quantity')}
              className={errors['quantity'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['quantity']}</small>
          </div>
          <div className="field col f-col">
          <label htmlFor="weight">Weight *</label>
            <Dropdown
              filter
              value={weight}
              onChange={(e) => setWeight(e.value)}
              options={weightDropdown}
              optionLabel="name"
              placeholder="Select Weight"
              className={errors['weight'] && 'p-invalid'}
            ></Dropdown>
            <small className="p-invalid-txt">{errors['weight']}</small>
          </div>
          <div className="field col f-col"></div>
        </div>
      </Dialog>

      {/* View Dialog Start*/}
      <Dialog
        visible={gtmViewDialog}
        className="p-fluid"
        header="View GTM"
        onHide={hideViewDialog}
        footer={true}
      >
        <div className="p-fluid formgrid grid">
          <div className="field col f-col">
            <label htmlFor="crop"><b>Crop :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.crop_obj) ? viewGTMData?.crop_obj?.name : ""}</label>
          </div>

          <div className="field col f-col">
            <label htmlFor="variety"><b>Variety :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.variety_obj) ? viewGTMData?.variety_obj?.name : ""}</label>
          </div>
          <div className="field col f-col">
            <label htmlFor="unit"><b>Unit :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.unit) ? viewGTMData?.unit: ""}</label>
          </div>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field col f-col">
            <label htmlFor="storage"><b>Storage (From) :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.storage) ? viewGTMData?.storage : ""}</label>
          </div>
          <div className="field col f-col">
            <label htmlFor="processing"><b>Processing (To) :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.processing) ? viewGTMData?.processing : ""}</label>
          </div>
          <div className="field col f-col">
            <label htmlFor="carrier"><b>Name Of Carrier/Driver Name :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.name_of_carrier) ? viewGTMData?.name_of_carrier : ""}</label>
          </div>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field col f-col">
            <label htmlFor="amount"><b>Amount :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.amount) ? viewGTMData?.amount : ""}</label>
          </div>
          <div className="field col f-col">
            <label htmlFor="vehicle_no"><b>Vehicle No. :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.vehicle_no) ? viewGTMData?.vehicle_no : ""}</label>
          </div>
          <div className="field col f-col">
            <label htmlFor="remark"><b>Remark :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.remark) ? viewGTMData?.remark : ""}</label>
          </div>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field col f-col">
            <label htmlFor="quantity"><b>Quantity :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.quantity) ? viewGTMData?.quantity + " " + viewGTMData?.weight : ""}</label>
          </div>
          <div className="field col f-col">
          <label htmlFor="data_added_by_user"><b>Data Added By :</b> {!window.cn(viewGTMData) && !window.cn(viewGTMData?.data_added_by_user) ? viewGTMData?.data_added_by_user : ""}</label>
          </div>
          <div className="field col f-col"></div>
        </div>
      </Dialog>

      {/* deleteDialog start*/}
      <Dialog
        visible={gtmDeleteDialog}
        style={{ width: '450px' }}
        header="Delete"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-secondary"
              onClick={hideDeleteGTMDialog}
            />
            <Button
              label="Delete"
              icon="pi pi-trash"
              className="p-button-danger"
              onClick={deleteGtm}
              loading={loading}
            />
          </>
        }
        onHide={hideDeleteGTMDialog}
      >
        <div className="flex align-items-center justify-content-start">
          <i
            className="pi pi-exclamation-triangle mr-3 delete-triangle"
            style={{ fontSize: '2rem' }}
          />
          <span className="delete-dialog-note">
            Are you sure you want to delete ?
          </span>
        </div>
      </Dialog>
      {/* deleteDialog End*/}
    </>
  );
};
