import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';

//Services
import PageService from '../../service/PageService';
import { GerminationValidate } from '../../config/Validate';
import { Loader } from '../../components/Loader/Loader';
import { formatDateTime } from '../../appconfig/AppHelper';
import { Badge } from 'primereact/badge';

export const AddGermination = () => {

    document.title = 'Germination | Swarnim Farms';

    const pageService = new PageService();

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    const toast = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageLoad, setPageLoad] = useState(false);

    const [codeNo, setCodeNo] = useState<any>(null);
    const [testData, setTestData] = useState<any>({});
    const [germinationData, setGerminationData] = useState<any>(null);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setCodeNo(state);
            const stateLength = Object.keys(state).length;
            if (stateLength == 2) {
                getTestingDataFromApi(state);
            }
        }
    }, []);

    // Get Production detail Data from API
    const getTestingDataFromApi = async (state: any) => {
        setPageLoad(true);
        // call api
        pageService.fetchTestDetails(state.lot_no).then((response) => {
            // get response
            if (response) {
                const DataList = response;
                setGerminationData(DataList.germination)
                setPageLoad(false);
            }
        });
    };

    //onChange inspection Data
    const onInputChange = (e: any, name: string) => {
        let val;
        if (name == 'germination_date_of_observation' || name == 'date_of_putting') {
            val = e;
        } else {
            val = (e.target && e.target.value) || '';
        }

        setTestData({ ...testData, [name]: val });
    };

    // add inspection data
    const onSubmit = async () => {
        const { errors, isError } = GerminationValidate(testData);

        setErrors(errors);
        setIsError(isError);

        //Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            let formData = new FormData();

            if (!window.cn(testData) && testData.germination_date_of_observation) {
                var dateOfObs = new Date(testData?.germination_date_of_observation);
                let obsDate = dateOfObs.getFullYear() + "-" + (dateOfObs.getMonth() + 1) + "-" + dateOfObs.getDate();
                formData.append('germination_date_of_observation', obsDate);
            }
            var dateOfPutting = new Date(testData?.date_of_putting);
            let puttingDt = dateOfPutting.getFullYear() + "-" + (dateOfPutting.getMonth() + 1) + "-" + dateOfPutting.getDate();
            formData.append('date_of_putting', puttingDt);

            formData.append('no_of_seeds', testData.no_of_seeds);
            formData.append('germinated_seeds', testData.germinated_seeds);
            formData.append('germination', ((testData.germinated_seeds / testData.no_of_seeds) * 100).toFixed(2));
            formData.append('no_of_seeds_1', testData.no_of_seeds_1);
            formData.append('germinated_seeds_1', testData.germinated_seeds_1);
            formData.append('germination_1', ((testData.germinated_seeds_1 / testData.no_of_seeds_1) * 100).toFixed(2));
            let totalSeeds = parseInt(testData.no_of_seeds) + parseInt(testData.no_of_seeds_1);
            var totalGerminated = parseInt(testData.germinated_seeds_1) + parseInt(testData.germinated_seeds);
            formData.append('total_seeds', totalSeeds.toString());
            formData.append('total_germinated', totalGerminated.toString());
            formData.append('total_germination', (((parseInt(testData.germinated_seeds) + parseInt(testData.germinated_seeds_1)) / (parseInt(testData.no_of_seeds) + parseInt(testData.no_of_seeds_1))) * 100).toFixed(2));

            if (!window.cn(testData) && testData.remark) {
                formData.append('remark', testData.remark);
            }
            formData.append('test_type', 'Germination');
            formData.append('lot_no', codeNo.code_no);

            // Api call
            pageService.addTestData(formData).then((response) => {
                // Get Response
                setLoading(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: response.message,
                });
                setTimeout(() => {
                    navigate('/product/testing');
                }, 1000)
            }).catch(error => {
                setLoading(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.error,
                });
            }
            );
        }
    };

    return (
        <>
            {!window.cn(germinationData) && germinationData != null ?
                <>
                    <div className="col-12">
                        <h4> <Button
                            //label="Back"
                            icon="pi pi-arrow-left"
                            className="p-button-secondary mr-2"
                            onClick={() => navigate('/product/testing')}
                        />  Germination Data</h4>

                        {pageLoad == false ?
                            <div className="card">
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Date Of Putting</b> : {!window.cn(germinationData) ? formatDateTime(germinationData?.date_of_putting) : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Date Of Observation</b> : {!window.cn(germinationData) ? formatDateTime(germinationData?.germination_date_of_observation) : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Date</b> : {!window.cn(germinationData) ? formatDateTime(germinationData?.created_at) : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                    </div>
                                    <div className="field col-12 flex flex-column">
                                        <p> <b>Replication - 1</b></p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No. Of Seeds Putted(A)</b> : {!window.cn(germinationData) ? germinationData?.no_of_seeds : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No. Of Emerged Seeds (B)</b> : {!window.cn(germinationData) ? germinationData?.germinated_seeds : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Germination</b> : {!window.cn(germinationData) ? germinationData?.germination : ""}</p>
                                    </div>
                                    <div className="field col-12 flex flex-column">
                                        <p> <b>Replication - 2</b></p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No. Of Seeds Putted (C)</b> : {!window.cn(germinationData) ? germinationData?.no_of_seeds_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>No. Of Germinated Seeds (D)</b> : {!window.cn(germinationData) ? germinationData?.germinated_seeds_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Germination</b> : {!window.cn(germinationData) ? germinationData?.germination_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Total No. Seeds Putted (A + C)</b> : {!window.cn(germinationData) ? germinationData?.total_seeds : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Total No. Seeds Putted (B + D)</b> : {!window.cn(germinationData) ? germinationData?.total_germinated : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Total Germination %</b> : {!window.cn(germinationData) ? germinationData?.total_germination : ""}</p>
                                    </div>

                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Result</b> : {!window.cn(germinationData) && germinationData.result == 'Pass' ?
                                            <Badge severity="success" value={germinationData.result}></Badge> :
                                            <Badge severity="warning" value={germinationData.result}></Badge>
                                        }</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Remark</b> : {!window.cn(germinationData) ? germinationData?.remark : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Data Added By</b> : {!window.cn(germinationData) ? germinationData?.data_added_by_user : ""}</p>
                                    </div>
                                </div>
                            </div> : <>
                                <Loader />
                            </>}
                    </div>
                </> :
                <div className="col-12">
                    <h4> <Button
                        //label="Back"
                        icon="pi pi-arrow-left"
                        className="p-button-secondary mr-2"
                        onClick={() => navigate('/product/testing')}
                    />    Add Germination Data</h4>

                    {pageLoad == false ?
                        <div className="card">

                            <Toast ref={toast} />
                            <>
                                <div className="p-fluid formgrid grid">

                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="code_no">Code No</label>
                                        <InputText
                                            value={!window.cn(codeNo) ? codeNo.code_no : ""}
                                            name="code_no"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="date_of_putting">Date Of Putting *</label>
                                        <Calendar
                                            value={!window.cn(testData) ? testData.date_of_putting : ""}
                                            dateFormat="dd/mm/yy"
                                            onChange={(e) => onInputChange(e.value, 'date_of_putting')}
                                            name="date_of_putting"
                                            showIcon
                                        />
                                        <small className="p-invalid-txt">{errors['date_of_putting']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="germination_date_of_observation">Date Of Observation</label>
                                        <Calendar
                                            value={!window.cn(testData) ? testData.germination_date_of_observation : ""}
                                            dateFormat="dd/mm/yy"
                                            onChange={(e) => onInputChange(e.value, 'germination_date_of_observation')}
                                            name="germination_date_of_observation"
                                            showIcon
                                        />
                                    </div>
                                    <div className="field col-12 flex flex-column"> <b> Replication-1</b></div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="no_of_seeds">No Of Seeds Putted (A) *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.no_of_seeds : ""}
                                            name="no_of_seeds"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "no_of_seeds")}
                                        />
                                        <small className="p-invalid-txt">{errors['no_of_seeds']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="germinated_seeds">Germinated Seeds (B) *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.germinated_seeds : ""}
                                            name="germinated_seeds"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "germinated_seeds")}
                                        />
                                        <small className="p-invalid-txt">{errors['germinated_seeds']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="germination">Germination</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.germinated_seeds && testData.no_of_seeds ? ((testData.germinated_seeds / testData.no_of_seeds) * 100).toFixed(2) : ""}
                                            name="germination"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-12 flex flex-column"> <b> Replication-2</b></div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="no_of_seeds_1">No Of Seeds Putted (C) *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.no_of_seeds_1 : ""}
                                            name="no_of_seeds_1"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "no_of_seeds_1")}
                                        />
                                        <small className="p-invalid-txt">{errors['no_of_seeds_1']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="germinated_seeds_1">Germinated Seeds (D) *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.germinated_seeds_1 : ""}
                                            name="germinated_seeds_1"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "germinated_seeds_1")}
                                        />
                                        <small className="p-invalid-txt">{errors['germinated_seeds_1']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="germination_1">Germination</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.germinated_seeds_1 && testData.no_of_seeds_1 ? ((testData.germinated_seeds_1 / testData.no_of_seeds_1) * 100).toFixed(2) : ""}
                                            name="germination_1"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_seeds">Total No. Of Seeds Putted (A+C)</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.no_of_seeds_1 && testData.no_of_seeds ? (parseInt(testData.no_of_seeds) + parseInt(testData.no_of_seeds_1)) : ""}
                                            name="total_seeds"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_germinated">Total No. Of Seeds Putted (B+D)</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.germinated_seeds_1 && testData.germinated_seeds ? (parseInt(testData.germinated_seeds_1) + parseInt(testData.germinated_seeds)) : ""}
                                            name="total_germinated"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_germination">Total Germination %</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.no_of_seeds_1 && testData.no_of_seeds ? (((parseInt(testData.germinated_seeds) + parseInt(testData.germinated_seeds_1)) / (parseInt(testData.no_of_seeds) + parseInt(testData.no_of_seeds_1))) * 100).toFixed(2) : ""}
                                            name="total_germination"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="remark">Remark</label>
                                        <InputText
                                            value={!window.cn(testData) ? testData.remark : ""}
                                            name="remark"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "remark")}
                                        />
                                    </div>
                                </div>
                            </>

                            <div className="card-footer">
                                <div className="button-group">
                                    <>
                                        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-4" onClick={() => navigate('/product/testing')} />
                                        <Button label="Save" icon="pi pi-check" loading={loading} onClick={onSubmit} />
                                    </>
                                </div>
                            </div>
                        </div> : <>
                            <Loader />
                        </>}
                </div>}
        </>
    )
}