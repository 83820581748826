import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import moment from "moment/moment";

// Column
import { AttendanceColumns } from '../../appconfig/DatatableSetting';

// Data table
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

export const Attendance = () => {
  document.title = "Attendance | Swarnim Farms"

  //Attendance Crud Object
  const [attendanceList, setAttendanceList] = useState<any>([]);

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState(false);

  const [project, setProject] = useState<any>('');
  const [projectData, setProjectData] = useState<any>();

  // use effect method
  useEffect(() => {
    getAttendanceDataFromAPI();
    projectList();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [dates]);

  useEffect(() => {
    if (project) {
      getAttendanceDataFromAPI();
    }
  }, [project]);


  const projectList = async () => {
    try {
      const projectData: any = [];

      pageService.projectList().then((response) => {
        if (response) {
          const projectDataList = response;
          for (let i = 0; i < projectDataList.length; i++) {
            const projectObj: any = {};
            projectObj['name'] = projectDataList[i].title;
            projectObj['code'] = projectDataList[i].id;
            projectData.push(projectObj);
          }
          setProjectData(projectData);
        }
      });

    } catch (error) { }
  };

  // Get Attendance Data from API
  const getAttendanceDataFromAPI = async () => {

    // Api call
    pageService
      .getAttendanceData(!window.cn(dates) && dates[0], !window.cn(dates) && dates[1], !window.cn(project.name) ? project.name : "")
      .then((response) => {
        // Get response
        if (response) {
          const DataList = response;
          if (DataList.length == 0) {
            setAttendanceList([]);
          } else {
            setAttendanceList(DataList);
          }
          setPageLoad(true);
        } else {
          setPageLoad(false);
          setAttendanceList([]);
        }
      });
  };

  // On Date Change
  const onDateChange = (e: any) => {
    setDates(e.value);
  };


  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // right part of toolbar
  const rightToolbarTemplate = () => {
    return (
      <>
        {/* <label>Project : </label> */}
        {/* <Dropdown
          value={project}
          onChange={(e) => setProject(e.value)}
          options={projectData}
          optionLabel="name"
          placeholder="Project : All"
        ></Dropdown> */}
        <div style={{ marginLeft: '15px' }}></div>
        <Calendar
          value={dates}
          dateFormat="dd/mm/yy"
          onChange={(e) => onDateChange(e)}
          selectionMode="range"
          showIcon
        />
      </>
    );
  };

  //Start Date Time Conversion Template
  const dateFormatStartTimeTemplate = (rowData: any, rowIndex: any) => {

    return (
      <>
        {rowData.start_time === rowData[rowIndex.field] && rowData.start_time !== null ? moment.utc(rowData.start_time).format('MMM DD, YYYY') : rowData[rowIndex.field]}
      </>
    );
  };

  //End Date Time Conversion Template
  const dateFormatEndTimeTemplate = (rowData: any, rowIndex: any) => {

    return (
      <>
        {rowData.end_time === rowData[rowIndex.field] && rowData.end_time !== null ? moment.utc(rowData.end_time).format('MMM DD, YYYY') : '-'}
      </>
    );
  };

  //End distance conversion
  const distance = (rowData: any) => {

    return (
      <>
        {(rowData.distance / 1000).toFixed(2) + " km"}
      </>
    );
  };

  // for column action
  const startImageTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          tooltip='View'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-primary mt-2 mb-2"
          style={{ marginRight: '17px' }}
          onClick={() => window.open(rowData.start_image, '_blank')}
        />
      </div>
    )
  }

  // for column action
  const endImageTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          tooltip='View'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-primary mt-2 mb-2"
          style={{ marginRight: '17px' }}
          onClick={() => window.open(rowData.end_image, '_blank')}
          disabled={rowData.end_image == null}
        />
      </div>
    )
  }

  // page template
  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <h4> Attendance List </h4>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            {/* Datatable Start */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive"
                  value={attendanceList}
                  paginator={attendanceList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Attendance Data Found"
                >
                  {AttendanceColumns.map((col, i) => {
                    if (col.field === 'start_time') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={dateFormatStartTimeTemplate}
                          filter
                          sortable
                        />
                      );
                    } else if (col.field === 'end_time') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={dateFormatEndTimeTemplate}
                          filter
                          sortable
                        />
                      );
                    } else if (col.field === 'start_image') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={startImageTemplate}
                        />
                      );
                    } else if (col.field === 'end_image') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={endImageTemplate}
                        />
                      );
                    } else if (col.field === 'distance') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={distance}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1}
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {AttendanceColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
            {/* Datatable End */}
          </div>
        </div>
      </div>
    </>
  );
};
