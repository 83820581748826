import moment from 'moment';

export const DEFAULT_TIMEZONE = "America/New_York";

export const convertUTCtoTimeZone = function (date : any, timeZone = getTimeZone()) {
    // return date ? moment.utc(date).tz(timeZone).format('DD MMM YYYY, hh:mm A') : null;
}

export const formatDateTime = function (date : any) {
    return date ? moment.utc(date).format('DD MMM YYYY') : null;
}

export const getTimeZone = function () {
    let timezone;
    var pageUrl = window.location.href;
    if (pageUrl.indexOf("/admin/") > 0) {
        timezone = DEFAULT_TIMEZONE;
    } else {
        if (localStorage.getItem('timezone') && localStorage.getItem('timezone') !== null) {
            timezone = localStorage.getItem('timezone');
        }
    }
    return timezone;
}

export const createMarkup = (converdata : any) => {
    return { __html: converdata };
};


export const dateFormatTemplate = (rowData: any, rowIndex: any) => {

    return (
        <>
            {rowData.created_at === rowData[rowIndex.field] ? moment.utc(rowData.created_at).format('MMM DD, YYYY')  : rowData[rowIndex.field]}
        </>
    );
};

export const imageTemplate = (rowData: any, rowIndex: any) => {
    return (
        <>

            {!window.cn(rowData.photo) ? <img src={rowData.photo} width="60" height="40" /> : <img src="/assets/images/no_data_img.png" width="60" height="40"
            />}
        </>
    );
};