import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../appconfig/AppHelper';

import moment from "moment/moment";

// Column
import { DealerColumns } from '../../appconfig/DatatableSetting';

// Data table
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

export const Dealer = () => {
  document.title = 'Dealer | Swarnim Farms';

  //Navigate Another Route
  const navigate = useNavigate();

  //Farmer Crud Object
  const [dealerList, setDealerList] = useState<any>([]);
  const [dealerData, setDealerData] = useState<any>([]);

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  // Edit and Delete Model/ Dialog
  const [deleteDealerDialog, setDeleteDealerDialog] = useState<boolean>(false);

  // Edit and Delete Data Details
  const [deleteId, setDeleteId] = useState<any>(null);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  // Approve / Reject Leave Model/ Dialog
  const [approveConfirmModal, setApproveConfirmModal] = useState(false);

  // Approve / Reject Leave Data Details
  const [approveId, setApproveId] = useState(null);
  const [isHold, setIsHold] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [approveStatus, setApproveStatus] = useState(null);

  const [status, setStatus] = useState<any>({ name: 'Status : All', code: 'all' });

  // drop down status
  const statusDropdownItems = [
    { name: 'Status : All', code: 'all' },
    { name: 'Hold', code: '1' },
    { name: 'Unhold', code: '0' }
  ];

  // use effect method
  useEffect(() => {
    getDealerDataFromAPI();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [dates]);

  useEffect(() => {
    if (status) {
      getDealerDataFromAPI();
    }
  }, [status]);

  // Get Dealer Data from API
  const getDealerDataFromAPI = async () => {
    setPageLoad(false);
    // call api
    pageService
      .getDealerData(status.code)
      .then((response) => {
        // get response
        if (response) {
          const DataList = response;
          if (DataList.length == 0) {
            setDealerList([]);
          } else {
            setDealerList(DataList);
          }
          setPageLoad(true);
        } else {
          setPageLoad(false);
          setDealerList([]);
        }
      });
  };

  // On Date Change
  const onDateChange = (e: any) => {
    setDates(e.value);
  };

  // call delete api
  const deleteDealer = async () => {
    setSubmitLoading(true);

    //Request object
    let formRequestData = new FormData();

    if (approveId !== undefined && approveId !== null) {
      formRequestData.append('id', approveId);
      formRequestData.append('is_hold', isHold);
      if(!window.cn(isHold) && isHold == 1){
        formRequestData.append('hold_remark', remark);
      }
    }

    // call api
    pageService.deleteDealerData(formRequestData).then((response) => {
      // get response
      if (response) {
        setApproveConfirmModal(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Dealer Status Changed Successfully.',
        });
        getDealerDataFromAPI();
        setPageLoad(true);
        setSubmitLoading(false);
        setRemark("");
      } else {
        setPageLoad(false);
        setSubmitLoading(false);
        setApproveConfirmModal(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
        setPageLoad(false);
      }
    });

    setDeleteId(null);
    setDeleteDealerDialog(false);
  };

  //Approve Data
  const approveData = (rowData: any, e: any) => {
    setDealerData(rowData);
    setApproveId(rowData.id);
    setApproveStatus(e.target.checked);
    setApproveConfirmModal(true);
    if (rowData.is_hold == 1) {
      setIsHold(0);
    } else {
      setIsHold(1);
    }

  };

  //Approve Modal close
  const hideConfirmDialog = () => {
    setApproveId(null);
    setApproveStatus(null);
    setApproveConfirmModal(false);
  };

  //navigate to edit page
  const editDealer = async (dealer: any) => {
    navigate('/user/dealer/add-dealer', { state: { id: dealer.id } });
  };

  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // right part of toolbar
  const rightToolbarTemplate = () => {
    return (
      <>
        <Dropdown
          value={status}
          onChange={(e) => setStatus(e.value)}
          options={statusDropdownItems}
          optionLabel="name"
          placeholder="Status"
        ></Dropdown>
       
      </>
    );
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          tooltip='View'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-primary mt-2 mb-2 mr-3"
          style={{ marginRight: '17px' }}
          onClick={() => navigate('/user/dealer/view-dealer', { state: { id: rowData.id } })}
        />
        <Button
          icon="pi pi-pencil"
          tooltip='Edit'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-success mt-2 mb-2"
          onClick={() => editDealer(rowData)}
        />
      </div>
    );
  };

  // for column action
  const statusBodyTemplate = (rowData: any) => {
    let statusClass = rowData.is_hold === 1 ? "status status-inactive" : "status status-active";
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
          <InputSwitch
            checked={rowData.is_hold === 0 ? true : false}
            onChange={(e) => approveData(rowData, e)}
          />
          <span className={statusClass}>{rowData.is_hold === 1 ? "Hold" : "Unhold"}</span>
        </div>
      </>
    );
  };

  const imageTemplate = (rowData: any, rowIndex: any) => {

    return (
      <>
        {rowData.photo === rowData[rowIndex.field] ? <img src={rowData.photo} alt="No Block" width="60" /> : rowData[rowIndex.field]}
      </>
    );
  };

  //Start Date Time Conversion Template
  const dateFormatStartTimeTemplate = (rowData: any, rowIndex: any) => {
    return (
      <>
        {rowData.valid_upto === rowData[rowIndex.field] && rowData.valid_upto !== null ? moment.utc(rowData.valid_upto).format('MMM DD, YYYY') : rowData[rowIndex.field]}
      </>
    );
  };

  const createdAtTemplate = (rowData: any) => {
    return (<>
      <div>{formatDateTime(rowData?.created_at)}</div>
    </>);
  };
  
  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='main-header'>
              <h4 className='header'> Dealer List </h4>
              <Button
                label="Add New Dealer"
                icon="pi pi-plus"
                className="p-button-success mr-2"
                onClick={() => navigate('/user/dealer/add-dealer')}
              />
            </div>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            {/* Datatable */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive p-datatable-sm"
                  value={dealerList}
                  paginator={dealerList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Dealer Data Found"
                >
                  {DealerColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'photo') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={imageTemplate}
                        />
                      );
                    } else if (col.field === 'is_hold') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={statusBodyTemplate}
                        />
                      );
                    } else if (col.field === 'created_at') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={createdAtTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no.') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else if (col.field === 'valid_upto') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={dateFormatStartTimeTemplate}
                          sortable
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {DealerColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Approve / Reject Confirmation Modal */}
      <Dialog
        visible={approveConfirmModal}
        style={{ width: '450px' }}
        header="Hold / Unhold Dealer"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideConfirmDialog}
            />
            <Button
              label="Submit"
              icon="pi pi-check"
              className="p-button-success"
              onClick={deleteDealer}
              loading={submitLoading}
            />
          </>
        }
        onHide={hideConfirmDialog}
      >
        <div className="flex " style={{ alignItems: "center" }}>

          {dealerData.is_hold == 1 ?
            <>
              <span className="delete-dialog-note">
                Note: Once you Approve, Dealer will be on Unhold
              </span>
            </> :
            <>
              <div className="formgrid grid">
                <div className="field col-12 flex " style={{ alignItems: "center" }}>
                  <i
                    className="pi pi-exclamation-triangle mr-3 delete-triangle"
                    style={{ fontSize: '2rem' }}
                  />
                  <span>   Note: Once you Approve, Dealer will be on Hold</span>
                </div>
                <div className="field col-8 flex  flex-column">
                  <label htmlFor="remark">Remark</label>
                  <InputText
                    value={remark}
                    name="remark"
                    autoComplete="off"
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </div>
              </div>
            </>}

        </div>
      </Dialog>
      {/* Approve / Reject Confirmation Modal End */}
    </>
  );
};
