import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';

//Services
import PageService from '../../service/PageService';
import { GeneticPurityValidate } from '../../config/Validate';
import { Loader } from '../../components/Loader/Loader';
import { formatDateTime } from '../../appconfig/AppHelper';
import { Badge } from 'primereact/badge';

export const AddGeneticPurity = () => {

    document.title = 'Genetic Purity | Swarnim Farms';

    const pageService = new PageService();

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    const toast = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageLoad, setPageLoad] = useState(false);

    const [codeNo, setCodeNo] = useState<any>(null);
    const [testData, setTestData] = useState<any>({});
    const [geneticData, setGeneticData] = useState<any>(null);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setCodeNo(state);
            const stateLength = Object.keys(state).length;
            if (stateLength == 2) {
                getTestingDataFromApi(state);
            }
        }
    }, []);

    // Get Production detail Data from API
    const getTestingDataFromApi = async (state: any) => {
        setPageLoad(true);
        // call api
        pageService.fetchTestDetails(state.lot_no).then((response) => {
            // get response
            if (response) {
                const DataList = response;
                setGeneticData(DataList.genetic_purity)
                setPageLoad(false);
            }
        });
    };

    //onChange inspection Data
    const onInputChange = (e: any, name: string) => {
        let val;
        if (name == 'genetice_date_of_observation' || name == 'date_of_planting') {
            val = e;
        } else {
            val = (e.target && e.target.value) || '';
        }

        setTestData({ ...testData, [name]: val });
    };

    // add inspection data
    const onSubmit = async () => {
        const { errors, isError } = GeneticPurityValidate(testData);
        setErrors(errors);
        setIsError(isError);

        //Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            let formData = new FormData();

            if (!window.cn(testData) && testData.genetice_date_of_observation) {
                var dateOfObs = new Date(testData.genetice_date_of_observation);
                let obsDate = dateOfObs.getFullYear() + "-" + (dateOfObs.getMonth() + 1) + "-" + dateOfObs.getDate();
                formData.append('genetice_date_of_observation', obsDate);
            }
            var dateOfPlanting = new Date(testData?.date_of_planting);
            let sowingDt = dateOfPlanting.getFullYear() + "-" + (dateOfPlanting.getMonth() + 1) + "-" + dateOfPlanting.getDate();
            formData.append('date_of_planting', sowingDt);

            formData.append('rep_plant_stand_1', testData.rep_plant_stand_1);
            formData.append('rep_f_1', testData.rep_f_1);
            formData.append('rep_m_1', testData.rep_m_1);
            formData.append('rep_ot_1', testData.rep_ot_1);
            var total1 = parseInt(testData.rep_f_1) + parseInt(testData.rep_m_1) + parseInt(testData.rep_ot_1);
            formData.append('rep_total_1', total1.toString());
            formData.append('rep_gp_1', (100 - ((total1 / testData.rep_plant_stand_1) * 100)).toFixed(2).toString());

            formData.append('rep_plant_stand_2', testData.rep_plant_stand_2);
            formData.append('rep_f_2', testData.rep_f_2);
            formData.append('rep_m_2', testData.rep_m_2);
            formData.append('rep_ot', testData.rep_ot);
            var total2 = parseInt(testData.rep_f_2) + parseInt(testData.rep_m_2) + parseInt(testData.rep_ot);
            formData.append('rep_total_2', total2.toString());
            formData.append('rep_gp_2', (100 - ((total2 / testData.rep_plant_stand_2) * 100)).toFixed(2).toString());

            var totalPlantStand = (parseInt(testData.rep_plant_stand_2) + parseInt(testData.rep_plant_stand_1));
            formData.append('total_plant_stand', totalPlantStand.toString());
            formData.append('total_f', (parseInt(testData.rep_f_1) + parseInt(testData.rep_f_2)).toString());
            formData.append('total_m', (parseInt(testData.rep_m_1) + parseInt(testData.rep_m_2)).toString());
            formData.append('total_ot', (parseInt(testData.rep_ot_1) + parseInt(testData.rep_ot)).toString());
            var total = parseInt(testData.rep_f_1) + parseInt(testData.rep_f_2) + parseInt(testData.rep_m_1) + parseInt(testData.rep_m_2) + parseInt(testData.rep_ot) + parseInt(testData.rep_ot_1);
            formData.append('final_total', total.toString());
            formData.append('total_gp', (100 - ((total / totalPlantStand) * 100)).toFixed(2).toString());

            if (!window.cn(testData) && testData.remark) {
                formData.append('remark', testData.remark);
            }
            formData.append('test_type', 'Genetic Purity');
            formData.append('lot_no', codeNo.code_no);

            // Api call
            pageService.addTestData(formData).then((response) => {
                // Get Response
                setLoading(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: response.message,
                });
                setTimeout(() => {
                    navigate('/product/testing');
                }, 1000)
            }).catch(error => {
                setLoading(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.error,
                });
            }
            );
        }
    };

    return (
        <>
            {!window.cn(geneticData) && geneticData != null ?
                <>
                    <div className="col-12">
                        <h4> <Button
                            //label="Back"
                            icon="pi pi-arrow-left"
                            className="p-button-secondary mr-2"
                            onClick={() => navigate('/product/testing')}
                        />  Genetic Purity Data</h4>

                        {pageLoad == false ?
                            <div className="card">
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Date Of Planting</b> : {!window.cn(geneticData) ? formatDateTime(geneticData?.date_of_planting) : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Date Of Observation</b> : {!window.cn(geneticData) ? formatDateTime(geneticData?.genetice_date_of_observation) : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Date</b> : {!window.cn(geneticData) ? formatDateTime(geneticData?.created_at) : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                    </div>
                                    <div className="field col-12 flex flex-column">
                                        <p> <b>Replication - 1</b></p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Plant Stand (A)</b> : {!window.cn(geneticData) ? geneticData?.rep_plant_stand_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Female</b> : {!window.cn(geneticData) ? geneticData?.rep_f_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Male</b> : {!window.cn(geneticData) ? geneticData?.rep_m_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>OT</b> : {!window.cn(geneticData) ? geneticData?.rep_ot_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Total</b> : {!window.cn(geneticData) ? geneticData?.rep_total_1 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>GP %</b> : {!window.cn(geneticData) ? geneticData?.rep_gp_1 : ""}</p>
                                    </div>
                                    <div className="field col-12 flex flex-column">
                                        <p> <b>Replication - 2</b></p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Plant Stand (B)</b> : {!window.cn(geneticData) ? geneticData?.rep_plant_stand_2 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Female</b> : {!window.cn(geneticData) ? geneticData?.rep_f_2 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Male</b> : {!window.cn(geneticData) ? geneticData?.rep_m_2 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>OT</b> : {!window.cn(geneticData) ? geneticData?.rep_ot : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Total</b> : {!window.cn(geneticData) ? geneticData?.rep_total_2 : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>GP %</b> : {!window.cn(geneticData) ? geneticData?.rep_gp_2 : ""}</p>
                                    </div>
                                    <div className="field col-12 flex flex-column">
                                        <p> <b>Replication - 1</b></p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Total No. Of Plant Stand (R1 + R2)</b> : {!window.cn(geneticData) ? geneticData?.total_plant_stand : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Female</b> : {!window.cn(geneticData) ? geneticData?.total_f : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Male</b> : {!window.cn(geneticData) ? geneticData?.total_m : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>OT</b> : {!window.cn(geneticData) ? geneticData?.total_ot : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Final Total</b> : {!window.cn(geneticData) ? geneticData?.final_total : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Total GP %</b> : {!window.cn(geneticData) ? geneticData?.total_gp : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Result</b> : {!window.cn(geneticData) && geneticData.result == 'Pass' ?
                                            <Badge severity="success" value={geneticData.result}></Badge> :
                                            <Badge severity="warning" value={geneticData.result}></Badge>
                                        }</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Remark</b> : {!window.cn(geneticData) ? geneticData?.remark : ""}</p>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <p> <b>Data Added By</b> : {!window.cn(geneticData) ? geneticData?.data_added_by_user : ""}</p>
                                    </div>
                                </div>
                            </div> : <>
                                <Loader />
                            </>}
                    </div>
                </> :
                <div className="col-12">
                    <h4> <Button
                        //label="Back"
                        icon="pi pi-arrow-left"
                        className="p-button-secondary mr-2"
                        onClick={() => navigate('/product/testing')}
                    />    Add Genetic Purity Data</h4>

                    {pageLoad == false ?
                        <div className="card">

                            <Toast ref={toast} />
                            <>
                                <div className="p-fluid formgrid grid">

                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="code_no">Code No</label>
                                        <InputText
                                            value={!window.cn(codeNo) ? codeNo.code_no : ""}
                                            name="code_no"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                        <small className="p-invalid-txt">{errors['field_emergence_status']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="date_of_planting">Date Of Planting </label>
                                        <Calendar
                                            value={!window.cn(testData) ? testData.date_of_planting : ""}
                                            dateFormat="dd/mm/yy"
                                            onChange={(e) => onInputChange(e.value, 'date_of_planting')}
                                            name="date_of_planting"
                                            showIcon
                                        />
                                        <small className="p-invalid-txt">{errors['date_of_planting']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="genetice_date_of_observation">Date Of Observation</label>
                                        <Calendar
                                            value={!window.cn(testData) ? testData.genetice_date_of_observation : ""}
                                            dateFormat="dd/mm/yy"
                                            onChange={(e) => onInputChange(e.value, 'genetice_date_of_observation')}
                                            name="genetice_date_of_observation"
                                            showIcon
                                        />
                                    </div>
                                    <div className="field col-12 flex flex-column"> <b> Replication-1</b></div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_plant_stand_1">Plant Stand (A) *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.rep_plant_stand_1 : ""}
                                            name="rep_plant_stand_1"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "rep_plant_stand_1")}
                                        />
                                        <small className="p-invalid-txt">{errors['rep_plant_stand_1']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_f_1">Female *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.rep_f_1 : ""}
                                            name="rep_f_1"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "rep_f_1")}
                                        />
                                        <small className="p-invalid-txt">{errors['rep_f_1']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_m_1">Male *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.rep_m_1 : ""}
                                            name="rep_m_1"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "rep_m_1")}
                                        />
                                        <small className="p-invalid-txt">{errors['rep_m_1']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_ot_1">OT *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.rep_ot_1 : ""}
                                            name="rep_ot_1"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "rep_ot_1")}
                                        />
                                        <small className="p-invalid-txt">{errors['rep_ot_1']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_total_1">Total</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.rep_ot_1 && testData.rep_m_1 && testData.rep_f_1 ? parseInt(testData.rep_ot_1) + parseInt(testData.rep_m_1) + parseInt(testData.rep_f_1) : ""}
                                            name="rep_total_1"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_gp_1">GP %</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.rep_ot_1 && testData.rep_m_1 && testData.rep_f_1 ? (100 - ((parseInt(testData.rep_ot_1) + parseInt(testData.rep_m_1) + parseInt(testData.rep_f_1)) / testData.rep_plant_stand_1) * 100).toFixed(2) : ""}
                                            name="rep_gp_1"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-12 flex flex-column"> <b> Replication-2</b></div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_plant_stand_2">Plant Stand (B) *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.rep_plant_stand_2 : ""}
                                            name="rep_plant_stand_2"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "rep_plant_stand_2")}
                                        />
                                        <small className="p-invalid-txt">{errors['rep_plant_stand_2']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_f_2">Female *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.rep_f_2 : ""}
                                            name="rep_f_2"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "rep_f_2")}
                                        />
                                        <small className="p-invalid-txt">{errors['rep_f_2']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_m_2">Male *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.rep_m_2 : ""}
                                            name="rep_m_2"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "rep_m_2")}
                                        />
                                        <small className="p-invalid-txt">{errors['rep_m_2']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_ot">OT *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) ? testData.rep_ot : ""}
                                            name="rep_ot"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "rep_ot")}
                                        />
                                        <small className="p-invalid-txt">{errors['rep_ot']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_total_2">Total</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.rep_ot && testData.rep_m_2 && testData.rep_f_2 ? (parseInt(testData.rep_ot) + parseInt(testData.rep_m_2) + parseInt(testData.rep_f_2)) : ""}
                                            name="rep_total_2"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="rep_gp_2">GP %</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.rep_ot && testData.rep_m_2 && testData.rep_f_2 ? (100 - (((parseInt(testData.rep_ot) + parseInt(testData.rep_m_2) + parseInt(testData.rep_f_2)) / testData.rep_plant_stand_2) * 100)).toFixed(2) : ""}
                                            name="rep_gp_2"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-12 flex flex-column">Total(R1 + R2)</div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_plant_seed">Total No Of Plant Stand (R1 + R2)</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.rep_plant_stand_1 && testData.rep_plant_stand_2 ? parseInt(testData.rep_plant_stand_1) + parseInt(testData.rep_plant_stand_2) : ""}
                                            name="total_plant_seed"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                        <small className="p-invalid-txt">{errors['total_plant_seed']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_f">Female</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.rep_f_1 && testData.rep_f_2 ? parseInt(testData.rep_f_1) + parseInt(testData.rep_f_2) : ""}
                                            name="total_f"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_m">Male</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.rep_m_1 && testData.rep_m_2 ? parseInt(testData.rep_m_1) + parseInt(testData.rep_m_2) : ""}
                                            name="total_m"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_ot">OT</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.rep_ot && testData.rep_ot_1 ? parseInt(testData.rep_ot_1) + parseInt(testData.rep_ot) : ""}
                                            name="total_ot"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="final_total">Final Total</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.rep_f_1 && testData.rep_f_2 && testData.rep_m_1 && testData.rep_m_2 && testData.rep_ot && testData.rep_ot_1 ? parseInt(testData.rep_f_1) + parseInt(testData.rep_f_2) + parseInt(testData.rep_m_1) + parseInt(testData.rep_m_2) + parseInt(testData.rep_ot) + parseInt(testData.rep_ot_1) : ""}
                                            name="final_total"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="total_gp">Total GP %</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(testData) && testData.rep_f_1 && testData.rep_f_2 && testData.rep_m_1 && testData.rep_m_2 && testData.rep_ot && testData.rep_ot_1 ? (100 - (((parseInt(testData.rep_f_1) + parseInt(testData.rep_f_2) + parseInt(testData.rep_m_1) + parseInt(testData.rep_m_2) + parseInt(testData.rep_ot) + parseInt(testData.rep_ot_1)) / (parseInt(testData.rep_plant_stand_1) + parseInt(testData.rep_plant_stand_2))) * 100)).toFixed(2) : ""}
                                            name="total_gp"
                                            autoComplete="off"
                                            disabled
                                            readOnly
                                        />
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="remark">Remark</label>
                                        <InputText
                                            value={!window.cn(testData) ? testData.remark : ""}
                                            name="remark"
                                            autoComplete="off"
                                            onChange={(e) => onInputChange(e, "remark")}
                                        />
                                    </div>
                                </div>
                            </>

                            <div className="card-footer">
                                <div className="button-group">
                                    <>
                                        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-4" onClick={() => navigate('/product/testing')} />
                                        <Button label="Save" icon="pi pi-check" loading={loading} onClick={onSubmit} />
                                    </>
                                </div>
                            </div>
                        </div> : <>
                            <Loader />
                        </>}
                </div>
            }

        </>
    )
}