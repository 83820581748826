import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useNavigate } from 'react-router-dom';

// Column
import {
  CropsColumns,
  CropsViewColumns,
} from '../../appconfig/DatatableSetting';

// Data table
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';

//App Helper 
import { imageTemplate } from '../../appconfig/AppHelper';

//Services
import PageService from '../../service/PageService';
import { Loader } from '../../components/Loader/Loader';

export const Crops = () => {
  document.title = 'Crops  | Swarnim Farms';

  //Navigate Another Route
  const navigate = useNavigate();

  //Crops Crud Object
  const [cropsList, setCropsList] = useState<any>([]);
  const [viewCropsData, setViewCropsData] = useState<any>({});

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  // Edit, View and Delete Model/ Dialog
  const [deleteCropsDialog, setDeleteCropsDialog] = useState<boolean>(false);
  const [viewCropDataDialog, setViewCropDataDialog] = useState<boolean>(false);

  //Delete Data Details
  const [deleteId, setDeleteId] = useState<any>(null);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [viewLoading, setViewLoading] = useState<boolean>(false);

  // use effect method
  useEffect(() => {
    getCropsDataFromAPI();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // Get Crops Data from API
  const getCropsDataFromAPI = async () => {
    // api call
    pageService
      .getCropStockData()
      .then((response) => {
        // get response
        if (response) {
          const DataList = response;
          if (DataList.length == 0) {
            setCropsList([]);
          } else {
            setCropsList(DataList);
          }
          setPageLoad(true);
        } else {
          setPageLoad(false);
          setCropsList([]);
        }
      });
  };

  // open view crops data dialog
  const viewCropDetails = (rowData: any) => {
    setViewLoading(true);
    pageService.getCropsDataById(rowData.id).then((result) => {
      setViewCropsData(result.varieties);
      setViewCropDataDialog(true);
      setViewLoading(false);

    })
  };

  // close view crops data dialog
  const hideCropDataDialog = () => {
    setViewCropDataDialog(false);
    setViewCropsData([]);
    setViewLoading(false);

  };

  // open delete dialog
  const confirmDeleteCrop = (deleteID: any) => {
    setDeleteId(deleteID);
    setDeleteCropsDialog(true);
  };

  //close delete dialog
  const hideDeleteCropDialog = () => {
    setDeleteCropsDialog(false);
    setDeleteId(null);
  };

  // call delete api
  const deleteCrops = async () => {
    setLoading(true);

    // request data
    let requestData = {
      id: deleteId,
    };

    // call api
    pageService.deleteCropData(requestData).then((response) => {
      // get response
      if (response) {
        toast.current?.show({
          severity: 'Success',
          summary: 'Success',
          detail: 'Crop Data Deleted Successfully.',
        });
        setLoading(false);
        getCropsDataFromAPI();
        setPageLoad(true);
      } else {
        setLoading(false);
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
        setPageLoad(false);
      }
    });

    setDeleteId(null);
    setDeleteCropsDialog(false);
  };

  //open edit dialog
  const editCrops = async (crops: any) => {
    navigate('/crop/add-crop', { state: { id: crops.id } });
  };

  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          style={{ marginRight: '17px' }}
          onClick={() => viewCropDetails(rowData)}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-3"
          onClick={() => editCrops(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteCrop(rowData.id)}
        />
      </div>
    );
  };

  const stateBodyTemplate = (rowData: any) => {
    return (
      <>
        <div>{rowData?.state?.name}</div>
      </>
    )
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='main-header'>
              <h4 className='header'> Crop List </h4>
              <Button
                label="Add New Crop"
                icon="pi pi-plus"
                className="p-button-success mr-2"
                onClick={() => navigate('/crop/add-crop')}
              />
            </div>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
            ></Toolbar>
            {/* Datatable */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive"
                  value={cropsList}
                  paginator={cropsList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Crops Data Found"
                >
                  {CropsColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else if (col.field === 'photo') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={imageTemplate}
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {CropsColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </div>

      {/* deleteDialog start*/}
      <Dialog
        visible={deleteCropsDialog}
        style={{ width: '450px' }}
        header="Delete"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-secondary"
              onClick={hideDeleteCropDialog}
            />
            <Button
              label="Delete"
              icon="pi pi-trash"
              className="p-button-danger"
              onClick={deleteCrops}
              loading={loading}
            />
          </>
        }
        onHide={hideDeleteCropDialog}
      >
        <div className="flex align-items-center justify-content-start">
          <i
            className="pi pi-exclamation-triangle mr-3 delete-triangle"
            style={{ fontSize: '2rem' }}
          />
          <span className="delete-dialog-note">
            Are you sure you want to delete ?
          </span>
        </div>
      </Dialog>
      {/* deleteDialog End*/}

      {/* View Dialog start*/}
      <Dialog
        visible={viewCropDataDialog}
        style={{ width: '650px' }}
        className='crud-demo '
        header="View Crop Data"
        modal
        footer
        onHide={hideCropDataDialog}
      >
        <DataTable
          className="datatable-responsive"
          value={viewCropsData}
          rows={defaultRowOptions}
          currentPageReportTemplate={showingEntries}
          emptyMessage="No Crops Varieties Data Found"
        >
          {CropsViewColumns.map((col, i) => {
            if (col.field === 'sr_no') {
              return (
                <Column
                  key={col.field}
                  header={col.header}
                  body={(_, { rowIndex }) => rowIndex + 1} />
              );
            } else if (col.field === 'state') {
              return (
                <Column
                  key={col.field}
                  header={col.header}
                  body={stateBodyTemplate} />
              );
            } else {
              return (
                <Column
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  sortable
                />
              );
            }
          })}
        </DataTable>

      
      </Dialog>
      {/* View Dialog End*/}

          {/* Loader Start */}
      {
          viewLoading && <Loader />
        }
    </>
  );
};