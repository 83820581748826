import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../appconfig/Settings';

// Column
import { LotNoWiseStock, lotNoChildColumns } from '../../appconfig/DatatableSetting';

//Services
import PageService from '../../service/PageService';
import { ProgressSpinner } from 'primereact/progressspinner';

export const LotNoStock = () => {
    document.title = 'Lot No Wise Stock | Swarnim Farms';

    // Page service
    const pageService = new PageService();

    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);

    //Stock Crud Object
    const [lotNoList, setLotNoList] = useState<any>([]);
    const [lotData, setLotData] = useState<any>({});

    const [stockDialog, setStockDialog] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);
    const [editLotId, setEditLotId] = useState<any>({});
    const [lotQnty, setLotQnty] = useState<any>();
    const [stockEditDialog, setStockEditDialog] = useState<any>(false);

    // use effect method
    useEffect(() => {
        getLotNoDataFromApi();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);


    // Get lot no stock Data from API
    const getLotNoDataFromApi = async () => {
        // api call
        pageService.lotNoStock().then((response) => {
            // get response
            if (response) {
                setLotNoList(response);
                setPageLoad(true);
            } else {
                setPageLoad(false);
                setLotNoList([]);
            }
        });
    };

    // open view data dialog
    const viewData = (rowData: any) => {
        setSubmitLoading(true);
        setStockDialog(true);
        setLotData(rowData.lot_no_child);
        setSubmitLoading(false);
    };

    // close view data dialog
    const hideDialog = () => {
        setStockDialog(false);
        setLotData({});
        setSubmitLoading(false);
        setStockEditDialog(false);
        setLotQnty({});
        setEditLotId("");

    };

    // for column action
    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button
                    icon="p-button-rounded pi pi-eye"
                    className="p-button-success mr-3"
                    onClick={() => viewData(rowData)}
                />
            </div>
        );
    };

    // for column action
    const actionBodyChildTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button
                    icon="p-button-rounded pi pi-pencil"
                    className="p-button-success mr-3"
                    onClick={() => editLotStock(rowData)}
                />
            </div>
        );
    };

    // Edit lot number stock
    const editLotStock = (rowData: any) => {
        setStockDialog(false);
        setStockEditDialog(true);
        setLotQnty({
            "quantity" : rowData.quantity
        });
        setEditLotId(rowData.id);
    }

    // handlechange stock quantity
    const handleChangeLotQnty = (e: any, name: any) => {
        const val = (e.target && e.target.value) || '';
        setLotQnty({ ...lotQnty, [name]: val });
    };

    const onSubmit = () => {
        setLoading(true);
  
        //Request object
        let formRequestData = new FormData();
        formRequestData.append('id', editLotId);
        formRequestData.append('quantity', lotQnty.quantity);
  
        // api call
        pageService.editLotNoStock(formRequestData).then((result) => {
            setLotQnty({});
            setEditLotId("");
            setLoading(false);
            setStockEditDialog(false);
            toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: result.message,
            });
            getLotNoDataFromApi();
        }).catch(error => {
            setLoading(false);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error,
            });
        }
        );
    }

    const cropBodyTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData?.crop?.name}</div>
            </>);
    };

    const varietyBodyTemplate = (rowData: any) => {
        return (<>
            <div>{rowData?.variety?.name}</div>
        </>);
    };

    const leftToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
                        placeholder="Search..."
                    />
                </span>
            </>
        );
    };

    return (
        <>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h4>Lot No Wise Stock</h4>
                        </div>

                        <Toast ref={toast} />
                        <Toolbar
                            className="mb-4"
                            left={leftToolbarTemplate}
                        ></Toolbar>
                        <Toast ref={toast} />
                        {/* Datatable */}
                        {pageLoad ? (
                            <>
                                <DataTable
                                    className="datatable-responsive"
                                    value={lotNoList}
                                    paginator={lotNoList.length > 0 && true}
                                    globalFilter={globalFilter}
                                    rows={defaultRowOptions}
                                    rowsPerPageOptions={defaultPageRowOptions}
                                    paginatorTemplate={paginatorLinks}
                                    currentPageReportTemplate={showingEntries}
                                    emptyMessage="No Crops Data Found"
                                >
                                    {LotNoWiseStock.map((col, i) => {
                                        if (col.field === 'action') {
                                            return (
                                                <Column key={col.field} field={col.field} header={col.header} body={actionBodyTemplate} />
                                            );
                                        } else if (col.field === 'sr_no') {
                                            return (
                                                <Column key={col.field} header={col.header} body={(_, { rowIndex }) => rowIndex + 1} />
                                            );
                                        } if (col.field === 'crop') {
                                            return <Column key={col.field} field={col.field} header={col.header} body={cropBodyTemplate} />;
                                        } else if (col.field === 'variety') {
                                            return <Column key={col.field} field={col.field} header={col.header} body={varietyBodyTemplate} />;
                                        } else {
                                            return (
                                                <Column key={col.field} field={col.field} header={col.header} sortable filter />
                                            );
                                        }
                                    })}
                                </DataTable>
                            </>
                        ) : (
                            <>
                                {/* Skeleton Data table */}
                                <DataTable value={Skeletonitems}>
                                    {LotNoWiseStock.map((col, i) => (
                                        <Column
                                            key={col.field}
                                            field={col.field}
                                            header={col.header}
                                            body={SkeletonbodyTemplate}
                                        />
                                    ))}
                                </DataTable>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* View Dialog Start*/}
            <Dialog
                visible={stockDialog}
                className="p-fluid crud-demo"
                onHide={hideDialog}
                header='Lot No. Stock'
                footer
            >

                <DataTable
                    className="datatable-responsive"
                    value={lotData}
                    rows={defaultRowOptions}
                    currentPageReportTemplate={showingEntries}
                    emptyMessage="No Data Found"
                >
                    {lotNoChildColumns.map((col, i) => {
                        if (col.field === 'sr_no') {
                            return (
                                <Column key={col.field} header={col.header} body={(_, { rowIndex }) => rowIndex + 1} />
                            );
                        }else if (col.field === 'action') {
                            return (
                                <Column key={col.field} field={col.field} header={col.header} body={actionBodyChildTemplate} />
                            );
                        } else {
                            return <Column key={col.field} field={col.field} header={col.header} />
                        }
                    })}
                </DataTable>


                {
                    submitLoading && <div className="col-12 loading flex justify-content-center">
                        <ProgressSpinner />
                    </div>
                }
            </Dialog>
            {/* View Dialog End*/}

            {/* Edit Dialog Start*/}
            <Dialog
                visible={stockEditDialog}
                className="p-fluid crud-demo"
                onHide={hideDialog}
                header='Edit Lot No. Stock'
                footer={
                    <>
                      <Button
                        label="Cancel"
                        icon="pi pi-times"
                        className="p-button-danger"
                        onClick={hideDialog}
                      />
                      <Button
                        label="Submit"
                        icon="pi pi-check"
                        className="p-button-success"
                        onClick={onSubmit}
                        loading={loading}
                      />
                    </>
                  }
            >
                <label htmlFor="losses">Quantity *</label><br/><br/>
                <InputText
                    value={!window.cn(lotQnty) ? lotQnty.quantity : ""}
                    name="quantity"
                    autoComplete="off"
                    onChange={(e) => handleChangeLotQnty(e, "quantity")}
                />
            </Dialog>
            {/* Edit Dialog End*/}
        </>
    )

};