import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

//Services
import PageService from '../../service/PageService';
import { Loader } from '../../components/Loader/Loader';

export const PackagingMaterial = () => {
  document.title = 'Packaging Stock | Swarnim Farms';

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  //Set Toast/ Filter Properties
  const toast = useRef<any>(null);

  //Stock Crud Object
  const [stockList, setstockList] = useState<any>({});
  const [promotionalMaterialStock, setPromotionalMaterialStock] = useState<any>({});
  const [outerBagStock, setOuterBagStock] = useState<any>({});
  const [stockData, setstockData] = useState<any>({});
  const [promotionalMaterialData, setPromotionalMaterialData] = useState<any>({});
  const [outerBagData, setOuterBagData] = useState<any>({});
  const [editId, setEditId] = useState<any>();
  const [literatureStock, setLiteratureStock] = useState<any>({});
  const [literatureData, setLiteratureData] = useState<any>({});
  const [labelStock, setLabelStock] = useState<any>({});
  const [labelData, setLabelData] = useState<any>({});

  const [stockDialog, setStockDialog] = useState<any>(false);
  const [chemicalDialog, setChemicalDialog] = useState<any>(false);
  const [labelDialog, setLabelDialog] = useState<any>(false);

  const [chemicalData, setChemicalData] = useState<any>({});
  const [chemicalName, setChemicalName] = useState<any>(null);

  // use effect method
  useEffect(() => {
    getDataFromApi();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  //onChange stockData
  const onInputChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    if (name == 'label_count' || name == 'outer_bag' || name == 'sampling_inner_bag' || name == 'sampling_outer_bag') {
      setstockData({ ...stockData, [name]: val });
    } else {
      setPromotionalMaterialData({ ...promotionalMaterialData, [name]: val });
    }

  };

  const chemicalInputChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';

    setChemicalData({ ...chemicalData, [name]: val });
  };

  const outerBagInputChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    setOuterBagData({ ...outerBagData, [name]: val });
  };

  const literatureInputChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    setLiteratureData({ ...literatureData, [name]: val });
  };

  const labelInputChange = (e: any, name: string) => {
    const val = (e.target && e.target.value) || '';
    setLabelData({ ...labelData, [name]: val });
  };

  // Get packaging material stock Data from API
  const getDataFromApi = async () => {
    setPageLoad(true);

    // api call
    pageService.getPackagingStockData().then((response) => {
      // get response
      if (response) {
        setstockList(response);
        setPromotionalMaterialStock(JSON.parse(response.promotional));
        setOuterBagStock(JSON.parse(response.outer_bag));
        setLiteratureStock(JSON.parse(response.literature));
        setLabelStock(JSON.parse(response.label));
        setPageLoad(false)
      } else {
        setPageLoad(false);
        setstockList([]);
      }
    });
  };

  //open edit dialog
  const editData = async (data: any) => {
    setEditId(data.id);
    // set data in edit form
    setstockData(data);
    setPromotionalMaterialData(JSON.parse(data.promotional));
    setOuterBagData(JSON.parse(data.outer_bag));
    setLiteratureData(JSON.parse(data.literature));
    setStockDialog(true);
  }

  const hideEditDialog = () => {
    setStockDialog(false);
    setstockData({});
    setEditId(null);
  };

  //open edit dialog
  const editChemicalData = async (data: any, name: any) => {
    setEditId(data.id);
    setChemicalName(name);
    // set data in edit form
    if (name == 'thirum_chemical') {
      setChemicalData(JSON.parse(data.thirum_chemical));
    } else if (name == 'sdm_chemical') {
      setChemicalData(JSON.parse(data.sdm_chemical));
    } else if (name == 'polymer_chemical') {
      setChemicalData(JSON.parse(data.polymer_chemical));
    } else if (name == 'gaucho_chemical') {
      setChemicalData(JSON.parse(data.gaucho_chemical));
    } else if (name == 'metalyxil_chemical') {
      setChemicalData(JSON.parse(data.metalyxil_chemical));
    }
    setChemicalDialog(true);
  }

  const hideChemicalDialog = () => {
    setChemicalDialog(false);
    setChemicalData({});
    setChemicalName(null);
    setEditId(null);
  };

  //open edit dialog
  const editLabelData = async (data: any) => {
    setEditId(data.id);
    // set data in edit form
    setLabelData(JSON.parse(data.label));
    setLabelDialog(true);
  }

  const hideEditLabelDialog = () => {
    setLabelDialog(false);
    setLabelData({});
    setEditId(null);
  };

  //edit packing material Data
  const onSubmit = async () => {
    try {
      setSubmitLoading(true);

      //Request object
      let formRequestData = new FormData();

      formRequestData.append('outer_bag', JSON.stringify(outerBagData));
      formRequestData.append('literature', JSON.stringify(literatureData));
      formRequestData.append('sampling_outer_bag', stockData.sampling_outer_bag);
      formRequestData.append('sampling_inner_bag', stockData.sampling_inner_bag);
      formRequestData.append('promotional', JSON.stringify(promotionalMaterialData));

      if (editId !== undefined && editId !== null) {
        formRequestData.append('id', editId);
      }

      pageService.editPackagingMaterialStock(formRequestData).then((response) => {
        if (response) {
          setStockDialog(false);
          setstockData({});
          setSubmitLoading(false);
          toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Data Updated Sucessfully',
          });
          getDataFromApi();
        } else {
          setStockDialog(false);
          setstockData({ stockData });
          setSubmitLoading(false);
          toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
        }
      });
    } catch (error: any) {
      if (error.response) {
        setSubmitLoading(false);
        toast.current?.show({
          severity: 'error', summary: 'Error', detail: error.response.data.error
        });
      }
    }
  };

  //edit label Data
  const onLabelSubmit = async () => {
    try {
      setSubmitLoading(true);

      //Request object
      let formRequestData = new FormData();

      formRequestData.append('label', JSON.stringify(labelData));

      if (editId !== undefined && editId !== null) {
        formRequestData.append('id', editId);
      }

      pageService.editPackagingMaterialStock(formRequestData).then((response) => {
        if (response) {
          setLabelDialog(false);
          setLabelData({});
          setSubmitLoading(false);
          toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Data Updated Sucessfully',
          });
          getDataFromApi();
        } else {
          setLabelDialog(false);
          setLabelData({ labelData });
          setSubmitLoading(false);
          toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
        }
      });
    } catch (error: any) {
      if (error.response) {
        setSubmitLoading(false);
        toast.current?.show({
          severity: 'error', summary: 'Error', detail: error.response.data.error
        });
      }
    }
  };

  //edit packing material Data
  const onChemicalDataSubmit = async () => {
    setSubmitLoading(true);

    //Request object
    let formRequestData = new FormData();

    formRequestData.append(chemicalName, JSON.stringify(chemicalData));

    if (editId !== undefined && editId !== null) {
      formRequestData.append('id', editId);
    }

    pageService.editPackagingMaterialStock(formRequestData).then((response) => {
      setChemicalDialog(false);
      setChemicalName(null);
      setChemicalData({});
      setSubmitLoading(false);
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Data Updated Sucessfully',
      });
      getDataFromApi();
    }).catch(error => {
      setChemicalDialog(false);
      setSubmitLoading(false);
      toast.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.error });
    });

  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card" style={{ position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h4 className='mb-3'>Packaging Stock Data</h4>
              <Button
                label='Edit'
                style={{ minWidth: "70px" }}
                className="p-button-success p-2"
                onClick={() => editData(stockList)}
              />
            </div>
            <Toast ref={toast} />
            <div className="grid">
            
              <div className="field col-12 md:col-4">
                <p> <b>Sampling Inner Bag</b> : {!window.cn(stockList) ? stockList.sampling_inner_bag : ""}</p>
              </div>

              <div className="field col-12 md:col-4">
                <p> <b>Sampling Outer Bag</b> : {!window.cn(stockList) ? stockList.sampling_outer_bag : ""}</p>
              </div>
            </div>

            <hr />
            <h5 className='mb-3'>Outer Bags</h5>
            <div className="grid">
              <div className="field col-12 md:col-4">
                <p> <b>Colour Standard Bag</b> : {!window.cn(stockList) ? outerBagStock.colour_std_bag : ""}</p>
              </div>
              <div className="field col-12 md:col-4">
                <p> <b>White Standard Bag</b> : {!window.cn(stockList) ? outerBagStock.white_std_bag : ""}</p>
              </div>
              <div className="field col-12 md:col-4">
                <p> <b>Cotton HDP Standard Bag</b> : {!window.cn(stockList) ? outerBagStock.cotton_hdp_std_bag : ""}</p>
              </div>
            </div>
            <div className="grid">
              <div className="field col-12 md:col-4">
                <p> <b>CORUGETED Box (Onion)</b> : {!window.cn(stockList) ? outerBagStock.corugeted_box : ""}</p>
              </div>
            </div>

            <hr />
            <h5 className='mb-3'>Promotional Material</h5>
            <div className="grid">
              <div className="field col-12 md:col-4">
                <p> <b>Flex</b> : {!window.cn(stockList) ? promotionalMaterialStock.FLEX : ""}</p>
              </div>
              <div className="field col-12 md:col-4">
                <p> <b>Poster</b> : {!window.cn(stockList) ? promotionalMaterialStock.POSTER : ""}</p>
              </div>
              <div className="field col-12 md:col-4">
                <p> <b>Literatures</b> : {!window.cn(stockList) ? promotionalMaterialStock.LITERATURES : ""}</p>
              </div>
            </div>

            <div className="grid">
              <div className="field col-12 md:col-4">
                <p> <b>HandBag</b> : {!window.cn(stockList) ? promotionalMaterialStock.HANDBAG : ""}</p>
              </div>
              <div className="field col-12 md:col-4">
                <p> <b>CarryBag</b> : {!window.cn(stockList) ? promotionalMaterialStock.CARRYBAG : ""}</p>
              </div>
              <div className="field col-12 md:col-4">
                <p> <b>Diaries</b> : {!window.cn(stockList) ? promotionalMaterialStock.DIARIES : ""}</p>
              </div>
            </div>

            <hr />
            <h5 className='mb-3'>Literature</h5>
            <div className="grid">
              <div className="field col-12 md:col-4">
                <p> <b>Paddy</b> : {!window.cn(stockList) ? literatureStock.PADDY : ""}</p>
              </div>
              <div className="field col-12 md:col-4">
                <p> <b>Cumin</b> : {!window.cn(stockList) ? literatureStock.CUMIN : ""}</p>
              </div>
              <div className="field col-12 md:col-4">
                <p> <b>Gram</b> : {!window.cn(stockList) ? literatureStock.GRAM : ""}</p>
              </div>
            </div>
            <div className="grid">
              <div className="field col-12 md:col-4">
                <p> <b>Wheat</b> : {!window.cn(stockList) ? literatureStock.WHEAT : ""}</p>
              </div>
              <div className="field col-12 md:col-4">
                <p> <b>Soyabean</b> : {!window.cn(stockList) ? literatureStock.SOYABEAN : ""}</p>
              </div>
              <div className="field col-12 md:col-4">
                <p> <b>Groundnut</b> : {!window.cn(stockList) ? literatureStock.GROUNDNUT : ""}</p>
              </div>
            </div>

            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h5 className='mb-3'>Label</h5>
              <Button
                label='Edit'
                style={{ minWidth: "70px" }}
                className="p-button-success p-2"
                onClick={() => editLabelData(stockList)}
              />
            </div>
            <div className="grid">
              <div className="field col-12 md:col-2">
                <p> <b>Paddy</b> </p>
              </div>
              <div className="field col-12 md:col-10">
                <p><b> 25kg : </b>{!window.cn(stockList) ? labelStock.PADDY_25kg : ""}</p>
              </div>
              <div className="field col-12 md:col-2">
                <p> <b>Cumin</b> </p>
              </div>
              <div className="field col-12 md:col-2">
                <p><b> 5kg : </b>{!window.cn(stockList) ? labelStock.CUMIN_5kg : ""}</p>
              </div>
              <div className="field col-12 md:col-8">
                <p><b> 10kg : </b>{!window.cn(stockList) ? labelStock.CUMIN_10kg : ""}</p>
              </div>
              <div className="field col-12 md:col-2">
                <p> <b>Gram</b> </p>
              </div>
              <div className="field col-12 md:col-10">
                <p><b> 10kg : </b>{!window.cn(stockList) ? labelStock.GRAM_10kg : ""}</p>
              </div>
              <div className="field col-12 md:col-2">
                <p> <b>Wheat</b> </p>
              </div>
              <div className="field col-12 md:col-2">
                <p><b> 20kg : </b>{!window.cn(stockList) ? labelStock.WHEAT_20kg : ""}</p>
              </div>
              <div className="field col-12 md:col-8">
                <p><b> 40kg : </b>{!window.cn(stockList) ? labelStock.WHEAT_40kg : ""}</p>
              </div>
              <div className="field col-12 md:col-2">
                <p> <b>Soyabean</b> </p>
              </div>
              <div className="field col-12 md:col-2">
                <p><b> 25kg : </b>{!window.cn(stockList) ? labelStock.SOYABEAN_25kg : ""}</p>
              </div>
              <div className="field col-12 md:col-8">
                <p><b> 30kg : </b>{!window.cn(stockList) ? labelStock.SOYABEAN_30kg : ""}</p>
              </div>
              <div className="field col-12 md:col-2">
                <p> <b>Groundnut</b> </p>
              </div>
              <div className="field col-12 md:col-10">
                <p><b> 20kg : </b>{!window.cn(stockList) ? labelStock.GROUNDNUT_20kg : ""}</p>
              </div>
              <div className="field col-12 md:col-2">
                <p> <b>Green Gram (Mung)</b> </p>
              </div>
              <div className="field col-12 md:col-10">
                <p><b> 2kg : </b>{!window.cn(stockList) ? labelStock.GREEN_GRAM_2kg : ""}</p>
              </div>
              <div className="field col-12 md:col-2">
                <p> <b>Castor</b> </p>
              </div>
              <div className="field col-12 md:col-10">
                <p><b> 2kg : </b>{!window.cn(stockList) ? labelStock.CASTOR_2kg : ""}</p>
              </div>
              <div className="field col-12 md:col-2">
                <p> <b>Black Gram</b> </p>
              </div>
              <div className="field col-12 md:col-10">
                <p><b> 2kg : </b>{!window.cn(stockList) ? labelStock.BLACK_GRAM_2kg : ""}</p>
              </div>
            </div>

            <hr />
            <h5 className='mb-3'>Chemical</h5>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h6>Thirum - 25g : {!window.cn(stockList) && stockList.thirum_chemical != undefined ? JSON.parse(stockList.thirum_chemical)["twentyFiveG"] : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 30g : {!window.cn(stockList) && stockList.thirum_chemical != undefined ? JSON.parse(stockList.thirum_chemical)["thirtyG"] : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 20g : {!window.cn(stockList) && stockList.thirum_chemical != undefined ? JSON.parse(stockList.thirum_chemical)["twentyG"] : ""} </h6>
              <Button
                label='Edit'
                style={{ minWidth: "70px" }}
                className="p-button-success p-2 mb-2"
                onClick={() => editChemicalData(stockList, 'thirum_chemical')}
              />
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h6>SDM - kg : {!window.cn(stockList) && stockList.sdm_chemical != undefined ? JSON.parse(stockList.sdm_chemical)["kg"] : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; g : {!window.cn(stockList) && stockList.sdm_chemical != undefined ? JSON.parse(stockList.sdm_chemical)["g"] : ""}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; L : {!window.cn(stockList) && stockList.sdm_chemical != undefined ? JSON.parse(stockList.sdm_chemical)["l"] : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; mL : {!window.cn(stockList) && stockList.sdm_chemical != undefined ? JSON.parse(stockList.sdm_chemical)["ml"] : ""}</h6>
              <Button
                label='Edit'
                style={{ minWidth: "70px" }}
                className="p-button-success p-2 mb-2"
                onClick={() => editChemicalData(stockList, 'sdm_chemical')}
              />
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h6>POLYMER - L : {!window.cn(stockList) && stockList.polymer_chemical != undefined ? JSON.parse(stockList.polymer_chemical)["l"] : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; mL : {!window.cn(stockList) && stockList.polymer_chemical != undefined ? JSON.parse(stockList.polymer_chemical)["ml"] : ""} </h6>
              <Button
                label='Edit'
                style={{ minWidth: "70px" }}
                className="p-button-success p-2 mb-2"
                onClick={() => editChemicalData(stockList, 'polymer_chemical')}
              />
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h6>Gaucho - kg : {!window.cn(stockList) && stockList.gaucho_chemical != undefined ? JSON.parse(stockList.gaucho_chemical)["l"] : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; mL : {!window.cn(stockList) && stockList.gaucho_chemical != undefined ? JSON.parse(stockList.gaucho_chemical)["ml"] : ""}</h6>
              <Button
                label='Edit'
                style={{ minWidth: "70px" }}
                className="p-button-success p-2 mb-2"
                onClick={() => editChemicalData(stockList, 'gaucho_chemical')}
              />
            </div>
            <hr /> <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h6>Metalyxil - kg : {!window.cn(stockList) && stockList.metalyxil_chemical != undefined ? JSON.parse(stockList.metalyxil_chemical)["kg"] : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; g : {!window.cn(stockList) && stockList.metalyxil_chemical != undefined ? JSON.parse(stockList.metalyxil_chemical)["g"] : ""} </h6>
              <Button
                label='Edit'
                style={{ minWidth: "70px" }}
                className="p-button-success p-2"
                onClick={() => editChemicalData(stockList, 'metalyxil_chemical')}
              />
            </div>

            {
              pageLoad && <Loader />
            }
          </div>
        </div>
      </div >

      {/* Edit Dialog Start*/}
      < Dialog
        visible={stockDialog}
        className="p-fluid"
        onHide={hideEditDialog}
        header='Edit Packaging Materail Stock Data'
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideEditDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              className="p-button-text"
              loading={submitLoading}
              onClick={onSubmit}
            />
          </>
        }
      >
        <div className="formgrid grid">
          <div className="field col-4">
            <label htmlFor="sampling_inner_bag">Sampling Inner Bag</label>
            <InputText
              value={!window.cn(stockData) ? stockData.sampling_inner_bag : ""}
              name="sampling_inner_bag"
              onChange={(e) => onInputChange(e, 'sampling_inner_bag')}
              autoComplete="off"
            />
          </div>

          <div className="field col-4">
            <label htmlFor="sampling_outer_bag">Sampling Outer Bag</label>
            <InputText
              value={!window.cn(stockData) ? stockData.sampling_outer_bag : ""}
              name="sampling_outer_bag"
              onChange={(e) => onInputChange(e, 'sampling_outer_bag')}
              autoComplete="off"
            />
          </div>
        </div>

        <hr />
        <h5 className='mb-3'>Outer Bags</h5>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="colour_std_bag">Colour Std Bag</label>
            <InputText
              value={!window.cn(outerBagData) ? outerBagData.colour_std_bag : ""}
              name="colour_std_bag"
              autoComplete="off"
              onChange={(e) => outerBagInputChange(e, 'colour_std_bag')}
            />
          </div>
          <div className="field col">
            <label htmlFor="white_std_bag">White Std Bag</label>
            <InputText
              value={!window.cn(outerBagData) ? outerBagData.white_std_bag : ""}
              name="white_std_bag"
              autoComplete="off"
              onChange={(e) => outerBagInputChange(e, 'white_std_bag')}
            />
          </div>
          <div className="field col">
            <label htmlFor="cotton_hdp_std_bag">Cotton HDP Std Bag</label>
            <InputText
              value={!window.cn(outerBagData) ? outerBagData.cotton_hdp_std_bag : ""}
              name="cotton_hdp_std_bag"
              autoComplete="off"
              onChange={(e) => outerBagInputChange(e, 'cotton_hdp_std_bag')}
            />
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-4">
            <label htmlFor="corugeted_box">Corugeted Box</label>
            <InputText
              value={!window.cn(outerBagData) ? outerBagData.corugeted_box : ""}
              name="corugeted_box"
              autoComplete="off"
              onChange={(e) => outerBagInputChange(e, 'corugeted_box')}
            />
          </div>
        </div>

        <hr />
        <h5 className='mb-3'>Promotional Material</h5>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="flex">Flex</label>
            <InputText
              value={!window.cn(promotionalMaterialData) ? promotionalMaterialData.FLEX : ""}
              name="FLEX"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'FLEX')}
            />
          </div>
          <div className="field col">
            <label htmlFor="poster">Poster</label>
            <InputText
              value={!window.cn(promotionalMaterialData) ? promotionalMaterialData.POSTER : ""}
              name="POSTER"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'POSTER')}
            />
          </div>
          <div className="field col">
            <label htmlFor="literatures">Literatures</label>
            <InputText
              value={!window.cn(promotionalMaterialData) ? promotionalMaterialData.LITERATURES : ""}
              name="LITERATURES"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'LITERATURES')}
            />
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="handbag">HandBag</label>
            <InputText
              value={!window.cn(promotionalMaterialData) ? promotionalMaterialData.HANDBAG : ""}
              name="HANDBAG"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'HANDBAG')}
            />
          </div>
          <div className="field col">
            <label htmlFor="carrybag">Carry Bag</label>
            <InputText
              value={!window.cn(promotionalMaterialData) ? promotionalMaterialData.CARRYBAG : ""}
              name="CARRYBAG"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'CARRYBAG')}
            />
          </div>
          <div className="field col">
            <label htmlFor="diaries">Diaries</label>
            <InputText
              value={!window.cn(promotionalMaterialData) ? promotionalMaterialData.DIARIES : ""}
              name="DIARIES"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'DIARIES')}
            />
          </div>
        </div>

        <hr />
        <h5 className='mb-3'>Literature</h5>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="PADDY">Paddy</label>
            <InputText
              value={!window.cn(literatureData) ? literatureData.PADDY : ""}
              name="PADDY"
              autoComplete="off"
              onChange={(e) => literatureInputChange(e, 'PADDY')}
            />
          </div>
          <div className="field col">
            <label htmlFor="CUMIN">Cumin</label>
            <InputText
              value={!window.cn(literatureData) ? literatureData.CUMIN : ""}
              name="CUMIN"
              autoComplete="off"
              onChange={(e) => literatureInputChange(e, 'CUMIN')}
            />
          </div>
          <div className="field col">
            <label htmlFor="GRAM">Gram</label>
            <InputText
              value={!window.cn(literatureData) ? literatureData.GRAM : ""}
              name="GRAM"
              autoComplete="off"
              onChange={(e) => literatureInputChange(e, 'GRAM')}
            />
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="WHEAT">Wheat</label>
            <InputText
              value={!window.cn(literatureData) ? literatureData.WHEAT : ""}
              name="WHEAT"
              autoComplete="off"
              onChange={(e) => literatureInputChange(e, 'WHEAT')}
            />
          </div>
          <div className="field col">
            <label htmlFor="SOYABEAN">Soyabean</label>
            <InputText
              value={!window.cn(literatureData) ? literatureData.SOYABEAN : ""}
              name="SOYABEAN"
              autoComplete="off"
              onChange={(e) => literatureInputChange(e, 'SOYABEAN')}
            />
          </div>
          <div className="field col">
            <label htmlFor="GROUNDNUT">Groundnut</label>
            <InputText
              value={!window.cn(literatureData) ? literatureData.GROUNDNUT : ""}
              name="GROUNDNUT"
              autoComplete="off"
              onChange={(e) => literatureInputChange(e, 'GROUNDNUT')}
            />
          </div>
        </div>

      </Dialog >
      {/* Edit Dialog End*/}

      {/* Edit Label Dialog Start*/}
      < Dialog
        visible={labelDialog}
        className="p-fluid"
        onHide={hideEditLabelDialog}
        header='Edit Label Data'
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideEditLabelDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              className="p-button-text"
              loading={submitLoading}
              onClick={onLabelSubmit}
            />
          </>
        }
      >
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="PADDY_25kg">Paddy 25kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.PADDY_25kg : ""}
              name="PADDY_25kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'PADDY_25kg')}
            />
          </div>
          <div className="field col">
            <label htmlFor="CUMIN_5kg">Cumin 5kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.CUMIN_5kg : ""}
              name="CUMIN_5kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'CUMIN_5kg')}
            />
          </div>
          <div className="field col">
            <label htmlFor="CUMIN_10kg">Cumin 10kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.CUMIN_10kg : ""}
              name="CUMIN_10kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'CUMIN_10kg')}
            />
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="GRAM_10kg">Gram 10kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.GRAM_10kg : ""}
              name="GRAM_10kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'GRAM_10kg')}
            />
          </div>
          <div className="field col">
            <label htmlFor="WHEAT_20kg">Wheat 20kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.WHEAT_20kg : ""}
              name="WHEAT_20kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'WHEAT_20kg')}
            />
          </div>
          <div className="field col">
            <label htmlFor="WHEAT_40kg">Wheat 40kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.WHEAT_40kg : ""}
              name="WHEAT_40kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'WHEAT_40kg')}
            />
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="SOYABEAN_25kg">Soyabean 25kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.SOYABEAN_25kg : ""}
              name="SOYABEAN_25kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'SOYABEAN_25kg')}
            />
          </div>
          <div className="field col">
            <label htmlFor="SOYABEAN_30kg">Soyabean 30kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.SOYABEAN_30kg : ""}
              name="SOYABEAN_30kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'SOYABEAN_30kg')}
            />
          </div>
          <div className="field col">
            <label htmlFor="GROUNDNUT_20kg">Groundnut 20kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.GROUNDNUT_20kg : ""}
              name="GROUNDNUT_20kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'GROUNDNUT_20kg')}
            />
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="GREEN_GRAM_2kg">Green Gram (Mung) 2kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.GREEN_GRAM_2kg : ""}
              name="GREEN_GRAM_2kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'GREEN_GRAM_2kg')}
            />
          </div>
          <div className="field col">
            <label htmlFor="CASTOR_2kg">Castor 2kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.CASTOR_2kg : ""}
              name="CASTOR_2kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'CASTOR_2kg')}
            />
          </div>
          <div className="field col">
            <label htmlFor="BLACK_GRAM_2kg">Black Gram 2kg</label>
            <InputText
              value={!window.cn(labelData) ? labelData.BLACK_GRAM_2kg : ""}
              name="BLACK_GRAM_2kg"
              autoComplete="off"
              onChange={(e) => labelInputChange(e, 'BLACK_GRAM_2kg')}
            />
          </div>
        </div>
      </Dialog >
      {/* Edit Label Dialog End*/}

      {/* Chemical Dialog Start*/}
      <Dialog
        visible={chemicalDialog}
        className="p-fluid"
        onHide={hideChemicalDialog}
        header='Edit Chemical Data'
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideChemicalDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              className="p-button-text"
              loading={submitLoading}
              onClick={onChemicalDataSubmit}
            />
          </>
        }
      >
        {!window.cn(chemicalName) && chemicalName == 'thirum_chemical' ?
          <>
            <div className="formgrid grid">
              <div className="field col-4">
                <label htmlFor="twentyG">20g Thirum </label>
                <InputText
                  value={!window.cn(chemicalData) ? chemicalData.twentyG : ""}
                  name="twentyG"
                  autoComplete="off"
                  onChange={(e) => chemicalInputChange(e, 'twentyG')}
                />
              </div>
              <div className="field col-4">
                <label htmlFor="twentyFiveG">25g Thirum</label>
                <InputText
                  value={!window.cn(chemicalData) ? chemicalData.twentyFiveG : ""}
                  name="twentyFiveG"
                  autoComplete="off"
                  onChange={(e) => chemicalInputChange(e, 'twentyFiveG')}
                />
              </div>
              <div className="field col-4">
                <label htmlFor="thirtyG">30g Thirum</label>
                <InputText
                  value={!window.cn(chemicalData) ? chemicalData.thirtyG : ""}
                  name="thirtyG"
                  autoComplete="off"
                  onChange={(e) => chemicalInputChange(e, 'thirtyG')}
                />
              </div>
            </div>
          </> : !window.cn(chemicalName) && chemicalName == 'sdm_chemical' ?
            <>
              <div className="formgrid grid">
                <div className="field col-6">
                  <label htmlFor="kg">SDM in Kg</label>
                  <InputText
                    value={!window.cn(chemicalData) ? chemicalData.kg : ""}
                    name="kg"
                    autoComplete="off"
                    onChange={(e) => chemicalInputChange(e, 'kg')}
                  />
                </div>
                <div className="field col-6">
                  <label htmlFor="g">SDM in Gram</label>
                  <InputText
                    value={!window.cn(chemicalData) ? chemicalData.g : ""}
                    name="g"
                    autoComplete="off"
                    onChange={(e) => chemicalInputChange(e, 'g')}
                  />
                </div>
                <div className="field col-6">
                  <label htmlFor="l">SDM in L</label>
                  <InputText
                    value={!window.cn(chemicalData) ? chemicalData.l : ""}
                    name="l"
                    autoComplete="off"
                    onChange={(e) => chemicalInputChange(e, 'l')}
                  />
                </div>
                <div className="field col-6">
                  <label htmlFor="ml">SDM in mL</label>
                  <InputText
                    value={!window.cn(chemicalData) ? chemicalData.ml : ""}
                    name="ml"
                    autoComplete="off"
                    onChange={(e) => chemicalInputChange(e, 'ml')}
                  />
                </div>
              </div>
            </> : !window.cn(chemicalName) && chemicalName == 'polymer_chemical' ?
              <>
                <div className="formgrid grid">
                  <div className="field col-6">
                    <label htmlFor="l">Polymer in L</label>
                    <InputText
                      value={!window.cn(chemicalData) ? chemicalData.l : ""}
                      name="l"
                      autoComplete="off"
                      onChange={(e) => chemicalInputChange(e, 'l')}
                    />
                  </div>
                  <div className="field col-6">
                    <label htmlFor="ml">Polymer in mL</label>
                    <InputText
                      value={!window.cn(chemicalData) ? chemicalData.ml : ""}
                      name="ml"
                      autoComplete="off"
                      onChange={(e) => chemicalInputChange(e, 'ml')}
                    />
                  </div>
                </div>
              </> : !window.cn(chemicalName) && chemicalName == 'gaucho_chemical' ?
                <>
                  <div className="formgrid grid">
                    <div className="field col-6">
                      <label htmlFor="l">Gaucho in L</label>
                      <InputText
                        value={!window.cn(chemicalData) ? chemicalData.l : ""}
                        name="l"
                        autoComplete="off"
                        onChange={(e) => chemicalInputChange(e, 'l')}
                      />
                    </div>
                    <div className="field col-6">
                      <label htmlFor="ml">Gaucho in mL</label>
                      <InputText
                        value={!window.cn(chemicalData) ? chemicalData.ml : ""}
                        name="ml"
                        autoComplete="off"
                        onChange={(e) => chemicalInputChange(e, 'ml')}
                      />
                    </div>
                  </div>
                </> : !window.cn(chemicalName) && chemicalName == 'metalyxil_chemical' ?
                  <>
                    <div className="formgrid grid">
                      <div className="field col-6">
                        <label htmlFor="kg">Metalyxil in Kg</label>
                        <InputText
                          value={!window.cn(chemicalData) ? chemicalData.kg : ""}
                          name="kg"
                          autoComplete="off"
                          onChange={(e) => chemicalInputChange(e, 'kg')}
                        />
                      </div>
                      <div className="field col-6">
                        <label htmlFor="g">Metalyxil in Gram</label>
                        <InputText
                          value={!window.cn(chemicalData) ? chemicalData.g : ""}
                          name="g"
                          autoComplete="off"
                          onChange={(e) => chemicalInputChange(e, 'g')}
                        />
                      </div>
                    </div>
                  </> : ""
        }

      </Dialog>
      {/* Chemical Dialog End*/}
    </>
  )

};