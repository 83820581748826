import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { DeleteProductColumns, ProductColumns } from '../../appconfig/DatatableSetting';
import { Column } from 'primereact/column';
import ProductService from '../../service/ProductService';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import { defaultPageRowOptions, defaultRowOptions, paginatorLinks, showingEntries, SkeletonbodyTemplate, Skeletonitems } from '../../appconfig/Settings';

export const DeleteMultipleData = () => {
    const [products, setProducts] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageLoad, setPageLoad] = useState(false);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [deleteProductDialog, setDeleteProductDialog] = useState<boolean>(false);
    const [selectedProducts, setSelectedProducts] = useState<any>(null);

    const toast = useRef<any>(null);

    useEffect(() => {
        getData();
    }, []);


    const getData: any = () => {
        if (pageLoad) setLoading(true);

        const productService = new ProductService();
        productService.getProducts().then((data) => {
            if (data.length == 0) {
                setProducts([]);
            } else {
                setProducts(data);
            }
            setPageLoad(true);
            setLoading(false);
        });
    };

    // open delete dialog
    const confirmDeleteProduct = () => {
        setDeleteProductDialog(true);
    };

    //close delete dialog
    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setSelectedProducts(null);
    };

    const deleteProduct = () => {
        let _products = products.filter((val: any) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    };

    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-success mr-3" />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" />
            </div>
        );
    };
    const rightToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.currentTarget.value)} placeholder="Search..." />
                </span>
            </>
        );
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success mr-2 mb-2" />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger mb-2" onClick={confirmDeleteProduct} disabled={!selectedProducts || !selectedProducts.length} />
            </React.Fragment>
        );
    };

    return (
        <>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

                        {/* Datatable */}
                        {pageLoad ? (
                            <>
                                <DataTable
                                    value={products}
                                    selection={selectedProducts}
                                    onSelectionChange={(e) => setSelectedProducts(e.value)}
                                    loading={loading}
                                    paginator={products.length > 0 && true}
                                    globalFilter={globalFilter}
                                    rows={defaultRowOptions}
                                    rowsPerPageOptions={defaultPageRowOptions}
                                    className="datatable-responsive"
                                    paginatorTemplate={paginatorLinks}
                                    currentPageReportTemplate={showingEntries}
                                >
                                    {DeleteProductColumns.map((col, i) => {
                                        if (col.field === 'action') {
                                            return <Column key={col.field} field={col.field} header={col.header} body={actionBodyTemplate} />;
                                        } else if (col.field === 'check') {
                                            return <Column selectionMode="multiple" key={col.field} field={col.field} header={col.header} body={actionBodyTemplate} />;
                                        }
                                        else {
                                            return <Column key={col.field} field={col.field} header={col.header} />;
                                        }
                                    })}
                                </DataTable>
                            </>
                        ) : (
                            <>
                                <DataTable value={Skeletonitems}>
                                    {ProductColumns.map((col, i) => (
                                        <Column key={col.field} field={col.field} header={col.header} body={SkeletonbodyTemplate} />
                                    ))}
                                </DataTable>
                            </>
                        )}

                        {/* deleteDialog start*/}
                        <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Delete" modal footer={
                            <>
                                <Button label="Cancel" icon="pi pi-times" className='p-button-secondary' onClick={hideDeleteProductDialog} />
                                <Button label="Delete" icon="pi pi-trash" className='p-button-danger' onClick={deleteProduct} />
                            </>
                        } onHide={hideDeleteProductDialog}>
                            <div className="flex align-items-center justify-content-start">
                                <i className="pi pi-exclamation-triangle mr-3 delete-triangle" style={{ fontSize: '2rem' }} />
                                <span className='delete-dialog-note'>
                                    Are you sure you want to delete ?
                                </span>
                            </div>
                        </Dialog>
                        {/* deleteDialog End*/}

                    </div>

                    <div className="card">
                        <TabView>
                            <TabPanel header="Header I">
                                <p className="m-0">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                            </TabPanel>
                            <TabPanel header="Header II">
                                <p className="m-0">
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                                    eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo
                                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui
                                    ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                                </p>
                            </TabPanel>
                            <TabPanel header="Header III">
                                <p className="m-0">
                                    At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                                    quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
                                    culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                                    Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                                </p>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        </>
    );
};
