import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar'
import { CSVLink } from "react-csv";
import { formatDateTime } from '../../appconfig/AppHelper';

// Column
import { GrowerColumns } from '../../appconfig/DatatableSetting';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';


export const GrowerReport = () => {

    document.title = 'Inpection Report | Swarnim Farms';

    const pageService = new PageService();

    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [csvLoading, setCsvLoading] = useState<boolean>(false);

    // Date Object
    let today = new Date();
    const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);

    const [inspection, setGrowerData] = useState<any>([]);

    const csvGrowerLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const [csvGrowerData, setGrowerCsvData] = useState([]);

    // use effect method
    useEffect(() => {
        getGrowerData();
    }, []);

    useEffect(() => {
        if (csvGrowerData.length > 0) {
            csvGrowerLink?.current?.link.click();
            setGrowerCsvData([]);
        }
    }, [csvGrowerData]);


    //get dispatch data from api
    const getGrowerData = () => {
        pageService.getCSVProductionData(null, null).then((response) => {
            setPageLoad(false);
            // get response
            if (response) {
                const DataList = response.data;
                if (DataList.length == 0) {
                    setGrowerData([]);
                } else {
                    setGrowerData(DataList);
                }
                setPageLoad(true);
            } else {
                setPageLoad(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    };

    //download csv
    const exportGrowerCSV = async () => {
        setCsvLoading(true);
        //Validation Chec
        pageService.getCSVProductionData(null, null).then((response) => {
            // get response
            if (response) {
                let csvDetails = response.csv_data;
                if (csvDetails.length > 0) {
                    setGrowerCsvData(csvDetails);
                    setCsvLoading(false);
                }
            } else {
                setGrowerCsvData([]);
            }
        });
    }

    // on date change
    const onDateChange = (e: any) => {
        setDates(e.value);
    };

    // for right toolbar
    const rightToolbarTemplate = () => {
        return (
            <>
                <Calendar
                    value={dates}
                    dateFormat="dd/mm/yy"
                    onChange={(e) => onDateChange(e)}
                    selectionMode="range"
                    showIcon
                />
                <div style={{ marginLeft: '15px' }}></div>
                <Button
                    icon="pi pi-file-export"
                    className="p-button-success mr-2"
                    loading={csvLoading}
                    onClick={exportGrowerCSV} />
                <CSVLink
                    data={csvGrowerData}
                    filename='Grower_list.csv'
                    className='hidden'
                    ref={csvGrowerLink}
                    target='_blank'
                />
            </>
        );
    };

    const createdAtTemplate = (rowData: any) => {
        return (<>
            <div>{formatDateTime(rowData?.sowing_date)}</div>
        </>);
    };

    return (<>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h4>Grower List</h4>
                    <Toast ref={toast} />
                    <Toolbar
                        className="mb-4"
                        right={rightToolbarTemplate}
                    ></Toolbar>

                    {/* Datatable Start */}
                    {pageLoad ? (
                        <>
                            <DataTable
                                value={inspection}
                                loading={loading}
                                paginator={inspection.length > 0 && true}
                                globalFilter={globalFilter}
                                rows={defaultRowOptions}
                                rowsPerPageOptions={defaultPageRowOptions}
                                className="datatable-responsive"
                                paginatorTemplate={paginatorLinks}
                                currentPageReportTemplate={showingEntries}
                                emptyMessage="No Grower Data Found"
                            >
                                {GrowerColumns.map((col, i) => {
                                    if (col.field === 'sr_no') {
                                        return (
                                            <Column
                                                key={col.field}
                                                header={col.header}
                                                body={(_, { rowIndex }) => rowIndex + 1} />
                                        );
                                    } else if (col.field === 'sowing_date') {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                body={createdAtTemplate}
                                            />
                                        );
                                    } else {
                                        return (
                                            <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                sortable
                                                filter
                                            />
                                        );
                                    }
                                })}
                            </DataTable>
                        </>
                    ) : (
                        <>
                            {/* Skeleton Data table */}
                            <DataTable value={Skeletonitems}>
                                {GrowerColumns.map((col, i) => (
                                    <Column
                                        key={col.field}
                                        field={col.field}
                                        header={col.header}
                                        body={SkeletonbodyTemplate}
                                    />
                                ))}
                            </DataTable>
                        </>
                    )}
                    {/* Datatable End */}
                </div>
            </div>
        </div>
    </>)
}