import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { CSVLink } from "react-csv";
import { Badge } from 'primereact/badge';
import { Toast } from 'primereact/toast';
import moment from "moment/moment";

// Column
import { ProductionViewColumns } from '../../appconfig/DatatableSetting';

//File Uplaod Components
import { chooseOptions, emptyTemplate, headerTemplate,} from '../../components/ImageUploadComponent/ImageUploadSetting';

// Data table
import { defaultPageRowOptions, defaultRowOptions, paginatorLinks, showingEntries,} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

// Data table
import { weightDropdown, PaymentDropdown, packingTypeDropdown } from '../../appconfig/Settings';

// Validatetion
import { ProductValidate, ProductPaymentValidate, SampleValidate } from '../../config/Validate';
import { Loader } from '../../components/Loader/Loader';

export const ViewProduction = () => {

    document.title = 'View Production | Swarnim Farms';

    //Navigate Another Route
    const navigate = useNavigate();
    // let csvLink = React.createRef();

    const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const csvInspectionLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const csvSamplingLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const pageService = new PageService();
    const location = useLocation();

    //Loading/Page Loading
    const [loading, setLoading] = useState<boolean>(false);
    const [inspectionLoading, setInspectionLoading] = useState<boolean>(false);
    const [samplingLoading, setSamplingLoading] = useState<boolean>(false);
    const [pageLoad, setPageLoad] = useState(false);

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);

    // File Upload Details
    const fileUploadRef = useRef(null);
    const [image, setImage] = useState<any>();
    const removeFile = useRef(null);

    const [csvData, setCsvData] = useState([]);
    const [csvSamplingData, setSamplingCsvData] = useState([]);
    const [csvInspectionData, setInspectionCsvData] = useState([]);

    const [productionId, setProductionId] = useState<any>();
    const [productionData, setProductionData] = useState<any>(null);
    const [productionDetails, setProductionDetails] = useState<any>([]);

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    // Edit and Delete Model/ Dialog
    const [productDialog, setProductDialog] = useState<boolean>(false);
    const [afterRawMaterialDialog, setAfterRawMaterialDialog] = useState<boolean>(false);

    //Product Crud Object
    const [productData, setProuductData] = useState<any>({});
    const [afterRawMaterialData, setAfterRawMaterialData] = useState<any>({});
    const [productionDetailId, setProductionDetailId] = useState<any>();
    const [selectedCropId, setSelectedCropId] = useState<any>();
    const [selectedVarietyId, setSelectedVarietyId] = useState<any>();

    const [selectedPayment, setSelectedPayment] = useState<any>({ name: 'PAID', code: 'PAID' });
    const [selectedProcunment, setSelectedProcunment] = useState<any>(null);
    const [selectedPackingType, setSelectedPackingType] = useState<any>(null);
    const [totalLabourPrice, setTotalLabourPrice] = useState<number>(0);

    const [rawMatetrialId, setRawMaterialId] = useState<any>("");
    const [lotNo, setLotNo] = useState<any>({});
    const [weight, setWeight] = useState<any>({ name: 'kg', code: 'kg' });

    const [farmerData, setFarmerData] = useState<any>({});

    const [cropdropdown, setCropdropdown] = useState<any>([]);
    const [selectedCrop, setSelectedCrop] = useState<any>({ name: 'Crop : All', code: 'All' });

    //  Drop down items data
    const [cropDivisionDropdownItem, setCropDivisionItem] = useState<any>(null);
    const [conditionSampleDropdownItem, setConditionSampleDropdownItem] =
        useState<any>(null);
    const [testRequiredDropdownItem, setTestRequiredDropdownItem] =
        useState<any>(null);

    const [rowData, setRowData] = useState<any>(null);
    const [samplingData, setSamplingData] = useState<any>({});
    const [sampleDialog, setSampleDialog] = useState<boolean>(false);
    const [varietyCount, setVarietyCount] = useState<any>(null);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setProductionId(state);
            getProductionDataFromApi(state);
            getUniqueCropFromApi(state);
        }
    }, [selectedCrop]);

    useEffect(() => {
        if (csvData.length > 0) {
            csvLink?.current?.link.click();
            setCsvData([]);
        }
    }, [csvData, selectedCrop]);

    useEffect(() => {
        if (csvSamplingData.length > 0) {
            csvSamplingLink?.current?.link.click();
            setSamplingCsvData([]);
        }
    }, [csvSamplingData, selectedCrop]);

    useEffect(() => {
        if (csvInspectionData.length > 0) {
            csvInspectionLink?.current?.link.click();
            setInspectionCsvData([]);
        }
    }, [csvInspectionData, selectedCrop]);

    useEffect(() => {
        let tempAfterRawData = afterRawMaterialData.labour_no_of_bags * afterRawMaterialData.price_per_bag;
        let totalLabourCharge = tempAfterRawData;
        setTotalLabourPrice(totalLabourCharge);
    }, [afterRawMaterialData])

    // Get Production Data from API
    const getUniqueCropFromApi = async (state: any) => {
        // call api
        setPageLoad(true);
        pageService
            .getProductionDetails(state.id)
            .then((response) => {
                const DataList = response;

                let productiondetails = DataList.production_detail;

                let mymap = new Map();
                let unique = productiondetails.filter((el: any) => {
                    const val = mymap.get(el.crop_obj.name);
                    if (val) {
                        if (el.crop_obj.id != val) {
                            mymap.delete(el.crop_obj.name);
                            mymap.set(el.crop_obj.name, el.crop_obj.id);
                            return true;
                        } else {
                            return false;
                        }
                    }
                    mymap.set(el.crop_obj.name, el.crop_obj.id);
                    return true;
                });

                let tempArr: any = [];
                for (let i = 0; i < unique.length; i++) {
                    let cropObj: any = {};
                    cropObj['name'] = unique[i].crop_obj.name;
                    cropObj['code'] = unique[i].crop_obj.id;
                    tempArr.push(cropObj);
                }

                let crop: any = {
                    'name': 'Crop : All',
                    'code': 'All'
                }

                tempArr.unshift(crop);
                setCropdropdown(tempArr);

                setVarietyCount(DataList.variety_count);
                setPageLoad(false);
            });
    };

    // Get Production Data from API
    const getProductionDataFromApi = async (state: any) => {
        // call api
        setPageLoad(true);
        pageService
            .getProductionDataById(state.id, selectedCrop?.code)
            .then((response) => {
                // get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setProductionDetails(null);
                    } else {
                        setProductionDetails(DataList);
                        setProductionData(DataList[0].production)
                        // setVarietyCount()
                        setPageLoad(false);
                    }
                } else {
                    setProductionDetails(null);
                }
            });
    };

    // open add raw matrial product data dialog
    const addDialog = (rowData: any) => {
        setProductDialog(true);
        setProductionDetailId(rowData.id);
        setSelectedCropId(rowData?.crop_obj);
        setSelectedProcunment('FARMER PROCUNMENT');
        setSelectedVarietyId(rowData?.variety_obj);
        setFarmerData(rowData);
        setCropDivisionItem(rowData?.stage);
    };

    // close add salary slip dialog
    const closeAddDialog = () => {
        setProductDialog(false);
        setProuductData({});
        setSelectedProcunment("");
        setSelectedPackingType("");
        setCropDivisionItem("");
        setSelectedVarietyId({});
        setSelectedCropId({});
        setErrors({});
        {
            !window.cn(removeFile.current) && onTemplateRemove(removeFile?.current);
        }
    };

    // open add sample slip data dialog
    const hideAfterRawMaterialDialog = () => {
        setAfterRawMaterialDialog(false);
        setAfterRawMaterialData({});
        setErrors({});
    };

    //onChange Product raw matrial Data
    const onInputChange = (e: any, name: string) => {
        let val = (e.target && e.target.value) || '';
        if (name == 'crop_grown_year') {
            val = val;
        } else if (name == 'receiving_date') {
            val = e;
        } else {
            val = val.toUpperCase();
        }
        setProuductData({ ...productData, [name]: val });
    };

    //onChange Product raw matrial Data
    const onSampleInputChange = (e: any, name: string) => {
        if (name == 'sampling_date') {
            const val = (e) || '';
            setSamplingData({ ...samplingData, [name]: val });
        } else {
            const val = (e.target && e.target.value) || '';
            setSamplingData({ ...samplingData, [name]: val.toUpperCase() });
        }
    };

    // dropdown handle change packing type
    const handlePackingTypeDropdown = (id: any) => {
        setSelectedPackingType(id);
    };

    // dropdown handle change paymnet
    const handleChangePayment = (id: any) => {
        setSelectedPayment(id);
    };

    //onChange After Product raw matrial Data
    const onInputChangeAfterRawMaterial = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        setAfterRawMaterialData({ ...afterRawMaterialData, [name]: val.toUpperCase() });
    };

    // add raw matrial data
    const onSubmit = async () => {

        // Request Data
        let requestData = { ...productData };
        requestData['farmer_name'] = farmerData?.farmer_name;
        requestData['village'] = farmerData?.village;
        requestData['district'] = farmerData?.district;
        requestData['taluka'] = farmerData?.taluka;
        requestData['lot_no'] = farmerData?.lot_no;
        requestData['seed_organizer'] = productionData?.organizer?.first_name + " " + productionData?.organizer?.last_name
        requestData['weight_in'] = weight?.code;

        const { errors, isError } = ProductValidate(requestData, cropDivisionDropdownItem, selectedCropId, selectedProcunment, selectedPackingType, selectedVarietyId, image);
        setErrors(errors);
        setIsError(isError);

        //Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            let formRequestData = new FormData();

            formRequestData.append('farmer_name', farmerData?.farmer_name);
            formRequestData.append('village', farmerData?.village);
            formRequestData.append('district', farmerData?.district);
            formRequestData.append('taluka', farmerData?.taluka);
            formRequestData.append('lot_no', farmerData?.lot_no);
            formRequestData.append('seed_organizer', productionData?.organizer?.first_name + " " + productionData?.organizer?.last_name);
            formRequestData.append('weight_in', weight?.code);
            if (!window.cn(productData) && productData.location) {
                formRequestData.append('location', productData?.location);
            }
            if (!window.cn(productData) && productData.crop_grown_year) {
                formRequestData.append('crop_grown_year', productData?.crop_grown_year.getFullYear());
            }
            if (!window.cn(productData) && productData.ginning_centre) {
                formRequestData.append('ginning_centre', productData?.ginning_centre);
            }
            if (!window.cn(productData) && productData.processing_center) {
                formRequestData.append('processing_center', productData?.processing_center);
            }
            if (selectedPackingType != null) {
                formRequestData.append('packing_type', selectedPackingType?.code);
            }
            formRequestData.append('crop_qnty', productData?.crop_qnty);
            formRequestData.append('no_of_bags', productData?.no_of_bags);
            formRequestData.append('production_detail_id', productionDetailId);
            formRequestData.append('crop_division', cropDivisionDropdownItem);
            formRequestData.append('crop', selectedCropId.id);
            formRequestData.append('variety', selectedVarietyId.id);
            formRequestData.append('procunment', selectedProcunment);

            if (!window.cn(productData) && productData.receiving_date) {
                var date = new Date(productData.receiving_date);
                let receivingDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                formRequestData.append('receiving_date', receivingDate);
            }

            if (image !== undefined && image !== null && image !== "") {
                formRequestData.append('photo', image);
            }

            // api call
            pageService.addRawMatrialSlipData(formRequestData).then(response => {
                setRawMaterialId(response.data.id)
                setProductDialog(false);
                setProuductData({ productData });
                setErrors(false);
                setLoading(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Raw Matrial Slip Deatils Added.',
                });
                setLotNo(response.data);
                setAfterRawMaterialDialog(true);
                setSelectedVarietyId({});
                setSelectedCropId({});
                setCropDivisionItem("");
                setSelectedProcunment("");
                setSelectedPackingType("");
                setFarmerData({});
                setImage(null);
            }).catch(error => {
                setProductDialog(true);
                setProuductData({ productData });
                setErrors(false);
                setLoading(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Raw Matrial Slip Deatils not Added.',
                });
            });
        }
    };

    // add salary slip data
    const onSubmitAfterRawMaterialAdd = async () => {
        const { errors, isError } = ProductPaymentValidate(afterRawMaterialData, selectedPayment.code);
        setErrors(errors);
        setIsError(isError);

        // Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            let requestData = afterRawMaterialData;
            requestData["transport_PAID"] = selectedPayment.code;
            requestData["id"] = rawMatetrialId;
            requestData["total_labour_price"] = totalLabourPrice;

            // Api call
            pageService.addAfterRawmaterial(requestData).then((response) => {
                // Get Response
                if (response) {
                    setAfterRawMaterialDialog(false);
                    setAfterRawMaterialData({});
                    setErrors(false);
                    setLoading(false);
                    setSelectedProcunment("");
                    setCropDivisionItem("");
                    getProductionDataFromApi(productionId);
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Sample Slip Deatils Added.',
                    });
                } else {
                    setAfterRawMaterialDialog(true);
                    setAfterRawMaterialData({ afterRawMaterialData });
                    setConditionSampleDropdownItem({ conditionSampleDropdownItem });
                    setTestRequiredDropdownItem({ testRequiredDropdownItem });
                    setErrors(false);
                    setLoading(false);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Sample Slip Deatils not Added.',
                    });
                }
            });
        }
    }

    const cropBodyTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData?.crop_obj?.name}</div>
            </>
        )
    };

    const varietyBodyTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData?.variety_obj?.name}</div>
            </>
        )
    };

    const sowingDateFormat = (rowData: any) => {
        return (
            <>
                {rowData.sowing_date ? moment.utc(rowData.sowing_date).format('DD/MM/YYYY') : null}
            </>
        )
    };

    const inspectionBodyTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData.inspection.length}</div>
            </>
        )
    };

    const rawMaterialStatusTemplate = (rowData: any) => {
        return (
            <>
                <div>
                    {rowData.status > '3' ?
                        <Badge value='YES' severity="success"></Badge> :
                        <Badge value="NO" severity="danger"></Badge>}
                </div>
            </>
        )
    };

    const addSampleData = (rowData: any) => {
        if (!window.cn(rowData) && rowData.sampling_result == 'Fail') {
            setRowData(rowData);
            rowData.sampling_date = new Date(rowData.sampling_date);
            setSamplingData(rowData);
            setSampleDialog(true);
        } else {
            setRowData(rowData);
            setSampleDialog(true);
        }
    }

    const hideSampleDailog = () => {
        setRowData({});
        setSampleDialog(false);
        setSamplingData({});
        setErrors({});
        setIsError(false);
    };

    // add salary slip data
    const onSampleSubmit = async () => {

        const { errors, isError } = SampleValidate(samplingData);
        setErrors(errors);
        setIsError(isError);

        //Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            let formData = new FormData();
            var date = new Date(samplingData?.sampling_date);
            let samplingDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

            formData.append('id', rowData.id);
            formData.append('crop_id', rowData.crop);
            formData.append('variety_id', rowData.variety);
            formData.append('stage', rowData.stage);
            formData.append('sampling_date', samplingDate);
            formData.append('sampled_by', samplingData?.sampled_by);
            formData.append('sample_size', samplingData?.sample_size);
            formData.append('physical_purity', samplingData?.physical_purity);
            formData.append('lot_no', samplingData?.lot_no);

            // Api call
            pageService.addSampleData(formData).then((response) => {
                setSampleDialog(false);
                setRowData({});
                setSamplingData({});
                setLoading(false);
                getProductionDataFromApi(productionId)
                setErrors({});
                setIsError(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: response.message,
                });
            }).catch(error => {
                setSampleDialog(true);
                setSamplingData({ samplingData });
                setLoading(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.detail.error,
                });
            });
        }
    }

    // for column action
    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions action-btn production">
                <Button
                    label='Inspection'
                    className={!window.cn(rowData) && rowData.inspection.length == 3 ? "p-button-secondary mt-2" : "p-button-primary mt-2"}
                    style={{ marginRight: '17px' }}
                    onClick={() => navigate('/product/field-inspection', { state: { id: rowData.id } })}
                />
                <Button
                    label='sample'
                    className={!window.cn(rowData) && !window.cn(rowData.sampling_date) ? "p-button-secondary mt-2" : "p-button-primary mt-2"}
                    style={{ marginRight: '17px' }}
                    onClick={() => addSampleData(rowData)}
                    disabled={rowData["status"] >= 3 ? false : true}
                />
                <Button
                    label="Raw Material"
                    className={!window.cn(rowData) && rowData["status"] == 4 ? "p-button-secondary mt-2" : "p-button-primary mt-2"}
                    onClick={() => addDialog(rowData)}
                    disabled={rowData["sampling_current_date"] == null || rowData["status"] == 4 || rowData["sampling_result"] == 'Fail' ? true : false}
                />
            </div>
        );
    };

    //download csv
    const exportCSV = async () => {
        setLoading(true);
        //Validation Chec
        pageService
            .getCSVProductionData(productionId.id, selectedCrop?.code)
            .then((response) => {
                // get response
                if (response) {
                    let csvDetails = response.csv_data;
                    if (csvDetails.length > 0) {
                        setCsvData(csvDetails);
                        setLoading(false);
                    }
                } else {
                    setCsvData([]);
                }
            });
    }

    //download csv
    const exportSamplingCSV = async () => {
        setSamplingLoading(true);
        //Validation Chec
        pageService
            .samplingReportData(productionId.id, selectedCrop?.code)
            .then((response) => {
                // get response
                if (response) {
                    let csvDetails = response.csv_data;
                    if (csvDetails.length > 0) {
                        setSamplingCsvData(csvDetails);
                        setSamplingLoading(false);
                    }
                } else {
                    setSamplingCsvData([]);
                }
            });
    }

    //download csv
    const exportInspectionCSV = async () => {
        setInspectionLoading(true);
        //Validation Chec
        pageService
            .inspectionReportData(productionId.id, '', '', selectedCrop?.code)
            .then((response) => {
                // get response
                if (response) {
                    let csvDetails = response.csv_data;

                    if (csvDetails.length > 0) {
                        setInspectionCsvData(csvDetails);
                        setInspectionLoading(false);
                    }
                } else {
                    setInspectionCsvData([]);
                }
            });
    }

    // Template for image upload
    const itemImageTemplate = (file: any, props: any) => {
        setImage(file);
        removeFile.current = props.onRemove;
        return (
            <>
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        <img
                            alt={file.name}
                            role="presentation"
                            src={file.objectURL}
                            width={100}
                        />
                        <span className="flex flex-column text-left ml-3">{file.name}</span>
                        <Button
                            type="button"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                            onClick={() => onTemplateRemove(props.onRemove)}
                        />
                    </div>
                </div>
            </>
        );
    };

    // remove image template
    const onTemplateRemove = (callback: any) => {
        setImage({});
        callback();
    };

    return (
        <>
            <div className='main-header view-prodcution'>
                <h4 className='header'><Button
                    //label="Back"
                    icon="pi pi-arrow-left"
                    className="p-button-secondary mr-2"
                    onClick={() => navigate('/product/production')}
                />  Production Details </h4>
                <div>
                    <Dropdown
                        id="crop"
                        filter
                        value={selectedCrop}
                        onChange={(e) => setSelectedCrop(e.value)}
                        options={cropdropdown}
                        optionLabel="name"
                        placeholder="Select One"
                        name="crop"
                    ></Dropdown>
                    <Button
                        label="Grower List"
                        icon="pi pi-file-export"
                        className="p-button-success mr-2 ml-2"
                        loading={loading}
                        onClick={exportCSV} />
                    <CSVLink
                        data={csvData}
                        filename='Grower_list.csv'
                        className='hidden'
                        ref={csvLink}
                        target='_blank'
                    />
                    <Button
                        label="Inspection List"
                        icon="pi pi-file-export"
                        className="p-button-success mr-2"
                        loading={inspectionLoading}
                        onClick={exportInspectionCSV} />
                    <CSVLink
                        data={csvInspectionData}
                        filename='Inspection_list.csv'
                        className='hidden'
                        ref={csvInspectionLink}
                        target='_blank'
                    />
                    <Button
                        label="Sampling List"
                        icon="pi pi-file-export"
                        className="p-button-success"
                        loading={samplingLoading}
                        onClick={exportSamplingCSV} />
                    <CSVLink
                        data={csvSamplingData}
                        filename='Sampling_list.csv'
                        className='hidden'
                        ref={csvSamplingLink}
                        target='_blank'
                    />
                </div>
            </div>
            <Toast ref={toast} />
            <div className='crud-demo card mt-3' style={{ position: "relative" }}>
                <div className="field col">
                    <div className="grid">
                        <div className="field col-4 flex flex-column">
                            <p> <b>Organizer Name</b> : {!window.cn(productionData) ? productionData?.organizer?.first_name + " " + productionData?.organizer?.last_name : ""}</p>
                        </div>
                        <div className="field col-4 flex flex-column">
                            <p> <b>Total Area(in acres)</b> : {!window.cn(productionData) ? productionData?.total_area : ""}</p>
                        </div>
                        <div className="field col-4 flex flex-column">
                            <p> <b>Address</b> : {!window.cn(productionData) ? productionData?.organizer?.residence_address : ""}</p>
                        </div>
                        <div className="field col-4 flex flex-column">
                            <p> <b>Mobile</b> : {!window.cn(productionData) ? productionData?.organizer?.mobile : ""}</p>
                        </div>
                        <div className="field col-4 flex flex-column">
                            <p> <b>PAN No.</b> : {!window.cn(productionData) ? productionData?.organizer?.pan_no : ""}</p>
                        </div>
                        <div className="field col-4 flex flex-column">
                            <p> <b>AADHAR No.</b> : {!window.cn(productionData) ? productionData?.organizer?.aadhar_no : ""}</p>
                        </div>
                        <div className="field col-4 flex flex-column">
                            <p> <b>Data Added By</b> : {!window.cn(productionData) ? productionData?.data_added_by_user : ""}</p>
                        </div>
                        {!window.cn(productionData)  && !window.cn(productionData.data_edited_by_user) ? 
                        <div className="field col-4 flex flex-column">
                            <p> <b>Data Edited By</b> : {!window.cn(productionData.data_edited_by_user) ? productionData?.data_edited_by_user : ""}</p>
                        </div> : ""}
                    </div>
                    <hr/>
                    <h5>Acres Distributed For Each Variety</h5>
                    <div className="grid">
                        {!window.cn(varietyCount) && varietyCount.map((item: any, index: number) => (
                            <div className="field col-4 flex flex-column">
                                <p> <b>{varietyCount[index]["variety_obj"]["name"]}</b> : {varietyCount[index]["area_sum"] + " (acres)"}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Datatable Start */}
                <DataTable
                    value={productionDetails}
                    loading={loading}
                    paginator={productionDetails.length > 0 && true}
                    globalFilter={globalFilter}
                    rows={defaultRowOptions}
                    rowsPerPageOptions={defaultPageRowOptions}
                    className="datatable-responsive p-datatable-sm"
                    paginatorTemplate={paginatorLinks}
                    currentPageReportTemplate={showingEntries}
                    emptyMessage="No Production Data Found"
                >
                    {ProductionViewColumns.map((col, i) => {
                        if (col.field === 'action') {
                            return (
                                <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={actionBodyTemplate}
                                />
                            );
                        } else if (col.field === 'sr_no') {
                            return (
                                <Column
                                    key={col.field}
                                    header={col.header}
                                    body={(_, { rowIndex }) => rowIndex + 1} />
                            );
                        } else if (col.field === 'crop') {
                            return (
                                <Column
                                    key={col.field}
                                    header={col.header}
                                    body={cropBodyTemplate} />
                            );
                        } else if (col.field === 'variety') {
                            return (
                                <Column
                                    key={col.field}
                                    header={col.header}
                                    body={varietyBodyTemplate} />
                            );
                        } else if (col.field === 'sowing_date') {
                            return (
                                <Column
                                    key={col.field}
                                    header={col.header}
                                    field={col.field}
                                    body={sowingDateFormat} />
                            );
                        } else if (col.field === 'inspection') {
                            return (
                                <Column
                                    key={col.field}
                                    header={col.header}
                                    field={col.field}
                                    body={inspectionBodyTemplate} />
                            );
                        } else if (col.field === 'status') {
                            return (
                                <Column
                                    key={col.field}
                                    header={col.header}
                                    field={col.field}
                                    body={rawMaterialStatusTemplate} />
                            );
                        } else {
                            return (
                                <Column
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                />
                            );
                        }
                    })}
                </DataTable>
            </div>

            {/* Add Sample Dialog Start*/}
            <Dialog
                visible={sampleDialog}
                className="p-fluid"
                onHide={() => hideSampleDailog()}
                header={!window.cn(rowData) && rowData.sampling_current_date == null ? "Add Sample Details" : "Sample Details"}
                footer={
                    <>
                        {!window.cn(rowData) && (rowData.sampling_result == null || rowData.sampling_result == 'Fail') ? <>
                            <Button
                                label="Cancel"
                                icon="pi pi-times"
                                className="p-button-text"
                                onClick={() => hideSampleDailog()}
                            />
                            <Button
                                label="Save"
                                icon="pi pi-check"
                                className="p-button-text"
                                onClick={onSampleSubmit}
                                loading={loading}
                            />
                        </> : ""}
                    </>
                }
            >
                {!window.cn(rowData) && (rowData.sampling_result == null || rowData.sampling_result == 'Fail') ? <>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="sampling_date">Sampling Date *</label>
                            <Calendar
                                value={!window.cn(samplingData) ? samplingData.sampling_date : ""}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => onSampleInputChange(e.value, 'sampling_date')}
                                name="sampling_date"
                                showIcon
                            />
                            <small className="p-invalid-txt">{errors['sampling_date']}</small>
                        </div>
                        <div className="field col">
                            <label htmlFor="sampled_by">Sampled By *</label>
                            <InputText
                                value={!window.cn(samplingData) ? samplingData.sampled_by : ""}
                                name="sampled_by"
                                autoComplete="off"
                                onChange={(e) => onSampleInputChange(e, 'sampled_by')}
                            />
                            <small className="p-invalid-txt">{errors['sampled_by']}</small>
                        </div>
                        <div className="field col">
                            <label htmlFor="lot_no">Lot No. *</label>
                            <InputText
                                value={!window.cn(samplingData) ? samplingData.lot_no : ""}
                                name="lot_no"
                                autoComplete="off"
                                onChange={(e) => onSampleInputChange(e, "lot_no")}
                            />
                            <small className="p-invalid-txt">{errors['lot_no']}</small>
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="sample_size">Sample Size *</label>
                            <InputText
                                value={!window.cn(samplingData) ? samplingData.sample_size : ""}
                                name="sample_size"
                                autoComplete="off"
                                onChange={(e) => onSampleInputChange(e, 'sample_size')}
                            />
                            <small className="p-invalid-txt">{errors['sample_size']}</small>
                        </div>
                        <div className="field col">
                            <label htmlFor="physical_purity">Physical Purity (in %) *</label>
                            <InputText
                                keyfilter="num"
                                value={!window.cn(samplingData) ? samplingData.physical_purity : ""}
                                name="physical_purity"
                                autoComplete="off"
                                onChange={(e) => onSampleInputChange(e, 'physical_purity')}
                            />
                            <small className="p-invalid-txt">{errors['physical_purity']}</small>
                        </div>
                    </div>
                </> : <>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-4 flex flex-column">
                            <p> <b>Sampling Date</b> : {!window.cn(rowData) ? rowData.sampling_date : ""}</p>
                        </div>
                        <div className="field col-4 flex flex-column">
                            <p> <b>Sampled By</b> : {!window.cn(rowData) ? rowData.sampled_by : ""}</p>
                        </div>
                        <div className="field col-4 flex flex-column">
                            <p> <b>Lot No.</b> : {!window.cn(rowData) ? rowData.lot_no : ""}</p>
                        </div>
                    </div>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-4 flex flex-column">
                            <p> <b>Sample Size</b> : {!window.cn(rowData) ? rowData.sample_size : ""}</p>
                        </div>
                        <div className="field col-4 flex flex-column">
                            <p> <b>Physical Purity</b> : {!window.cn(rowData) ? rowData.physical_purity : ""}</p>
                        </div>
                        <div className="field col-4 flex flex-column">
                            <p> <b>Sampling Result</b> : {!window.cn(rowData) ? rowData.sampling_result : ""}</p>
                        </div>
                    </div>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 flex flex-column">
                            <p> <b>Sampling Data Added By</b> : {!window.cn(rowData) ? rowData.sampling_data_added_by_user : ""}</p>
                        </div>
                    </div>
                </>}
            </Dialog>
            {/* Add Sample Dialog End*/}

            {/* Add Raw matrial Dialog Start*/}
            <Dialog
                visible={productDialog}
                style={{ width: '1000px' }}
                className="p-fluid"
                header="Add Raw Material Data"
                onHide={closeAddDialog}
                footer={
                    <>
                        <Button
                            label="Cancel"
                            icon="pi pi-times"
                            className="p-button-text"
                            onClick={closeAddDialog}
                        />
                        <Button
                            label="Save"
                            icon="pi pi-check"
                            className="p-button-text"
                            onClick={onSubmit}
                            loading={loading}
                        />
                    </>
                }
            >
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="procunment">Procunment</label>
                        <InputText
                            value={selectedProcunment}
                            name="procunment"
                            autoComplete="off"
                            disabled
                        />
                        <small className="p-invalid-txt">{errors['procunment']}</small>
                    </div>
                    <div className="field col">
                        <label htmlFor="crop">Crop</label>
                        <InputText
                            value={selectedCropId?.name}
                            name="crop"
                            autoComplete="off"
                            disabled
                        />
                        <small className="p-invalid-txt">{errors['crop']}</small>
                    </div>
                    <div className="field col">
                        <label htmlFor="variety">Variety</label>
                        <InputText
                            value={selectedVarietyId?.name}
                            name="variety"
                            autoComplete="off"
                            disabled
                        />
                        <small className="p-invalid-txt">{errors['variety']}</small>
                    </div>
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="lot_no">Lot No.</label>
                        <InputText
                            value={farmerData?.lot_no}
                            name="lot_no"
                            autoComplete="off"
                            disabled
                        />
                        <small className="p-invalid-txt">{errors['lot_no']}</small>
                    </div>
                    <div className="field col-4">
                        <label htmlFor="crop_division">Class</label>
                        <InputText
                            value={cropDivisionDropdownItem}
                            name="crop_division"
                            autoComplete="off"
                            disabled
                        />
                        <small className="p-invalid-txt">
                            {errors['crop_division']}
                        </small>
                    </div>
                    <div className="field col">
                        <label htmlFor="location">Seed Organizer *</label>
                        <InputText
                            value={productionData?.organizer?.first_name + " " + productionData?.organizer?.last_name}
                            name="seed_organizer"
                            autoComplete="off"
                            disabled
                        />
                        <small className="p-invalid-txt">{errors['seed_organizer']}</small>
                    </div>
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="name">Farmer Name</label>
                        <InputText
                            value={farmerData?.farmer_name}
                            name="farmer_name"
                            autoComplete="off"
                            disabled
                        />
                        <small className="p-invalid-txt">
                            {errors['farmer_name']}
                        </small>
                    </div>
                    <div className="field col">
                        <label htmlFor="village">Village</label>
                        <InputText
                            value={farmerData?.village}
                            name="village"
                            autoComplete="off"
                            disabled
                            className={errors['village'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['village']}</small>
                    </div>
                    <div className="field col">
                        <label htmlFor="district">District</label>
                        <InputText
                            value={farmerData?.district}
                            name="district"
                            autoComplete="off"
                            disabled
                            className={errors['district'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['district']}</small>
                    </div>
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="taluka">Taluka</label>
                        <InputText
                            value={farmerData?.taluka}
                            name="taluka"
                            autoComplete="off"
                            disabled
                            className={errors['taluka'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['taluka']}</small>
                    </div>
                    <div className="field col">
                        <label htmlFor="location">Storage Location</label>
                        <InputText
                            value={productData.location}
                            name="location"
                            autoComplete="off"
                            onChange={(e) => onInputChange(e, 'location')}
                            className={errors['location'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['location']}</small>
                    </div>

                    <div className="field col">
                        <label htmlFor="crop_grown_year">Production Year</label>
                        <Calendar
                            id="yearpicker"
                            value={productData.crop_grown_year}
                            onChange={(e) => onInputChange(e, 'crop_grown_year')}
                            view="year"
                            dateFormat="yy"
                        />
                        <small className="p-invalid-txt">
                            {errors['crop_grown_year']}
                        </small>
                    </div>
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="packing_type">Packing Type </label>
                        <Dropdown
                            id="packing_type"
                            filter
                            value={selectedPackingType}
                            onChange={(e) => handlePackingTypeDropdown(e.value)}
                            options={packingTypeDropdown}
                            optionLabel="name"
                            name="packing_type"
                            placeholder="Select"
                            className={errors['packing_type'] && 'p-invalid'}
                        ></Dropdown>
                        <small className="p-invalid-txt">{errors['packing_type']}</small>
                    </div>
                    <div className="field col-2">
                        <label htmlFor="crop_qnty">Recieved Quantity *</label>
                        <InputText
                            keyfilter="num"
                            value={productData.crop_qnty}
                            name="crop_qnty"
                            autoComplete="off"
                            onChange={(e) => onInputChange(e, 'crop_qnty')}
                            className={errors['crop_qnty'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['crop_qnty']}</small>
                    </div>
                    <div className="field col-2">
                        <label htmlFor="crop_qnty">Weight *</label>
                        <Dropdown
                            filter
                            value={weight}
                            onChange={(e) => setWeight(e.value)}
                            options={weightDropdown}
                            optionLabel="name"
                            placeholder="Select Weight"
                        ></Dropdown>
                    </div>
                    <div className="field col-4">
                        <label htmlFor="name">No. of bags *</label>
                        <InputText
                            keyfilter="num"
                            value={productData.no_of_bags}
                            name="no_of_bags"
                            autoComplete="off"
                            onChange={(e) => onInputChange(e, 'no_of_bags')}
                            className={errors['no_of_bags'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['no_of_bags']}</small>
                    </div>

                </div>

                <div className="formgrid grid">
                    <div className="field col-4">
                        <label htmlFor="receiving_date">Receiving Date *</label>
                        <Calendar
                            value={productData.receiving_date}
                            dateFormat="dd/mm/yy"
                            onChange={(e) => onInputChange(e.value, 'receiving_date')}
                            name="receiving_date"
                            showIcon
                        />
                        <small className="p-invalid-txt">{errors['receiving_date']}</small>
                    </div>
                    <div className="field col-4">
                        <label htmlFor="location">Processing Center</label>
                        <InputText
                            value={productData.processing_center}
                            name="processing_center"
                            autoComplete="off"
                            onChange={(e) => onInputChange(e, 'processing_center')}
                            className={errors['processing_center'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['processing_center']}</small>
                    </div>
                    <div className="field col-4">
                        <label htmlFor="ginning_centre">Ginning Centre</label>
                        <InputText
                            value={productData.ginning_centre}
                            name="ginning_centre"
                            onChange={(e) => onInputChange(e, 'ginning_centre')}
                            required
                            autoComplete="off"
                            className={errors['ginning_centre'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">
                            {errors['ginning_centre']}
                        </small>
                    </div>
                </div>

                <div className="formgrid grid">
                    <div className="field col-6">
                        <label htmlFor="photo">Way Bridge Copy</label>
                        <FileUpload
                            ref={fileUploadRef}
                            accept="image/*"
                            name="photo[]"
                            className="imageupload"
                            chooseOptions={chooseOptions}
                            emptyTemplate={emptyTemplate}
                            headerTemplate={headerTemplate}
                            itemTemplate={itemImageTemplate}
                        ></FileUpload>
                        <small className="p-invalid-txt">{errors['photo']}</small>
                    </div>
                </div>
            </Dialog>
            {/* Add Raw matrial Dialog End*/}

            {/* Add After Raw Material Dialog Start*/}
            <Dialog
                visible={afterRawMaterialDialog}
                className="p-fluid"
                onHide={hideAfterRawMaterialDialog}
                footer={
                    <>
                        <Button
                            label="Save"
                            icon="pi pi-check"
                            className="p-button-text"
                            onClick={onSubmitAfterRawMaterialAdd}
                            loading={loading}
                        />
                    </>
                }
            >
                <h5>Payment Details</h5>
                <div className="field">
                    <div className="field col">
                        <label htmlFor="name">Payment *</label>
                        <Dropdown
                            id="payment"
                            filter
                            value={selectedPayment}
                            onChange={(e) => handleChangePayment(e.value)}
                            options={PaymentDropdown}
                            name="payment"
                            optionLabel="name"
                            placeholder="Select One"
                            className={errors['payment'] && 'p-invalid'}
                        ></Dropdown>
                        <small className="p-invalid-txt">
                            {errors['payment']}
                        </small>
                    </div>
                </div>

                {
                    !window.cn(selectedPayment) && selectedPayment.code == "TO PAY" ?
                        <>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="crop">Vehicle No.</label>
                                    <InputText
                                        value={afterRawMaterialData.car_no}
                                        name="car_no"
                                        onChange={(e) => onInputChangeAfterRawMaterial(e, 'car_no')}
                                        required
                                        autoComplete="off"
                                        className={errors['car_no'] && 'p-invalid'}
                                    />
                                    <small className="p-invalid-txt">
                                        {errors['car_no']}
                                    </small>
                                </div>

                                <div className="field col">
                                    <label htmlFor="transport_name">Transport Name / Driver Name</label>
                                    <InputText
                                        value={afterRawMaterialData.transport_name}
                                        name="transport_name"
                                        onChange={(e) => onInputChangeAfterRawMaterial(e, 'transport_name')}
                                        required
                                        autoComplete="off"
                                        className={errors['transport_name'] && 'p-invalid'}
                                    />
                                    <small className="p-invalid-txt">{errors['transport_name']}</small>
                                </div>

                                <div className="field col">
                                    <label htmlFor="transport_amount">Amount</label>
                                    <InputText
                                         keyfilter="num"
                                        value={afterRawMaterialData.amount}
                                        name="transport_amount"
                                        onChange={(e) => onInputChangeAfterRawMaterial(e, 'transport_amount')}
                                        required
                                        autoComplete="off"
                                        className={errors['transport_amount'] && 'p-invalid'}
                                    />
                                    <small className="p-invalid-txt">{errors['transport_amount']}</small>
                                </div>
                            </div>
                        </>
                        :
                        null
                }

                <hr></hr>
                <h5>Labour Details</h5>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="conractor_name">Labour Conractor Name *</label>
                        <InputText
                            value={afterRawMaterialData.conractor_name}
                            name="conractor_name"
                            onChange={(e) => onInputChangeAfterRawMaterial(e, 'conractor_name')}
                            required
                            autoComplete="off"
                            className={errors['conractor_name'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['conractor_name']}</small>
                    </div>

                    <div className="field col">
                        <label htmlFor="labour_no_of_bags">Number Of Bags *</label>
                        <InputText
                             keyfilter="num"
                            value={afterRawMaterialData.labour_no_of_bags}
                            name="labour_no_of_bags"
                            onChange={(e) => onInputChangeAfterRawMaterial(e, 'labour_no_of_bags')}
                            required
                            autoComplete="off"
                            className={errors['labour_no_of_bags'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['labour_no_of_bags']}</small>
                    </div>

                    <div className="field col">
                        <label htmlFor="bag_weight">Weight of Bags</label>
                        <InputText
                             keyfilter="num"
                            value={afterRawMaterialData.bag_weight}
                            name="bag_weight"
                            onChange={(e) => onInputChangeAfterRawMaterial(e, 'bag_weight')}
                            required
                            autoComplete="off"
                            className={errors['bag_weight'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['bag_weight']}</small>
                    </div>
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="price_per_bag">Price Per Bags *</label>
                        <InputText
                             keyfilter="num"
                            value={afterRawMaterialData.price_per_bag}
                            name="price_per_bag"
                            onChange={(e) => onInputChangeAfterRawMaterial(e, 'price_per_bag')}
                            required
                            autoComplete="off"
                            className={errors['price_per_bag'] && 'p-invalid'}
                        />
                        <small className="p-invalid-txt">{errors['price_per_bag']}</small>
                    </div>

                    <div className="field col">
                        <label htmlFor="price_per_bag">Total</label>
                        <InputText
                             keyfilter="num"
                            value={!window.cn(totalLabourPrice) && totalLabourPrice != undefined && totalLabourPrice != null ? totalLabourPrice : 0}
                            name="price_per_bag"
                            readOnly
                            disabled
                        />
                    </div>
                </div>

            </Dialog>
            {/* Add After Raw Material Dialog End*/}

            {
                pageLoad && <Loader />
            }
        </>
    )
};