import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { AgentValidate } from '../../config/Validate';
import { Calendar } from 'primereact/calendar';
import { formatDateTime } from '../../appconfig/AppHelper';

// Column
import { AgentColumns } from '../../appconfig/DatatableSetting';

// Data table
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

const Agent = () => {
  document.title = 'Agent | Swarnim Farms';

  const [agentList, setAgentList] = useState<any>([]);
  const [agentData, setAgentData] = useState<any>({});
  const [deleteId, setDeleteId] = useState<any>(null);
  const [deleteAgentDialog, setDeleteAgentDialog] = useState<boolean>(false);
  const [editId, setEditId] = useState<any>(null);
  const [globalFilter, setGlobalFilter] = useState<any>(null);

  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [stateData, setStateData] = useState<any>([]);
  const [selectedStateId, setSelectedStateId] = useState<any>(null);
  const [selectedCityId, setSelectedCityId] = useState<any>(null);
  const [cityData, setCityData] = useState<any>([]);

  const [agentDialog, setAgentDialog] = useState<boolean>(false);

  const [errors, setErrors] = useState<any>({});
  const [isError, setIsError] = useState<any>(false);

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  const toast = useRef<any>(null);

  // Page service
  const pageService = new PageService();

  useEffect(() => {
    getDataFromAPI();
    getStateDataFromAPI();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [dates]);

  //onChange ProductData
  const onInputChange = (e: any, name: string) => {
    let val = (e.target && e.target.value) || '';
    if (name == 'city') {
      setAgentData({ ...agentData, [name]: e.value });
    } else {
      if (name != 'email') {
        val = val.toUpperCase();
      }
      setAgentData({ ...agentData, [name]: val });
    }
  };

  // Handle Select state
  const handleSeleteState = (value: any) => {
    if (!window.cn(value)) {
      setSelectedStateId(value);
      getCityDataFromAPI(value);
    } else {
      setSelectedStateId(null);
      setCityData([]);
    }
  };

  const onDateChange = (e: any) => {
    setDates(e.value);
  };

  const getDataFromAPI = async () => {
    pageService.getAgentData('list').then((response) => {
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setAgentList([]);
        } else {
          setAgentList(DataList);
        }
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  };

  // Get States
  const getStateDataFromAPI = async () => {
    try {
      const stateData: any = [];

      pageService.getState().then((response) => {
        if (response) {
          const stateDataList = response;
          for (let i = 0; i < stateDataList.length; i++) {
            const userObj: any = {};
            userObj['name'] = stateDataList[i].name;
            userObj['code'] = stateDataList[i].id;
            stateData.push(userObj);
          }
          setStateData(stateData);
        }
      });

    } catch (error) { }
  };

  //Get City
  const getCityDataFromAPI = async (selectedStateId: any) => {
    try {
      let stateId = !window.cn(selectedStateId) ? selectedStateId.code : '';

      const cityData: any = [];

      pageService.getCity(stateId).then((response) => {
        if (response) {
          const cityDataList = response;

          for (let i = 0; i < cityDataList.length; i++) {
            const userObj: any = {};
            userObj['name'] = cityDataList[i].name;
            userObj['code'] = cityDataList[i].id;
            cityData.push(userObj);
          }
          setCityData(cityData);
        }
      });
    } catch (error) { }
  };

  // open delete dialog
  const confirmDeleteAgent = (deleteID: any) => {
    setDeleteId(deleteID);
    setDeleteAgentDialog(true);
  };

  //close delete dialog
  const hideDeleteAgentDialog = () => {
    setDeleteAgentDialog(false);
    setDeleteId(null);
  };

  // call delete api
  const deleteAgent = async () => {
    setSubmitLoading(true);
    // request data
    let requestData = {
      id: deleteId,
    };

    // call api
    pageService.deleteAgentData(requestData).then((response) => {
      if (response) {
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Agent Data Deleted Successfully.',
        });
        setSubmitLoading(false);
        getDataFromAPI();
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
        setPageLoad(false);
        setSubmitLoading(false);
      }
      setDeleteId(null);
      setDeleteAgentDialog(false);
    });
  }

  //open edit dialog
  const editAgent = async (Agent: any) => {
    setEditId(Agent.id);
    let agentData = Agent;

    // set data in edit form
    setAgentData({
      first_name:
        agentData && agentData.first_name ? agentData.first_name : '',
      last_name:
        agentData && agentData.last_name ? agentData.last_name : '',
      email: agentData && agentData.email ? agentData.email : '',
      mobile: agentData && agentData.mobile ? agentData.mobile : '',
      residence_address: agentData && agentData.residence_address ? agentData.residence_address : '',
      pan_no: agentData && agentData.pan_no ? agentData.pan_no : '',
      aadhar_no: agentData && agentData.aadhar_no ? agentData.aadhar_no : '',
      village: agentData && agentData.village ? agentData.village : ''
    });

    let state: any = {
      'name': agentData?.state,
      'code': agentData?.state_id
    }

    let city: any = {
      'name': agentData?.city,
      'code': agentData?.city_id
    }

    setSelectedStateId(state);
    getCityDataFromAPI(state);
    setSelectedCityId(city);
    setAgentDialog(true);
  }

  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <Calendar
          value={dates}
          dateFormat="dd/mm/yy"
          onChange={(e) => onDateChange(e)}
          selectionMode="range"
          showIcon
        />
      </>
    );
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          tooltip='Edit'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-success mt-2 mb-2"
          onClick={() => editAgent(rowData)}
        />
        {/* <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger "
          onClick={() => confirmDeleteAgent(rowData.id)}
        /> */}
      </div>
    );
  };

  const addDialog = () => {
    setAgentDialog(true);
  };

  const hideAddDialog = () => {
    setAgentDialog(false);
    setAgentData({});
    setSelectedCityId(null);
    setSelectedStateId(null);
    setCityData([]);
    setErrors({});
    setEditId(null);
    setSubmitLoading(false);
  };

  //Add Agent Data
  const onSubmit = async () => {
    try {
      const { errors, isError } = AgentValidate(agentData, selectedCityId, selectedStateId);
      setErrors(errors);
      setIsError(isError);

      if (!isError) {
        setSubmitLoading(true);

        //Request object
        let formRequestData = new FormData();

        formRequestData.append('first_name', agentData.first_name);
        formRequestData.append('last_name', agentData.last_name);
        if (!window.cn(agentData) && agentData.email) {
          formRequestData.append('email', agentData?.email);
        }
        formRequestData.append('mobile', agentData.mobile);
        formRequestData.append('residence_address', agentData.residence_address);
        formRequestData.append('city', selectedCityId.code);
        formRequestData.append('state', selectedStateId.code);
        if (!window.cn(agentData) && agentData.pan_no) {
          formRequestData.append('pan_no', agentData?.pan_no);
        }
        if (!window.cn(agentData) && agentData.aadhar_no) {
          formRequestData.append('aadhar_no', agentData?.aadhar_no);
        }
        formRequestData.append('village', agentData.village);

        if (editId !== undefined && editId !== null) {
          formRequestData.append('id', editId);
        }

        pageService.addUpdateAgentData(formRequestData).then((response) => {
          if (response) {
            setAgentDialog(false);
            setAgentData({ agentData });
            setErrors(false);
            setSubmitLoading(false);
            setSelectedCityId(null);
            setSelectedStateId(null);
            setCityData([]);
            toast.current?.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Agent Deatils Added.',
            });
            getDataFromAPI();
          } else {
            setAgentDialog(true);
            setAgentData({ agentData });
            setErrors(false);
            setSubmitLoading(false);
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Agent Deatils not Added.' });
          }
        });
      }
    } catch (error: any) {
      if (error.response) {
        setSubmitLoading(false);
        toast.current?.show({
          severity: 'error', summary: 'Error', detail: error.response.data.error
        });
      }
    }
  };

  const createdAtTemplate = (rowData: any) => {
    return (<>
      <div>{formatDateTime(rowData?.created_at)}</div>
    </>);
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='main-header'>
              <h4 className='header'> Organizer List </h4>
              <Button
                label="Add New Organizer"
                icon="pi pi-plus"
                className="p-button-success mr-2"
                onClick={addDialog}
              />
            </div>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              // right={rightToolbarTemplate}
            ></Toolbar>
            {/* Datatable */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive"
                  value={agentList}
                  paginator={agentList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Organizer Found"
                >
                  {AgentColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'created_at') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={createdAtTemplate}
                        />
                      );
                    }  else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {AgentColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}

            {/* Add Dialog Start*/}
            <Dialog
              visible={agentDialog}
              header={editId !== null ? "Edit Organizer Details" : "Add Organizer Details"}
              className="p-fluid"
              onHide={hideAddDialog}
              footer={
                <>
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={hideAddDialog}
                  />
                  <Button
                    label="Save"
                    icon="pi pi-check"
                    className="p-button-text"
                    loading={submitLoading}
                    onClick={onSubmit}
                  />
                </>
              }
            >
              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="name">First Name *</label>
                  <InputText
                    value={agentData.first_name}
                    name="first_name"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'first_name')}
                    className={errors['first_name'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">
                    {errors['first_name']}
                  </small>
                </div>

                <div className="field col">
                  <label htmlFor="lastname">Last Name *</label>
                  <InputText
                    value={agentData.last_name}
                    name="last_name"
                    onChange={(e) => onInputChange(e, 'last_name')}
                    autoComplete="off"
                    className={errors['last_name'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['last_name']}</small>
                </div>
              </div>

              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="lastname">Email</label>
                  <InputText
                    value={agentData?.email}
                    name="email"
                    onChange={(e) => onInputChange(e, 'email')}
                    autoComplete="off"
                    className={errors['email'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['email']}</small>
                </div>

                <div className="field col">
                  <label htmlFor="lastname">Mobile *</label>
                  <InputText
                    value={agentData.mobile}
                    name="mobile"
                    onChange={(e) => onInputChange(e, 'mobile')}
                    autoComplete="off"
                    className={errors['mobile'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['mobile']}</small>
                </div>
              </div>

              <div className="field">
                <label htmlFor="desc">Address *</label>
                <InputTextarea
                  value={agentData.residence_address}
                  name="residence_address"
                  onChange={(e) => onInputChange(e, 'residence_address')}
                  rows={4}
                  className={errors['residence_address'] && 'p-invalid'}
                />
                <small className="p-invalid-txt">{errors['residence_address']}</small>
              </div>

              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="pan_no">PAN No.</label>
                  <InputText
                    value={agentData?.pan_no}
                    name="pan_no"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'pan_no')}
                    className={errors['pan_no'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['pan_no']}</small>
                </div>

                <div className="field col">
                  <label htmlFor="aadhar_no">AADHAR No.</label>
                  <InputText
                    value={agentData?.aadhar_no}
                    name="aadhar_no"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'aadhar_no')}
                    className={errors['aadhar_no'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['aadhar_no']}</small>
                </div>
              </div>

              <div className="formgrid grid">
                <div className="field col">
                  <label htmlFor="state">State *</label>
                  <Dropdown
                    filter
                    name="state"
                    value={selectedStateId}
                    onChange={(e) => handleSeleteState(e.value)}
                    options={stateData}
                    optionLabel="name"
                    placeholder="Select State"
                    className={errors['state'] && 'p-invalid'}
                  ></Dropdown>
                  <small className="p-invalid-txt">{errors['state']}</small>
                </div>

                <div className="field col">
                  <label htmlFor="city">City *</label>
                  <Dropdown
                    filter
                    name="city"
                    value={selectedCityId}
                    onChange={(e) => setSelectedCityId(e.value)}
                    options={cityData}
                    optionLabel="name"
                    placeholder="Select City"
                    className={errors['city'] && 'p-invalid'}
                  ></Dropdown>
                  <small className="p-invalid-txt">{errors['city']}</small>
                </div>
              </div>
              <div className="formgrid grid">
                <div className="field col-6">
                  <label htmlFor="village">Village *</label>
                  <InputText
                    value={agentData.village}
                    name="village"
                    autoComplete="off"
                    onChange={(e) => onInputChange(e, 'village')}
                    className={errors['village'] && 'p-invalid'}
                  />
                  <small className="p-invalid-txt">{errors['village']}</small>
                </div>
              </div>
            </Dialog>
            {/* Add Dialog End*/}

            {/* deleteDialog start*/}
            <Dialog
              visible={deleteAgentDialog}
              style={{ width: '450px' }}
              header="Delete"
              modal
              footer={
                <>
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-secondary"
                    onClick={hideDeleteAgentDialog}
                  />
                  <Button
                    label="Delete"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    onClick={deleteAgent}
                    loading={submitLoading}
                  />
                </>
              }
              onHide={hideDeleteAgentDialog}
            >
              <div className="flex align-items-center justify-content-start">
                <i
                  className="pi pi-exclamation-triangle mr-3 delete-triangle"
                  style={{ fontSize: '2rem' }}
                />
                <span className="delete-dialog-note">
                  Are you sure you want to delete ?
                </span>
              </div>
            </Dialog>
            {/* deleteDialog End*/}

          </div>
        </div>
      </div>
    </>
  );
};

export default Agent;
