import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Prime React Component Inbuilt
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { CSVLink } from "react-csv";
import { formatDateTime } from '../../appconfig/AppHelper';

//Services
import PageService from '../../service/PageService';

import { defaultPageRowOptions, defaultRowOptions, paginatorLinks, showingEntries, SkeletonbodyTemplate, Skeletonitems,} from '../../appconfig/Settings';

import { DisptachColumns } from '../../appconfig/DatatableSetting';

export const ProductDispatch = () => {
    document.title = 'Dispatch | Swarnim Farms';

    const pageService = new PageService();

    const toast = useRef<any>(null);
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [pageLoad, setPageLoad] = useState(false);
    const [dispatchRows, setDispatchRows] = useState<any>(null);
    const [deleteId, setDeleteId] = useState<any>(null);
    const [deleteDispatchDialog, setDeleteDispatchDialog] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<any>(false);

    const [csvLoading, setCsvLoading] = useState<boolean>(false);
    const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const [csvData, setCsvData] = useState([]);

    //Navigate Another Route
    const navigate = useNavigate();

    useEffect(() => {
        getDispatchData();
    }, []);

    useEffect(() => {
        if (csvData.length > 0) {
            csvLink?.current?.link.click();
            setCsvData([]);
        }
    }, [csvData]);

    // left part of toolbar
    const leftToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
                        placeholder="Search..."
                    />
                </span>
            </>
        );
    };

    // right part of toolbar
    const rightToolbarTemplate = () => {
        return (
            <> <Button
                label="GTM"
                icon="pi pi-truck"
                className="p-button-success mr-2"
                onClick={() => navigate('/gtm')} />
                <Button
                    label=""
                    icon="pi pi-file-export"
                    className="p-button-success mr-2"
                    loading={csvLoading}
                    onClick={exportCSV} />
                <CSVLink
                    data={csvData}
                    filename='Dispatch_list.csv'
                    className='hidden'
                    ref={csvLink}
                    target='_blank'
                />
            </>
        );
    };

    //get dispatch data from api
    const getDispatchData = () => {
        pageService.getDispatchData().then((response) => {
            setPageLoad(false);
            // get response
            if (response) {
                const DataList = response.data;
                if (DataList.length == 0) {
                    setDispatchRows([]);
                } else {
                    setDispatchRows(DataList);
                }
                setPageLoad(true);
            } else {
                setPageLoad(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    };

    //download csv
    const exportCSV = async () => {
        setCsvLoading(true);
        //Validation Chec
        pageService
            .getDispatchData()
            .then((response) => {
                // get response
                if (response) {
                    let csvDetails = response.csv_data;
                    if (csvDetails.length > 0) {
                        setCsvData(csvDetails);
                        setCsvLoading(false);
                    }
                } else {
                    setCsvData([]);
                }
            });
    };

    // open delete dialog
    const confirmDeleteDispatch = (deleteID: any) => {
        setDeleteId(deleteID);
        setDeleteDispatchDialog(true);
    };

    //close delete dialog
    const hideDeleteDispatchDialog = () => {
        setDeleteDispatchDialog(false);
        setDeleteId(null);
    };

    // for column action
    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <div className="actions">

                    <Button
                        icon="pi pi-pencil"
                        tooltip='Edit'
                        tooltipOptions={{ position: 'bottom' }}
                        className="p-button-rounded p-button-primary mt-2 mb-2 mr-3"
                        onClick={() => navigate('/dispatch/add-dispatch-details', { state: { id: rowData.id, type: "Direct" } })}
                        disabled={rowData.lr_no == null && rowData.transport_type == 'PART LOAD' ? false : true}
                    />
                    <Button
                        icon="pi pi-eye"
                        tooltip='View'
                        className="p-button-rounded p-button-primary mt-2 mb-2 mr-3"
                        tooltipOptions={{ position: 'bottom' }}
                        onClick={() => navigate('/dispatch/view-dispatch-details', { state: { id: rowData.id } })}
                    />
                    <Button
                        icon="pi pi-file-pdf"
                        tooltip='Pdf'
                        tooltipOptions={{ position: 'bottom' }}
                        className="p-button-primary mt-2 mr-3 mb-2"
                        onClick={() => window.open(rowData.dispatch_bill_pdf, '_blank')}
                        disabled={rowData.dispatch_bill_pdf == null ? true : false}
                    />
                    <Button
                        icon="pi pi-trash"
                        tooltip='Delete'
                        tooltipOptions={{ position: 'bottom' }}
                        className="p-button-rounded p-button-danger mt-2 mb-2"
                        onClick={() => confirmDeleteDispatch(rowData.id)}
                    />
                </div>
            </>
        );
    };

    const DateTemplate = (rowData: any) => {
        return (<>
            <div>{formatDateTime(rowData?.created_at)}</div>
        </>);
    };

    const typeBodyTemplate = (rowData: any) => {
        return (
            <>
                <div>
                    {rowData.order_id == null ? "DIRECT" : "ORDER"}
                </div>
            </>
        )
    };

    // call delete api
    const deleteDispatch = async () => {
        setSubmitLoading(true);

        // call api
        pageService.deleteDispatch(deleteId).then((response) => {
            if (response) {
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Dispatch Deleted Successfully.',
                });
                setSubmitLoading(false);
                getDispatchData();
                setPageLoad(true);
            } else {
                setPageLoad(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
                setPageLoad(false);
                setSubmitLoading(false);
            }
            setDeleteId(null);
            setDeleteDispatchDialog(false);
        });
    }

    return (
        <>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <div className='main-header'>
                            <h4 className='header'> Dispatch List </h4>
                            <Button
                                label="Add Dispatch"
                                icon="pi pi-plus"
                                className="p-button-success mr-2"
                                onClick={() => navigate('/dispatch/add-dispatch-details', { state: { id: null, type: "Direct" } })}
                            />
                        </div>
                        <Toast ref={toast} />
                        <Toolbar
                            className="mb-4"
                            left={leftToolbarTemplate}
                            right={rightToolbarTemplate}
                        ></Toolbar>
                        {/* Datatable */}
                        {pageLoad ? (
                            <>
                                <DataTable
                                    className="datatable-responsive p-datatable-sm"
                                    value={dispatchRows}
                                    paginator={dispatchRows.length > 0 && true}
                                    globalFilter={globalFilter}
                                    rows={defaultRowOptions}
                                    rowsPerPageOptions={defaultPageRowOptions}
                                    paginatorTemplate={paginatorLinks}
                                    currentPageReportTemplate={showingEntries}
                                    emptyMessage="No Dispatch Data Found"
                                >
                                    {DisptachColumns.map((col, i) => {
                                        if (col.field === 'sr_no') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    header={col.header}
                                                    body={(_, { rowIndex }) => rowIndex + 1}
                                                />
                                            );
                                        } else if (col.field === 'created_at') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={DateTemplate}
                                                />
                                            )
                                        } else if (col.field === 'action') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={actionBodyTemplate}
                                                />
                                            );
                                        } else if (col.field === 'order_id') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={typeBodyTemplate}
                                                />
                                            );
                                        } else {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    sortable
                                                    filter
                                                />
                                            );
                                        }
                                    })}
                                </DataTable>
                            </>
                        ) : (
                            <>
                                {/* Skeleton Data table */}
                                <DataTable value={Skeletonitems}>
                                    {DisptachColumns.map((col, i) => (
                                        <Column
                                            key={col.field}
                                            field={col.field}
                                            header={col.header}
                                            body={SkeletonbodyTemplate}
                                        />
                                    ))}
                                </DataTable>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* deleteDialog start*/}
            <Dialog
                visible={deleteDispatchDialog}
                style={{ width: '450px' }}
                header="Delete Dispatch"
                modal
                footer={
                    <>
                        <Button
                            label="Cancel"
                            icon="pi pi-times"
                            className="p-button-secondary"
                            onClick={hideDeleteDispatchDialog}
                        />
                        <Button
                            label="Delete"
                            icon="pi pi-trash"
                            className="p-button-danger"
                            onClick={deleteDispatch}
                            loading={submitLoading}
                        />
                    </>
                }
                onHide={hideDeleteDispatchDialog}
            >
                <div className="flex align-items-center justify-content-start">
                    <i
                        className="pi pi-exclamation-triangle mr-3 delete-triangle"
                        style={{ fontSize: '2rem' }}
                    />
                    <span className="delete-dialog-note">
                        Are you sure you want to delete ?
                    </span>
                </div>
            </Dialog>
            {/* deleteDialog End*/}
        </>
    );
};