import { Skeleton } from 'primereact/skeleton';

export const APP_BASE_URL =
// 'http://127.0.0.1:4000';
// 'https://64.227.162.130'; 
// 'http://64.227.153.78'; prod-test
// "https://7278-49-36-69-117.ngrok-free.app";
"https://api.swarnimfarm.in";


export const ASSET_PATH = APP_BASE_URL;
                                                                                                  
const SHOPIFY_DOMAIN = '';
export const IMAGE_BASEURL = APP_BASE_URL + 'front/assets/img/search_image.png';
export const PRODUCT_VIEW = SHOPIFY_DOMAIN + 'products/';
export const COLLECTION_VIEW = SHOPIFY_DOMAIN + 'collections/';
export const PRODUCT_EDIT = SHOPIFY_DOMAIN + 'admin/products/';

// PAGINATION
//default pagination Row data
export const defaultRowOptions = 25;
    
//default PerPageRow options
export const defaultPageRowOptions = [10, 25, 50, 100];

//paginatior entries showing
export const showingEntries =
  'Showing {first} to {last} of {totalRecords} products';

//paginator links & dropdown
export const paginatorLinks =
  'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown';

// PAGINATION

export const SowingTimeOptions = [
  { label: 'Winter', value: 'winter' },
  { label: 'Summer', value: 'summer' },
  { label: 'Monsoon', value: 'monsoon' },
];

export const GrowingTimeOptions = [
  { label: 'Winter', value: 'winter' },
  { label: 'Summer', value: 'summer' },
  { label: 'Monsoon', value: 'monsoon' },
];

export const ProductStatusOptions = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

//date picker options
export const datePickerOptions = [
  { label: 'Custom', value: 'custom' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 days', value: 'last7day' },
  { label: 'Last 30 days', value: 'last30days' },
  { label: 'Last 90 days', value: 'last90days' },
  { label: 'Last month', value: 'lastmonth' },
  { label: 'Last year', value: 'lastyear' },
  { label: 'Week to date', value: 'weektodate' },
  { label: 'Month to date', value: 'monthtodate' },
  { label: 'Year to date', value: 'yeartodate' },
];

export const rolePermission = [
  { label: 'Dashboard', value: 'Dashboard' },
  { label: 'Manage Orders', value: 'Manage Orders' },
  { label: 'Manage Category', value: 'Manage Category' },
  { label: 'Manage Merchants', value: 'Manage Merchants' },
  { label: 'Manage Products', value: 'Manage Products' },
  { label: 'Customers', value: 'Customers' },
  { label: 'Reports', value: 'Reports' },
];

export const roleMaster = [
  { name: 'Doctor', code: 3 },
  { name: 'Executive', code: 6 },
  { name: 'Salesman', code: 2 }
];

//emilPattern
export const emailPattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

//Date Convert To YYYY/MM/DD
export function convertDateFrom(date: any) {
  date = new Date(date); //Date pass
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return (
    year +
    '-' +
    (month <= 9 ? '0' + month : month) +
    '-' +
    (day <= 9 ? '0' + day : day)
  );
}

//Skeleton

// skeleton items & length
export const Skeletonitems: number[] = Array.from({ length: 10 }, (v, i) => i);

// SKELETON FOR COLUMN BODY
export function SkeletonbodyTemplate(): JSX.Element {
  return <Skeleton></Skeleton>;
}

export const TransportType = [
  { name: 'PART LOAD', code: 'PART LOAD' },
  { name: 'FULL LOAD', code: 'FULL LOAD' },
  { name: 'PRIVATE', code: 'PRIVATE' },
  { name: 'COURIER', code: 'COURIER' },
  { name: 'PARCEL', code: 'PARCEL' }
];

export const PaymentPAIDOrNot = [
  { name: 'PAID', code: "PAID" },
  { name: 'TO PAY', code: "TO PAY" },
];

export const DispatchType = [
  { name: 'SEED DISPATCH', code: "SEED DISPATCH" },
  { name: 'LOOSE PACKET', code: "LOOSE PACKET" },
  { name: 'PACKING MATERIAL', code: "PACKING MATERIAL" },
  { name: 'SAMPLING MATERIAL', code: "SAMPLING MATERIAL" },
  { name: 'PROMOTIONAL MATERIAL', code: "PROMOTIONAL MATERIAL" },
];

export const SeedDispatchType = [
  { name: 'DEALER', code: "DEALER" },
  { name: 'DIRECT BUYER', code: "DIRECT BUYER" },
];

export const packingTypeDropdown = [
  { name: 'HDP', code: 'HDP' },
  { name: 'JUTE BAG', code: 'JUTE BAG' },
];

export const cropDivisionDropdownItems = [
  { name: 'FOUNDATION', code: 'FOUNDATION' },
  { name: 'CERTIFIED', code: 'CERTIFIED' },
  { name: 'TRUTHFUL', code: 'TRUTHFUL' },
  { name: 'RESEARCH', code: 'RESEARCH' },
  { name: 'BREEDER', code: 'BREEDER' },
];

export const inspectionTypeDropdown = [
  { name: 'MULTIPLICATION', code: 'MULTIPLICATION' },
  { name: 'HYBRID', code: 'HYBRID' },
];

export const isolationDistanceDropdown = [
  { name: 'SATISFACTORY', code: 'SATISFACTORY' },
  { name: 'UNSATISFACTORY', code: 'UNSATISFACTORY' },
];

export const cropConditionDropdown = [
  { name: 'POOR', code: 'POOR' },
  { name: 'MEDIUM', code: 'MEDIUM' },
  { name: 'GOOD', code: 'GOOD' },
];

export const roughingStatusDropdown2 = [
  { name: 'DONE', code: 'DONE' },
  { name: 'TO BE DONE', code: 'TO BE DONE' },
  { name: 'NOT DONE', code: 'NOT DONE' },
];

export const roughingStatusDropdown3 = [
  { name: 'DONE', code: 'DONE' },
  { name: 'NOT DONE', code: 'NOT DONE' },
];

export const cropStageDropdown = [
  { name: 'VEGETATIVE', code: 'VEGETATIVE' },
  { name: 'FLOWERING', code: 'FLOWERING' },
  { name: 'HARVESTING', code: 'HARVESTING' },
];

export const wasteAreaDropdown = [
  { name: 'REJECTED AREA', code: 'REJECTED AREA' },
  { name: 'PLOUGH DOWN AREA', code: 'PLOUGH DOWN AREA' },
  { name: 'ISOLATION', code: 'ISOLATION' },
];

export const weightDropdown = [
  { name: 'q', code: 'q' },
  { name: 'kg', code: 'kg' },
  { name: 'mt', code: 'mt' },
];

export const procunmentDropdown = [
  { name: 'DIRECT PROCUNMENT', code: 'DIRECT PROCUNMENT' },
  // { name: 'FARMER PROCUNMENT', code: 'FARMER PROCUNMENT' },
];

export const PaymentDropdown = [
  { name: 'PAID', code: 'PAID' },
  { name: 'TO PAY', code: 'TO PAY' },
];

export const particularsDropdown = [
  { name: 'FLEX', code: 'FLEX' },
  { name: 'POSTER', code: 'POSTER' },
  { name: 'LITERATURES', code: 'LITERATURES' },
  { name: 'HANDBAG', code: 'HANDBAG' },
  { name: 'CARRYBAG', code: 'CARRYBAG' },
  { name: 'DIARIES', code: 'DIARIES' },
]

export const seasonDropdown = [
  { name: 'KHARIF', code: 'KHARIF' },
  { name: 'RAVI', code: 'RAVI' },
  { name: 'SEMI RAVI', code: 'SEMI RAVI' },
  { name: 'SUMMER', code: 'SUMMER' }
];

export const chemicalDropdown = [
  { name: 'THIRUM', code: 'THIRUM' },
  { name: 'SDM', code: 'SDM' },
  { name: 'POLYMER', code: 'POLYMER' },
  { name: 'GAUCHO', code: 'GAUCHO' },
  { name: 'METALYXIL', code: 'METALYXIL' },
];

export const thirumDropdown = [
  { name: '20g', code: 'twentyG' },
  { name: '25g', code: 'twentyFiveG' },
  { name: '30g', code: 'thirtyG' },
];

export const metalyxilDropdown = [
  { name: 'g', code: 'g' },
  { name: 'kg', code: 'kg' },
];

export const sdmDropdown = [
  { name: 'g', code: 'g' },
  { name: 'kg', code: 'kg' },
  { name: 'ml', code: 'ml' },
  { name: 'l', code: 'l' },
];

export const polymerDropdown = [
  { name: 'ml', code: 'ml' },
  { name: 'l', code: 'l' },
];

export const gauchoDropdown = [
  { name: 'ml', code: 'ml' },
  { name: 'l', code: 'l' },
];

export const processStatusDropdown = [
  { name: 'All', code: 'All' },
  { name: 'Completed', code: 'Completed' },
  { name: 'Inprocess', code: 'Inprocess' },
];

export const rawMaterialTypeDropdown = [
  { name: 'All', code: 'All' },
  { name: 'original', code: 'original' },
  { name: 'split', code: 'split' },
  { name: 'merge', code: 'merge' },
];

export const outerBagDropdown = [
  { name: 'COLOUR STANDARD BAG', code: 'colour_std_bag' },
  { name: 'WHITE STANDARD BAG', code: 'white_std_bag' },
  { name: 'COTTON HDP STANDARD BAG', code: 'cotton_hdp_std_bag' },
  { name: 'CORUGETED BOX', code: 'corugeted_box' },
  { name: 'NO STANDARD BAG REQUIRED', code: 'no_std_bag' },
];

export const testResultDropdown = [
  { name: 'Pass', code: 'Pass' },
  { name: 'Fail', code: 'Fail' },
];

export const orderTransferTypeDropdown = [
  { name: 'All', code: 'All' },
  { name: 'Sales Return', code: 'Swarnim' },
  { name: 'Sales Transfer', code: 'Dealer' },
];

export const dealerFirmTypeOptions = [
  { name: 'Proprietorship', code: 'Proprietorship' },
  { name: 'Partnership', code: 'Partnership' },
  { name: 'Private Limited', code: 'Private Limited' },
];

export const partLoadTranportNameDropdown = [
  { name: 'SHUBH MANGAL ROADWAYS', code: 'SHUBH MANGAL ROADWAYS' },
  { name: 'DEEP TRANSPORT', code: 'DEEP TRANSPORT' },
  { name: 'SAIKRUPA TRANSPORT', code: 'SAIKRUPA TRANSPORT' },
  { name: 'SHREE RAVINDRA TRANSPORT CO.', code: 'SHREE RAVINDRA TRANSPORT CO.' },
  { name: 'SUPREME TRANSPORT', code: 'SUPREME TRANSPORT' },
  { name: 'PRANAV ROADWAYS', code: 'PRANAV ROADWAYS' },
  { name: 'VEER EXPRESS', code: 'VEER EXPRESS' },
  { name: 'PATEL TRANSPORT', code: 'PATEL TRANSPORT' },
  { name: 'RAJSHAKTI TRANSPORT', code: 'RAJSHAKTI TRANSPORT' },
  { name: 'NODHANVADAR TRANSPORT', code: 'NODHANVADAR TRANSPORTl' },
  { name: 'HARISH TRANSPORT', code: 'HARISH TRANSPORT' },
  { name: 'JAMNAGAR TRANSPORT COMPANY', code: 'JAMNAGAR TRANSPORT COMPANY' },
  { name: 'BHAVNAGAR ROAD CARGO SERVICE', code: 'BHAVNAGAR ROAD CARGO SERVICE' },
  { name: 'MILAN TRANSPORT', code: 'MILAN TRANSPORT' },
  { name: 'TAKATUKA TRANSPORT', code: 'TAKATUKA TRANSPORT' },
  { name: 'SABUGAR TRANSPORT', code: 'SABUGAR TRANSPORT' },
  { name: 'MANAN ROADWAYS', code: 'MANAN ROADWAYS' },
  { name: 'NAVBHARAT TRANSPORT', code: 'NAVBHARAT TRANSPORT' },
  { name: 'GHANSHYAM ROADWAYS', code: 'GHANSHYAM ROADWAYS' },
  { name: 'SATKAR TRANSPORT', code: 'SATKAR TRANSPORT' },
  { name: 'POPULAR TRANSPORT', code: 'POPULAR TRANSPORT' },
  { name: 'VIJAY LAXMI PARCEL SERVICE', code: 'VIJAY LAXMI PARCEL SERVICE' },
  { name: 'PATEL CARRIYING CORPO', code: 'PATEL CARRIYING CORPO' },
  { name: 'DIAMOND TRANSPORT', code: 'DIAMOND TRANSPORT' },
  { name: 'PATEL ROADLINES', code: 'PATEL ROADLINES' },
  { name: 'KANAIYA TRANSPORT', code: 'KANAIYA TRANSPORT' },
  { name: 'HARISH TRANSPORT', code: 'HARISH TRANSPORT' },
  { name: 'RAJSHAKTI TRANSPORT', code: 'RAJSHAKTI TRANSPORT' },
  { name: 'SHREE AMBICA VIJAY TRANSPORT', code: 'SHREE AMBICA VIJAY TRANSPORT' },
  { name: 'H. H. ROADWAYS', code: 'H. H. ROADWAYS' },
  { name: 'M. M. ROADWAYS', code: 'M. M. ROADWAYS' },
  { name: 'RAJDEEP TRANSPORT', code: 'RAJDEEP TRANSPORT' },
  { name: 'SATKAR TRANSPORT', code: 'SATKAR TRANSPORT' },
  { name: 'RAJ TRANSPORT', code: 'RAJ TRANSPORT' },
  { name: 'SHREEJI TRANSPORT', code: 'SHREEJI TRANSPORT' },
  { name: 'JAMNAGAR TRANSPORT SERVICE', code: 'JAMNAGAR TRANSPORT SERVICE' },
  { name: 'SHREE BALAJI CARGO MOVERS', code: 'SHREE BALAJI CARGO MOVERS' },
  { name: 'PUJA ROADLINES', code: 'PUJA ROADLINES' },
  { name: 'SHREE BANAS LOGISTICS', code: 'SHREE BANAS LOGISTICS' },
  { name: 'CHIRAG TRANSPORT', code: 'CHIRAG TRANSPORT' },
  { name: 'VIRENDRA ROADLINES PRIVATE LIMITED', code: 'VIRENDRA ROADLINES PRIVATE LIMITED' },
  { name: 'VIRENDRA CARGO', code: 'VIRENDRA CARGO' },
  { name: 'NEW CHOUDHARY ROADLINES', code: 'NEW CHOUDHARY ROADLINES' },
  { name: 'ALI TRAVELS AND CARGO', code: 'ALI TRAVELS AND CARGO' },
  { name: 'SANTOSHI LOGISTICS', code: 'SANTOSHI LOGISTICS' },
  { name: 'PORBANDAR LOGISTICS', code: 'PORBANDAR LOGISTICS' },
  { name: 'RAMKRUPA TRAVELERS', code: 'RAMKRUPA TRAVELERS' },
  { name: 'Other', code: 'Other' }
];
