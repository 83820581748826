import { stat } from 'fs';
import { emailPattern } from '../appconfig/Settings';

// --------------------------------   START LOGIN PAGE VALIDATION --------------------------  //
export const LoginValidate = (inputDetail: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(inputDetail.email) && !window.cb(inputDetail.email)) {
    errors = { ...errors, email: 'Enter Mobile No. *' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (window.cn(inputDetail.password) && !window.cb(inputDetail.password)) {
    errors = { ...errors, password: 'Enter Password *' };
    isError = true;
  } else {
    errors = { ...errors, password: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// --------------------------------   END LOGIN PAGE VALIDATION --------------------------  //

// --------------------------------   START DISPATCH PAGE VALIDATION --------------------------  //
export const DispatchValidate = (dispatchMasterData: any, dispatchData: any, dispatchType: any, paymentType: any, image: any, editId: any, seedDispatchType: any, bagType: any, selectedDealer: any, seedDispatchData: any, lotNoArr: any, partLoadTransportName: any, lr_image: any) => {

  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (editId.id == null) {
    if (window.cn(dispatchData) && !window.cb(dispatchData) && dispatchData == null) {
      errors = { ...errors, transport_type: 'Please Select Transport Type *' };
      isError = true;
    } else {
      errors = { ...errors, transport_type: '' };
    }

    if (window.cn(dispatchType) && !window.cb(dispatchType) && dispatchType == null) {
      errors = { ...errors, dispatch_type: 'Please Select Dispatch Type *' };
      isError = true;
    } else {
      errors = { ...errors, dispatch_type: '' };
    }

    if (!window.cn(dispatchType) && dispatchType.code == 'SEED DISPATCH') {
      if (window.cn(seedDispatchType) && !window.cb(seedDispatchType) && seedDispatchType == null) {
        errors = { ...errors, seed_dispatch_type: 'Please Select Seed Dispatch Type *' };
        isError = true;
      } else {
        errors = { ...errors, seed_dispatch_type: '' };
      }

      if (!window.cn(seedDispatchType) && seedDispatchType.code == 'DEALER') {
        if (window.cn(selectedDealer) && !window.cb(selectedDealer) && selectedDealer == null) {
          errors = { ...errors, dealer: 'Please Select Dealer *' };
          isError = true;
        } else {
          errors = { ...errors, dealer: '' };
        }
      }

      lotNoArr.map((item: any, index: number) => {
        let validation = item.every((o: any) => o.lot_no && o.no_of_bag && o.packing_size);
        if (!validation) {
          errors = { ...errors, all_lots: 'Please Enter value in all lot_no, No. of packets and Packing Size *' };
          isError = true;
        }
      });
    }

    if (!window.cn(dispatchType) && dispatchType.code == 'PACKING MATERIAL') {
      if (window.cn(bagType) && !window.cb(bagType) && bagType == null) {
        errors = { ...errors, bag_type: 'Please Select Bag Type *' };
        isError = true;
      } else {
        errors = { ...errors, bag_type: '' };
      }

      if (window.cn(dispatchMasterData.name_of_organizer) && !window.cb(dispatchMasterData.name_of_organizer)) {
        errors = { ...errors, name_of_organizer: 'Enter Name Of Organizer *' };
        isError = true;
      } else {
        errors = { ...errors, name_of_organizer: '' };
      }
    }

    if (!window.cn(dispatchType) && dispatchType.code == 'PROMOTIONAL MATERIAL') {
      if (window.cn(selectedDealer) && !window.cb(selectedDealer) && selectedDealer == null) {
        errors = { ...errors, dealer: 'Please Select Dealer *' };
        isError = true;
      } else {
        errors = { ...errors, dealer: '' };
      }
    }

    seedDispatchData.every((o: any) => {
      if (!window.cn(dispatchType) && dispatchType.code == 'PROMOTIONAL MATERIAL') {
        if (window.cn(o.particulars) && !window.cb(o.particulars)) {
          errors = { ...errors, particulars: 'Please Select Particulars *' };
          isError = true;
        } else {
          errors = { ...errors, particulars: '' };
        }

        if (window.cn(o.no_of_bag) && !window.cb(o.no_of_bag)) {
          errors = { ...errors, no_of_bag: 'Please Enter No Of Bags *' };
          isError = true;
        } else {
          errors = { ...errors, no_of_bag: '' };
        }
      }

      if (!window.cn(dispatchType) && dispatchType.code == 'SEED DISPATCH') {
        if (window.cn(o.crop) && !window.cb(o.crop)) {
          errors = { ...errors, crop: 'Please Select Crop *' };
          isError = true;
        } else {
          errors = { ...errors, crop: '' };
        }

        if (window.cn(o.variety) && !window.cb(o.variety)) {
          errors = { ...errors, variety: 'Please Select Variety *' };
          isError = true;
        } else {
          errors = { ...errors, variety: '' };
        }

        // if (window.cn(o.stage) && !window.cb(o.stage)) {
        //   errors = { ...errors, stage: 'Please Select Stage *' };
        //   isError = true;
        // } else {
        //   errors = { ...errors, stage: '' };
        // }
      }

      if (!window.cn(dispatchType) && dispatchType.code == 'PACKING MATERIAL') {
        if (window.cn(o.crop) && !window.cb(o.crop)) {
          errors = { ...errors, crop: 'Please Select Crop *' };
          isError = true;
        } else {
          errors = { ...errors, crop: '' };
        }

        if (window.cn(o.variety) && !window.cb(o.variety)) {
          errors = { ...errors, variety: 'Please Select Variety *' };
          isError = true;
        } else {
          errors = { ...errors, variety: '' };
        }

        if (window.cn(o.packing_size) && !window.cb(o.packing_size)) {
          errors = { ...errors, packing_size: 'Please Select Packing Size *' };
          isError = true;
        } else {
          errors = { ...errors, packing_size: '' };
        }

        if (window.cn(o.no_of_bag) && !window.cb(o.no_of_bag)) {
          errors = { ...errors, no_of_bag: 'Please Enter No Of Bag *' };
          isError = true;
        } else {
          errors = { ...errors, no_of_bag: '' };
        }

        if (window.cn(o.no_of_inner_bag) && !window.cb(o.no_of_inner_bag)) {
          errors = { ...errors, no_of_inner_bag: 'Please Enter No Of Inner Bag *' };
          isError = true;
        } else {
          errors = { ...errors, no_of_inner_bag: '' };
        }
      }

      if (!window.cn(dispatchType) && dispatchType.code == 'SAMPLING MATERIAL') {
        if (window.cn(o.crop) && !window.cb(o.crop)) {
          errors = { ...errors, crop: 'Please Select Crop *' };
          isError = true;
        } else {
          errors = { ...errors, crop: '' };
        }

        if (window.cn(o.no_of_inner_bag) && !window.cb(o.no_of_inner_bag)) {
          errors = { ...errors, no_of_inner_bag: 'Please Enter Inner Bag *' };
          isError = true;
        } else {
          errors = { ...errors, no_of_inner_bag: '' };
        }

        if (window.cn(o.sampling_rate_per_inner_bag) && !window.cb(o.sampling_rate_per_inner_bag)) {
          errors = { ...errors, sampling_rate_per_inner_bag: 'Please Enter Sampling Rate per Inner Bag *' };
          isError = true;
        } else {
          errors = { ...errors, sampling_rate_per_inner_bag: '' };
        }

        if (window.cn(o.no_of_bag) && !window.cb(o.no_of_bag)) {
          errors = { ...errors, no_of_bag: 'Please Enter No Of Bag *' };
          isError = true;
        } else {
          errors = { ...errors, no_of_bag: '' };
        }

        if (window.cn(o.sampling_rate_per_outer_bag) && !window.cb(o.sampling_rate_per_outer_bag)) {
          errors = { ...errors, sampling_rate_per_outer_bag: 'Please Enter Sampling Rate pEr Outer Bag *' };
          isError = true;
        } else {
          errors = { ...errors, sampling_rate_per_outer_bag: '' };
        }

        if (window.cn(o.wax_qty) && !window.cb(o.wax_qty)) {
          errors = { ...errors, wax_qty: 'Please Enter Wax Qty *' };
          isError = true;
        } else {
          errors = { ...errors, wax_qty: '' };
        }

        if (window.cn(o.rate_per_wax) && !window.cb(o.rate_per_wax)) {
          errors = { ...errors, rate_per_wax: 'Please Enter Rate Per Wax Qty *' };
          isError = true;
        } else {
          errors = { ...errors, rate_per_wax: '' };
        }

        if (window.cn(o.sutali) && !window.cb(o.sutali)) {
          errors = { ...errors, sutali: 'Please Enter Sutali *' };
          isError = true;
        } else {
          errors = { ...errors, sutali: '' };
        }

        if (window.cn(o.rate_per_sutali) && !window.cb(o.rate_per_sutali)) {
          errors = { ...errors, rate_per_sutali: 'Please Enter Rate Per Sutali *' };
          isError = true;
        } else {
          errors = { ...errors, rate_per_sutali: '' };
        }

        if (window.cn(o.total_amount) && !window.cb(o.total_amount)) {
          errors = { ...errors, total_amount: 'Please Enter Total Amount *' };
          isError = true;
        } else {
          errors = { ...errors, total_amount: '' };
        }
      }
    });

    if (window.cn(dispatchMasterData.bill_to) && !window.cb(dispatchMasterData.bill_to)) {
      errors = { ...errors, bill_to: 'Enter Bill To *' };
      isError = true;
    } else {
      errors = { ...errors, bill_to: '' };
    }

    if (window.cn(dispatchMasterData.billing_address) && !window.cb(dispatchMasterData.billing_address)) {
      errors = { ...errors, billing_address: 'Enter Billing Address *' };
      isError = true;
    } else {
      errors = { ...errors, billing_address: '' };
    }

    // if (window.cn(dispatchMasterData.gst_no) && !window.cb(dispatchMasterData.gst_no)) {
    //   errors = { ...errors, gst_no: 'Please GSTIN No *' };
    //   isError = true;
    // } else {
    //   errors = { ...errors, gst_no: '' };
    // }

    if (window.cn(dispatchMasterData.mobile) && !window.cb(dispatchMasterData.mobile)) {
      errors = { ...errors, mobile: 'Enter Mobile No. *' };
      isError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }

    if (window.cn(dispatchMasterData.delivery_at) && !window.cb(dispatchMasterData.delivery_at)) {
      errors = { ...errors, delivery_at: 'Enter Delivery At *' };
      isError = true;
    } else {
      errors = { ...errors, delivery_at: '' };
    }

    if (window.cn(dispatchMasterData.delivery_address) && !window.cb(dispatchMasterData.delivery_address)) {
      errors = { ...errors, delivery_address: 'Enter Delivery Address *' };
      isError = true;
    } else {
      errors = { ...errors, delivery_address: '' };
    }

    if (window.cn(dispatchMasterData.delivery_mobile) && !window.cb(dispatchMasterData.delivery_mobile)) {
      errors = { ...errors, delivery_mobile: 'Enter Mobile No. *' };
      isError = true;
    } else {
      errors = { ...errors, delivery_mobile: '' };
    }

  }

  if (!window.cn(dispatchData) && dispatchData != null) {

    if (dispatchData.code == 'PART LOAD') {

      if (!window.cn(partLoadTransportName) && partLoadTransportName == null) {
        errors = { ...errors, transport_name: 'Enter Transport Name *' };
        isError = true;
      } else {
        errors = { ...errors, transport_name: '' };
      }
    } else if (dispatchData.code == 'FULL LOAD') {

      if (window.cn(dispatchMasterData.lr_no) && !window.cb(dispatchMasterData.lr_no)) {
        errors = { ...errors, lr_no: 'Enter LR No *' };
        isError = true;
      } else {
        errors = { ...errors, lr_no: '' };
      }

      if (window.cn(lr_image) && !window.cb(lr_image)) {
        errors = { ...errors, lr_image: 'Please Add Image *' };
        isError = true;
      } else {
        errors = { ...errors, lr_image: '' };
      }

      if (window.cn(paymentType) && !window.cb(paymentType) && paymentType == null) {
        errors = { ...errors, payment_type: 'Please Select Payment Type *' };
        isError = true;
      } else {
        errors = { ...errors, payment_type: '' };
      }

      if (window.cn(dispatchMasterData.transport_name) && !window.cb(dispatchMasterData.transport_name)) {
        errors = { ...errors, transport_name: 'Enter Transport Name *' };
        isError = true;
      } else {
        errors = { ...errors, transport_name: '' };
      }

      if (window.cn(dispatchMasterData.car_no) && !window.cb(dispatchMasterData.car_no)) {
        errors = { ...errors, car_no: 'Enter Vehicle No. *' };
        isError = true;
      } else {
        errors = { ...errors, car_no: '' };
      }

      if (window.cn(dispatchMasterData.driver_name) && !window.cb(dispatchMasterData.driver_name)) {
        errors = { ...errors, driver_name: 'Enter Driver Name *' };
        isError = true;
      } else {
        errors = { ...errors, driver_name: '' };
      }

      if (window.cn(dispatchMasterData.driver_mobile) && !window.cb(dispatchMasterData.driver_mobile)) {
        errors = { ...errors, driver_mobile: 'Enter Driver Mobile No. *' };
        isError = true;
      } else {
        errors = { ...errors, driver_mobile: '' };
      }

      if (window.cn(dispatchMasterData.license_no) && !window.cb(dispatchMasterData.license_no)) {
        errors = { ...errors, license_no: 'Enter License No. *' };
        isError = true;
      } else {
        errors = { ...errors, license_no: '' };
      }

      if (window.cn(dispatchMasterData.license_state) && !window.cb(dispatchMasterData.license_state)) {
        errors = { ...errors, license_state: 'Enter License State *' };
        isError = true;
      } else {
        errors = { ...errors, license_state: '' };
      }

      if (window.cn(dispatchMasterData.is_advance) && !window.cb(dispatchMasterData.is_advance)) {
        errors = { ...errors, is_advance: 'Please Select *' };
        isError = true;
      } else {
        errors = { ...errors, is_advance: '' };
      }

      if (!window.cn(dispatchMasterData) && dispatchMasterData.is_advance === 'Yes') {
        if (window.cn(dispatchMasterData.advance_amount) && !window.cb(dispatchMasterData.advance_amount)) {
          errors = { ...errors, advance_amount: 'Enter Advance Amount *' };
          isError = true;
        } else {
          errors = { ...errors, advance_amount: '' };
        }
      }

      if (window.cn(dispatchMasterData.amount) && !window.cb(dispatchMasterData.amount)) {
        errors = { ...errors, amount: 'Enter Amount *' };
        isError = true;
      } else {
        errors = { ...errors, amount: '' };
      }
    } else if (dispatchData.code == 'PRIVATE') {
      if (window.cn(dispatchMasterData.transport_name) && !window.cb(dispatchMasterData.transport_name)) {
        errors = { ...errors, transport_name: 'Enter Transport Name *' };
        isError = true;
      } else {
        errors = { ...errors, transport_name: '' };
      }

      if (window.cn(dispatchMasterData.car_no) && !window.cb(dispatchMasterData.car_no)) {
        errors = { ...errors, car_no: 'Enter Vehicle No. *' };
        isError = true;
      } else {
        errors = { ...errors, car_no: '' };
      }

      if (window.cn(dispatchMasterData.no_of_bags) && !window.cb(dispatchMasterData.no_of_bags)) {
        errors = { ...errors, no_of_bags: 'Enter No Of Bags *' };
        isError = true;
      } else {
        errors = { ...errors, no_of_bags: '' };
      }

      if (window.cn(dispatchMasterData.driver_mobile) && !window.cb(dispatchMasterData.driver_mobile)) {
        errors = { ...errors, driver_mobile: 'Enter Driver Mobile No. *' };
        isError = true;
      } else {
        errors = { ...errors, driver_mobile: '' };
      }

      if (window.cn(dispatchMasterData.from_location) && !window.cb(dispatchMasterData.from_location)) {
        errors = { ...errors, from_location: 'Enter From Loaction *' };
        isError = true;
      } else {
        errors = { ...errors, from_location: '' };
      }

      if (window.cn(dispatchMasterData.to_location) && !window.cb(dispatchMasterData.to_location)) {
        errors = { ...errors, to_location: 'Enter To Location *' };
        isError = true;
      } else {
        errors = { ...errors, to_location: '' };
      }

      if (window.cn(paymentType) && !window.cb(paymentType) && paymentType == null) {
        errors = { ...errors, payment_type: 'Please Select Payment Type *' };
        isError = true;
      } else {
        errors = { ...errors, payment_type: '' };
      }

      if (window.cn(dispatchMasterData.is_advance) && !window.cb(dispatchMasterData.is_advance)) {
        errors = { ...errors, is_advance: 'Please Select *' };
        isError = true;
      } else {
        errors = { ...errors, is_advance: '' };
      }

      if (!window.cn(dispatchMasterData.is_advance) && dispatchMasterData.is_advance === 'Yes') {
        if (window.cn(dispatchMasterData.advance_amount) && !window.cb(dispatchMasterData.advance_amount)) {
          errors = { ...errors, advance_amount: 'Enter Advance Amount *' };
          isError = true;
        } else {
          errors = { ...errors, advance_amount: '' };
        }
      }

      if (window.cn(dispatchMasterData.amount) && !window.cb(dispatchMasterData.amount)) {
        errors = { ...errors, amount: 'Enter Amount *' };
        isError = true;
      } else {
        errors = { ...errors, amount: '' };
      }
    } else {
      if (window.cn(dispatchMasterData.tracking_id) && !window.cb(dispatchMasterData.tracking_id)) {
        errors = { ...errors, tracking_id: 'Enter Tracking Id *' };
        isError = true;
      } else {
        errors = { ...errors, tracking_id: '' };
      }

      if (window.cn(dispatchMasterData.courier_name) && !window.cb(dispatchMasterData.courier_name)) {
        errors = { ...errors, courier_name: 'Enter Courier Name *' };
        isError = true;
      } else {
        errors = { ...errors, courier_name: '' };
      }
    }

  }

  if (window.cn(editId) && !window.cb(editId) && editId != null) {
    if (window.cn(dispatchMasterData.lr_no) && !window.cb(dispatchMasterData.lr_no) && dispatchMasterData.lr_no == null) {
      errors = { ...errors, lr_no: 'Enter LR No *' };
      isError = true;
    } else {
      errors = { ...errors, lr_no: '' };
    }

    if (window.cn(lr_image) && !window.cb(lr_image)) {
      errors = { ...errors, lr_image: 'Please Add Image *' };
      isError = true;
    } else {
      errors = { ...errors, lr_image: '' };
    }

    if (window.cn(dispatchMasterData.date_of_supply) && !window.cb(dispatchMasterData.date_of_supply)) {
      errors = { ...errors, date_of_supply: 'Select date *' };
      isError = true;
    } else {
      errors = { ...errors, date_of_supply: '' };
    }

    if (window.cn(paymentType) && !window.cb(paymentType) && paymentType == null) {
      errors = { ...errors, payment_type: 'Please Select Payment Type *' };
      isError = true;
    } else {
      errors = { ...errors, payment_type: '' };
    }

    if (window.cn(dispatchMasterData.is_advance) && !window.cb(dispatchMasterData.is_advance) && dispatchMasterData.is_advance == null) {
      errors = { ...errors, is_advance: 'Please Select *' };
      isError = true;
    } else {
      errors = { ...errors, is_advance: '' };
    }

    if (!window.cn(dispatchMasterData.is_advance) && dispatchMasterData.is_advance === 'Yes') {
      if (window.cn(dispatchMasterData.advance_amount) && !window.cb(dispatchMasterData.advance_amount)) {
        errors = { ...errors, advance_amount: 'Enter Advance Amount *' };
        isError = true;
      } else {
        errors = { ...errors, advance_amount: '' };
      }
    }

    if (window.cn(dispatchMasterData.amount) && !window.cb(dispatchMasterData.amount)) {
      errors = { ...errors, amount: 'Enter Amount *' };
      isError = true;
    } else {
      errors = { ...errors, amount: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const OrderDispatchValidate = (dispatchMasterData: any, dispatchData: any, paymentType: any, lotNoArr: any, partLoadTransportName: any, lr_image: any) => {

  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(dispatchMasterData.bill_to) && dispatchMasterData.bill_to == '') {
    errors = { ...errors, bill_to: 'Enter Bill To *' };
    isError = true;
  } else {
    errors = { ...errors, bill_to: '' };
  }

  if (window.cn(dispatchMasterData.billing_address) && !window.cb(dispatchMasterData.billing_address)) {
    errors = { ...errors, billing_address: 'Enter Billing Address *' };
    isError = true;
  } else {
    errors = { ...errors, billing_address: '' };
  }

  if (window.cn(dispatchMasterData.gst_no) && !window.cb(dispatchMasterData.gst_no)) {
    errors = { ...errors, gst_no: 'Please GSTIN No *' };
    isError = true;
  } else {
    errors = { ...errors, gst_no: '' };
  }

  if (window.cn(dispatchMasterData.mobile) && !window.cb(dispatchMasterData.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile No. *' };
    isError = true;
  } else {
    errors = { ...errors, mobile: '' };
  }

  if (window.cn(dispatchMasterData.delivery_at) && !window.cb(dispatchMasterData.delivery_at)) {
    errors = { ...errors, delivery_at: 'Enter Delivery At *' };
    isError = true;
  } else {
    errors = { ...errors, delivery_at: '' };
  }

  if (window.cn(dispatchMasterData.delivery_address) && !window.cb(dispatchMasterData.delivery_address)) {
    errors = { ...errors, delivery_address: 'Enter Delivery Address *' };
    isError = true;
  } else {
    errors = { ...errors, delivery_address: '' };
  }

  if (window.cn(dispatchMasterData.delivery_mobile) && !window.cb(dispatchMasterData.delivery_mobile)) {
    errors = { ...errors, delivery_mobile: 'Enter Mobile No. *' };
    isError = true;
  } else {
    errors = { ...errors, delivery_mobile: '' };
  }

  // lotNoArr.map((item: any, index:number) => {
  //   console.log(item);
  //   let validation = item.every((o: any) => o.lot_no && o.no_of_bag);
  //   console.log(validation)
  //   if(!validation){
  //     errors = { ...errors, all_lots: 'Please Enter value in all lot_no, No. of packets and Packing Size *' };
  //     isError = true;
  //   }
  // });

  if (window.cn(dispatchData) && !window.cb(dispatchData) && dispatchData == null) {
    errors = { ...errors, transport_type: 'Please Select Transport Type *' };
    isError = true;
  } else {
    errors = { ...errors, transport_type: '' };
  }

  if (!window.cn(dispatchData) && dispatchData != null) {

    if (dispatchData.code == 'PART LOAD') {


      if (!window.cn(partLoadTransportName) && partLoadTransportName == null) {
        errors = { ...errors, transport_name: 'Enter Transport Name *' };
        isError = true;
      } else {
        errors = { ...errors, transport_name: '' };
      }
    } else if (dispatchData.code == 'FULL LOAD') {

      if (window.cn(dispatchMasterData.lr_no) && !window.cb(dispatchMasterData.lr_no)) {
        errors = { ...errors, lr_no: 'Enter LR No *' };
        isError = true;
      } else {
        errors = { ...errors, lr_no: '' };
      }

      if (window.cn(lr_image) && !window.cb(lr_image)) {
        errors = { ...errors, lr_image: 'Please Select Lr Image *' };
        isError = true;
      } else {
        errors = { ...errors, lr_image: '' };
      }

      if (window.cn(paymentType) && !window.cb(paymentType) && paymentType == null) {
        errors = { ...errors, payment_type: 'Please Select Payment Type *' };
        isError = true;
      } else {
        errors = { ...errors, payment_type: '' };
      }

      if (window.cn(dispatchMasterData.transport_name) && !window.cb(dispatchMasterData.transport_name)) {
        errors = { ...errors, transport_name: 'Enter Transport Name *' };
        isError = true;
      } else {
        errors = { ...errors, transport_name: '' };
      }

      if (window.cn(dispatchMasterData.car_no) && !window.cb(dispatchMasterData.car_no)) {
        errors = { ...errors, car_no: 'Enter Vehicle No. *' };
        isError = true;
      } else {
        errors = { ...errors, car_no: '' };
      }

      if (window.cn(dispatchMasterData.driver_name) && !window.cb(dispatchMasterData.driver_name)) {
        errors = { ...errors, driver_name: 'Enter Driver Name *' };
        isError = true;
      } else {
        errors = { ...errors, driver_name: '' };
      }

      if (window.cn(dispatchMasterData.driver_mobile) && !window.cb(dispatchMasterData.driver_mobile)) {
        errors = { ...errors, driver_mobile: 'Enter Driver Mobile No. *' };
        isError = true;
      } else {
        errors = { ...errors, driver_mobile: '' };
      }

      if (window.cn(dispatchMasterData.license_no) && !window.cb(dispatchMasterData.license_no)) {
        errors = { ...errors, license_no: 'Enter License No. *' };
        isError = true;
      } else {
        errors = { ...errors, license_no: '' };
      }

      if (window.cn(dispatchMasterData.license_state) && !window.cb(dispatchMasterData.license_state)) {
        errors = { ...errors, license_state: 'Enter License State *' };
        isError = true;
      } else {
        errors = { ...errors, license_state: '' };
      }

      if (window.cn(dispatchMasterData.is_advance) && !window.cb(dispatchMasterData.is_advance)) {
        errors = { ...errors, is_advance: 'Please Select *' };
        isError = true;
      } else {
        errors = { ...errors, is_advance: '' };
      }

      if (!window.cn(dispatchMasterData) && dispatchMasterData.is_advance === 'Yes') {
        if (window.cn(dispatchMasterData.advance_amount) && !window.cb(dispatchMasterData.advance_amount)) {
          errors = { ...errors, advance_amount: 'Enter Advance Amount *' };
          isError = true;
        } else {
          errors = { ...errors, advance_amount: '' };
        }
      }

      if (window.cn(dispatchMasterData.amount) && !window.cb(dispatchMasterData.amount)) {
        errors = { ...errors, amount: 'Enter Amount *' };
        isError = true;
      } else {
        errors = { ...errors, amount: '' };
      }
    } else if (dispatchData.code == 'PRIVATE') {
      if (window.cn(dispatchMasterData.transport_name) && !window.cb(dispatchMasterData.transport_name)) {
        errors = { ...errors, transport_name: 'Enter Transport Name *' };
        isError = true;
      } else {
        errors = { ...errors, transport_name: '' };
      }

      if (window.cn(dispatchMasterData.car_no) && !window.cb(dispatchMasterData.car_no)) {
        errors = { ...errors, car_no: 'Enter Vehicle No. *' };
        isError = true;
      } else {
        errors = { ...errors, car_no: '' };
      }

      if (window.cn(dispatchMasterData.no_of_bags) && !window.cb(dispatchMasterData.no_of_bags)) {
        errors = { ...errors, no_of_bags: 'Enter No Of Bags *' };
        isError = true;
      } else {
        errors = { ...errors, no_of_bags: '' };
      }

      if (window.cn(dispatchMasterData.driver_mobile) && !window.cb(dispatchMasterData.driver_mobile)) {
        errors = { ...errors, driver_mobile: 'Enter Driver Mobile No. *' };
        isError = true;
      } else {
        errors = { ...errors, driver_mobile: '' };
      }

      if (window.cn(dispatchMasterData.from_location) && !window.cb(dispatchMasterData.from_location)) {
        errors = { ...errors, from_location: 'Enter From Loaction *' };
        isError = true;
      } else {
        errors = { ...errors, from_location: '' };
      }

      if (window.cn(dispatchMasterData.to_location) && !window.cb(dispatchMasterData.to_location)) {
        errors = { ...errors, to_location: 'Enter To Location *' };
        isError = true;
      } else {
        errors = { ...errors, to_location: '' };
      }

      if (window.cn(paymentType) && !window.cb(paymentType) && paymentType == null) {
        errors = { ...errors, payment_type: 'Please Select Payment Type *' };
        isError = true;
      } else {
        errors = { ...errors, payment_type: '' };
      }

      if (window.cn(dispatchMasterData.is_advance) && !window.cb(dispatchMasterData.is_advance)) {
        errors = { ...errors, is_advance: 'Please Select *' };
        isError = true;
      } else {
        errors = { ...errors, is_advance: '' };
      }

      if (!window.cn(dispatchMasterData.is_advance) && dispatchMasterData.is_advance === 'Yes') {
        if (window.cn(dispatchMasterData.advance_amount) && !window.cb(dispatchMasterData.advance_amount)) {
          errors = { ...errors, advance_amount: 'Enter Advance Amount *' };
          isError = true;
        } else {
          errors = { ...errors, advance_amount: '' };
        }
      }

      if (window.cn(dispatchMasterData.amount) && !window.cb(dispatchMasterData.amount)) {
        errors = { ...errors, amount: 'Enter Amount *' };
        isError = true;
      } else {
        errors = { ...errors, amount: '' };
      }
    } else {
      if (window.cn(dispatchMasterData.tracking_id) && !window.cb(dispatchMasterData.tracking_id)) {
        errors = { ...errors, tracking_id: 'Enter Tracking Id *' };
        isError = true;
      } else {
        errors = { ...errors, tracking_id: '' };
      }

      if (window.cn(dispatchMasterData.courier_name) && !window.cb(dispatchMasterData.courier_name)) {
        errors = { ...errors, courier_name: 'Enter Courier Name *' };
        isError = true;
      } else {
        errors = { ...errors, courier_name: '' };
      }
    }
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const RemenantDispatchValidate = (dispatchMasterData: any, dispatchData: any, dispatchType: any, paymentType: any, image: any, seedDispatchType: any, selectedDealer: any, seedDispatchData: any, partLoadTransportName: any, lr_image: any) => {

  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(dispatchData) && !window.cb(dispatchData) && dispatchData == null) {
    errors = { ...errors, transport_type: 'Please Select Transport Type *' };
    isError = true;
  } else {
    errors = { ...errors, transport_type: '' };
  }


  if (window.cn(seedDispatchType) && !window.cb(seedDispatchType) && seedDispatchType == null) {
    errors = { ...errors, seed_dispatch_type: 'Please Select Seed Dispatch Type *' };
    isError = true;
  } else {
    errors = { ...errors, seed_dispatch_type: '' };
  }

  if (!window.cn(seedDispatchType) && seedDispatchType.code == 'DEALER') {
    if (window.cn(selectedDealer) && !window.cb(selectedDealer) && selectedDealer == null) {
      errors = { ...errors, dealer: 'Please Select Dealer *' };
      isError = true;
    } else {
      errors = { ...errors, dealer: '' };
    }
  }

  seedDispatchData.every((o: any) => {

    if (!window.cn(dispatchType) && dispatchType.code == 'PACKING MATERIAL') {
      if (window.cn(o.crop) && !window.cb(o.crop)) {
        errors = { ...errors, crop: 'Please Select Crop *' };
        isError = true;
      } else {
        errors = { ...errors, crop: '' };
      }

      if (window.cn(o.variety) && !window.cb(o.variety)) {
        errors = { ...errors, variety: 'Please Select Variety *' };
        isError = true;
      } else {
        errors = { ...errors, variety: '' };
      }

      if (window.cn(o.remenant) && !window.cb(o.remenant)) {
        errors = { ...errors, remenant: 'Please Enter Remenant *' };
        isError = true;
      } else {
        errors = { ...errors, remenant: '' };
      }
    }

  });

  if (window.cn(dispatchMasterData.bill_to) && !window.cb(dispatchMasterData.bill_to)) {
    errors = { ...errors, bill_to: 'Enter Bill To *' };
    isError = true;
  } else {
    errors = { ...errors, bill_to: '' };
  }

  if (window.cn(dispatchMasterData.billing_address) && !window.cb(dispatchMasterData.billing_address)) {
    errors = { ...errors, billing_address: 'Enter Billing Address *' };
    isError = true;
  } else {
    errors = { ...errors, billing_address: '' };
  }

  if (window.cn(dispatchMasterData.mobile) && !window.cb(dispatchMasterData.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile No. *' };
    isError = true;
  } else {
    errors = { ...errors, mobile: '' };
  }

  if (window.cn(dispatchMasterData.delivery_at) && !window.cb(dispatchMasterData.delivery_at)) {
    errors = { ...errors, delivery_at: 'Enter Delivery At *' };
    isError = true;
  } else {
    errors = { ...errors, delivery_at: '' };
  }

  if (window.cn(dispatchMasterData.delivery_address) && !window.cb(dispatchMasterData.delivery_address)) {
    errors = { ...errors, delivery_address: 'Enter Delivery Address *' };
    isError = true;
  } else {
    errors = { ...errors, delivery_address: '' };
  }

  if (window.cn(dispatchMasterData.delivery_mobile) && !window.cb(dispatchMasterData.delivery_mobile)) {
    errors = { ...errors, delivery_mobile: 'Enter Mobile No. *' };
    isError = true;
  } else {
    errors = { ...errors, delivery_mobile: '' };
  }

  if (!window.cn(dispatchData) && dispatchData != null) {

    if (dispatchData.code == 'PART LOAD') {

      if (!window.cn(partLoadTransportName) && partLoadTransportName == null) {
        errors = { ...errors, transport_name: 'Enter Transport Name *' };
        isError = true;
      } else {
        errors = { ...errors, transport_name: '' };
      }
    } else if (dispatchData.code == 'FULL LOAD') {

      if (window.cn(dispatchMasterData.lr_no) && !window.cb(dispatchMasterData.lr_no)) {
        errors = { ...errors, lr_no: 'Enter LR No *' };
        isError = true;
      } else {
        errors = { ...errors, lr_no: '' };
      }

      if (window.cn(lr_image) && !window.cb(lr_image)) {
        errors = { ...errors, lr_image: 'Please Add Image *' };
        isError = true;
      } else {
        errors = { ...errors, lr_image: '' };
      }

      if (window.cn(paymentType) && !window.cb(paymentType) && paymentType == null) {
        errors = { ...errors, payment_type: 'Please Select Payment Type *' };
        isError = true;
      } else {
        errors = { ...errors, payment_type: '' };
      }

      if (window.cn(dispatchMasterData.transport_name) && !window.cb(dispatchMasterData.transport_name)) {
        errors = { ...errors, transport_name: 'Enter Transport Name *' };
        isError = true;
      } else {
        errors = { ...errors, transport_name: '' };
      }

      if (window.cn(dispatchMasterData.car_no) && !window.cb(dispatchMasterData.car_no)) {
        errors = { ...errors, car_no: 'Enter Vehicle No. *' };
        isError = true;
      } else {
        errors = { ...errors, car_no: '' };
      }

      if (window.cn(dispatchMasterData.driver_name) && !window.cb(dispatchMasterData.driver_name)) {
        errors = { ...errors, driver_name: 'Enter Driver Name *' };
        isError = true;
      } else {
        errors = { ...errors, driver_name: '' };
      }

      if (window.cn(dispatchMasterData.driver_mobile) && !window.cb(dispatchMasterData.driver_mobile)) {
        errors = { ...errors, driver_mobile: 'Enter Driver Mobile No. *' };
        isError = true;
      } else {
        errors = { ...errors, driver_mobile: '' };
      }

      if (window.cn(dispatchMasterData.license_no) && !window.cb(dispatchMasterData.license_no)) {
        errors = { ...errors, license_no: 'Enter License No. *' };
        isError = true;
      } else {
        errors = { ...errors, license_no: '' };
      }

      if (window.cn(dispatchMasterData.license_state) && !window.cb(dispatchMasterData.license_state)) {
        errors = { ...errors, license_state: 'Enter License State *' };
        isError = true;
      } else {
        errors = { ...errors, license_state: '' };
      }

      if (window.cn(dispatchMasterData.is_advance) && !window.cb(dispatchMasterData.is_advance)) {
        errors = { ...errors, is_advance: 'Please Select *' };
        isError = true;
      } else {
        errors = { ...errors, is_advance: '' };
      }

      if (!window.cn(dispatchMasterData) && dispatchMasterData.is_advance === 'Yes') {
        if (window.cn(dispatchMasterData.advance_amount) && !window.cb(dispatchMasterData.advance_amount)) {
          errors = { ...errors, advance_amount: 'Enter Advance Amount *' };
          isError = true;
        } else {
          errors = { ...errors, advance_amount: '' };
        }
      }

      if (window.cn(dispatchMasterData.amount) && !window.cb(dispatchMasterData.amount)) {
        errors = { ...errors, amount: 'Enter Amount *' };
        isError = true;
      } else {
        errors = { ...errors, amount: '' };
      }
    } else if (dispatchData.code == 'PRIVATE') {
      if (window.cn(dispatchMasterData.transport_name) && !window.cb(dispatchMasterData.transport_name)) {
        errors = { ...errors, transport_name: 'Enter Transport Name *' };
        isError = true;
      } else {
        errors = { ...errors, transport_name: '' };
      }

      if (window.cn(dispatchMasterData.car_no) && !window.cb(dispatchMasterData.car_no)) {
        errors = { ...errors, car_no: 'Enter Vehicle No. *' };
        isError = true;
      } else {
        errors = { ...errors, car_no: '' };
      }

      if (window.cn(dispatchMasterData.no_of_bags) && !window.cb(dispatchMasterData.no_of_bags)) {
        errors = { ...errors, no_of_bags: 'Enter No Of Bags *' };
        isError = true;
      } else {
        errors = { ...errors, no_of_bags: '' };
      }

      if (window.cn(dispatchMasterData.driver_mobile) && !window.cb(dispatchMasterData.driver_mobile)) {
        errors = { ...errors, driver_mobile: 'Enter Driver Mobile No. *' };
        isError = true;
      } else {
        errors = { ...errors, driver_mobile: '' };
      }

      if (window.cn(dispatchMasterData.from_location) && !window.cb(dispatchMasterData.from_location)) {
        errors = { ...errors, from_location: 'Enter From Loaction *' };
        isError = true;
      } else {
        errors = { ...errors, from_location: '' };
      }

      if (window.cn(dispatchMasterData.to_location) && !window.cb(dispatchMasterData.to_location)) {
        errors = { ...errors, to_location: 'Enter To Location *' };
        isError = true;
      } else {
        errors = { ...errors, to_location: '' };
      }

      if (window.cn(paymentType) && !window.cb(paymentType) && paymentType == null) {
        errors = { ...errors, payment_type: 'Please Select Payment Type *' };
        isError = true;
      } else {
        errors = { ...errors, payment_type: '' };
      }

      if (window.cn(dispatchMasterData.is_advance) && !window.cb(dispatchMasterData.is_advance)) {
        errors = { ...errors, is_advance: 'Please Select *' };
        isError = true;
      } else {
        errors = { ...errors, is_advance: '' };
      }

      if (!window.cn(dispatchMasterData.is_advance) && dispatchMasterData.is_advance === 'Yes') {
        if (window.cn(dispatchMasterData.advance_amount) && !window.cb(dispatchMasterData.advance_amount)) {
          errors = { ...errors, advance_amount: 'Enter Advance Amount *' };
          isError = true;
        } else {
          errors = { ...errors, advance_amount: '' };
        }
      }

      if (window.cn(dispatchMasterData.amount) && !window.cb(dispatchMasterData.amount)) {
        errors = { ...errors, amount: 'Enter Amount *' };
        isError = true;
      } else {
        errors = { ...errors, amount: '' };
      }
    } else {
      if (window.cn(dispatchMasterData.tracking_id) && !window.cb(dispatchMasterData.tracking_id)) {
        errors = { ...errors, tracking_id: 'Enter Tracking Id *' };
        isError = true;
      } else {
        errors = { ...errors, tracking_id: '' };
      }

      if (window.cn(dispatchMasterData.courier_name) && !window.cb(dispatchMasterData.courier_name)) {
        errors = { ...errors, courier_name: 'Enter Courier Name *' };
        isError = true;
      } else {
        errors = { ...errors, courier_name: '' };
      }
    }

  }

  errorObj = { errors, isError };

  return errorObj;
};
// --------------------------------   END DISPATCH PAGE VALIDATION --------------------------  //

// --------------------------------   START PRODUCT PAGE VALIDATION --------------------------  //
export const ProductValidate = (
  inputDetail: any,
  cropDivisionDropdownItem: any, crop: any, procunment: any, packingType: any, variety: any, image: any
) => {

  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  // if (
  //   window.cn(inputDetail.farmer_name) &&
  //   !window.cb(inputDetail.farmer_name)
  // ) {
  //   errors = { ...errors, farmer_name: 'Enter Farmer Name *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, farmer_name: '' };
  // }

  // if (window.cn(inputDetail.location) && !window.cb(inputDetail.location)) {
  //   errors = { ...errors, location: 'Enter Production Location *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, location: '' };
  // }

  // if (window.cn(inputDetail.village) && !window.cb(inputDetail.village)) {
  //   errors = { ...errors, village: 'Enter Village *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, village: '' };
  // }

  // if (window.cn(inputDetail.taluka) && !window.cb(inputDetail.taluka)) {
  //   errors = { ...errors, taluka: 'Enter Taluka *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, taluka: '' };
  // }

  // if (window.cn(inputDetail.district) && !window.cb(inputDetail.district)) {
  //   errors = { ...errors, district: 'Enter District *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, district: '' };
  // }

  if (window.cn(crop) && !window.cb(crop)) {
    errors = { ...errors, crop: 'Please Select Crop *' };
    isError = true;
  } else {
    errors = { ...errors, crop: '' };
  }

  if (window.cn(variety) && !window.cb(variety)) {
    errors = { ...errors, variety: 'Please Select variety *' };
    isError = true;
  } else {
    errors = { ...errors, variety: '' };
  }

  // if (
  //   window.cn(inputDetail.crop_grown_year) &&
  //   !window.cb(inputDetail.crop_grown_year)
  // ) {
  //   errors = { ...errors, crop_grown_year: 'Enter Crop Grown Year *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, crop_grown_year: '' };
  // }

  if (window.cn(inputDetail.crop_qnty) && !window.cb(inputDetail.crop_qnty)) {
    errors = { ...errors, crop_qnty: 'Enter Crop Quantity *' };
    isError = true;
  } else {
    errors = { ...errors, crop_qnty: '' };
  }

  // if (
  //   window.cn(inputDetail.processing_center) &&
  //   !window.cb(inputDetail.processing_center)
  // ) {
  //   errors = { ...errors, processing_center: 'Enter Processing Centre *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, processing_center: '' };
  // }

  // if (
  //   window.cn(inputDetail.seed_organizer) &&
  //   !window.cb(inputDetail.seed_organizer)
  // ) {
  //   errors = { ...errors, seed_organizer: 'Enter Seed Organizer *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, seed_organizer: '' };
  // }

  if (
    window.cn(inputDetail.no_of_bags) &&
    !window.cb(inputDetail.no_of_bags)
  ) {
    errors = { ...errors, no_of_bags: 'Enter No Of Bags *' };
    isError = true;
  } else {
    errors = { ...errors, no_of_bags: '' };
  }

  if (
    window.cn(inputDetail.receiving_date) &&
    !window.cb(inputDetail.receiving_date)
  ) {
    errors = { ...errors, receiving_date: 'Enter Receiving Date *' };
    isError = true;
  } else {
    errors = { ...errors, receiving_date: '' };
  }

  if (
    window.cn(cropDivisionDropdownItem) &&
    !window.cb(cropDivisionDropdownItem)
  ) {
    errors = { ...errors, crop_division: 'Please Select Class *' };
    isError = true;
  } else {
    errors = { ...errors, crop_division: '' };
  }

  // if (
  //   window.cn(procunment) &&
  //   !window.cb(procunment)
  // ) {
  //   errors = { ...errors, procunment: 'Please Select Procunment  *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, procunment: '' };
  // }

  // if (
  //   window.cn(packingType) &&
  //   !window.cb(packingType)
  // ) {
  //   errors = { ...errors, packing_type: 'Please Select Packing Type *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, packing_type: '' };
  // }

  if (window.cn(inputDetail.lot_no) && !window.cb(inputDetail.lot_no)) {
    errors = { ...errors, lot_no: 'Enter Lot No. *' };
    isError = true;
  } else {
    errors = { ...errors, lot_no: '' };
  }

  // if (window.cn(image) && !window.cb(image)) {
  //   errors = { ...errors, photo: 'Please Add Image *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, photo: '' };
  // }

  errorObj = { errors, isError };
  return errorObj;
};

export const ProductPaymentValidate = (data: any, payment: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (payment === 'to_PAID') {
    if (window.cn(data.car_no) && !window.cb(data.car_no)) {
      errors = { ...errors, car_no: 'Enter Car No. *' };
      isError = true;
    } else {
      errors = { ...errors, car_no: '' };
    }

    if (window.cn(data.transport_amount) && !window.cb(data.transport_amount)) {
      errors = { ...errors, transport_amount: 'Enter Transport Amount *' };
      isError = true;
    } else {
      errors = { ...errors, transport_amount: '' };
    }

    if (window.cn(data.transport_name) && !window.cb(data.transport_name)) {
      errors = { ...errors, transport_name: 'Enter Transport Name *' };
      isError = true;
    } else {
      errors = { ...errors, transport_name: '' };
    }
  }

  if (window.cn(data.conractor_name) && !window.cb(data.conractor_name)) {
    errors = { ...errors, conractor_name: 'Enter Contractor Name *' };
    isError = true;
  } else {
    errors = { ...errors, conractor_name: '' };
  }

  if (window.cn(data.price_per_bag) && !window.cb(data.price_per_bag)) {
    errors = { ...errors, price_per_bag: 'Enter Price Per Bag *' };
    isError = true;
  } else {
    errors = { ...errors, price_per_bag: '' };
  }

  if (window.cn(data.labour_no_of_bags) && !window.cb(data.labour_no_of_bags)) {
    errors = { ...errors, labour_no_of_bags: 'Enter Labour No. Of Bags *' };
    isError = true;
  } else {
    errors = { ...errors, labour_no_of_bags: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const PackingValidate = (inputDetail: any, packingArray: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(inputDetail.losses) && !window.cb(inputDetail.losses)) {
    errors = { ...errors, losses: 'Enter Losses *' };
    isError = true;
  } else {
    errors = { ...errors, losses: '' };
  }

  if (window.cn(inputDetail.label_from) && !window.cb(inputDetail.label_from)) {
    errors = { ...errors, label_from: 'Enter Label From *' };
    isError = true;
  } else {
    errors = { ...errors, label_from: '' };
  }

  if (window.cn(inputDetail.label_to) && !window.cb(inputDetail.label_to)) {
    errors = { ...errors, label_to: 'Enter Label To *' };
    isError = true;
  } else {
    errors = { ...errors, label_to: '' };
  }

  packingArray.every((o: any) => {
    if (window.cn(o.crop_detail_id) && !window.cb(o.crop_detail_id)) {
      errors = { ...errors, crop_detail_id: 'Select Bag Weight *' };
      isError = true;
    } else {
      errors = { ...errors, crop_detail_id: '' };
    }

    if (window.cn(o.no_of_packets) && !window.cb(o.no_of_packets)) {
      errors = { ...errors, no_of_packets: 'Enter No. of Packets *' };
      isError = true;
    } else {
      errors = { ...errors, no_of_packets: '' };
    }

    if (window.cn(o.damage) && !window.cb(o.damage)) {
      errors = { ...errors, damage: 'Enter Damage *' };
      isError = true;
    } else {
      errors = { ...errors, damage: '' };
    }

  });

  errorObj = { errors, isError };

  return errorObj;
};

export const ProcessingValidate = (inputDetail: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(inputDetail.processed_qnty) && !window.cb(inputDetail.processed_qnty)) {
    errors = { ...errors, processed_qnty: 'Enter Processed Oty *' };
    isError = true;
  } else {
    errors = { ...errors, processed_qnty: '' };
  }

  if (window.cn(inputDetail.remenant) && !window.cb(inputDetail.remenant)) {
    errors = { ...errors, remenant: 'Enter Remenant *' };
    isError = true;
  } else {
    errors = { ...errors, remenant: '' };
  }

  if (window.cn(inputDetail.losses) && !window.cb(inputDetail.losses)) {
    errors = { ...errors, losses: 'Enter Losses *' };
    isError = true;
  } else {
    errors = { ...errors, losses: '' };
  }


  errorObj = { errors, isError };

  return errorObj;
};

export const SplitValidate = (splitArr: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  splitArr.every((o: any) => {
    if (window.cn(o.lot_no) && !window.cb(o.lot_no)) {
      errors = { ...errors, lot_no: 'Select Lot No *' };
      isError = true;
    } else {
      errors = { ...errors, lot_no: '' };
    }

    if (window.cn(o.no_of_bags) && !window.cb(o.no_of_bags)) {
      errors = { ...errors, no_of_bags: 'Enter No Of Bags *' };
      isError = true;
    } else {
      errors = { ...errors, no_of_bags: '' };
    }

    if (window.cn(o.crop_qnty) && !window.cb(o.crop_qnty)) {
      errors = { ...errors, crop_qnty: 'Enter Quantity *' };
      isError = true;
    } else {
      errors = { ...errors, crop_qnty: '' };
    }
  });

  errorObj = { errors, isError };

  return errorObj;
};


export const MergeValidate = (lotNo: any, crop: any, variety: any, lotNoArr: any, mergeType: any, cropDivisionDropdownItem : any, existingLotNo : any , lotNoDetailsArr : any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(crop) && !window.cb(crop)) {
    errors = { ...errors, crop: 'Select Crop *' };
    isError = true;
  } else {
    errors = { ...errors, crop: '' };
  }

  if (window.cn(variety) && !window.cb(variety)) {
    errors = { ...errors, variety: 'Select Variety *' };
    isError = true;
  } else {
    errors = { ...errors, variety: '' };
  }

  if (mergeType == 'New') {
    if (window.cn(lotNo) && !window.cb(lotNo)) {
      errors = { ...errors, lot_no: 'Enter New Lot No. *' };
      isError = true;
    } else {
      errors = { ...errors, lot_no: '' };
    }

    if (window.cn(lotNoArr) && !window.cb(lotNoArr)) {
      errors = { ...errors, lot_no_to_merge: 'Select Lot No *' };
      isError = true;
    } else {
      errors = { ...errors, lot_no_to_merge: '' };
    }

    if (window.cn(cropDivisionDropdownItem) && !window.cb(cropDivisionDropdownItem)) {
      errors = { ...errors, crop_division: 'Select Class *' };
      isError = true;
    } else {
      errors = { ...errors, crop_division: '' };
    }
  }else{
    if (window.cn(existingLotNo) && !window.cb(existingLotNo)) {
      errors = { ...errors, merge_to_existing_lot_no: 'Select Existing Lot No *' };
      isError = true;
    } else {
      errors = { ...errors, merge_to_existing_lot_no: '' };
    }

      lotNoDetailsArr.every((o: any) => {
        if (window.cn(o.lot_no) && !window.cb(o.lot_no)) {
          errors = { ...errors, lot_no: 'Select Lot No *' };
          isError = true;
        } else {
          errors = { ...errors, lot_no: '' };
        }
    
        if (window.cn(o.crop_qnty) && !window.cb(o.crop_qnty)) {
          errors = { ...errors, crop_qnty: 'Enter Quantity *' };
          isError = true;
        } else {
          errors = { ...errors, crop_qnty: '' };
        }
      });
  }



  errorObj = { errors, isError };

  return errorObj;
};

export const RevertValidate = (data: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(data.no_of_packets) && !window.cb(data.no_of_packets)) {
    errors = { ...errors, no_of_packets: 'Enter No. of Packets *' };
    isError = true;
  } else {
    errors = { ...errors, no_of_packets: '' };
  }

  if (window.cn(data.packing_size) && !window.cb(data.packing_size)) {
    errors = { ...errors, packing_size: 'Select Packing Size *' };
    isError = true;
  } else {
    errors = { ...errors, packing_size: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const OrderTransferApprove = (transferData: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};
    if (window.cn(transferData.amount) && !window.cb(transferData.amount)) {
      errors = { ...errors, amount: 'Enter Amount *' };
      isError = true;
    } else {
      errors = { ...errors, amount: '' };
    }

  errorObj = { errors, isError };

  return errorObj;
};

export const ProductionValidate = (agentData: any, productionDetailData: any, season: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(agentData) && !window.cb(agentData) && agentData == null) {
    errors = { ...errors, organizer: 'Select Organizer *' };
    isError = true;
  } else {
    errors = { ...errors, organizer: '' };
  }

  if (window.cn(season) && !window.cb(season) && season == null) {
    errors = { ...errors, season: 'Select Season *' };
    isError = true;
  } else {
    errors = { ...errors, season: '' };
  }

  productionDetailData.every((o: any) => {
    if (window.cn(o.farmer_name) && !window.cb(o.farmer_name)) {
      errors = { ...errors, farmer_name: 'Select Farmer *' };
      isError = true;
    } else {
      errors = { ...errors, farmer_name: '' };
    }

    if (window.cn(o.crop) && !window.cb(o.crop)) {
      errors = { ...errors, crop: 'Select Crop *' };
      isError = true;
    } else {
      errors = { ...errors, crop: '' };
    }

    if (window.cn(o.variety) && !window.cb(o.variety)) {
      errors = { ...errors, variety: 'Select Variety *' };
      isError = true;
    } else {
      errors = { ...errors, variety: '' };
    }

    if (window.cn(o.stage) && !window.cb(o.stage)) {
      errors = { ...errors, stage: 'Select Class *' };
      isError = true;
    } else {
      errors = { ...errors, stage: '' };
    }

    if (window.cn(o.district) && !window.cb(o.district)) {
      errors = { ...errors, district: 'Enter District *' };
      isError = true;
    } else {
      errors = { ...errors, district: '' };
    }

    if (window.cn(o.taluka) && !window.cb(o.taluka)) {
      errors = { ...errors, taluka: 'Enter Taluka *' };
      isError = true;
    } else {
      errors = { ...errors, taluka: '' };
    }

    if (window.cn(o.village) && !window.cb(o.village)) {
      errors = { ...errors, village: 'Enter Village*' };
      isError = true;
    } else {
      errors = { ...errors, village: '' };
    }

    if (window.cn(o.sowing_date) && !window.cb(o.sowing_date)) {
      errors = { ...errors, sowing_date: 'Select Sowing Date*' };
      isError = true;
    } else {
      errors = { ...errors, sowing_date: '' };
    }

    if (window.cn(o.area) && !window.cb(o.area)) {
      errors = { ...errors, area: 'Enter Area*' };
      isError = true;
    } else {
      errors = { ...errors, area: '' };
    }
  });

  errorObj = { errors, isError };

  return errorObj;
};

export const InspectionValidate = (inspectionData: any, productionDetail: any, inspectionType: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (!window.cn(productionDetail) && productionDetail?.status == 0) {
    if (window.cn(inspectionType) && !window.cb(inspectionType) && inspectionType == null) {
      errors = { ...errors, inspection_type: 'Select Inspection Type *' };
      isError = true;
    } else {
      errors = { ...errors, inspection_type: '' };
    }

    if (window.cn(inspectionData.isolation) && !window.cb(inspectionData.isolation)) {
      errors = { ...errors, isolation: 'Select Isolation *' };
      isError = true;
    } else {
      errors = { ...errors, isolation: '' };
    }

    if (window.cn(inspectionData.germination_status) && !window.cb(inspectionData.germination_status)) {
      errors = { ...errors, germination_status: 'Enter Germination Status *' };
      isError = true;
    } else {
      errors = { ...errors, germination_status: '' };
    }

    if (window.cn(inspectionData.actual_sowing_area) && !window.cb(inspectionData.actual_sowing_area)) {
      errors = { ...errors, actual_sowing_area: 'Enter Actual Sowing Area *' };
      isError = true;
    } else {
      errors = { ...errors, actual_sowing_area: '' };
    }


  } else if (!window.cn(productionDetail) && productionDetail?.status == 1) {
    if (window.cn(inspectionData.off_type) && !window.cb(inspectionData.off_type)) {
      errors = { ...errors, off_type: 'Enter Off Type *' };
      isError = true;
    } else {
      errors = { ...errors, off_type: '' };
    }

    if (window.cn(inspectionData.roughing_status) && !window.cb(inspectionData.roughing_status)) {
      errors = { ...errors, roughing_status: 'Select Roughing Status *' };
      isError = true;
    } else {
      errors = { ...errors, roughing_status: '' };
    }
  } else if (!window.cn(productionDetail) && productionDetail?.status == 2) {
    if (window.cn(inspectionData.expected_harvesting_date) && !window.cb(inspectionData.expected_harvesting_date)) {
      errors = { ...errors, expected_harvesting_date: 'Enter Expected Harvesting Date *' };
      isError = true;
    } else {
      errors = { ...errors, expected_harvesting_date: '' };
    }

    if (window.cn(inspectionData.roughing_status) && !window.cb(inspectionData.roughing_status)) {
      errors = { ...errors, roughing_status: 'Select Roughing Status *' };
      isError = true;
    } else {
      errors = { ...errors, roughing_status: '' };
    }

    if (window.cn(inspectionData.expected_yield) && !window.cb(inspectionData.expected_yield)) {
      errors = { ...errors, expected_yield: 'Enter Expected Yield *' };
      isError = true;
    } else {
      errors = { ...errors, expected_yield: '' };
    }
  }

  if (window.cn(inspectionData.crop_stage) && !window.cb(inspectionData.crop_stage)) {
    errors = { ...errors, crop_stage: 'Select Crop Stage *' };
    isError = true;
  } else {
    errors = { ...errors, crop_stage: '' };
  }

  if (window.cn(inspectionData.crop_condition) && !window.cb(inspectionData.crop_condition)) {
    errors = { ...errors, crop_condition: 'Select Crop Condition *' };
    isError = true;
  } else {
    errors = { ...errors, crop_condition: '' };
  }

  if (window.cn(inspectionData.insect_disease) && !window.cb(inspectionData.insect_disease)) {
    errors = { ...errors, insect_disease: 'Enter Insect/Disease *' };
    isError = true;
  } else {
    errors = { ...errors, insect_disease: '' };
  }

  if (window.cn(inspectionData.recommendation) && !window.cb(inspectionData.recommendation)) {
    errors = { ...errors, recommendation: 'Enter Recommendation *' };
    isError = true;
  } else {
    errors = { ...errors, recommendation: '' };
  }

  if (window.cn(inspectionData.inspection_date) && !window.cb(inspectionData.remarks)) {
    errors = { ...errors, remarks: 'Enter Remarks *' };
    isError = true;
  } else {
    errors = { ...errors, remarks: '' };
  }

  if (window.cn(inspectionData.inspection_date) && !window.cb(inspectionData.inspection_date)) {
    errors = { ...errors, inspection_date: 'Enter Inspection Date *' };
    isError = true;
  } else {
    errors = { ...errors, inspection_date: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

export const SampleValidate = (data: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(data.sampling_date) && !window.cb(data.sampling_date)) {
    errors = { ...errors, sampling_date: 'Enter Sampling Date *' };
    isError = true;
  } else {
    errors = { ...errors, sampling_date: '' };
  }

  if (window.cn(data.sampled_by) && !window.cb(data.sampled_by)) {
    errors = { ...errors, sampled_by: 'Enter Sampled By *' };
    isError = true;
  } else {
    errors = { ...errors, sampled_by: '' };
  }

  if (window.cn(data.sample_size) && !window.cb(data.sample_size)) {
    errors = { ...errors, sample_size: 'Enter Sample Size *' };
    isError = true;
  } else {
    errors = { ...errors, sample_size: '' };
  }

  if (window.cn(data.physical_purity) && !window.cb(data.physical_purity)) {
    errors = { ...errors, physical_purity: 'Enter Physical Purity *' };
    isError = true;
  } else {
    errors = { ...errors, physical_purity: '' };
  }

  if (window.cn(data.lot_no) && !window.cb(data.lot_no)) {
    errors = { ...errors, lot_no: 'Enter Lot No. *' };
    isError = true;
  } else {
    errors = { ...errors, lot_no: '' };
  }

  errorObj = { errors, isError };
  return errorObj;
};

export const GerminationValidate = (data: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(data.date_of_putting) && !window.cb(data.date_of_putting)) {
    errors = { ...errors, date_of_putting: 'Enter Date Of Putting *' };
    isError = true;
  } else {
    errors = { ...errors, date_of_putting: '' };
  }

  if (window.cn(data.germinated_seeds_1) && !window.cb(data.germinated_seeds_1)) {
    errors = { ...errors, germinated_seeds_1: 'Enter Germinated Seeds *' };
    isError = true;
  } else {
    errors = { ...errors, germinated_seeds_1: '' };
  }

  if (window.cn(data.no_of_seeds) && !window.cb(data.no_of_seeds)) {
    errors = { ...errors, no_of_seeds: 'Enter No Of Seeds *' };
    isError = true;
  } else {
    errors = { ...errors, no_of_seeds: '' };
  }

  if (window.cn(data.germinated_seeds) && !window.cb(data.germinated_seeds)) {
    errors = { ...errors, germinated_seeds: 'Enter Germinated Seeds *' };
    isError = true;
  } else {
    errors = { ...errors, germinated_seeds: '' };
  }

  if (window.cn(data.no_of_seeds_1) && !window.cb(data.no_of_seeds_1)) {
    errors = { ...errors, no_of_seeds_1: 'Enter No Of Seeds *' };
    isError = true;
  } else {
    errors = { ...errors, no_of_seeds_1: '' };
  }

  errorObj = { errors, isError };
  return errorObj;
};

export const fieldEmergenceValidate = (data: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(data.date_of_sowing) && !window.cb(data.date_of_sowing)) {
    errors = { ...errors, date_of_sowing: 'Enter Date Of Sowing *' };
    isError = true;
  } else {
    errors = { ...errors, date_of_sowing: '' };
  }

  if (window.cn(data.seeds_emerged_1) && !window.cb(data.seeds_emerged_1)) {
    errors = { ...errors, seeds_emerged_1: 'Enter No. Of Seeds Emerged *' };
    isError = true;
  } else {
    errors = { ...errors, seeds_emerged_1: '' };
  }

  if (window.cn(data.seeds_planted) && !window.cb(data.seeds_planted)) {
    errors = { ...errors, seeds_planted: 'Enter No Of Seeds Planted *' };
    isError = true;
  } else {
    errors = { ...errors, seeds_planted: '' };
  }

  if (window.cn(data.seeds_emerged) && !window.cb(data.seeds_emerged)) {
    errors = { ...errors, seeds_emerged: 'Enter No. Of Seeds Emerged *' };
    isError = true;
  } else {
    errors = { ...errors, seeds_emerged: '' };
  }

  if (window.cn(data.seeds_planted_1) && !window.cb(data.seeds_planted_1)) {
    errors = { ...errors, seeds_planted_1: 'Enter No Of Seeds Planted *' };
    isError = true;
  } else {
    errors = { ...errors, seeds_planted_1: '' };
  }

  errorObj = { errors, isError };
  return errorObj;
};

export const GeneticPurityValidate = (data: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(data.date_of_planting) && !window.cb(data.date_of_planting)) {
    errors = { ...errors, date_of_planting: 'Enter Date Of Planting *' };
    isError = true;
  } else {
    errors = { ...errors, date_of_planting: '' };
  }

  if (window.cn(data.rep_plant_stand_1) && !window.cb(data.rep_plant_stand_1)) {
    errors = { ...errors, rep_plant_stand_1: 'Enter No. Of Plant Stand *' };
    isError = true;
  } else {
    errors = { ...errors, rep_plant_stand_1: '' };
  }

  if (window.cn(data.rep_f_1) && !window.cb(data.rep_f_1)) {
    errors = { ...errors, rep_f_1: 'Enter Female *' };
    isError = true;
  } else {
    errors = { ...errors, rep_f_1: '' };
  }

  if (window.cn(data.rep_m_1) && !window.cb(data.rep_m_1)) {
    errors = { ...errors, rep_m_1: 'Enter Male *' };
    isError = true;
  } else {
    errors = { ...errors, rep_m_1: '' };
  }

  if (window.cn(data.rep_ot_1) && !window.cb(data.rep_ot_1)) {
    errors = { ...errors, rep_ot_1: 'Enter OT *' };
    isError = true;
  } else {
    errors = { ...errors, rep_ot_1: '' };
  }

  if (window.cn(data.rep_plant_stand_2) && !window.cb(data.rep_plant_stand_2)) {
    errors = { ...errors, rep_plant_stand_2: 'Enter No. Of Plant stand *' };
    isError = true;
  } else {
    errors = { ...errors, rep_plant_stand_2: '' };
  }

  if (window.cn(data.rep_f_2) && !window.cb(data.rep_f_2)) {
    errors = { ...errors, rep_f_2: 'Enter Female *' };
    isError = true;
  } else {
    errors = { ...errors, rep_f_2: '' };
  }

  if (window.cn(data.rep_m_2) && !window.cb(data.rep_m_2)) {
    errors = { ...errors, rep_m_2: 'Enter Male *' };
    isError = true;
  } else {
    errors = { ...errors, rep_m_2: '' };
  }

  if (window.cn(data.rep_ot) && !window.cb(data.rep_ot)) {
    errors = { ...errors, rep_ot: 'Enter OT *' };
    isError = true;
  } else {
    errors = { ...errors, rep_ot: '' };
  }

  errorObj = { errors, isError };
  return errorObj;
};

// --------------------------------   END PRODUCT PAGE VALIDATION --------------------------  //

// --------------------------------   START AGENT PAGE VALIDATION --------------------------  //
export const AgentValidate = (inputDetail: any, cityData: any, stateData: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(inputDetail.first_name) && !window.cb(inputDetail.first_name)) {
    errors = { ...errors, first_name: 'Enter First Name *' };
    isError = true;
  } else {
    errors = { ...errors, first_name: '' };
  }

  if (window.cn(inputDetail.last_name) && !window.cb(inputDetail.last_name)) {
    errors = { ...errors, last_name: 'Enter Last Name *' };
    isError = true;
  } else {
    errors = { ...errors, last_name: '' };
  }

  if (window.cn(inputDetail.mobile) && !window.cb(inputDetail.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else if (inputDetail.mobile.length > 10 || inputDetail.mobile.length < 10) {
    errors = { ...errors, mobile: 'Mobile Number Should be 10 digits*' };
    isError = true;
  } else {
    errors = { ...errors, mobile: '' };
  }

  if (window.cn(inputDetail.residence_address) && !window.cb(inputDetail.residence_address)) {
    errors = { ...errors, residence_address: 'Enter Address *' };
    isError = true;
  } else {
    errors = { ...errors, residence_address: '' };
  }

  if (window.cn(inputDetail.village) && !window.cb(inputDetail.village)) {
    errors = { ...errors, village: 'Enter Village *' };
    isError = true;
  } else {
    errors = { ...errors, village: '' };
  }

  if (window.cn(stateData) && !window.cb(stateData) && stateData == null) {
    errors = { ...errors, state: 'Select State *' };
    isError = true;
  } else {
    errors = { ...errors, state: '' };
  }

  if (window.cn(cityData) && !window.cb(cityData) && cityData == null) {
    errors = { ...errors, city: 'Select City *' };
    isError = true;
  } else {
    errors = { ...errors, city: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// --------------------------------   END AGENT PAGE VALIDATION --------------------------  //

// --------------------------------   START DEALER PAGE VALIDATION --------------------------  //
export const DealerDataValidate = (dealerData: any, panImage: any, gstImage: any, licenseImage: any, profileImage: any, state: any, city: any, editId: any) => {

  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(dealerData.full_name) && !window.cb(dealerData.full_name)) {
    errors = { ...errors, full_name: 'Enter Full Name *' };
    isError = true;
  } else {
    errors = { ...errors, full_name: '' };
  }

  if (window.cn(dealerData.firm_name) && !window.cb(dealerData.firm_name)) {
    errors = { ...errors, firm_name: 'Enter Firm Name *' };
    isError = true;
  } else {
    errors = { ...errors, firm_name: '' };
  }

  if (window.cn(dealerData.mobile) && !window.cb(dealerData.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else if (dealerData.mobile.length > 10 || dealerData.mobile.length < 10) {
    errors = { ...errors, mobile: 'Mobile Number Should be 10 digits*' };
    isError = true;
  } else {
    errors = { ...errors, mobile: '' };
  }

  // if (window.cn(dealerData.business_address) && !window.cb(dealerData.business_address)) {
  //   errors = { ...errors, business_address: 'Enter Business Address *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, business_address: '' };
  // }

  // if (window.cn(dealerData.residence_address) && !window.cb(dealerData.residence_address)) {
  //   errors = { ...errors, residence_address: 'Enter Residence Address *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, residence_address: '' };
  // }

  if (window.cn(state) && !window.cb(state)) {
    errors = { ...errors, state: 'Enter State *' };
    isError = true;
  } else {
    errors = { ...errors, state: '' };
  }

  if (window.cn(city) && !window.cb(city)) {
    errors = { ...errors, city: 'Enter City *' };
    isError = true;
  } else {
    errors = { ...errors, city: '' };
  }

  // if (window.cn(dealerData.taluka) && !window.cb(dealerData.taluka)) {
  //   errors = { ...errors, taluka: 'Enter Taluka *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, taluka: '' };
  // }

  // if (window.cn(dealerData.district) && !window.cb(dealerData.district)) {
  //   errors = { ...errors, district: 'Enter District *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, district: '' };
  // }

  // if (window.cn(dealerData.pincode) && !window.cb(dealerData.pincode)) {
  //   errors = { ...errors, pincode: 'Enter Pincode *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, pincode: '' };
  // }

  // if (window.cn(dealerData.account_no) && !window.cb(dealerData.account_no)) {
  //   errors = { ...errors, account_no: 'Enter Account No *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, account_no: '' };
  // }

  // if (window.cn(dealerData.ifsc_code) && !window.cb(dealerData.ifsc_code)) {
  //   errors = { ...errors, ifsc_code: 'Enter IFSC Code *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, ifsc_code: '' };
  // }

  // if (window.cn(dealerData.bank_name) && !window.cb(dealerData.bank_name)) {
  //   errors = { ...errors, bank_name: 'Enter Bank Name *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, bank_name: '' };
  // }

  // if (editId == null) {
  //   if (window.cn(dealerData.password) && !window.cb(dealerData.password)) {
  //     errors = { ...errors, password: 'Enter Password *' };
  //     isError = true;
  //   } else {
  //     errors = { ...errors, password: '' };
  //   }
  // }

  // if (window.cn(dealerData.license_no) && !window.cb(dealerData.license_no)) {
  //   errors = { ...errors, license_no: 'Enter License No. *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, license_no: '' };
  // }

  // if (window.cn(dealerData.pan_no) && !window.cb(dealerData.pan_no)) {
  //   errors = { ...errors, pan_no: 'Enter Pan No. *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, pan_no: '' };
  // }

  // if (window.cn(dealerData.aadhar_no) && !window.cb(dealerData.aadhar_no)) {
  //   errors = { ...errors, aadhar_no: 'Enter Aadhar No. *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, aadhar_no: '' };
  // }

  // if (window.cn(dealerData.gst_no) && !window.cb(dealerData.gst_no)) {
  //   errors = { ...errors, gst_no: 'Enter GSTIN No. *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, gst_no: '' };
  // }

  // if (window.cn(dealerData.valid_upto) && !window.cb(dealerData.valid_upto)) {
  //   errors = { ...errors, valid_upto: 'Enter License Validity *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, valid_upto: '' };
  // }

  // if (window.cn(panImage) && !window.cb(panImage)) {
  //   errors = { ...errors, pancard: 'Please Add Image *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, pancard: '' };
  // }

  // if (window.cn(gstImage) && !window.cb(gstImage)) {
  //   errors = { ...errors, gstin_reg_copy: 'Please Add Image *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, gstin_reg_copy: '' };
  // }

  // if (window.cn(licenseImage) && !window.cb(licenseImage)) {
  //   errors = { ...errors, seed_license_copy: 'Please Add Image *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, seed_license_copy: '' };
  // }

  // if (window.cn(profileImage) && !window.cb(profileImage)) {
  //   errors = { ...errors, photo: 'Please Add Image *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, photo: '' };
  // }

  errorObj = { errors, isError };

  return errorObj;
};

// --------------------------------   END DEALER PAGE VALIDATION --------------------------  //

// --------------------------------   START DEALER LEAD PAGE VALIDATION --------------------------  //
export const DealerLeadDataValidate = (dealerLeadData: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (
    window.cn(dealerLeadData.password) &&
    !window.cb(dealerLeadData.password)
  ) {
    errors = { ...errors, password: 'Enter Password *' };
    isError = true;
  } else {
    errors = { ...errors, password: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};

// --------------------------------   END DEALER LEAD PAGE VALIDATION --------------------------  //

// --------------------------------   START FARMER PAGE VALIDATION --------------------------  //
export const FarmerDataValidate = (editId: any, selectedAgent: any, farmerData: any, stateId: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (editId == undefined && editId == null) {
    if (window.cn(selectedAgent) && !window.cb(selectedAgent) && selectedAgent == null) {
      errors = { ...errors, organizer: 'Select Organizer *' };
      isError = true;
    } else {
      errors = { ...errors, organizer: '' };
    }
  }

  if (window.cn(farmerData.full_name) && !window.cb(farmerData.full_name)) {
    errors = { ...errors, full_name: 'Enter Full Name *' };
    isError = true;
  } else {
    errors = { ...errors, full_name: '' };
  }

  if (window.cn(farmerData.village) && !window.cb(farmerData.village)) {
    errors = { ...errors, village: 'Enter Village *' };
    isError = true;
  } else {
    errors = { ...errors, village: '' };
  }

  if (window.cn(farmerData.mobile) && !window.cb(farmerData.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile *' };
    isError = true;
  } else {
    errors = { ...errors, mobile: '' };
  }

  if (window.cn(farmerData.taluka) && !window.cb(farmerData.taluka)) {
    errors = { ...errors, taluka: 'Enter Taluka *' };
    isError = true;
  } else {
    errors = { ...errors, taluka: '' };
  }

  if (window.cn(farmerData.district) && !window.cb(farmerData.district)) {
    errors = { ...errors, district: 'Enter District *' };
    isError = true;
  } else {
    errors = { ...errors, district: '' };
  }

  if (window.cn(stateId) && !window.cb(stateId) && stateId == null) {
    errors = { ...errors, state: 'Enter State *' };
    isError = true;
  } else {
    errors = { ...errors, state: '' };
  }

  errorObj = { errors, isError };

  return errorObj;
};
// --------------------------------   END FARMER PAGE VALIDATION --------------------------  //

// --------------------------------   START CROP PAGE VALIDATION --------------------------  //
export const CropDataValidate = (cropData: any, image: any, name: any, variety: any, packingsize: any, editId: any, crop: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (editId == null) {
    if (window.cn(name) && !window.cb(name)) {
      errors = { ...errors, crop: 'Please Select Crop *' };
      isError = true;
    } else {
      errors = { ...errors, crop: '' };
    }

    if (window.cn(variety) && !window.cb(variety)) {
      errors = { ...errors, variety: 'Please Select Variety *' };
      isError = true;
    } else {
      errors = { ...errors, variety: '' };
    }

    if (window.cn(packingsize) && !window.cb(packingsize)) {
      errors = { ...errors, weight: 'Please Select Packing Size *' };
      isError = true;
    } else {
      errors = { ...errors, weight: '' };
    }
  }

  if (window.cn(crop.quantity) && !window.cb(crop.quantity)) {
    errors = { ...errors, quantity: 'Enter Quantity *' };
    isError = true;
  } else {
    errors = { ...errors, quantity: '' };
  }

  // if (window.cn(image) && !window.cb(image)) {
  //   errors = { ...errors, photo: 'Please Add Image *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, photo: '' };
  // }

  cropData.every((o: any) => {
    if (window.cn(o.amount) && !window.cb(o.amount)) {
      errors = { ...errors, amount: 'Enter amount *' };
      isError = true;
    } else {
      errors = { ...errors, amount: '' };
    }

    if (window.cn(o.state) && !window.cb(o.state)) {
      errors = { ...errors, state: 'Please select State *' };
      isError = true;
    } else {
      errors = { ...errors, state: '' };
    }
  });

  errorObj = { errors, isError };
  return errorObj;
};

export const globalCropDataValidate = (cropData: any, name: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(name) && !window.cb(name)) {
    errors = { ...errors, crop: 'Please Select Crop *' };
    isError = true;
  } else {
    errors = { ...errors, crop: '' };
  }

  cropData.every((o: any) => {
    if (window.cn(o.name) && !window.cb(o.name)) {
      errors = { ...errors, name: 'Enter Variety *' };
      isError = true;
    } else {
      errors = { ...errors, name: '' };
    }
  });

  errorObj = { errors, isError };
  return errorObj;
};

export const globalPackingSizeValidate = (packingSizeArray: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  packingSizeArray.every((o: any) => {
    if (window.cn(o.packing_size) && !window.cb(o.packing_size)) {
      errors = { ...errors, packing_size: 'Enter Packing Size *' };
      isError = true;
    } else {
      errors = { ...errors, packing_size: '' };
    }

    if (window.cn(o.no_of_packets) && !window.cb(o.no_of_packets)) {
      errors = { ...errors, no_of_packets: 'Enter No of Packets *' };
      isError = true;
    } else {
      errors = { ...errors, no_of_packets: '' };
    }

    if (window.cn(o.weight) && !window.cb(o.weight)) {
      errors = { ...errors, weight: 'Enter Weight *' };
      isError = true;
    } else {
      errors = { ...errors, weight: '' };
    }
  });

  errorObj = { errors, isError };
  return errorObj;
};

export const addPackingBagCountValidate = (stockData: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(stockData.packing_bag_count) && !window.cb(stockData.packing_bag_count)) {
    errors = { ...errors, packing_bag_count: 'Enter Packing Bag Count *' };
    isError = true;
  } else {
    errors = { ...errors, packing_bag_count: '' };
  }




  errorObj = { errors, isError };
  return errorObj;
};

// --------------------------------   END CROP PAGE VALIDATION --------------------------  //

// --------------------------------   START ORDER PAGE VALIDATION --------------------------  //
export const OrderDataValidate = (dealer: any, orderArray: any, editId: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(dealer) && !window.cb(dealer)) {
    errors = { ...errors, dealer: 'Select Dealer *' };
    isError = true;
  } else {
    errors = { ...errors, dealer: '' };
  }

  orderArray.every((o: any) => {
    if (window.cn(o.crop) && !window.cb(o.crop)) {
      errors = { ...errors, crop: 'Select Crop *' };
      isError = true;
    } else {
      errors = { ...errors, crop: '' };
    }

    if (window.cn(o.variety) && !window.cb(o.variety)) {
      errors = { ...errors, variety: 'Select Variety *' };
      isError = true;
    } else {
      errors = { ...errors, variety: '' };
    }

    // if (editId != null) {
    if (window.cn(o.packing_size) && !window.cb(o.packing_size)) {
      errors = { ...errors, packing_size: 'Select Packing Size *' };
      isError = true;
    } else {
      errors = { ...errors, packing_size: '' };
    }
    // }

    if (window.cn(o.quantity) && !window.cb(o.quantity)) {
      errors = { ...errors, quantity: 'Enter No. Of Bags*' };
      isError = true;
    } else {
      errors = { ...errors, quantity: '' };
    }

    // if (window.cn(o.stage) && !window.cb(o.stage)) {
    //   errors = { ...errors, stage: 'Select Class *' };
    //   isError = true;
    // } else {
    //   errors = { ...errors, stage: '' };
    // }
  });

  errorObj = { errors, isError };

  return errorObj;
};
// --------------------------------   END ORDER PAGE VALIDATION --------------------------  //

// --------------------------------   START EMPLOYEE PAGE VALIDATION --------------------------  //
export const EmployeeDataValidate = (editId: any, employeeData: any, image: any, stateId: any, cityId: any, role: any) => {
  let isError: boolean = false;

  let errorObj: any = {};

  let errors: any = {};

  if (window.cn(employeeData.first_name) && !window.cb(employeeData.first_name)) {
    errors = { ...errors, first_name: 'Enter First Name *' };
    isError = true;
  } else {
    errors = { ...errors, first_name: '' };
  }

  if (window.cn(employeeData.last_name) && !window.cb(employeeData.last_name)) {
    errors = { ...errors, last_name: 'Enter Last Name *' };
    isError = true;
  } else {
    errors = { ...errors, last_name: '' };
  }

  if (window.cn(employeeData.email) && !window.cb(employeeData.email)) {
    errors = { ...errors, email: 'Enter Email *' };
    isError = true;
  } else if (!emailPattern.test(employeeData.email)) {
    errors = { ...errors, email: 'Enter Valid Email*' };
    isError = true;
  } else {
    errors = { ...errors, email: '' };
  }

  if (window.cn(employeeData.mobile) && !window.cb(employeeData.mobile)) {
    errors = { ...errors, mobile: 'Enter Mobile Number *' };
    isError = true;
  } else if (employeeData.mobile.length > 10 || employeeData.mobile.length < 10) {
    errors = { ...errors, mobile: 'Mobile Number Should be 10 digits*' };
    isError = true;
  } else {
    errors = { ...errors, mobile: '' };
  }

  if (window.cn(employeeData.login_for_both) && !window.cb(employeeData.login_for_both)) {
    errors = { ...errors, login_for_both: 'Select Any One*' };
    isError = true;
  } else {
    errors = { ...errors, login_for_both: '' };
  }

  if (window.cn(stateId) && !window.cb(stateId) && stateId == null) {
    errors = { ...errors, state: 'Enter State *' };
    isError = true;
  } else {
    errors = { ...errors, state: '' };
  }

  // if (image === undefined) {
  //   errors = { ...errors, photo: 'Select Photo *' };
  //   isError = true;
  // } else {
  //   errors = { ...errors, photo: '' };
  // }

  if (window.cn(cityId) && !window.cb(cityId) && cityId == null) {
    errors = { ...errors, city: 'Enter City *' };
    isError = true;
  } else {
    errors = { ...errors, city: '' };
  }

  if (window.cn(role) && !window.cb(role) && role == null) {
    errors = { ...errors, role: 'Select Role *' };
    isError = true;
  } else {
    errors = { ...errors, role: '' };
  }

  if (editId == null) {
    if (window.cn(employeeData.password) && !window.cb(employeeData.password)) {
      errors = { ...errors, password: 'Enter Password *' };
      isError = true;
    } else {
      errors = { ...errors, password: '' };
    }
  }

  errorObj = { errors, isError };

  return errorObj;
};

// --------------------------------   END EMPLOYEE PAGE VALIDATION --------------------------  //

// --------------------------------   User Profile  FORM VALIDATION --------------------------  //

//Four Simple Steps Validate
export const ChangePasswordFormValidate = (inputDetail: any) => {

  //Define Errors Object / Validate
  let isError: boolean = false;
  let errorObj: any = {};
  let errors: any = {};

  //Check Every Input Condition
  if (window.cn(inputDetail.password) && !window.cb(inputDetail.password)) {
    errors = { ...errors, password: "Enter New Password *" }
    isError = true;
  }
  else {
    errors = { ...errors, password: "" };
  }

  if (window.cn(inputDetail.confirm_password) && !window.cb(inputDetail.confirm_password)) {
    errors = { ...errors, confirm_password: "Enter Confirm Password *" }
    isError = true;
  } else if (inputDetail.password !== inputDetail.confirm_password) {
    errors = { ...errors, confirm_password: "Confirm Password does not Match to Password*" }
    isError = true;
  } else {
    errors = { ...errors, confirm_password: "" };
  }

  //End Every Input Condition

  errorObj = { errors, isError }

  return errorObj;
}

// --------------------------------   User Profile  FORM VALIDATION --------------------------  //

//GTM Validation
export const GTMValidate = (gtmData: any, cropId: any, varietyId: any, weight: any) => {
  try {

    //Define Errors Object / Validate
    let isError: boolean = false;
    let errorObj: any = {};
    let errors: any = {};

    //Check Every Input Condition
    if (window.cn(cropId) && !window.cb(cropId)) {
      errors = { ...errors, crop: "Please Select Crop *" }
      isError = true;
    }
    else {
      errors = { ...errors, crop: "" };
    }

    if (window.cn(varietyId) && !window.cb(varietyId)) {
      errors = { ...errors, variety: "Please Select Variety *" }
      isError = true;
    }
    else {
      errors = { ...errors, variety: "" };
    }

    if (window.cn(gtmData.unit) && !window.cb(gtmData.unit)) {
      errors = { ...errors, unit: "Please Enter Unit *" }
      isError = true;
    }
    else {
      errors = { ...errors, unit: "" };
    }

    if (window.cn(gtmData.storage) && !window.cb(gtmData.storage)) {
      errors = { ...errors, storage: "Please Storage Unit *" }
      isError = true;
    }
    else {
      errors = { ...errors, storage: "" };
    }

    if (window.cn(gtmData.processing) && !window.cb(gtmData.processing)) {
      errors = { ...errors, processing: "Please Processing Unit *" }
      isError = true;
    }
    else {
      errors = { ...errors, processing: "" };
    }

    if (window.cn(gtmData.carrier) && !window.cb(gtmData.carrier)) {
      errors = { ...errors, carrier: "Please Carrier Unit *" }
      isError = true;
    }
    else {
      errors = { ...errors, carrier: "" };
    }

    if (window.cn(gtmData.amount) && !window.cb(gtmData.amount)) {
      errors = { ...errors, amount: "Please Amount Unit *" }
      isError = true;
    }
    else {
      errors = { ...errors, amount: "" };
    }

    if (window.cn(gtmData.vehicle_no) && !window.cb(gtmData.vehicle_no)) {
      errors = { ...errors, vehicle_no: "Please Vehicle Number Unit *" }
      isError = true;
    }
    else {
      errors = { ...errors, vehicle_no: "" };
    }

    if (window.cn(gtmData.remark) && !window.cb(gtmData.remark)) {
      errors = { ...errors, remark: "Please Remark Unit *" }
      isError = true;
    }
    else {
      errors = { ...errors, remark: "" };
    }

    if (window.cn(gtmData.quantity) && !window.cb(gtmData.quantity)) {
      errors = { ...errors, quantity: "Please Quantity Unit *" }
      isError = true;
    }
    else {
      errors = { ...errors, quantity: "" };
    }

    if (window.cn(weight) && !window.cb(weight)) {
      errors = { ...errors, weight: "Please Select Weight Unit *" }
      isError = true;
    }
    else {
      errors = { ...errors, weight: "" };
    }

    //End Every Input Condition

    errorObj = { errors, isError }

    return errorObj;
  } catch (error: any) {

  }
}
