import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';

// Data table
import { cropDivisionDropdownItems, seasonDropdown } from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';
import { ProductionValidate } from '../../config/Validate';

export const AddProduction = () => {

    document.title = 'Add production | Swarnim Farms';

    const pageService = new PageService();

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    const toast = useRef<any>(null);

    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [uiUpdated, setIsUiUpdated] = useState<boolean>(false);

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    // crop and variety
    const [agentList, setAgentList] = useState<any>([]);
    const [agentData, setAgentData] = useState<any>(null);
    const [cropData, setCropData] = useState<any>([]);
    const [varietyDropdown, setVarietyDropdown] = useState<any>([]);

    const [selectedAgent, setSelectedAgent] = useState<any>(null);
    const [selectedSeason, setSelectedSeason] = useState<any>(null);
    const [year, setYear] = useState<any>(null);

    const [productionDetailData, setProductionDetailData] = useState<any>([{
        crop: '',
        variety: '',
        stage: '',
        farmer_name: '',
        village: '',
        taluka: '',
        district: '',
        sowing_date: '',
        area: ''
    }]);

    // Edit and Delete Data Details
    const [editId, setEditId] = useState<any>(null);
    const [productionDetailId, setProductionDetailId] = useState<any>(null);

    // use effect method
    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setEditId(state);
            editProduction(state);
        }
        agentListApiCall();
        getCropList();
        fYear();
    }, []);

    useEffect(() => {
        if (uiUpdated) {
            setIsUiUpdated(false);
        }
    }, [productionDetailData, uiUpdated]);

    const fYear = async () => {
        var today = new Date();

        //get current month
        var curMonth = today.getMonth();

        var fiscalYr = "";
        if (curMonth > 3) { //
            var nextYr1 = (today.getFullYear() + 1).toString();
            fiscalYr = today.getFullYear().toString() + "-" + nextYr1.charAt(2) + nextYr1.charAt(3);
        } else {
            var nextYr2 = today.getFullYear().toString();
            fiscalYr = (today.getFullYear() - 1).toString() + "-" + nextYr2.charAt(2) + nextYr2.charAt(3);
        }

        setYear(fiscalYr);
    }

    // Get crops
    const agentListApiCall = async () => {
        try {
            const agentData: any = [];

            pageService.agentList(5).then((response) => {
                if (response) {
                    const list = response;
                    for (let i = 0; i < list.length; i++) {
                        const userObj: any = {};
                        userObj['name'] = list[i].first_name + " " + list[i].last_name;
                        userObj['code'] = list[i].id;
                        agentData.push(userObj);
                    }
                    setAgentList(agentData);
                }
            });
        } catch (error) { }
    };

    const handleChangeAgent = async (e: any) => {
        setSelectedAgent(e);

        pageService.userData(e.code).then((response) => {
            setAgentData(response);
        })
    };

    // Get crops
    const getCropList = async () => {
        try {
            const cropData: any = [];

            pageService.cropList().then((response) => {
                if (response) {
                    const cropDataList = response;
                    for (let i = 0; i < cropDataList.length; i++) {
                        const cropObj: any = {};
                        cropObj['name'] = cropDataList[i].name;
                        cropObj['code'] = cropDataList[i].id;
                        cropData.push(cropObj);
                    }
                    setCropData(cropData);
                }
            });

        } catch (error) { }
    };

    const getVarietyList = async (id: any, index: number) => {

        pageService.varietyList(id.code).then((response) => {
            // get response
            if (response) {
                const DataList = response;
                if (DataList.length == 0) {
                    setVarietyDropdown([]);
                } else {
                    if (response) {
                        const tempVarietyArr = response;
                        const varietyData: any = [];
                        for (let i = 0; i < tempVarietyArr.length; i++) {
                            const varietyObj: any = {};
                            varietyObj['name'] = tempVarietyArr[i].name;
                            varietyObj['code'] = tempVarietyArr[i].id;
                            varietyData.push(varietyObj);
                        }

                        if (varietyDropdown[index] != undefined) {
                            varietyDropdown.splice(index, 1, varietyData);
                        } else {
                            varietyDropdown.splice(index, 0, varietyData);
                        }
                        setIsUiUpdated(true);
                    }
                }
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });

    }

    const onHandleChangeProductionDetailData = (e: any, index: number, name: string) => {
        let tempArray = productionDetailData;

        if (name == 'stage' || name == 'sowing_date') {
            tempArray[index][name] = e;
        } else if (name == 'crop') {
            if (tempArray[index] == undefined) {
                tempArray.push({
                    crop: e,
                    variety: '',
                    stage: '',
                    farmer_name: '',
                    village: '',
                    taluka: '',
                    district: '',
                    sowing_date: '',
                    area: ''
                });
            } else {
                tempArray[index]["crop"] = e;
            }
            getVarietyList(e, index);
        } else if (name == 'variety') {
            if (tempArray[index] == undefined) {
                tempArray.push({
                    crop: productionDetailData[index]["crop"],
                    variety: e,
                    stage: '',
                    farmer_name: '',
                    village: '',
                    taluka: '',
                    district: '',
                    sowing_date: '',
                    area: ''
                });
            } else {
                tempArray[index]["variety"] = e;
            }
        } else {
            tempArray[index][name] = e.target.value.toUpperCase();
        }
        setProductionDetailData(tempArray);
        setIsUiUpdated(true);
    }

    //handle add more
    const handleAddMore = () => {
        setProductionDetailData([...productionDetailData, {
            crop: '',
            variety: '',
            stage: '',
            farmer_name: '',
            village: '',
            taluka: '',
            district: '',
            sowing_date: '',
            area: ''
        }]);
    }

    //delete dispatch detail
    const deleteAddMore = (index: number) => {
        productionDetailData.splice(index, 1);
        setIsUiUpdated(true);
    }

    //open edit dialog and set data
    const editProduction = async (id: any) => {
        setPageLoad(true)

        await pageService.getProductionDetails(id.id).then((result) => {
            let productionDetails = result.production_detail;

            let productionDetailArray: {
                id: any;
                farmer_name: any;
                stage: any;
                crop: any;
                variety: any;
                taluka: any;
                district: any;
                village: any;
                sowing_date: any;
                area: any;

            }[] = [];

            productionDetails.map((item: any, index: number) => {
                let crop: any = {
                    'name': item.crop_obj?.name,
                    'code': item.crop_obj?.id
                }

                let variety: any = {
                    'name': item.variety_obj?.name,
                    'code': item.variety_obj?.id
                }

                varietyDropdown.push([variety])

                let stage: any = {
                    'name': item.stage,
                    'code': item.stage
                }

                var date = new Date(item.sowing_date);

                productionDetailArray.push({
                    id: item.id,
                    farmer_name: item.farmer_name,
                    stage: stage,
                    crop: crop,
                    variety: variety,
                    taluka: item.taluka,
                    district: item.district,
                    village: item.village,
                    sowing_date: date,
                    area: item.area,
                });
            });

            if (productionDetails && productionDetails.id) {
                setProductionDetailId(productionDetails.id);
            }

            let agent: any = {
                'name': result.organizer.first_name + " " + result.organizer.last_name,
                'code': result.organizer.id
            }

            let season: any = {
                'name': result.season,
                'code': result.season
            }

            // setCropsData(result);
            setSelectedAgent(agent);
            handleChangeAgent(agent)
            setSelectedSeason(season);
            setYear(result.year);
            setProductionDetailData(productionDetailArray);
            setIsUiUpdated(true);
            setPageLoad(false);
        }
        )
    }

    // call delete api
    const deleteProductionDetail = async (id: any, index: any) => {
        setPageLoad(true);
        let deleteProductionArray = productionDetailData;
        deleteProductionArray.splice(index, 1);
        setProductionDetailData(deleteProductionArray);
        setIsUiUpdated(true);

        // request data
        let requestData = {
            id: id,
        };

        // call api
        pageService.deletetProductionDetailById(requestData).then((response) => {
            // get response
            setPageLoad(false);
        });
    };

    // add production data
    const onSubmit = async () => {
        const { errors, isError } = ProductionValidate(selectedAgent, productionDetailData, selectedSeason);
        setErrors(errors);
        setIsError(isError);

        //Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            let formData = new FormData();


            productionDetailData.map((value: any, index: number) => {

                var date = new Date(value["sowing_date"]);
                let sowingDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                value["sowing_date"] = sowingDate
            });

            if (!window.cn(editId) && editId != null) {
                formData.append('id', editId.id);
            }

            if (!window.cn(selectedAgent)) {
                formData.append('organizer', selectedAgent.code);
            }
            formData.append('year', year);
            formData.append('season', selectedSeason?.name);
       
            formData.append('production_detail', JSON.stringify(productionDetailData));

            // Api call
            pageService.addUpdateProduction(formData).then((response) => {
                // Get Response
                setLoading(false);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: response.message,
                });
                navigate('/product/production');
            }).catch(error => {
                setLoading(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.error,
                });
            });
        }
    };

    return (
        <>
            <div className="col-12 add-production">
                <h4> <Button
                    icon="pi pi-arrow-left"
                    className="p-button-secondary mr-2"
                    onClick={() => navigate('/product/production')}
                />{editId !== null ? "Edit Production Details" : "Add Production Details"}</h4>

                <div className="card">
                    <Toast ref={toast} />

                    {editId != null ? <>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-4">
                                <label htmlFor="year">Year</label>
                                <InputText
                                    value={year}
                                    name="year"
                                    autoComplete="off"
                                    readOnly
                                    disabled
                                />
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="season">Season *</label>
                                <InputText
                                    value={selectedSeason?.name}
                                    name="season"
                                    autoComplete="off"
                                    readOnly
                                    disabled
                                />
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="organizer">Agent *</label>
                                <InputText
                                    value={selectedAgent?.name}
                                    name="year"
                                    autoComplete="off"
                                    readOnly
                                    disabled
                                />
                            </div>
                            {!window.cn(agentData) ?
                                <>
                                    <div className="field col-6 flex flex-column">
                                        <p> <b>PAN No.</b> : {!window.cn(agentData) ? agentData?.pan_no : ""}</p>
                                    </div>
                                    <div className="field col-6 flex flex-column">
                                        <p> <b>AADHAR No.</b> : {!window.cn(agentData) ? agentData?.aadhar_no : ""}</p>
                                    </div>
                                    <div className="field col-6 flex flex-column">
                                        <p> <b>Address</b> : {!window.cn(agentData) ? agentData?.residence_address : ""}</p>
                                    </div>
                                    <div className="field col-6 flex flex-column">
                                        <p> <b>Mobile</b> : {!window.cn(agentData) ? agentData?.mobile : ""}</p>
                                    </div>
                                </> : ""}
                        </div> </> : <><div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-4">
                                <label htmlFor="year">Year</label>
                                <InputText
                                    value={year}
                                    name="year"
                                    autoComplete="off"
                                    readOnly
                                    disabled
                                />
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="season">Season *</label>
                                <Dropdown
                                    filter
                                    value={selectedSeason}
                                    onChange={(e) => setSelectedSeason(e.value)}
                                    options={seasonDropdown}
                                    optionLabel="name"
                                    name="season"
                                    placeholder="Select Season"
                                ></Dropdown>
                                <small className="p-invalid-txt">{errors['season']}</small>
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="organizer">Agent *</label>
                                <Dropdown
                                    filter
                                    value={selectedAgent}
                                    onChange={(e) => handleChangeAgent(e.value)}
                                    options={agentList}
                                    optionLabel="name"
                                    name="organizer"
                                    placeholder="Select Name of Organizer/ Agent"
                                ></Dropdown>
                                <small className="p-invalid-txt">{errors['organizer']}</small>
                            </div>
                            {!window.cn(agentData) ?
                                <>
                                    <div className="field col-6 flex flex-column">
                                        <p> <b>PAN No.</b> : {!window.cn(agentData) ? agentData?.pan_no : ""}</p>
                                    </div>
                                    <div className="field col-6 flex flex-column">
                                        <p> <b>AADHAR No.</b> : {!window.cn(agentData) ? agentData?.aadhar_no : ""}</p>
                                    </div>
                                    <div className="field col-6 flex flex-column">
                                        <p> <b>Address</b> : {!window.cn(agentData) ? agentData?.residence_address : ""}</p>
                                    </div>
                                    <div className="field col-6 flex flex-column">
                                        <p> <b>Mobile</b> : {!window.cn(agentData) ? agentData?.mobile : ""}</p>
                                    </div>
                                </> : ""}
                        </div> </>}

                    <hr />
                    {productionDetailData.map((item: any, index: number) => (
                        <>
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-2">
                                    <label htmlFor="farmer_name">Farmer Name *</label>
                                        <InputText
                                            value={!window.cn(productionDetailData[index]["farmer_name"]) ? productionDetailData[index]["farmer_name"] : ""}
                                            name="farmer_name"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeProductionDetailData(e, index, "farmer_name")}
                                            disabled={editId != null && !window.cn(productionDetailData[index]["id"]) ? true : false}
                                        />                                     
                                    <small className="p-invalid-txt">{errors['farmer_name']}</small>
                                </div>
                                <div className="field col-12 md:col-2">
                                    <label htmlFor="district">District *</label>
                                    <InputText
                                        value={!window.cn(productionDetailData[index]["district"]) ? productionDetailData[index]["district"] : ""}
                                        name="district"
                                        autoComplete="off"
                                        onChange={(e) => onHandleChangeProductionDetailData(e, index, "district")}
                                        disabled={editId != null && !window.cn(productionDetailData[index]["id"]) ? true : false}
                                    />
                                    <small className="p-invalid-txt">{errors['district']}</small>
                                </div>
                                <div className="field col-12 md:col-2">
                                    <label htmlFor="taluka">Talulka *</label>
                                    <InputText
                                        value={!window.cn(productionDetailData[index]["taluka"]) ? productionDetailData[index]["taluka"] : ""}
                                        name="taluka"
                                        autoComplete="off"
                                        onChange={(e) => onHandleChangeProductionDetailData(e, index, "taluka")}
                                        disabled={editId != null && !window.cn(productionDetailData[index]["id"]) ? true : false}
                                    />
                                    <small className="p-invalid-txt">{errors['taluka']}</small>
                                </div>
                                <div className="field col-12 md:col-2">
                                    <label htmlFor="village">Village *</label>
                                    <InputText
                                        value={!window.cn(productionDetailData[index]["village"]) ? productionDetailData[index]["village"] : ""}
                                        name="village"
                                        autoComplete="off"
                                        onChange={(e) => onHandleChangeProductionDetailData(e, index, "village")}
                                        disabled={editId != null && !window.cn(productionDetailData[index]["id"]) ? true : false}
                                    />
                                    <small className="p-invalid-txt">{errors['village']}</small>
                                </div>

                                {editId != null && !window.cn(productionDetailData[index]["id"]) ? <>
                                    <div className="field col-12 md:col-2">
                                        <label htmlFor="crop">Crop *</label>
                                        <InputText
                                            value={productionDetailData[index]["crop"]["name"]}
                                            name="crop"
                                            autoComplete="off"
                                            readOnly
                                            disabled
                                        />
                                    </div>
                                    <div className="field col-12 md:col-2">
                                        <label htmlFor="variety">Variety *</label>
                                        <InputText
                                            value={productionDetailData[index]["variety"]["name"]}
                                            name="variety"
                                            autoComplete="off"
                                            readOnly
                                            disabled
                                        />
                                        <small className="p-invalid-txt">{errors['variety']}</small>
                                    </div> </> : <>
                                    <div className="field col-12 md:col-2">
                                        <label htmlFor="crop">Crop *</label>
                                        <Dropdown
                                            filter
                                            value={productionDetailData[index]["crop"]}
                                            onChange={(e) => onHandleChangeProductionDetailData(e.value, index, 'crop')}
                                            options={cropData}
                                            optionLabel="name"
                                            name="crop"
                                            placeholder="Select Crop"
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['crop']}</small>
                                    </div>
                                    <div className="field col-12 md:col-2">
                                        <label htmlFor="variety">Variety *</label>
                                        <Dropdown
                                            filter
                                            value={productionDetailData[index]["variety"]}
                                            onChange={(e) => onHandleChangeProductionDetailData(e.value, index, 'variety')}
                                            options={varietyDropdown[index]}
                                            optionLabel="name"
                                            name="variety"
                                            placeholder="Select Variety"
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['variety']}</small>
                                    </div>
                                </>}

                                <div className="field col-12 md:col-2">
                                    <label htmlFor="stage">Class *</label>
                                    <Dropdown
                                        id="stage"
                                        filter
                                        value={productionDetailData[index]["stage"]}
                                        onChange={(e) => onHandleChangeProductionDetailData(e.value, index, 'stage')}
                                        options={cropDivisionDropdownItems}
                                        optionLabel="name"
                                        placeholder="Select One"
                                        name="stage"
                                        className='dispatch-drp'
                                    ></Dropdown>
                                    <small className="p-invalid-txt">{errors['stage']}</small>
                                </div>
                                <div className="field col-12 md:col-2">
                                    <label htmlFor="sowing_date">Sowing Date *</label>
                                    <Calendar
                                        value={!window.cn(productionDetailData[index]["sowing_date"]) ? productionDetailData[index]["sowing_date"] : ""}
                                        dateFormat="dd/mm/yy"
                                        onChange={(e) => onHandleChangeProductionDetailData(e.value, index, 'sowing_date')}
                                        name="sowing_date"
                                        showIcon
                                    />
                                    <small className="p-invalid-txt">{errors['sowing_date']}</small>
                                </div>
                                <div className="field col-12 md:col-2">
                                    <label htmlFor="area">Area (in acres) *</label>
                                    <InputText
                                        keyfilter="num"
                                        value={!window.cn(productionDetailData[index]["area"]) ? productionDetailData[index]["area"] : ""}
                                        name="area"
                                        autoComplete="off"
                                        onChange={(e) => onHandleChangeProductionDetailData(e, index, "area")}
                                    />
                                    <small className="p-invalid-txt">{errors['area']}</small>
                                </div>
                            

                            {index > 0 ?
                                <div className="field col-12 md:col-2">
                                    {item.id != null ? (
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-rounded p-button-danger mt-2"
                                            onClick={() => deleteProductionDetail(item.id, index)}
                                        />
                                    ) : (
                                        <Button
                                            icon="pi pi-trash"
                                            className="p-button-rounded p-button-danger mt-2"
                                            onClick={() => deleteAddMore(index)} />
                                    )}
                                </div>
                                : ""
                            }
                            </div>
                            <hr />
                        </>
                    ))}

                    <div className="field col-12 md:col-12">
                        <Button
                            style={{ width: 'fit-content' }}
                            label="Add More"
                            icon="pi pi-plus"
                            className="p-button"
                            onClick={handleAddMore}
                        />
                    </div>

                    <div className="card-footer">
                        <div className="button-group">
                            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary mr-4" onClick={() => navigate('/product/production')} />
                            <Button label="Save" icon="pi pi-check" loading={loading} onClick={onSubmit} />
                        </div>
                    </div>
                </div >
            </div>

            {
                pageLoad && <Loader />
            }
        </>
    )
}
