import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';

import moment from "moment/moment";

// Column
import { LeaveColumns } from '../../appconfig/DatatableSetting';

// Data table
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

export const LeaveManagement = () => {
  document.title = 'Leave Management | Swarnim Farms';

  //Leave Crud Object
  const [leaveList, setLeaveList] = useState<any>([]);

  // Approve / Reject Leave Model/ Dialog
  const [approveConfirmModal, setApproveConfirmModal] = useState(false);

  // Approve / Reject Leave Data Details
  const [approveId, setApproveId] = useState(null);
  const [approveStatus, setApproveStatus] = useState(null);

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31) ), new Date()]);

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [loading, setLoading] = useState<boolean>(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [status, setStatus] = useState<any>({ name: 'Leave Status : All', code: 'all' });

  const [leaveType, setLeaveType] = useState<any>({ name: 'Leave Type : All', code: 'all' });

  // drop down status
  const statusDropdownItems = [
    { name: 'Leave Status : All', code: 'all' },
    { name: 'Approved', code: 'Approved' },
    { name: 'Pending', code: 'Pending' },
    { name: 'Rejected', code: 'Rejected' }
  ];

    // drop down status
    const leaveTypeDropdwonItems = [
      { name: 'Leave Type : All', code: 'all' },
      { name: 'Full', code: 'Full' },
      { name: 'Half', code: 'Half' },
      { name: 'Custom', code: 'Custom' }
    ];
  // use effect method
  useEffect(() => {
    getLeaveDataFromAPI();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [dates]);

  useEffect(() => {
    if (status) {
      getLeaveDataFromAPI();
    }
  }, [status]);

  useEffect(() => {
    if (leaveType) {
      getLeaveDataFromAPI();
    }
  }, [leaveType]);

  // Get Leave Data from API
  const getLeaveDataFromAPI = async () => {
    setPageLoad(false);
    // Api call
    pageService
      .getLeaveManagementData(!window.cn(dates) && dates[0], !window.cn(dates) && dates[1],status.code, leaveType.code)
      .then((response) => {
        // Get response
        if (response) {
          const DataList = response;
          if (DataList.length == 0) {
            setLeaveList([]);
          } else {
            setLeaveList(DataList);
          }
          setPageLoad(true);
        } else {
          setPageLoad(false);
          setLeaveList([]);
        }
      });
  };

  // On Date Change
  const onDateChange = (e: any) => {
    setDates(e.value);
  };

  // approve the leave by id
  const approveLeave = async () => {
    setSubmitLoading(true);

    // request data
    const requestData = {
      id: approveId,
      status: 'Approved',
    };

    // call api
    pageService.approveLeaveData(requestData).then((response) => {
      // Get response
      if (response) {
        setSubmitLoading(false);
        setLoading(true);
        setApproveConfirmModal(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Leave Approved Successfully.',
        });
        getLeaveDataFromAPI();
      } else {
        setSubmitLoading(false);
        setApproveConfirmModal(true);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  };

  // reject the leave by id
  const rejectLeave = async () => {
    setPageLoad(true);

    // request data
    const requestData = {
      id: approveId,
      status: 'Rejected',
    };

    // call api
    pageService.rejectLeaveData(requestData).then((response) => {
      // Get response
      if (response) {
        setPageLoad(false);
        setApproveConfirmModal(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Leave Rejected Successfully.',
        });
        // get leave data
        getLeaveDataFromAPI();
      } else {
        setPageLoad(false);
        setApproveConfirmModal(true);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  };

  //Approve Data
  const approveData = (approveId: any, e: any) => {
    setApproveId(approveId);
    setApproveStatus(e.target.checked);
    setApproveConfirmModal(true);
  };

  //Approve Modal close
  const hideApproveConfirmModal = () => {
    setApproveId(null);
    setApproveStatus(null);
    setApproveConfirmModal(false);
  };

  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // right part of toolbar
  const rightToolbarTemplate = () => {
    return (
      <>
       <Dropdown
          value={leaveType}
          onChange={(e) => setLeaveType(e.value)}
          options={leaveTypeDropdwonItems}
          optionLabel="name"
          placeholder="Type"
        ></Dropdown>
        <div style={{ marginLeft:'15px'}}></div>

       <Dropdown
          value={status}
          onChange={(e) => setStatus(e.value)}
          options={statusDropdownItems}
          optionLabel="name"
          placeholder="Status"
        ></Dropdown>
        <div style={{ marginLeft:'15px'}}></div>
        <Calendar
          value={dates}
          dateFormat="dd/mm/yy"
          onChange={(e) => onDateChange(e)}
          selectionMode="range"
          showIcon
        />
      </>
    );
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    let statusClass = rowData.status === "Approved" ? "status status-active" : "status status-inactive";
    return (
      <>
        <div style={{display:"flex",flexDirection:"column"}}>
        <InputSwitch 
          checked={rowData.status === 'Approved' ? true : false}
          onChange={(e) => approveData(rowData.id, e)}
          disabled={rowData.status === 'Approved' || rowData.status === 'Rejected' ? true : false}
        />
        <span className={statusClass}>{rowData.status === "Approved" ? "Approved" :rowData.status  }</span>
        </div>
      </>
    );
  };

  //Start Date Time Conversion Template
  const dateFormatStartTimeTemplate = (rowData: any, rowIndex: any) => {
    return (
        <>
            {rowData.from_date === rowData[rowIndex.field] && rowData.from_date !== null ? moment.utc(rowData.from_date).format('MMM DD, YYYY')  : rowData[rowIndex.field]}
        </>
    );
  };

  //End Date Time Conversion Template
  const dateFormatEndTimeTemplate = (rowData: any, rowIndex: any) => {
    return (
        <>
            {rowData.to_date === rowData[rowIndex.field] && rowData.to_date !== null ? moment.utc(rowData.to_date).format('MMM DD, YYYY')  : '-'}
        </>
    );
  };

  return (
    <>
      <div className="grid crud-demo main-div-sec">
        <div className="col-12">
          <div className="card">
          <h4> Leave List </h4>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            {/* Datatable Start*/}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive"
                  value={leaveList}
                  paginator={leaveList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Leave Data Found"
                >
                  {LeaveColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column   
                          key={col.field} 
                          header={col.header} 
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else if (col.field === 'from_date') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={dateFormatStartTimeTemplate}
                          sortable
                          filter
                        />
                      );
                    }else if (col.field === 'to_date') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={dateFormatEndTimeTemplate}
                          sortable
                          filter
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {LeaveColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
            {/* Datatable End*/}
          </div>
        </div>
      </div>

      {/* Approve / Reject Confirmation Modal */}
      <Dialog
        visible={approveConfirmModal}
        style={{ width: '450px' }}
        header="Approve / Reject Leave"
        modal
        footer={
          <>
            <Button
              label="Reject"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={rejectLeave}
            />
            <Button
              label="Approve"
              icon="pi pi-check"
              className="p-button-success"
              onClick={approveLeave}
              loading={submitLoading}
            />
          </>
        }
        onHide={hideApproveConfirmModal}
      >
        <div className="flex align-items-center justify-content-start">
          <i
            className="pi pi-exclamation-triangle mr-3 delete-triangle"
            style={{ fontSize: '2rem' }}
          />
          <span className="delete-dialog-note">
            Note: Once you Approve, This will count as accepted.
          </span>
        </div>
      </Dialog>
      {/* Approve / Reject Confirmation Modal End */}
    </>
  );
};
