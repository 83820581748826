import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Password } from 'primereact/password';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';

//Buffer Storage
import { Buffer } from 'buffer';

//File Uplaod Components
import { chooseOptions, emptyTemplate, headerTemplate } from '../../components/ImageUploadComponent/ImageUploadSetting';

// Validatetion
import { DealerDataValidate } from '../../config/Validate';

//Services
import PageService from '../../service/PageService';
import { Dropdown } from 'primereact/dropdown';
import { Loader } from '../../components/Loader/Loader';
import { dealerFirmTypeOptions } from '../../appconfig/Settings';

export const AddDealer = () => {
    document.title = 'Dealer | Swarnim Farms';

    // Page service
    const pageService = new PageService();

    //Navigate Another Route
    const navigate = useNavigate();
    const location = useLocation();

    const toast = useRef<any>(null);
    const [dealerData, setDealerData] = useState<any>({});

    // Edit and Delete Data Details
    const [editId, setEditId] = useState<any>(null);
    const [bankEditId, setBankEditId] = useState<any>(null);

    // File Upload Details
    const fileUploadRef = useRef(null);
    const removeFile = useRef(null);

    // adhar and pan image object
    const [licenseImage, setLicenseImage] = useState<any>();
    const [gstImage, setGstImage] = useState<any>();
    const [panImage, setPanImage] = useState<any>();
    const [aadharImage, setAadharImage] = useState<any>();
    const [profileImage, setProfileImage] = useState<any>();

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    const [stateData, setStateData] = useState<any>([]);
    const [selectedStateId, setSelectedStateId] = useState<any>();
    const [selectedCityId, setSelectedCityId] = useState<any>();
    const [cityData, setCityData] = useState<any>([]);

    const [firmType, setFirmType] = useState<any>();

    //Loading
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [pageLoad, setPageLoad] = useState(false);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setEditId(state);
            getDealerDataById(state);
        }
        getStateDataFromAPI();
    }, []);

    // for remove profile image
    const onTemplateRemoveProile = (callback: any) => {
        setProfileImage({});
        callback();
    };

    // for remove license image
    const onTemplateRemoveLicense = (callback: any) => {
        setLicenseImage({});
        callback();
    };

    // for remove image pan
    const onTemplateRemovePan = (callback: any) => {
        setPanImage({});
        callback();
    };

    // for remove image pan
    const onTemplateRemoveAadhar = (callback: any) => {
        setAadharImage({});
        callback();
    };

    // for remove gst image
    const onTemplateRemoveGst = (callback: any) => {
        setGstImage({});
        callback();
    };

    // for upload image adhar
    const itemProfileTemplate = (file: any, props: any) => {
        setProfileImage(file);
        removeFile.current = props.onRemove;
        return (
            <>
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        <img
                            alt={file.name}
                            role="presentation"
                            src={file.objectURL}
                            width={100}
                        />
                        <span className="flex flex-column text-left ml-3">{file.name}</span>
                        <Button
                            type="button"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                            onClick={() => onTemplateRemoveProile(props.onRemove)}
                        />
                    </div>
                </div>
            </>
        );
    };

    // for upload image adhar
    const itemLicenseTemplate = (file: any, props: any) => {
        setLicenseImage(file);
        removeFile.current = props.onRemove;
        return (
            <>
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        {!window.cn(file) && file.objectURL ?
                            <img
                                alt={file.name}
                                role="presentation"
                                src={file.objectURL}
                                width={100}
                            />
                            : <img
                                alt={file.name}
                                role="presentation"
                                src="/assets/images/pdf-1.png"
                                width={100}
                            />}

                        <span className="flex flex-column text-left ml-3">{file.name}</span>
                        <Button
                            type="button"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                            onClick={() => onTemplateRemoveLicense(props.onRemove)}
                        />
                    </div>
                </div>
            </>
        );
    };

    // for upload image pan
    const itemPanTemplate = (file: any, props: any) => {
        setPanImage(file);
        removeFile.current = props.onRemove;
        return (
            <>
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        {!window.cn(file) && file.objectURL ?
                            <img
                                alt={file.name}
                                role="presentation"
                                src={file.objectURL}
                                width={100}
                            />
                            : <img
                                alt={file.name}
                                role="presentation"
                                src="/assets/images/pdf-1.png"
                                width={100}
                            />}

                        <span className="flex flex-column text-left ml-3">{file.name}</span>
                        <Button
                            type="button"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                            onClick={() => onTemplateRemovePan(props.onRemove)}
                        />
                    </div>
                </div>
            </>
        );
    };

    // for upload image aadhar
    const itemAadharTemplate = (file: any, props: any) => {
        setAadharImage(file);
        removeFile.current = props.onRemove;
        return (
            <>
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        {!window.cn(file) && file.objectURL ?
                            <img
                                alt={file.name}
                                role="presentation"
                                src={file.objectURL}
                                width={100}
                            />
                            : <img
                                alt={file.name}
                                role="presentation"
                                src="/assets/images/pdf-1.png"
                                width={100}
                            />}

                        <span className="flex flex-column text-left ml-3">{file.name}</span>
                        <Button
                            type="button"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                            onClick={() => onTemplateRemoveAadhar(props.onRemove)}
                        />
                    </div>
                </div>
            </>
        );
    };

    // for upload image pan
    const itemGstTemplate = (file: any, props: any) => {
        setGstImage(file);
        removeFile.current = props.onRemove;
        return (
            <>
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        {!window.cn(file) && file.objectURL ?
                            <img
                                alt={file.name}
                                role="presentation"
                                src={file.objectURL}
                                width={100}
                            />
                            : <img
                                alt={file.name}
                                role="presentation"
                                src="/assets/images/pdf-1.png"
                                width={100}
                            />
                        }

                        <span className="flex flex-column text-left ml-3">{file.name}</span>
                        <Button
                            type="button"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                            onClick={() => onTemplateRemoveGst(props.onRemove)}
                        />
                    </div>
                </div>
            </>
        );
    };

    //onChange Dealer Data
    const onInputChange = (e: any, name: string) => {
        let val;
        if (name == 'valid_upto') {
            val = e || '';
        } else if (name == 'email') {
            val = (e.target && e.target.value) || '';
        } else {
            val = (e.target && e.target.value) || '';
            val = val.toUpperCase();
        }
        setDealerData({ ...dealerData, [name]: val });
    };

    // Handle Select state
    const handleState = (value: any) => {
        if (!window.cn(value)) {
            setSelectedStateId(value);
            getCityDataFromAPI(value);
        } else {
            setSelectedStateId('');
            setCityData([]);
        }
    };

    const getDealerDataById = async (id: any) => {
        setPageLoad(true);
        setEditId(id.id);

        pageService.getDealerDataById(id.id).then((result) => {

            let dealerData = result;
            let bankDetails = dealerData.payment_info;

            if (bankDetails && bankDetails.id) {
                setBankEditId(bankDetails.id);
            } else {
                setBankEditId(null);
            }

            var date = new Date(dealerData.valid_upto);

            // set data in edit form
            setDealerData({
                firm_name: dealerData && dealerData.firm_name ? dealerData.firm_name : '',
                full_name: dealerData && dealerData.full_name ? dealerData.full_name : '',
                email: dealerData && dealerData.email ? dealerData.email : '',
                mobile: dealerData && dealerData.mobile ? dealerData.mobile : '',
                alt_mobile_no: dealerData && dealerData.alt_mobile_no ? dealerData.alt_mobile_no : '',
                pan_no: dealerData && dealerData.pan_no ? dealerData.pan_no : '',
                aadhar_no: dealerData && dealerData.aadhar_no ? dealerData.aadhar_no : '',
                gst_no: dealerData && dealerData.gst_no ? dealerData.gst_no : '',
                license_no: dealerData && dealerData.license_no ? dealerData.license_no : '',
                valid_upto: dealerData && dealerData.valid_upto ? date : '',
                taluka: dealerData && dealerData.taluka ? dealerData.taluka : '',
                district: dealerData && dealerData.district ? dealerData.district : '',
                pincode: dealerData && dealerData.pincode ? dealerData.pincode : '',
                business_address: dealerData && dealerData.business_address ? dealerData.business_address : '',
                residence_address: dealerData && dealerData.residence_address ? dealerData.residence_address : '',
                account_no: bankDetails && bankDetails.account_no ? bankDetails.account_no : '',
                ifsc_code: bankDetails && bankDetails.ifsc_code ? bankDetails.ifsc_code : '',
                bank_name: bankDetails && bankDetails.bank_name ? bankDetails.bank_name : '',
            });

            let state: any = {
                'name': dealerData?.state?.name,
                'code': dealerData?.state?.id
            }

            let city: any = {
                'name': dealerData?.city?.name,
                'code': dealerData?.city?.id
            }

            let firm_type: any = {
                'name': dealerData.firm_type,
                'code': dealerData.firm_type
            }

            setFirmType(firm_type);
            setSelectedStateId(state);
            getCityDataFromAPI(state);
            setSelectedCityId(city);
            setLicenseImage(dealerData.seed_license_copy);
            setPanImage(dealerData.pancard);
            setAadharImage(dealerData.aadharcard);
            setProfileImage(dealerData.photo);
            setGstImage(dealerData.gstin_reg_copy);
            setPageLoad(false);
        }).catch(error => {
            if (error) {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: error.error });
            }
        });
    }

    // Get States
    const getStateDataFromAPI = async () => {
        try {
            const stateData: any = [];

            pageService.getState().then((response) => {
                if (response) {
                    const stateDataList = response;
                    for (let i = 0; i < stateDataList.length; i++) {
                        const userObj: any = {};
                        userObj['name'] = stateDataList[i].name;
                        userObj['code'] = stateDataList[i].id;
                        stateData.push(userObj);
                    }
                    setStateData(stateData);
                }
            });

        } catch (error) { }
    };

    //Get City
    const getCityDataFromAPI = async (selectedStateId: any) => {
        try {
            let stateId = !window.cn(selectedStateId) ? selectedStateId.code : '';

            const cityData: any = [];

            pageService.getCity(stateId).then((response) => {
                if (response) {
                    const cityDataList = response;

                    for (let i = 0; i < cityDataList.length; i++) {
                        const userObj: any = {};
                        userObj['name'] = cityDataList[i].name;
                        userObj['code'] = cityDataList[i].id;
                        cityData.push(userObj);
                    }
                    setCityData(cityData);
                }
            });
        } catch (error) { }
    };

    // for adding dealer data
    const onSubmit = async () => {

        const { errors, isError } = DealerDataValidate(dealerData, panImage, gstImage, licenseImage, profileImage, selectedStateId, selectedCityId, editId);
        setErrors(errors);

        //Check Error if no errors then call API
        if (!isError) {
            setSubmitLoading(true);

            // let apiUrl;
            let isEdit = false;
            let bank_details = {};

            if (editId !== undefined && editId !== null) {
                isEdit = true;

                // bank object on the basis of condition
                if (bankEditId) {
                    bank_details = {
                        id: bankEditId,
                        account_no: dealerData.account_no,
                        ifsc_code: dealerData.ifsc_code,
                        bank_name: dealerData.bank_name,
                    };
                } else {
                    bank_details = {
                        account_no: dealerData.account_no,
                        ifsc_code: dealerData.ifsc_code,
                        bank_name: dealerData.bank_name,
                    };
                }
            } else {
                isEdit = false;
                bank_details = {
                    account_no: dealerData.account_no,
                    ifsc_code: dealerData.ifsc_code,
                    bank_name: dealerData.bank_name,
                };
            }

            //Request object
            let formRequestData = new FormData();

            formRequestData.append('full_name', !window.cn(dealerData) && dealerData.full_name ? dealerData.full_name : '');

            formRequestData.append('email', !window.cn(dealerData) && dealerData.email ? dealerData.email : '');

            formRequestData.append('firm_name', !window.cn(dealerData) && dealerData.firm_name ? dealerData.firm_name : '');
            if (!window.cn(firmType) && firmType.code) {
                formRequestData.append('firm_type', firmType?.code);
            }
            formRequestData.append('mobile', !window.cn(dealerData) && dealerData.mobile ? dealerData.mobile : '');
            formRequestData.append('alt_mobile_no', !window.cn(dealerData) && dealerData.alt_mobile_no ? dealerData.alt_mobile_no : '');
            formRequestData.append('business_address', !window.cn(dealerData) && dealerData.business_address ? dealerData.business_address : '');
            formRequestData.append('residence_address', !window.cn(dealerData) && dealerData.residence_address ? dealerData.residence_address : '');
            if (!window.cn(selectedCityId) && selectedCityId.code) {
                formRequestData.append('city', selectedCityId?.code);
            }
            if (!window.cn(selectedStateId) && selectedStateId.code) {
                formRequestData.append('state', selectedStateId?.code);
            }
            formRequestData.append('taluka', !window.cn(dealerData) && dealerData.taluka ? dealerData.taluka : '');
            formRequestData.append('district', !window.cn(dealerData) && dealerData.district ? dealerData.district : '');
            formRequestData.append('pincode', !window.cn(dealerData) && dealerData.pincode ? dealerData.pincode : '');
            formRequestData.append('pan_no', !window.cn(dealerData) && dealerData.pan_no ? dealerData.pan_no : '');
            formRequestData.append('aadhar_no', !window.cn(dealerData) && dealerData.aadhar_no ? dealerData.aadhar_no : '');
            formRequestData.append('license_no', !window.cn(dealerData) && dealerData.license_no ? dealerData.license_no : '');
            formRequestData.append('gst_no', !window.cn(dealerData) && dealerData.gst_no ? dealerData.gst_no : '');

            if (!window.cn(dealerData) && dealerData.valid_upto) {
                var date = new Date(dealerData.valid_upto);
                let validity = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                formRequestData.append('valid_upto', validity);
            }
            formRequestData.append('bank_details', JSON.stringify(bank_details));

            // set image if it is uploaded
            if (licenseImage && licenseImage.name) {
                formRequestData.append('seed_license_copy', licenseImage);
            }

            // set image if it is uploaded
            if (gstImage && gstImage.name) {
                formRequestData.append('gstin_reg_copy', gstImage);
            }

            // set image if it is uploaded
            if (panImage && panImage.name) {
                formRequestData.append('pancard', panImage);
            }

            if (aadharImage && aadharImage.name) {
                formRequestData.append('aadharcard', aadharImage);
            }

            // set image if it is uploaded
            if (profileImage && profileImage.name) {
                formRequestData.append('photo', profileImage);
            }

            if (editId !== undefined && editId !== null) {
                formRequestData.append('id', editId);
            }

            // api call
            if (isEdit) {
                // call edit api
                pageService.editDealerData(formRequestData).then((result) => {
                    setDealerData({ dealerData });
                    setErrors(false);
                    setSelectedStateId({});
                    setSelectedCityId("");
                    setCityData([]);
                    navigate('/user/dealer/list');
                    toast.current?.show({ severity: 'success', summary: 'Success', detail: result });
                    setSubmitLoading(false);
                }).catch(error => {
                    if (error) {
                        setDealerData(dealerData);
                        setSelectedStateId({});
                        setErrors(false);
                        toast.current?.show({ severity: 'error', summary: 'Error', detail: error.error });
                        setSubmitLoading(false);
                    }
                });
            } else {
                // call add api
                pageService.addDealerData(formRequestData).then((result) => {
                    setDealerData({ dealerData });
                    setSelectedStateId({});
                    setSelectedCityId("");
                    setCityData([]);
                    setErrors(false);
                    navigate('/user/dealer/list');
                    toast.current?.show({ severity: 'success', summary: 'Success', detail: result });
                    setSubmitLoading(false);
                }).catch(error => {
                    if (error) {
                        setDealerData({ dealerData });
                        setSelectedStateId({});
                        setErrors(false);
                        setSubmitLoading(false);
                        toast.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.error });
                    }
                });
            }
        }
    };

    return (
        <>
            <div className="col-12">
                <h4 className='flex mb-4'> <Button
                    //label="Back"
                    icon="pi pi-arrow-left"
                    className="p-button-secondary mr-2"
                    onClick={() => navigate('/user/dealer/list')}
                />{editId !== null ? "Edit Dealer Details" : " Add Dealer Details"}</h4>


                <div className="card">
                    <Toast ref={toast} />
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="firm_name">Firm Name</label>
                            <InputText
                                value={dealerData.firm_name}
                                name="firm_name"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'firm_name')}
                                className={errors['firm_name'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['firm_name']}</small>
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="firm_type">Firm Type</label>
                            <Dropdown
                                filter
                                value={firmType}
                                onChange={(e) => setFirmType(e.value)}
                                options={dealerFirmTypeOptions}
                                optionLabel="name"
                                name="firm_type"
                                placeholder="Select Firm Type"
                            ></Dropdown>
                            <small className="p-invalid-txt">{errors['firm_type']}</small>
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="name">Full Name</label>
                            <InputText
                                value={dealerData.full_name}
                                name="full_name"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'full_name')}
                                className={errors['full_name'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['full_name']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="email">Email </label>
                            <InputText
                                value={dealerData?.email}
                                name="email"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'email')}
                                className={errors['email'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['email']}</small>
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="mobile">Mobile No.</label>
                            <InputText
                                value={dealerData.mobile}
                                name="mobile"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'mobile')}
                                className={errors['mobile'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['mobile']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="alt_mobile_no">Alternative Mobile No. </label>
                            <InputText
                                value={dealerData.alt_mobile_no}
                                name="alt_mobile_no"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'alt_mobile_no')}
                                className={errors['alt_mobile_no'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['alt_mobile_no']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="business_address">Business Address</label>
                            <InputTextarea
                                value={dealerData.business_address}
                                name="business_address"
                                onChange={(e) => onInputChange(e, 'business_address')}
                                className={errors['business_address'] && 'p-invalid'}
                                rows={4}
                            />
                            <small className="p-invalid-txt">{errors['business_address']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="residence_address">Residence Address</label>
                            <InputTextarea
                                value={dealerData.residence_address}
                                name="residence_address"
                                onChange={(e) => onInputChange(e, 'residence_address')}
                                className={errors['residence_address'] && 'p-invalid'}
                                rows={4}
                            />
                            <small className="p-invalid-txt">{errors['residence_address']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="state">State</label>
                            <Dropdown
                                filter
                                value={selectedStateId}
                                onChange={(e) => handleState(e.value)}
                                options={stateData}
                                optionLabel="name"
                                name="state"
                                placeholder="Select State"
                            ></Dropdown>
                            <small className="p-invalid-txt">{errors['state']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="city">City</label>
                            <Dropdown
                                filter
                                name="city"
                                value={selectedCityId}
                                onChange={(e) => setSelectedCityId(e.value)}
                                options={cityData}
                                optionLabel="name"
                                placeholder="Select City"
                            ></Dropdown>
                            <small className="p-invalid-txt">{errors['city']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="district">District</label>
                            <InputText
                                value={dealerData.district}
                                name="district"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'district')}
                                className={errors['district'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['district']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="taluka">Taluka</label>
                            <InputText
                                value={dealerData.taluka}
                                name="taluka"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'taluka')}
                                className={errors['taluka'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['taluka']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="pincode">Pincode</label>
                            <InputText
                                keyfilter="num"
                                value={dealerData.pincode}
                                name="pincode"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'pincode')}
                                className={errors['pincode'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['pincode']}</small>
                        </div>


                        <div className="field col-12">
                            <h4 className='mb-0'>Registration Particulars</h4>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="gst_no">GSTIN No.</label>
                            <InputText
                                value={dealerData.gst_no}
                                name="gst_no"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'gst_no')}
                                className={errors['gst_no'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['gst_no']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="pan_no">PAN No.</label>
                            <InputText
                                value={dealerData.pan_no}
                                name="pan_no"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'pan_no')}
                                className={errors['pan_no'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['pan_no']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="aadhar_no">AADHAR No.</label>
                            <InputText
                                keyfilter="num"
                                value={dealerData.aadhar_no}
                                name="aadhar_no"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'aadhar_no')}
                                className={errors['aadhar_no'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['aadhar_no']}</small>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="license_no">Seed License No.</label>
                            <InputText
                                value={dealerData.license_no}
                                name="license_no"
                                autoComplete="off"
                                onChange={(e) => onInputChange(e, 'license_no')}
                                className={errors['license_no'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['license_no']}</small>
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="valid_upto">License Valid Upto</label>
                            <Calendar
                                value={!window.cn(dealerData) ? dealerData.valid_upto : ""}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => onInputChange(e.value, 'valid_upto')}
                                name="valid_upto"
                                showIcon
                            />
                            <small className="p-invalid-txt">{errors['valid_upto']}</small>
                        </div>

                        <div className="field col-12">
                            <h4 className='mb-0'>Bank Details</h4>
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="account_no">Account No</label>
                            <InputText
                                value={dealerData.account_no}
                                name="account_no"
                                onChange={(e) => onInputChange(e, 'account_no')}
                                className={errors['account_no'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['account_no']}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="bank_name">Bank Name</label>
                            <InputText
                                value={dealerData.bank_name}
                                name="bank_name"
                                onChange={(e) => onInputChange(e, 'bank_name')}
                                className={errors['bank_name'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['bank_name']}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="ifsc_code">IFSC Code</label>
                            <InputText
                                value={dealerData.ifsc_code}
                                name="ifsc_code"
                                onChange={(e) => onInputChange(e, 'ifsc_code')}
                                className={errors['ifsc_code'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['ifsc_code']}</small>
                        </div>

                        <div className="field col-12">
                            <h4 className='mb-0'>Documents</h4>
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="photo">Profile Picture</label>
                            <FileUpload
                                ref={fileUploadRef}
                                accept="image/*"
                                name="photo[]"
                                className="imageupload"
                                chooseOptions={chooseOptions}
                                emptyTemplate={emptyTemplate}
                                headerTemplate={headerTemplate}
                                itemTemplate={itemProfileTemplate}
                            ></FileUpload>
                            <small className="p-invalid-txt">{errors['photo']}</small>
                        </div>

                        {editId != null && (
                            <>
                                <div className="field col-12 md:col-4 editImage">
                                    {typeof (profileImage) === 'string' ? (
                                        <>  <label htmlFor="photo">Old Image</label>
                                            <img src={profileImage}
                                                onClick={() => window.open(profileImage, '_blank')}></img></>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </>
                        )}

                        <div className="field col-12 md:col-6">
                            <label htmlFor="seed_license_copy">Seed License Copy</label>
                            <FileUpload
                                ref={fileUploadRef}
                                accept="application/pdf, image/*"
                                name="seed_license_copy[]"
                                className="imageupload"
                                chooseOptions={chooseOptions}
                                emptyTemplate={emptyTemplate}
                                headerTemplate={headerTemplate}
                                itemTemplate={itemLicenseTemplate}
                            ></FileUpload>
                            <small className="p-invalid-txt">{errors['seed_license_copy']}</small>
                        </div>

                        {editId != null && (
                            <>
                                <div className="field col-12 md:col-4 editImage">
                                    {typeof (licenseImage) === 'string' ? (
                                        <>  <label htmlFor="photo">Old Image</label>
                                            {licenseImage.split('.').pop() == 'pdf' ?
                                                <img src="/assets/images/pdf-1.png"
                                                    onClick={() => window.open(licenseImage, '_blank')} width={50} height={50}></img> :
                                                <img src={licenseImage}
                                                    onClick={() => window.open(licenseImage, '_blank')}></img>
                                            }</>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </>
                        )}

                        <div className="field col-12 md:col-6">
                            <label htmlFor="pancard">Pancard</label>
                            <FileUpload
                                ref={fileUploadRef}
                                accept="application/pdf, image/*"
                                name="pancard[]"
                                className="imageupload"
                                chooseOptions={chooseOptions}
                                emptyTemplate={emptyTemplate}
                                headerTemplate={headerTemplate}
                                itemTemplate={itemPanTemplate}
                            ></FileUpload>
                            <small className="p-invalid-txt">{errors['pancard']}</small>
                        </div>

                        {editId != null && (
                            <>
                                <div className="field col-12 md:col-4 editImage">
                                    {typeof (panImage) === 'string' ? (
                                        <>  <label htmlFor="photo">Old Image</label>
                                            {panImage.split('.').pop() == 'pdf' ?
                                                <img src="/assets/images/pdf-1.png"
                                                    onClick={() => window.open(panImage, '_blank')} width={50} height={50}></img> :
                                                <img src={panImage}
                                                    onClick={() => window.open(panImage, '_blank')}></img>
                                            }</>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </>
                        )}

                        <div className="field col-12 md:col-6">
                            <label htmlFor="aadharcard">AadharCard</label>
                            <FileUpload
                                ref={fileUploadRef}
                                accept="application/pdf, image/*"
                                name="aadharcard[]"
                                className="imageupload"
                                chooseOptions={chooseOptions}
                                emptyTemplate={emptyTemplate}
                                headerTemplate={headerTemplate}
                                itemTemplate={itemAadharTemplate}
                            ></FileUpload>
                            <small className="p-invalid-txt">{errors['aadharcard']}</small>
                        </div>

                        {editId != null && (
                            <>
                                <div className="field col-12 md:col-4 editImage">
                                    {typeof (aadharImage) === 'string' ? (
                                        <>  <label htmlFor="photo">Old Image</label>
                                            {aadharImage.split('.').pop() == 'pdf' ?
                                                <img src="/assets/images/pdf-1.png"
                                                    onClick={() => window.open(aadharImage, '_blank')} width={50} height={50}></img> :
                                                <img src={aadharImage}
                                                    onClick={() => window.open(aadharImage, '_blank')}></img>
                                            }</>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </>
                        )}

                        <div className="field col-12 md:col-6">
                            <label htmlFor="gstin_reg_copy">GSTIN Registration Copy</label>
                            <FileUpload
                                ref={fileUploadRef}
                                accept="application/pdf, image/*"
                                name="gstin_reg_copy[]"
                                className="imageupload"
                                chooseOptions={chooseOptions}
                                emptyTemplate={emptyTemplate}
                                headerTemplate={headerTemplate}
                                itemTemplate={itemGstTemplate}
                            ></FileUpload>
                            <small className="p-invalid-txt">{errors['gstin_reg_copy']}</small>
                        </div>

                        {editId != null && (
                            <>
                                <div className="field col-12 md:col-4 editImage">
                                    {typeof (gstImage) === 'string' ? (
                                        <>  <label htmlFor="photo">Old Image</label>
                                            {gstImage.split('.').pop() == 'pdf' ?
                                                <img src="/assets/images/pdf-1.png"
                                                    onClick={() => window.open(gstImage, '_blank')} width={50} height={50}></img> :
                                                <img src={gstImage}
                                                    onClick={() => window.open(gstImage, '_blank')}></img>
                                            }
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </>
                        )}

                        <div className="card-footer">
                            <div className="button-group">
                                <Button label="Save" icon="pi pi-check" loading={submitLoading} onClick={onSubmit} />
                            </div>
                        </div>
                    </div>

                    {
                        pageLoad && <Loader />
                    }
                </div>
            </div>


        </>
    );
}