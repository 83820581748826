import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF, Polygon } from '@react-google-maps/api';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';

//Services
import PageService from '../../service/PageService';

export const ViewLocation = () => {
    document.title = 'View Location | Swarnim Farms';

    //Navigate Another Route
    const navigate = useNavigate();

    const pageService = new PageService();
    const location = useLocation();

    const [pageLoad, setPageLoad] = useState(false);

    const toast = useRef<any>(null);
    const [userData, setUserData] = useState<any>();
    const [locationData, setLocationData] = useState<any>(null);
    const [date, setDate] = useState<any>(new Date());

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            setUserData(state);
            getLocationData(state);
        }
    }, [date]);

    // Get location Data from API
    const getLocationData = async (state: any) => {
        setPageLoad(true);
        // call api
        pageService
            .userLocation(state.id, date)
            .then((response) => {
                // get response
                if (response) {
                    const DataList = response;
                    const tempLocData: any = [];

                    if (DataList.length == 0) {
                        setLocationData(null);
                    } else {
                        for (let i = 0; i < DataList.length; i++) {
                            const locObj: any = {};
                            locObj['lat'] = DataList[i].latitude;
                            locObj['lng'] = DataList[i].longitude;
                            tempLocData.push(locObj);
                        }
                        setLocationData(tempLocData);
                        setPageLoad(false);
                    }
                } else {
                    setLocationData(null);
                }
            });
    };

    const containerStyle = {
        width: '1200px',
        height: '500px'
    };

    const center = {
        lat: 23.109242356709238,
        lng: 72.60353329447777
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAPLPRUov5fdxLTJUGQokatkrWYvnz4vjo"
    });

    const [map, setMap] = useState<any>(null);

    const onLoad = React.useCallback(function callback(map: any) {

        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    // Create an array of LatLng objects from coordinates
    // const pathCoordinates = !window.cn(locationData) && locationData.map((item: any, index: any) => {
    //     return { lat:  parseFloat(item.lat), lng:  parseFloat(item.lng) };
    // });

    return (<>
        <div className='main-header view-prodcution'>
            <h4 className='header'><Button
                icon="pi pi-arrow-left"
                className="p-button-secondary mr-2"
                onClick={() => navigate('/location-tracker')}
            />  User Location Details </h4>
            <div>
                <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon />
            </div>
        </div>

        <Toast ref={toast} />
        <div className='crud-demo card mt-3' style={{ position: "relative" }}>
            <div className="field col">
                <div className="grid">
                    <div className="field col-4 flex flex-column">
                        <p> <b>Name</b> : {!window.cn(userData) ? userData?.data?.name : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Email</b> : {!window.cn(userData) ? userData?.data.email : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Mobile</b> : {!window.cn(userData) ? userData?.data.mobile : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>State</b> : {!window.cn(userData) ? userData?.data.state : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>City</b> : {!window.cn(userData) ? userData?.data.city : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Role</b> : {!window.cn(userData) ? userData?.data.role : ""}</p>
                    </div>
                </div>
            </div>
        </div>

        {isLoaded && !window.cn(locationData) ?
            <>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
{/* 
                    <Polygon
                        path={pathCoordinates}
                    /> */}
                    {!window.cn(locationData) && locationData.map((item: any, index: number) => (
                        <>
                            <MarkerF position={{ lat: parseFloat(item.lat), lng: parseFloat(item.lng) }} />
                        </>
                    ))}
                </GoogleMap> </> : <><p><center>No Record Found For Selected Date</center></p></>
        }
    </>)
}