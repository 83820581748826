import React, { useEffect } from 'react';
// import logo from "../../../public/assets/images/logo.png"

const Dashboard = () => {

  document.title = 'Dashboard | Swarnim Farms';

  let growth = '$620,076';
  let avgCustomer = '$1,120';

  useEffect(() => { }, []);
  return (
    <div className="layout-dashboard">
      <section
        id="hero-animated"
        className="hero-animated flex align-items-center "
        style={{justifyContent : "center"}}
      >
        <div
          className="container d-flex flex-column justify-content-center align-items-center text-center position-relative"
          data-aos="zoom-out"
        >
          <img
            src="/assets/images/logo.png"
            className="img-fluid animated"
          />
          <p className="mb-0">
            HELLO {localStorage.getItem('name')}
          </p>
          <h2>
            Welcome to <span>Swarnim Farms Pvt. Ltd.</span>
          </h2>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
