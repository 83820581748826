import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  OrderColumns, ReturnOrderColumns, ordersViewColumns, returnOrderViewColumns
} from '../../appconfig/DatatableSetting';
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';


//Services
import PageService from '../../service/PageService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { formatDateTime } from '../../appconfig/AppHelper';

export const ReturnOrder = () => {
  document.title = 'Return Order | Swarnim Farms';
  const [ordersList, setOrdersList] = useState<any>([]);

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);
  const [pageLoad, setPageLoad] = useState(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [viewOrderDataDialog, setViewOrderDataDialog] =
    useState<boolean>(false);
  const [viewordersData, setViewordersData] = useState<any>({});
  
  const pageService = new PageService();

  //Navigate Another Route
  const navigate = useNavigate();

  // use effect method
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getordersDataFromAPI();
  }, []);

  useEffect(() => {
    getordersDataFromAPI();
  }, [ dates]);

  // Get orders Data from API
  const getordersDataFromAPI = async () => {

    pageService.returOrderList(!window.cn(dates) && dates[0], !window.cn(dates) && dates[1]).then((response) => {
      setPageLoad(false);
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setOrdersList([]);
        } else {
          setOrdersList(DataList);
        }
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }

  // On Date Change
  const onDateChange = (e: any) => {
    setDates(e.value);
  };

  // open view orders data dialog
  const viewOrderDetails = (rowData: any) => {
    setSpinner(true);
    setViewOrderDataDialog(true);
    setViewordersData(rowData.returnlineitems);
    setSpinner(false);
  };

  // close view orders data dialog
  const hideOrderDataDialog = () => {
    setViewOrderDataDialog(false);
    setViewordersData([]);
    setSpinner(false);
  };


  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // right part of toolbar
  const rightToolbarTemplate = () => {
    return (
      <>
        <div>
          <Calendar
            value={dates}
            className='order-cle'
            dateFormat="dd/mm/yy"
            onChange={(e) => onDateChange(e)}
            selectionMode="range"
            showIcon
          />
        </div>
      </>
    );
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions action-btn">
        <Button
          icon="pi pi-eye"
          className="p-button-primary mt-2 icn-btn"
          style={{ marginRight: '17px' }}
          onClick={() => viewOrderDetails(rowData)}
        />      
      </div>
    );
  };


  const cropBodyTemplate = (rowData: any) => {
    return (<>
      <div>{rowData?.crop_obj?.name}</div>
    </>);
  };

  const varietyBodyTemplate = (rowData: any) => {
    return (<>
      <div>{rowData?.variety_obj?.name}</div>
    </>);
  };

  const orderDateTemplate = (rowData: any) => {
    return (<>
      <div>{formatDateTime(rowData?.created_at)}</div>
    </>);
  };


  const returnAddedBy = (rowData: any) => {
    return (<>
      <div>{ !window.cn(rowData.return_added_by_user) ? rowData?.user?.first_name +" "+ rowData?.user?.last_name : "-"}</div>
    </>);
  };

  return (
    <>
      <div className="grid crud-demo main-div-sec ">
        <div className="col-12">
          <div className="card">
            <div className='main-header'>
              <h4 className='header'>Return Order List </h4>
             
            </div>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4" 
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            {/* Datatable */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive"
                  value={ordersList}
                  paginator={ordersList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Orders Found"
                >
                  {ReturnOrderColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else if (col.field === 'created_at') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={orderDateTemplate}
                        />
                      )
                    } else if (col.field === 'return_added_by_user') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={returnAddedBy}
                        />
                      )
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {ReturnOrderColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </div>

      {/* View Dialog start*/}
      <Dialog
        visible={viewOrderDataDialog}
        style={{ width: '1000px' }}
        header="View Order Data"
        className='crud-demo'
        modal
        footer={true}
        onHide={hideOrderDataDialog}
      >
        <DataTable
          className="datatable-responsive"
          value={viewordersData}
          rows={defaultRowOptions}
          currentPageReportTemplate={showingEntries}
          emptyMessage="No orders Varieties Data Found"
        >

          {returnOrderViewColumns.map((col, i) => {
            if (col.field === 'crop') {
              return <Column key={col.field} field={col.field} header={col.header} body={cropBodyTemplate} />;
            } else if (col.field === 'variety') {
              return <Column key={col.field} field={col.field} header={col.header} body={varietyBodyTemplate} />;
            } else if (col.field === 'sr_no') {
              return (
                <Column key={col.field} header={col.header} body={(_, { rowIndex }) => rowIndex + 1} />
              );
            } else {
              return <Column key={col.field} field={col.field} header={col.header} />
            }
          })}

          {
            spinner && <div className="loading flex justify-content-center">
              <ProgressSpinner />
            </div>
          }

        </DataTable>
      </Dialog>
      {/* View Dialog End*/}

    </>
  );
};
