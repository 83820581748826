import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from 'primereact/checkbox';

//Services
import PageService from '../../service/PageService';

// Data table
import { TransportType, PaymentPAIDOrNot, SeedDispatchType, partLoadTranportNameDropdown } from '../../appconfig/Settings';

import { RadioButton } from 'primereact/radiobutton';

//File Uplaod Components
import { chooseOptions, emptyTemplate, headerTemplate, } from '../../components/ImageUploadComponent/ImageUploadSetting';

import { RemenantDispatchValidate } from '../../config/Validate';

export const RemenantDispatch = () => {
    document.title = 'RemenantDispatch | Swarnim Farms';

    // File Upload Details
    const fileUploadRef = useRef(null);
    const [image, setImage] = useState<any>(null);
    const [lrImage, setLrImage] = useState<any>(null);
    const removeFile = useRef(null);

    // Error object
    const [isError, setIsError] = useState<any>(false);
    const [errors, setErrors] = useState<any>({});

    //Product Crud Object
    const toast = useRef<any>(null);
    const [dispatchData, setDispatchData] = useState<any>(null);
    const [uiUpdated, setIsUiUpdated] = useState<boolean>(false);
    const [seedUiUpdated, setIsSeedUiUpdated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dispatchType, setDispatchType] = useState<any>('SEED REMENANT DISPATCH');
    const [seedDispatchType, setSeedDispatchType] = useState<any>(null);
    const [seedDispatchData, setSeedDispatchData] = useState<any>([{
        crop: '',
        variety: '',
        remenant: ''
    }]);

    const [checkboxValue, setCheckboxValue] = useState<any>([]);
    const [partLoadTransportName, setPartTransportName] = useState<any>(null);

    // crop and variety
    const [cropData, setCropData] = useState<any>([]);
    const [dispatchMasterData, setDispatchMasterData] = useState<any>({
        dealer: "",
        bill_to: "",
        billing_address: "",
        gst_no: "",
        mobile: "",
        no_of_outer_bags: "",
        transport_name: "",
        car_no: "",
        driver_name: "",
        driver_mobile: "",
        license_no: "",
        license_state: "",
        payment_type: "",
        is_advance: "",
        amount: "",
        lr_no: "",
        date_of_supply: "",
        no_of_bags: "",
        from_location: "",
        to_location: "",
        tracking_id: "",
        courier_name: "",
        delivery_at: "",
        delivery_address: "",
        delivery_mobile: "",
    });
    const [varietyDropdown, setVarietyDropdown] = useState<any>([]);
    const [dealerDropdown, setDealerDropdown] = useState<any>([]);
    const [selectedDealer, setSelectedDealer] = useState<any>(null);
    const [paymentType, setPaymentType] = useState<any>(null);

    const pageService = new PageService();

    //Navigate Another Route
    const navigate = useNavigate();

    // use effect method
    useEffect(() => {
        getCropList();
        getDealerList();
    }, []);

    useEffect(() => {
        if (uiUpdated) {
            setIsUiUpdated(false);
            getTransportUi();
        }
    }, [dispatchData, uiUpdated]);

    useEffect(() => {
        if (seedUiUpdated) {
            setIsSeedUiUpdated(false);
            getSeedDispatchArrayUI();
        }
    }, [seedUiUpdated, seedDispatchData]);

    // Get dealer Data from API
    const getDealerList = async () => {
        pageService.dealerList().then((response) => {
            // get response
            if (response) {
                const DataList = response;
                if (DataList.length == 0) {
                    setDealerDropdown([]);
                } else {
                    if (response) {
                        const DataList = response;
                        let tempUserArr: any = [];
                        for (let i = 0; i < DataList.length; i++) {
                            let userObj: any = {};
                            userObj['name'] = DataList[i].firm_name;
                            userObj['code'] = DataList[i].id;
                            tempUserArr.push(userObj);
                        }
                        setDealerDropdown(tempUserArr);
                    }
                }
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });
    }

    // Get crops
    const getCropList = async () => {
        try {
            const cropData: any = [];

            pageService.cropList().then((response) => {
                if (response) {
                    const cropDataList = response;
                    for (let i = 0; i < cropDataList.length; i++) {
                        const userObj: any = {};
                        userObj['name'] = cropDataList[i].name;
                        userObj['code'] = cropDataList[i].id;
                        cropData.push(userObj);
                    }
                    setCropData(cropData);
                }
            });

        } catch (error) { }
    };

    const getVarietyList = async (id: any, index: number) => {

        pageService.varietyList(id.code).then((response) => {
            // get response
            if (response) {
                const DataList = response;
                if (DataList.length == 0) {
                    setVarietyDropdown([]);
                } else {
                    if (response) {
                        const tempVarietyArr = response;
                        const varietyData: any = [];
                        for (let i = 0; i < tempVarietyArr.length; i++) {
                            const varietyObj: any = {};
                            varietyObj['name'] = tempVarietyArr[i].name;
                            varietyObj['code'] = tempVarietyArr[i].id;
                            varietyData.push(varietyObj);
                        }

                        if (varietyDropdown[index] != undefined) {
                            varietyDropdown.splice(index, 1, varietyData);
                        } else {
                            varietyDropdown.splice(index, 0, varietyData);
                        }
                        setIsSeedUiUpdated(true);
                    }
                }

            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, Please try again.',
                });
            }
        });

    }

    //delete dispatch detail
    const deleteSeedDispatchData = (index: number) => {
        seedDispatchData.splice(index, 1);
        setIsUiUpdated(true);
    }

    const onCheckboxChange = (e: any) => {
        let selectedValue: any[] = [...checkboxValue];
        if (e.checked) selectedValue.push(e.value);
        else selectedValue.splice(selectedValue.indexOf(e.value), 1);
        if (selectedValue.indexOf('1') != -1) {
            let tempDispatchMasterDataObj = dispatchMasterData;
            tempDispatchMasterDataObj["delivery_at"] = dispatchMasterData.bill_to;
            tempDispatchMasterDataObj["delivery_address"] = dispatchMasterData.billing_address;
            tempDispatchMasterDataObj["delivery_mobile"] = dispatchMasterData.mobile;
            setDispatchMasterData(tempDispatchMasterDataObj);
        } else if (selectedValue.indexOf('1') == -1) {
            let tempDispatchMasterDataObj = dispatchMasterData;
            tempDispatchMasterDataObj["delivery_at"] = "";
            tempDispatchMasterDataObj["delivery_address"] = "";
            tempDispatchMasterDataObj["delivery_mobile"] = "";
            setDispatchMasterData(tempDispatchMasterDataObj);
        }

        setCheckboxValue(selectedValue);
        setIsUiUpdated(true);
    };

    // dropdown handle change crop
    const handleCropDropdown = (id: any, index: number) => {
        let tempArray = seedDispatchData;
        if (tempArray[index] == undefined) {
            tempArray.push({
                crop: id,
                variety: '',
                remenant: ''
            });
        } else {
            tempArray[index]["crop"] = id;
        }
        setSeedDispatchData(tempArray);
        getVarietyList(id, index);
        setIsSeedUiUpdated(true);
    };

    // dropdown handle change variety
    const handleVarietyDropdown = (id: any, index: number) => {
        let tempArray = seedDispatchData;
        if (tempArray[index] == undefined) {
            tempArray.push({
                crop: seedDispatchData[index]["crop"],
                variety: id,
                remenant: ''
            });
        } else {
            tempArray[index]["variety"] = id;
        }
        setSeedDispatchData(tempArray);
        setIsSeedUiUpdated(true);
    };

    const onHandleChangeText = (e: any, index: number, name: string) => {
        let tempArray = seedDispatchData;
        tempArray[index][name] = e.target.value;
        setSeedDispatchData(tempArray);
        setIsSeedUiUpdated(true);
    }

    const onHandleChangeDealer = (e: any) => {
        setSelectedDealer(e);
        pageService.getDealerDataById(e.code).then((result) => {

            setDispatchMasterData({
                ...dispatchMasterData,
                'bill_to': result.firm_name,
                'billing_address': result.business_address,
                'gst_no': result.gst_no,
                'mobile': result.mobile
            });
            setIsUiUpdated(true);
        });
    }

    //handle change transportation type dropdown
    const handleTransportationType = (value: any) => {

        setDispatchData(value);
        setDispatchMasterData({
            ...dispatchMasterData,
            transport_name: "",
            car_no: "",
            driver_name: "",
            driver_mobile: "",
            license_no: "",
            license_state: "",
            payment_type: "",
            is_advance: "",
            amount: "",
            lr_no: "",
            date_of_supply: "",
            no_of_bags: "",
            from_location: "",
            to_location: "",
            tracking_id: "",
            courier_name: ""
        });
        setIsUiUpdated(true);
    }

    //handle change dispatch Master details
    const onHandleChangeDispatchMasterDetails = (e: any, name: string) => {
        let val;
        if (name == 'date_of_supply' || name == 'is_advance' || name == 'local_dispatch_date') {
            val = e || '';
        } else {
            val = (e.target && e.target.value) || '';
            val = val.toUpperCase();
        }
        setDispatchMasterData({ ...dispatchMasterData, [name]: val });
        setIsUiUpdated(true);

    }

    //handle change dispatch Master details
    const onHandleChangePatLoadTransportName = (e: any) => {
        setPartTransportName(e);

        setDispatchMasterData({ ...dispatchMasterData, other_transport_name: '' });
        setIsUiUpdated(true);
    }

    //handle add more
    const handleAddMoreSeedDispatchDeta = () => {
        setSeedDispatchData([...seedDispatchData, {
            crop: '',
            variety: '',
            remenant: ''
        }]);
    }

    // Template for image upload
    const itemImageTemplate = (file: any, props: any) => {
        setImage(file);
        removeFile.current = props.onRemove;
        return (
            <>
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        <img
                            alt={file.name}
                            role="presentation"
                            src={file.objectURL}
                            width={100}
                        />
                        <span className="flex flex-column text-left ml-3">{file.name}</span>
                        <Button
                            type="button"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                            onClick={() => onTemplateRemove(props.onRemove)}
                        />
                    </div>
                </div>
            </>
        );
    };

    // remove image template
    const onTemplateRemove = (callback: any) => {
        setImage(null);
        callback();
    };

    // Template for image upload
    const lrImageTemplate = (file: any, props: any) => {
        setLrImage(file);
        removeFile.current = props.onRemove;
        return (
            <>
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{ width: '40%' }}>
                        <img
                            alt={file.name}
                            role="presentation"
                            src={file.objectURL}
                            width={100}
                        />
                        <span className="flex flex-column text-left ml-3">{file.name}</span>
                        <Button
                            type="button"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                            onClick={() => onLrImageTemplateRemove(props.onRemove)}
                        />
                    </div>
                </div>
            </>
        );
    };

    // remove image template
    const onLrImageTemplateRemove = (callback: any) => {
        setLrImage(null);
        callback();
    };

    const getTransportUi = () => {
        return (
            <>
                <div className="formgrid grid dispatch">
                    <div className="field col-4 flex flex-column">
                        <label htmlFor="dispatch_type">Select Transport Dispatch Type *</label>
                        <Dropdown
                            filter
                            value={!window.cn(dispatchData) ? dispatchData : ""}
                            onChange={(e) => handleTransportationType(e.value)}
                            options={TransportType}
                            optionLabel="name"
                            placeholder="Select One"
                            name="transport_type"
                            className='dispatch-drp'
                        ></Dropdown>
                        <small className="p-invalid-txt">{errors['transport_type']}</small>
                    </div>
                </div>

                {
                    !window.cn(dispatchData) && dispatchData.code == "PART LOAD" ?
                        <>
                            <div className="formgrid grid">
                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Lr. No.</label>
                                    <InputText
                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.lr_no : ""}
                                        name="lr_no"
                                        autoComplete="off"
                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'lr_no')}
                                    />
                                </div>

                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="date_of_supply">Date Of Supply</label>
                                    <Calendar
                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.date_of_supply : ""}
                                        dateFormat="dd/mm/yy"
                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, 'date_of_supply')}
                                        name="date_of_supply"
                                        showIcon
                                    />
                                </div>
                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="transport_name">Transport Name *</label>
                                    <Dropdown
                                        filter
                                        value={partLoadTransportName}
                                        onChange={(e) => onHandleChangePatLoadTransportName(e.value)}
                                        options={partLoadTranportNameDropdown}
                                        optionLabel="name"
                                        placeholder="Select One"
                                        name="transport_name"
                                    ></Dropdown>
                                    <small className="p-invalid-txt">{errors['transport_name']}</small>
                                </div>
                                {!window.cn(partLoadTransportName) && partLoadTransportName.code === 'Other' ?
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="other_transport_name">Other</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.other_transport_name : ""}
                                            name="other_transport_name"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'other_transport_name')}
                                        />
                                    </div> : ""}

                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Payment PAID Or Not</label>
                                    <Dropdown
                                        id="payment_type"
                                        value={paymentType}
                                        onChange={(e) => setPaymentType(e.value)}
                                        options={PaymentPAIDOrNot}
                                        optionLabel="name"
                                        placeholder="Select One"
                                        name="payment_type"
                                    ></Dropdown>
                                </div>

                                <div className="field col-4">
                                    <div className="col-12 mb-3">
                                        <label htmlFor="gtm">Advance Payment</label>
                                    </div>
                                    <div className='grid'>
                                        <div className="col-12 md:col-4 lg:col-4">
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="option1" name="option" value="Yes" checked={dispatchMasterData.is_advance === 'Yes'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                <label htmlFor="option1">Yes</label>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4">
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="option2" name="option" value="No" checked={dispatchMasterData.is_advance === 'No'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                <label htmlFor="option2">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!window.cn(dispatchMasterData) && dispatchMasterData.is_advance === 'Yes' ?
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="advance_amount">Advance Amount</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.advance_amount : ""}
                                            name="advance_amount"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'advance_amount')}
                                        />
                                    </div> : ""}

                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Amount</label>
                                    <InputText
                                        keyfilter="num"
                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.amount : ""}
                                        name="amount"
                                        autoComplete="off"
                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'amount')}
                                    />
                                </div>
                            </div>
                            <div className='formgrid grid'>
                                <div className="field col-4 flex flex-column">
                                    <label htmlFor="name">Lr. Image</label>
                                    <FileUpload
                                        ref={fileUploadRef}
                                        accept="image/*"
                                        name="lr_image[]"
                                        className="imageupload"
                                        chooseOptions={chooseOptions}
                                        emptyTemplate={emptyTemplate}
                                        headerTemplate={headerTemplate}
                                        itemTemplate={lrImageTemplate}
                                    ></FileUpload>
                                    <small className="p-invalid-txt">{errors['lr_image']}</small>
                                </div>
                            </div>
                        </>
                        : !window.cn(dispatchData) && dispatchData.code == "FULL LOAD" ?
                            <>
                                <div className='formgrid grid'>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Lr. No. *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.lr_no : ""}
                                            name="lr_no"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'lr_no')}
                                            className={errors['lr_no'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['lr_no']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Transport Name *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchData.transport_name : ""}
                                            name="transport_name"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'transport_name')}
                                            className={errors['transport_name'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['transport_name']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Vehicle No. *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.car_no : ""}
                                            name="car_no"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'car_no')}
                                            className={errors['car_no'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['car_no']}</small>
                                    </div>
                                </div>
                                <div className='formgrid grid'>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Driver Name *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.driver_name : ""}
                                            name="driver_name"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'driver_name')}
                                            className={errors['driver_name'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['driver_name']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Driver Mobile No. *</label>
                                        <InputText
                                             keyfilter="num"
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.driver_mobile : ""}
                                            name="driver_mobile"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'driver_mobile')}
                                            className={errors['driver_mobile'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['driver_mobile']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">License No. *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.license_no : ""}
                                            name="license_no"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'license_no')}
                                            className={errors['license_no'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['license_no']}</small>
                                    </div>
                                </div>
                                <div className='formgrid grid'>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">License Issuing State *</label>
                                        <InputText
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.license_state : ""}
                                            name="license_state"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'license_state')}
                                            className={errors['license_state'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['license_state']}</small>
                                    </div>
                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Payment PAID Or Not *</label>
                                        <Dropdown
                                            id="payment_type"
                                            filter
                                            value={paymentType}
                                            onChange={(e) => setPaymentType(e.value)}
                                            options={PaymentPAIDOrNot}
                                            optionLabel="name"
                                            placeholder="Select One"
                                            name="payment_type"
                                            className={errors['payment_type'] && 'p-invalid'}
                                        ></Dropdown>
                                        <small className="p-invalid-txt">{errors['payment_type']}</small>
                                    </div>

                                    <div className="field col-4">
                                        <div className="col-12 mb-3">
                                            <label htmlFor="is_advance">Advance Payment</label>
                                        </div>
                                        <div className='grid'>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <div className="field-radiobutton">
                                                    <RadioButton inputId="option1" name="option" value="Yes" checked={dispatchMasterData.is_advance === 'Yes'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                    <label htmlFor="option1">Yes</label>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-4 lg:col-4">
                                                <div className="field-radiobutton">
                                                    <RadioButton inputId="option2" name="option" value="No" checked={dispatchMasterData.is_advance === 'No'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                    <label htmlFor="option2">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <small className="p-invalid-txt">{errors['is_advance']}</small>
                                    </div>

                                    {!window.cn(dispatchMasterData) && dispatchMasterData.is_advance === 'Yes' ?
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="advance_amount">Advance Amount</label>
                                            <InputText
                                                keyfilter="num"
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.advance_amount : ""}
                                                name="advance_amount"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'advance_amount')}
                                            />
                                            <small className="p-invalid-txt">{errors['advance_amount']}</small>
                                        </div> : ""}

                                    <div className="field col-4 flex flex-column">
                                        <label htmlFor="name">Amount *</label>
                                        <InputText
                                            keyfilter="num"
                                            value={!window.cn(dispatchMasterData) ? dispatchMasterData.amount : ""}
                                            name="amount"
                                            autoComplete="off"
                                            onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'amount')}
                                            className={errors['amount'] && 'p-invalid'}
                                        />
                                        <small className="p-invalid-txt">{errors['amount']}</small>
                                    </div>
                                </div>
                                <div className='formgrid grid'>
                                    <div className="field col-6 flex flex-column">
                                        <label htmlFor="photo">Driver Image</label>
                                        <FileUpload
                                            ref={fileUploadRef}
                                            accept="image/*"
                                            name="photo[]"
                                            className="imageupload"
                                            chooseOptions={chooseOptions}
                                            emptyTemplate={emptyTemplate}
                                            headerTemplate={headerTemplate}
                                            itemTemplate={itemImageTemplate}
                                        ></FileUpload>
                                        <small className="p-invalid-txt">{errors['photo']}</small>
                                    </div>
                                    <div className="field col-6 flex flex-column">
                                        <label htmlFor="name">Lr. Image *</label>
                                        <FileUpload
                                            ref={fileUploadRef}
                                            accept="image/*"
                                            name="lr_image[]"
                                            className="imageupload"
                                            chooseOptions={chooseOptions}
                                            emptyTemplate={emptyTemplate}
                                            headerTemplate={headerTemplate}
                                            itemTemplate={lrImageTemplate}
                                        ></FileUpload>
                                        <small className="p-invalid-txt">{errors['lr_image']}</small>
                                    </div>

                                </div>
                            </>
                            : !window.cn(dispatchData) && dispatchData.code == "PRIVATE" ?
                                <>
                                    <div className='formgrid grid'>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">Transport Name *</label>
                                            <InputText
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.transport_name : ""}
                                                name="transport_name"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'transport_name')}
                                                className={errors['transport_name'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['transport_name']}</small>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">Vehicle No. *</label>
                                            <InputText
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.car_no : ""}
                                                name="car_no"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'car_no')}
                                                className={errors['car_no'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['car_no']}</small>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">No. of Bags *</label>
                                            <InputText
                                                keyfilter="num"
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.no_of_bags : ""}
                                                name="no_of_bags"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'no_of_bags')}
                                                className={errors['no_of_bags'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['no_of_bags']}</small>
                                        </div>
                                    </div>
                                    <div className='formgrid grid'>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">Driver Mobile No. *</label>
                                            <InputText
                                                 keyfilter="num"
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.driver_mobile : ""}
                                                name="driver_mobile"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'driver_mobile')}
                                                className={errors['driver_mobile'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['driver_mobile']}</small>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">From Location *</label>
                                            <InputText
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.from_location : ""}
                                                name="from_location"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'from_location')}
                                                className={errors['from_location'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['from_location']}</small>
                                        </div>
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">To Location *</label>
                                            <InputText
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.to_location : ""}
                                                name="to_location"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'to_location')}
                                                className={errors['to_location'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['to_location']}</small>
                                        </div>
                                    </div>
                                    <div className="formgrid grid ">
                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">Payment PAID Or Not *</label>
                                            <Dropdown
                                                id="payment_type"
                                                filter
                                                value={paymentType}
                                                onChange={(e) => setPaymentType(e.value)}
                                                options={PaymentPAIDOrNot}
                                                optionLabel="name"
                                                placeholder="Select One"
                                                name="payment_type"
                                                className={errors['payment_type'] && 'p-invalid'}
                                            ></Dropdown>
                                            <small className="p-invalid-txt">{errors['payment_type']}</small>
                                        </div>

                                        <div className="field col-4">
                                            <div className="col-12 mb-3">
                                                <label htmlFor="gtm">Advance Payment</label>
                                            </div>
                                            <div className='grid'>
                                                <div className="col-12 md:col-4 lg:col-4">
                                                    <div className="field-radiobutton">
                                                        <RadioButton inputId="option1" name="option" value="Yes" checked={dispatchMasterData.is_advance === 'Yes'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                        <label htmlFor="option1">Yes</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-4 lg:col-4">
                                                    <div className="field-radiobutton">
                                                        <RadioButton inputId="option2" name="option" value="No" checked={dispatchMasterData.is_advance === 'No'} onChange={(e) => onHandleChangeDispatchMasterDetails(e.value, "is_advance")} />
                                                        <label htmlFor="option2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <small className="p-invalid-txt">{errors['is_advance']}</small>
                                        </div>

                                        {!window.cn(dispatchMasterData) && dispatchMasterData.is_advance === 'Yes' ?
                                            <div className="field col-4 flex flex-column">
                                                <label htmlFor="advance_amount">Advance Amount</label>
                                                <InputText
                                                    keyfilter="num"
                                                    value={!window.cn(dispatchMasterData) ? dispatchMasterData.advance_amount : ""}
                                                    name="advance_amount"
                                                    autoComplete="off"
                                                    onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'advance_amount')}
                                                />
                                                <small className="p-invalid-txt">{errors['advance_amount']}</small>
                                            </div> : ""}

                                        <div className="field col-4 flex flex-column">
                                            <label htmlFor="name">Amount *</label>
                                            <InputText
                                                keyfilter="num"
                                                value={!window.cn(dispatchMasterData) ? dispatchMasterData.amount : ""}
                                                name="amount"
                                                autoComplete="off"
                                                onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'amount')}
                                                className={errors['amount'] && 'p-invalid'}
                                            />
                                            <small className="p-invalid-txt">{errors['amount']}</small>
                                        </div>
                                    </div>
                                </>
                                : !window.cn(dispatchData) && dispatchData.code == "COURIER" ?
                                    <>
                                        <div className='formgrid grid'>
                                            <div className="field col-4 flex flex-column">
                                                <label htmlFor="name">Tracking ID *</label>
                                                <InputText
                                                    value={!window.cn(dispatchMasterData) ? dispatchMasterData.tracking_id : ""}
                                                    name="tracking_id"
                                                    autoComplete="off"
                                                    onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'tracking_id')}
                                                    className={errors['tracking_id'] && 'p-invalid'}
                                                />
                                                <small className="p-invalid-txt">{errors['tracking_id']}</small>
                                            </div>
                                            <div className="field col-4 flex flex-column">
                                                <label htmlFor="name">Courier Name *</label>
                                                <InputText
                                                    value={!window.cn(dispatchMasterData) ? dispatchMasterData.courier_name : ""}
                                                    name="courier_name"
                                                    autoComplete="off"
                                                    onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'courier_name')}
                                                    className={errors['courier_name'] && 'p-invalid'}
                                                />
                                                <small className="p-invalid-txt">{errors['courier_name']}</small>
                                            </div>
                                        </div>
                                    </>
                                    : !window.cn(dispatchData) && dispatchData.code == "PARCEL" ?
                                        <>
                                            <div className='formgrid grid'>
                                                <div className="field col-4 flex flex-column">
                                                    <label htmlFor="name">Tracking ID *</label>
                                                    <InputText
                                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.tracking_id : ""}
                                                        name="tracking_id"
                                                        autoComplete="off"
                                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'tracking_id')}
                                                        className={errors['tracking_id'] && 'p-invalid'}
                                                    />
                                                    <small className="p-invalid-txt">{errors['tracking_id']}</small>
                                                </div>
                                                <div className="field col-4 flex flex-column">
                                                    <label htmlFor="name">Courier Name *</label>
                                                    <InputText
                                                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.courier_name : ""}
                                                        name="courier_name"
                                                        autoComplete="off"
                                                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, 'courier_name')}
                                                        className={errors['courier_name'] && 'p-invalid'}
                                                    />
                                                    <small className="p-invalid-txt">{errors['courier_name']}</small>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        ""
                }
            </>
        );
    };

    const getSeedDispatchArrayUI = () => {
        return (
            <>
                <div className="field col-12 md:col-4">
                    <label htmlFor="seed_dispatch_type">Seed Dispatch Type *</label>
                    <Dropdown
                        id="seed_dispatch_type"
                        filter
                        value={seedDispatchType}
                        onChange={(e) => setSeedDispatchType(e.value)}
                        options={SeedDispatchType}
                        optionLabel="name"
                        placeholder="Select One"
                        name="seed_dispatch_type"
                        className='dispatch-drp'
                    ></Dropdown>
                    <small className="p-invalid-txt">{errors['seed_dispatch_type']}</small>
                </div>

                {!window.cn(seedDispatchType) && seedDispatchType.code == 'DEALER' ?
                    <>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="dealer">Select Dealer *</label>
                            <Dropdown
                                id="dealer"
                                filter
                                value={selectedDealer}
                                onChange={(e) => onHandleChangeDealer(e.value)}
                                options={dealerDropdown}
                                optionLabel="name"
                                placeholder="Select One"
                                name="dealer"
                                className='dispatch-drp'
                            ></Dropdown>
                            <small className="p-invalid-txt">{errors['dealer']}</small>
                        </div>
                    </> :
                    <div className="field col-12 md:col-4"></div>}

                <div className="field col-12 md:col-4">
                    <label htmlFor="bill_to">Bill To *</label>
                    <InputText
                        value={!window.cn(dispatchMasterData.bill_to) ? dispatchMasterData.bill_to : ""}
                        name="bill_to"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "bill_to")}
                        className={errors['bill_to'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['bill_to']}</small>
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="billing_address">Billing Address *</label>
                    <InputText
                        value={!window.cn(dispatchMasterData.billing_address) ? dispatchMasterData.billing_address : ""}
                        name="billing_address"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "billing_address")}
                        className={errors['billing_address'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['billing_address']}</small>
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="gst_no">GSTIN No. </label>
                    <InputText
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.gst_no : ""}
                        name="gst_no"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "gst_no")}
                        className={errors['gst_no'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['gst_no']}</small>
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="mobile">Mobile *</label>
                    <InputText
                         keyfilter="num"
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.mobile : ""}
                        name="mobile"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "mobile")}
                        className={errors['mobile'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['mobile']}</small>
                </div>

                <div className="field col-12 md:col-8"></div>

                <div className="field col-12">
                    <div className="field-checkbox">
                        <Checkbox inputId="checkOption1" name="option" value="1" checked={checkboxValue.indexOf('1') !== -1} onChange={onCheckboxChange} />
                        <label htmlFor="checkOption1">Delivery Address Same as Billing Address</label>
                    </div>
                </div>

                <div className="field col-12 md:col-4">
                    <label htmlFor="delivery_at">Delivery At *</label>
                    <InputText
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.delivery_at : ""}
                        name="delivery_at"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "delivery_at")}
                        className={errors['delivery_at'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['delivery_at']}</small>
                </div>
                <div className="field col-12 md:col-4">
                    <label htmlFor="delivery_address">Delivery Address *</label>
                    <InputText
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.delivery_address : ""}
                        name="delivery_address"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "delivery_address")}
                        className={errors['delivery_address'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['delivery_address']}</small>
                </div>
                <div className="field col-12 md:col-4 flex flex-column">
                    <label htmlFor="delivery_mobile">Mobile *</label>
                    <InputText
                         keyfilter="num"
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.delivery_mobile : ""}
                        name="delivery_mobile"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "delivery_mobile")}
                        className={errors['delivery_mobile'] && 'p-invalid'}
                    />
                    <small className="p-invalid-txt">{errors['delivery_mobile']}</small>
                </div>

                <div className="field col-4 flex flex-column">
                    <label htmlFor="delivery_station">Delivery Station</label>
                    <InputText
                        value={!window.cn(dispatchMasterData) ? dispatchMasterData.delivery_station : ""}
                        name="delivery_station"
                        autoComplete="off"
                        onChange={(e) => onHandleChangeDispatchMasterDetails(e, "delivery_station")}
                        className={errors['delivery_station'] && 'p-invalid'}
                    />
                </div>
                <div className="field col-8 flex flex-column"></div>


                {seedDispatchData.map((item: any, index: number) => (
                    <>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="crop">Crop *</label>
                            <Dropdown
                                filter
                                value={seedDispatchData[index]["crop"]}
                                onChange={(e) => handleCropDropdown(e.value, index)}
                                options={cropData}
                                optionLabel="name"
                                name="crop"
                                placeholder="Select Crop"
                                className={errors['crop'] && 'p-invalid'}
                            ></Dropdown>
                            <small className="p-invalid-txt">{errors['crop']}</small>
                        </div>
                        <div className="field col-12 md:col-4">
                            <label htmlFor="variety">Variety *</label>
                            <Dropdown
                                filter
                                value={seedDispatchData[index]["variety"]}
                                onChange={(e) => handleVarietyDropdown(e.value, index)}
                                options={varietyDropdown[index]}
                                optionLabel="name"
                                name="variety"
                                placeholder="Select Variety"
                                className={errors['variety'] && 'p-invalid'}
                            ></Dropdown>
                            <small className="p-invalid-txt">{errors['variety']}</small>
                        </div>

                        <div className="field col-12 md:col-4">
                            <label htmlFor="remenant">Remenant *</label>
                            <InputText
                                keyfilter="num"
                                value={seedDispatchData[index]["remenant"]}
                                onChange={(e) => onHandleChangeText(e, index, 'remenant')}
                                name="remenant"
                                placeholder="Enter Remenant Qty"
                                className={errors['remenant'] && 'p-invalid'}
                            />
                            <small className="p-invalid-txt">{errors['remenant']}</small>
                        </div>

                        {index > 0 ?
                            <div className="field col-12 md:col-12">
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger mt-2 "
                                    onClick={() => deleteSeedDispatchData(index)}
                                />
                            </div>

                            : ""
                        }
                    </>
                ))}

                {!window.cn(dispatchType) && (dispatchType != null) ?
                    <div className="field col-12 md:col-12">
                        <Button
                            style={{ width: 'fit-content' }}
                            label="Add More"
                            icon="pi pi-plus"
                            className="p-button"
                            onClick={handleAddMoreSeedDispatchDeta}
                        />
                    </div> : ""}
            </>
        );
    };

    //on submit
    const onSubmitDispatchData = () => {

        const { errors, isError } = RemenantDispatchValidate(dispatchMasterData, dispatchData, dispatchType, paymentType, image, seedDispatchType, selectedDealer, seedDispatchData, partLoadTransportName, lrImage);

        setErrors(errors);
        setIsError(isError);
        //Check Error if no errors then call API
        if (!isError) {
            setLoading(true);

            let formData = new FormData();

            if (!window.cn(dispatchType)) {
                formData.append('dispatch_type', dispatchType);
            }

            if (!window.cn(seedDispatchType) && seedDispatchType.code) {
                formData.append('seed_dispatch_type', seedDispatchType?.code);
            }

            if (!window.cn(seedDispatchData)) {
                formData.append('dispatch_details', JSON.stringify(seedDispatchData));
            }

            if (!window.cn(partLoadTransportName) && partLoadTransportName != null) {
                formData.append('transport_name', partLoadTransportName.code);

                if (!window.cn(dispatchMasterData.other_transport_name)) {
                    formData.append('other_transport_name', dispatchMasterData.other_transport_name);
                }
            }

            if (!window.cn(selectedDealer)) {
                formData.append('dealer_id', selectedDealer?.code);
            }

            if (!window.cn(dispatchMasterData.bill_to)) {
                formData.append('bill_to', dispatchMasterData.bill_to);
            }

            if (!window.cn(dispatchMasterData.billing_address)) {
                formData.append('billing_address', dispatchMasterData.billing_address);
            }

            if (!window.cn(dispatchMasterData.gst_no)) {
                formData.append('gst_no', dispatchMasterData.gst_no);
            }

            if (!window.cn(dispatchMasterData.mobile)) {
                formData.append('mobile', dispatchMasterData.mobile);
            }

            if (!window.cn(dispatchMasterData.delivery_station)) {
                formData.append('delivery_station', dispatchMasterData.delivery_station);
            }

            if (!window.cn(dispatchMasterData.delivery_at)) {
                formData.append('delivery_at', dispatchMasterData.delivery_at);
            }
            if (!window.cn(dispatchMasterData.delivery_address)) {
                formData.append('delivery_address', dispatchMasterData.delivery_address);
            }
            if (!window.cn(dispatchMasterData.delivery_mobile)) {
                formData.append('delivery_mobile', dispatchMasterData.delivery_mobile);
            }

            if (!window.cn(dispatchData?.code)) {
                formData.append('transport_type', dispatchData?.code);
            }

            if (!window.cn(dispatchMasterData.transport_name)) {
                formData.append('transport_name', dispatchMasterData.transport_name);
            }

            if (!window.cn(dispatchMasterData.driver_name)) {
                formData.append('driver_name', dispatchMasterData.driver_name);
            }

            if (!window.cn(dispatchMasterData.driver_mobile)) {
                formData.append('driver_mobile', dispatchMasterData.driver_mobile);
            }

            if (!window.cn(dispatchMasterData.from_location)) {
                formData.append('from_location', dispatchMasterData.from_location);
            }

            if (!window.cn(dispatchMasterData.to_location)) {
                formData.append('to_location', dispatchMasterData.to_location);
            }

            if (!window.cn(dispatchMasterData.date_of_supply)) {
                var date = new Date(dispatchMasterData.date_of_supply);
                let supplyDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                formData.append('date_of_supply', supplyDate);
            }

            if (!window.cn(dispatchMasterData.no_of_bags)) {
                formData.append('no_of_bags', dispatchMasterData.no_of_bags);
            }

            if (!window.cn(paymentType?.code)) {
                formData.append('payment_type', paymentType?.code);
            }

            if (!window.cn(dispatchMasterData.amount)) {
                formData.append('amount', dispatchMasterData.amount);
            }

            if (!window.cn(dispatchMasterData.advance_amount)) {
                formData.append('advance_amount', dispatchMasterData.advance_amount);
            }

            if (!window.cn(dispatchMasterData.lr_no)) {
                formData.append('lr_no', dispatchMasterData.lr_no);
            }

            if (!window.cn(dispatchMasterData.license_no)) {
                formData.append('license_no', dispatchMasterData.license_no);
            }

            if (!window.cn(dispatchMasterData.license_state)) {
                formData.append('license_state', dispatchMasterData.license_state);
            }

            if (!window.cn(dispatchMasterData.is_advance)) {
                formData.append('is_advance', dispatchMasterData.is_advance);
            }

            if (!window.cn(dispatchMasterData.car_no)) {
                formData.append('car_no', dispatchMasterData.car_no);
            }

            if (image != undefined && image != null && image != "") {
                formData.append('photo', image);
            }

            if (lrImage != undefined && lrImage != null && lrImage != "") {
                formData.append('lr_image', lrImage);
            }

            if (!window.cn(dispatchData) && (dispatchData.code == 'PARCEL' || dispatchData.code == 'COURIER')) {
                if (!window.cn(dispatchMasterData.tracking_id)) {
                    formData.append('tracking_id', dispatchMasterData.tracking_id);
                }

                if (!window.cn(dispatchMasterData.courier_name)) {
                    formData.append('courier_name', dispatchMasterData.courier_name);
                }
            }

            pageService.addRemenantDispatchData(formData).then(result => {
                setLoading(false);
                navigate('/product/dispatch');
                if (result.data != "") {
                    window.open(result.data, '_blank')
                }
                setCheckboxValue([]);
                toast.current?.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: result.message
                });
            }).catch(error => {
                setLoading(false);
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.response.data.error,
                });
            });
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <h4> <Button
                icon="pi pi-arrow-left"
                className="p-button-secondary mr-2"
                onClick={() => navigate('/product/remenant')}
            />    Add Remenant Dispatch Data</h4>

            <div className="col-12">
                <div className="card">
                    <>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-4">
                                <label htmlFor="dispatch_type">Select Product Dispatch Type </label>
                                <InputText
                                    value={dispatchType}
                                    name="dispatch_type"
                                    disabled
                                />
                            </div>

                            {getSeedDispatchArrayUI()}
                        </div>
                        <hr></hr>

                        <h5>Transport Details</h5>
                        {getTransportUi()}
                    </>

                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12">
                            <div style={{ float: "right" }}>
                                <Button
                                    label="Save"
                                    icon="pi pi-check"
                                    onClick={onSubmitDispatchData}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};