import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { CSVLink } from "react-csv";
import { formatDateTime } from '../../appconfig/AppHelper';
import { Badge } from 'primereact/badge';

// Dropdown
import { testResultDropdown } from '../../appconfig/Settings';

// Column
import { TestColumns } from '../../appconfig/DatatableSetting';

// Data table
import {
    defaultPageRowOptions,
    defaultRowOptions,
    paginatorLinks,
    showingEntries,
    SkeletonbodyTemplate,
    Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

// Validatetion
import { ProgressSpinner } from 'primereact/progressspinner';

export const Testing = () => {
    document.title = 'Testing | Swarnim Farms';

    const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const [csvData, setCsvData] = useState([]);

    //Product Crud Object
    const [products, setProducts] = useState<any>([]);

    //Navigate Another Route
    const navigate = useNavigate();

    // Date Object
    let today = new Date();
    const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

    //Set Toast/ Filter Properties
    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const toast = useRef<any>(null);

    const [viewProductDialog, setViewProductDialog] =
        useState<boolean>(false);
    const [viewProductData, setViewProductData] = useState<any>({});

    // Edit and Delete Data Details
    const [editId, setEditId] = useState<any>(null);
    const [editData, setEditData] = useState<any>({});
    const [editDialog, setEditDialog] = useState<boolean>(false);
    const [testName, setTestName] = useState<any>(null);
    const [lotNo, setLotNo] = useState<any>(null);

    // Page service
    const pageService = new PageService();

    //Loading/Page Loading
    const [pageLoad, setPageLoad] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [csvLoading, setCsvLoading] = useState<boolean>(false);

    const [spinner, setSpinner] = useState<boolean>(false);

    // use effect method
    useEffect(() => {
        getData();
    }, [dates]);

    useEffect(() => {
        if (csvData.length > 0) {
            csvLink?.current?.link.click();
            setCsvData([]);
        }
    }, [csvData]);

    // get raw matrail product data
    const getData = async () => {
        setLoading(true);

        // Api call
        pageService.getProductRawMatrialData(null).then((response) => {
            // Get response
            if (response) {
                const productData = response.data;
                if (productData.length == 0) {
                    setProducts([]);
                    setLoading(false);
                    setPageLoad(true);
                } else {
                    setProducts(productData);
                    setLoading(false);
                    setPageLoad(true);
                }
            } else {
                setProducts([]);
                setLoading(false);
                setPageLoad(false);
            }
        });
    };

    //download csv
    const exportTestingCSV = async () => {
        setCsvLoading(true);
        //Validation Check
        pageService.testingReportData(!window.cn(dates) && dates[0], !window.cn(dates) && dates[1]).then((response) => {
            // get response
            if (response) {
                let csvDetails = response.csv_data;
                if (csvDetails.length > 0) {
                    setCsvData(csvDetails);
                    setCsvLoading(false);
                }
            } else {
                setCsvData([]);
            }
        });
    }

    // on date change
    const onDateChange = (e: any) => {
        setDates(e.value);
    };

    const viewRawMaterailData = (rowData: any) => {
        setSpinner(true);
        setViewProductDialog(true);
        setViewProductData(rowData);
        setSpinner(false);
    }

    // close view orders data dialog
    const hideProductDialog = () => {
        setViewProductDialog(false);
        setViewProductData([]);
        setSpinner(false);
    };

    // for column action
    const actionBodyTemplate = (rowData: any) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-eye"
                    tooltip='View'
                    tooltipOptions={{ position: 'bottom'}}
                    className="p-button-rounded p-button-primary mt-2 mb-2"
                    style={{ marginRight: '17px' }}
                    onClick={() => viewRawMaterailData(rowData)}
                />
                {/* <Button
          icon="pi pi-pencil"
          className="p-button-success mr-3"
          onClick={() => editProduct(rowData)}
        /> */}
            </div>
        );
    };

    const cropBodyTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData?.crop_obj?.name}</div>
            </>
        )
    };

    const varietyBodyTemplate = (rowData: any) => {
        return (
            <>
                <div>{rowData?.variety_obj?.name}</div>
            </>
        )
    };

    //Start Date Time Conversion Template
    const dateFormatStartTimeTemplate = (rowData: any) => {
        return (
            <>
                <div>{formatDateTime(rowData?.created_at)}</div>
            </>
        );
    };

    //open edit dialog
    const editTestResult = (rowData: any, test: any) => {
        setEditId(rowData.id);
        setEditData({ name: rowData.result, code: rowData.result });
        setTestName(test);
        setEditDialog(true);
        setLotNo(rowData.lot_no);
    };

    // close edit dialog
    const closeEditDialog = () => {
        setEditId(null);
        setEditData({});
        setEditDialog(false);
        setTestName(null);
        setLotNo(null);
    };

    const onSubmit = () => {
        setLoading(true);
        let formRequestData = new FormData();
        formRequestData.append('id', editId);
        formRequestData.append('test_name', testName);
        formRequestData.append('result', editData.code);
        formRequestData.append('lot_no', lotNo);

        // api call
        pageService.updateTestResult(formRequestData).then(response => {
            setLoading(false);
            setEditId(null);
            setEditData({});
            setEditDialog(false);
            setLotNo(null);
            setTestName(null);
            toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: response.message,
            });
            getData();
        }).catch(error => {
            setLoading(false);
            setEditDialog(true);
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response.data.error
            });
        });

    }

    const germinationTemplate = (rowData: any) => {
        return (
            <>
                <div>{!window.cn(rowData.germination) && rowData.germination != null ?
                    <>{rowData.germination.result == 'Pass' ?
                        <Badge severity="success" value={rowData.germination.result}></Badge> :
                        <Badge severity="danger" value={rowData.germination.result}></Badge>
                    }
                        <Button
                            icon="pi pi-eye"
                            tooltip='View'
                            tooltipOptions={{ position: 'bottom' }}
                            className={"p-button-rounded p-button-secondary ml-3 mt-2 mb-2"}
                            onClick={() => navigate('/product/testing/add-germination', { state: { lot_no: rowData.lot_no, code_no: rowData.code_no } })}
                        />
                        <Button
                            icon="pi pi-pencil"
                            tooltip='Edit'
                            tooltipOptions={{ position: 'bottom' }}
                            className={"p-button-rounded p-button-secondary mt-2 mb-2 ml-3"}
                            onClick={() => editTestResult(rowData.germination, 'germination')}
                        /></> : <><Badge severity="warning" value="Pending"></Badge> <Button
                            icon="pi pi-plus"
                            tooltip='Add'
                            tooltipOptions={{ position: 'bottom' }}
                            className={"p-button-rounded p-button-secondary mt-2 ml-3 mb-2"}
                            onClick={() => navigate('/product/testing/add-germination', { state: { code_no: rowData.code_no } })}
                        /></>}
                </div>
            </>
        )
    };

    const fieldEmergenceTemplate = (rowData: any) => {
        return (
            <>
                <div>{!window.cn(rowData.field_emergence) && rowData.field_emergence != null ?
                    <>{rowData.field_emergence.result == 'Pass' ?
                        <Badge severity="success" value={rowData.field_emergence.result}></Badge> :
                        <Badge severity="danger" value={rowData.field_emergence.result}></Badge>
                    }
                        <Button
                            icon="pi pi-eye"
                            tooltip='View'
                            tooltipOptions={{ position: 'bottom' }}
                            className={"p-button-rounded p-button-secondary ml-3 mt-2 mb-2"}
                            onClick={() => navigate('/product/testing/add-field-emergence', { state: { lot_no: rowData.lot_no, code_no: rowData.code_no } })}
                        />
                        <Button
                            icon="pi pi-pencil"
                            tooltip='Edit'
                            tooltipOptions={{ position: 'bottom' }}
                            className={"p-button-rounded p-button-secondary mt-2 mb-2 ml-3"}
                            onClick={() => editTestResult(rowData.field_emergence, 'field_emergence')}
                        /> </> : <><Badge severity="warning" value="Pending"></Badge>
                        <Button
                            icon="pi pi-plus"
                            tooltip='Add'
                            tooltipOptions={{ position: 'bottom' }}
                            className={"p-button-rounded p-button-secondary mt-2 ml-3 mb-2"}
                            onClick={() => navigate('/product/testing/add-field-emergence', { state: { code_no: rowData.code_no } })}
                        /></>}</div>
            </>
        )
    };

    const geneticPurityTemplate = (rowData: any) => {
        return (
            <>
                <div>{!window.cn(rowData.genetic_purity) && rowData.genetic_purity != null ?
                    <>{rowData.genetic_purity.result == 'Pass' ?
                        <Badge severity="success" value={rowData.genetic_purity.result}></Badge> :
                        <Badge severity="danger" value={rowData.genetic_purity.result}></Badge>
                    }
                        <Button
                            icon="pi pi-eye"
                            tooltip='View'
                            tooltipOptions={{ position: 'bottom' }}
                            className={"p-button-rounded p-button-secondary ml-3 mt-2 mb-2"}
                            onClick={() => navigate('/product/testing/add-genetic-purity', { state: { lot_no: rowData.lot_no, code_no: rowData.code_no } })}
                        />
                        <Button
                            icon="pi pi-pencil"
                            tooltip='Edit'
                            tooltipOptions={{ position: 'bottom' }}
                            className={"p-button-rounded p-button-secondary mt-2 mb-2 ml-3"}
                            onClick={() => editTestResult(rowData.genetic_purity, 'genetic_purity')}
                        /> </> : <><Badge severity="warning" value="Pending"></Badge> <Button
                            icon="pi pi-plus"
                            tooltip='Add'
                            tooltipOptions={{ position: 'bottom' }}
                            className={"p-button-rounded p-button-secondary mt-2 ml-3 mb-2"}
                            onClick={() => navigate('/product/testing/add-genetic-purity', { state: { code_no: rowData.code_no } })}
                        /></>}</div>
            </>
        )
    };

    // for left toolbar
    const leftToolbarTemplate = () => {
        return (
            <>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.currentTarget.value)}
                        placeholder="Search..."
                    />
                </span>
            </>
        );
    };

    // for right toolbar
    const rightToolbarTemplate = () => {
        return (
            <>
                {/* <Calendar
                    value={dates}
                    dateFormat="dd/mm/yy"
                    onChange={(e) => onDateChange(e)}
                    selectionMode="range"
                    showIcon
                /> */}
                <div style={{ marginLeft: '15px' }}></div>
                <Button
                    icon="pi pi-file-export"
                    className="p-button-success mr-2"
                    loading={csvLoading}
                    onClick={exportTestingCSV} />
                <CSVLink
                    data={csvData}
                    filename='Testing_list.csv'
                    className='hidden'
                    ref={csvLink}
                    target='_blank'
                />
            </>
        );
    };

    return (
        <>

            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <div className='main-header'>
                            <h4 className='header'> Testing List </h4>
                        </div>
                        <Toast ref={toast} />
                        <Toolbar
                            className="mb-4"
                            left={leftToolbarTemplate}
                            right={rightToolbarTemplate}
                        ></Toolbar>

                        {/* Datatable Start */}
                        {pageLoad ? (
                            <>
                                <DataTable
                                    value={products}
                                    loading={loading}
                                    paginator={products.length > 0 && true}
                                    globalFilter={globalFilter}
                                    rows={defaultRowOptions}
                                    rowsPerPageOptions={defaultPageRowOptions}
                                    className="datatable-responsive p-datatable-sm"
                                    paginatorTemplate={paginatorLinks}
                                    currentPageReportTemplate={showingEntries}
                                    emptyMessage="No Testing Data Found"
                                >
                                    {TestColumns.map((col, i) => {
                                        if (col.field === 'action') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={actionBodyTemplate}
                                                />
                                            );
                                        } else if (col.field === 'sr_no') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    header={col.header}
                                                    body={(_, { rowIndex }) => rowIndex + 1} />
                                            );
                                        } else if (col.field === 'crop') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={cropBodyTemplate}
                                                />
                                            );
                                        } else if (col.field === 'variety') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={varietyBodyTemplate}
                                                />
                                            );
                                        } else if (col.field === 'created_at') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={dateFormatStartTimeTemplate}
                                                    sortable
                                                />
                                            );
                                        } else if (col.field === 'germination') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={germinationTemplate}
                                                />
                                            );
                                        } else if (col.field === 'field_emergence') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={fieldEmergenceTemplate}
                                                />
                                            );
                                        } else if (col.field === 'genetic_purity') {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    body={geneticPurityTemplate}
                                                />
                                            );
                                        } else {
                                            return (
                                                <Column
                                                    key={col.field}
                                                    field={col.field}
                                                    header={col.header}
                                                    sortable
                                                    filter
                                                />
                                            );
                                        }
                                    })}
                                </DataTable>
                            </>
                        ) : (
                            <>
                                {/* Skeleton Data table */}
                                <DataTable value={Skeletonitems}>
                                    {TestColumns.map((col, i) => (
                                        <Column
                                            key={col.field}
                                            field={col.field}
                                            header={col.header}
                                            body={SkeletonbodyTemplate}
                                        />
                                    ))}
                                </DataTable>
                            </>
                        )}
                        {/* Datatable End */}

                        {/* View Dialog start*/}
                        <Dialog
                            visible={viewProductDialog}
                            style={{ width: '850px' }}
                            header="View Raw Material Data"
                            modal
                            footer
                            onHide={hideProductDialog}
                        >

                            <div className="formgrid grid">
                                <div className="field col">
                                    <p><b>Procunment</b> : {!window.cn(viewProductData.procunment) ? viewProductData.procunment : ""}</p>
                                </div>
                                <div className="field col">
                                    <p> <b>Farmer Name : </b>  {!window.cn(viewProductData.farmer_name) ? viewProductData.farmer_name : ""}</p>
                                </div>

                                <div className="field col">
                                    <p><b> Lot No.</b> : {!window.cn(viewProductData.lot_no) ? viewProductData.lot_no : ""}</p>
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <p> <b>Crop</b> : {!window.cn(viewProductData.crop_obj?.name) ? viewProductData.crop_obj?.name : ""}</p>
                                </div>
                                <div className="field col">
                                    <p> <b>Variety</b> : {!window.cn(viewProductData.variety_obj?.name) ? viewProductData.variety_obj?.name : ""}</p>
                                </div>
                                <div className="field col">
                                    <p> <b>Recieved Quantity</b> : {!window.cn(viewProductData.crop_qnty) ? viewProductData.crop_qnty + " " + viewProductData.weight_in : ""}</p>
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col-4">
                                    <p><b>Storage Location</b> : {!window.cn(viewProductData.location) ? viewProductData.location : ""}</p>
                                </div>
                                <div className="field col-4">
                                    <p> <b>Processing Centre</b> : {!window.cn(viewProductData.processing_center) ? viewProductData.processing_center : ""}</p>
                                </div>

                                {!window.cn(viewProductData.ginning_centre) ? <>
                                    <div className="field col-4">
                                        <p> <b>Ginning Centre</b> : {!window.cn(viewProductData.ginning_centre) ? viewProductData?.ginning_centre : ""}</p>
                                    </div>
                                </> : ""}
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <p> <b>Taluka</b> : {!window.cn(viewProductData.taluka) ? viewProductData.taluka : ""}</p>
                                </div>

                                <div className="field col">
                                    <p> <b>District</b> : {!window.cn(viewProductData.district) ? viewProductData.district : ""}</p>
                                </div>
                                <div className="field col">
                                    <p> <b>Village</b> : {!window.cn(viewProductData.village) ? viewProductData.village : ""}</p>
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <p> <b>Packing Type</b> : {!window.cn(viewProductData.packing_type) ? viewProductData.packing_type : ""}</p>
                                </div>

                                <div className="field col">
                                    <p> <b>Seed Organizer</b> : {!window.cn(viewProductData.seed_organizer) ? viewProductData.seed_organizer : ""}</p>
                                </div>

                                <div className="field col">
                                    <p> <b>Production Year</b> : {!window.cn(viewProductData.crop_grown_year) ? viewProductData.crop_grown_year : ""}</p>
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col-4">
                                    <p> <b>No. of Bags</b> : {!window.cn(viewProductData.no_of_bags) ? viewProductData.no_of_bags : ""}</p>
                                </div>
                                <div className="field col-4">
                                    <p> <b>Class</b> : {!window.cn(viewProductData.crop_division) ? viewProductData.crop_division : ""}</p>
                                </div>
                                <div className="field col-4">
                                    <p> <b>Receiving Date</b> : {!window.cn(viewProductData.receiving_date) ? formatDateTime(viewProductData.receiving_date) : ""}</p>
                                </div>
                            </div>
                            <hr></hr>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <p> <b>Transport PAID</b> : {!window.cn(viewProductData.transport_paid) ? viewProductData.transport_paid : ""}</p>
                                </div>
                            </div>

                            {viewProductData.transport_paid === 'PAID' ? (
                                <div></div>
                            ) : (
                                <div className="formgrid grid">
                                    <div className="field col-4">
                                        <p> <b>Car No.</b> : {!window.cn(viewProductData.car_no) ? viewProductData.car_no : ""}</p>
                                    </div>

                                    <div className="field col-4">
                                        <p> <b>Transport Name</b> : {!window.cn(viewProductData.transport_name) ? viewProductData.transport_name : ""}</p>
                                    </div>
                                    <div className="field col-4">
                                        <p> <b>Transport Amount</b> : {!window.cn(viewProductData.transport_amount) ? viewProductData.transport_amount : ""}</p>
                                    </div>
                                </div>

                            )}

                            <div className="formgrid grid">
                                <div className="field col-4">
                                    <p> <b>Labour Conractor Name</b> : {!window.cn(viewProductData.conractor_name) ? viewProductData.conractor_name : ""}</p>
                                </div>

                                <div className="field col-4">
                                    <p> <b>Labour No. of Bags</b> : {!window.cn(viewProductData.labour_no_of_bags) ? viewProductData.labour_no_of_bags : ""}</p>
                                </div>

                                <div className="field col-4">
                                    <p> <b>Weight of Bag</b> : {!window.cn(viewProductData.bag_weight) ? viewProductData.bag_weight : ""}</p>
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col-4">
                                    <p> <b>Price Per Bag</b> : {!window.cn(viewProductData.price_per_bag) ? viewProductData.price_per_bag : ""}</p>
                                </div>
                                <div className="field col-4">
                                    <p> <b>Total Labour Price</b> : {!window.cn(viewProductData.total_labour_price) ? viewProductData.total_labour_price : ""}</p>
                                </div>
                            </div>

                            <hr></hr>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <p> <b>Physical Purity Test</b> : Pass</p>
                                </div>

                                <div className="field col">
                                    <p> <b>Germination Test</b> : {viewProductData.germination?.result ? viewProductData.germination?.result : "Pending"}</p>
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <p> <b>Field Emergence Test</b> : {viewProductData.field_emergence?.result ? viewProductData.field_emergence?.result : "Pending"}</p>
                                </div>

                                <div className="field col">
                                    <p> <b>Genetic Purtiy Test</b> : {viewProductData.genetic_purity?.result ? viewProductData.genetic_purity?.result : "Pending"}</p>
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col-4">
                                    <p> <b>Way Bridge Copy</b> : {!window.cn(viewProductData) && viewProductData.photo ? <Button
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-primary ml-3"
                                        style={{ marginRight: '17px' }}
                                        onClick={() => window.open(viewProductData.photo, '_blank')}
                                    /> : ""}</p>
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-12">
                                    <p> <b>Raw Material Data Added By</b> : {!window.cn(viewProductData.user_id) ? viewProductData?.user?.first_name + " " + viewProductData?.user?.last_name : ""}</p>
                                </div>
                            </div>

                            {
                                spinner && <div className="loading flex justify-content-center">
                                    <ProgressSpinner />
                                </div>
                            }

                        </Dialog>
                        {/* View Dialog End*/}

                        {/* Add Raw matrial Dialog Start*/}
                        <Dialog
                            visible={editDialog}
                            style={{ width: '300px' }}
                            className="p-fluid"
                            header="Add Raw Material Data"
                            onHide={closeEditDialog}
                            footer={
                                <>
                                    <Button
                                        label="Cancel"
                                        icon="pi pi-times"
                                        className="p-button-text"
                                        onClick={closeEditDialog}
                                    />
                                    <Button
                                        label="Save"
                                        icon="pi pi-check"
                                        className="p-button-text"
                                        onClick={onSubmit}
                                        loading={loading}
                                    />
                                </>
                            }
                        >
                            <div className="field col">
                                <label htmlFor="result">Result</label>
                                <Dropdown
                                    id="result"
                                    value={editData}
                                    onChange={(e) => setEditData(e.value)}
                                    options={testResultDropdown}
                                    optionLabel="name"
                                    name="result"
                                    placeholder="Select"
                                ></Dropdown>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};
