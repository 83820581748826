import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../appconfig/AppHelper';

//Buffer Storage
import { Buffer } from 'buffer';

// Column
import { DealerLeadColumns } from '../../appconfig/DatatableSetting';

// Data table
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

// Validation
import { DealerLeadDataValidate } from '../../config/Validate';

export const LeadDealer = () => {
  document.title = 'Lead Dealer | Swarnim Farms';

  //Navigate Another Route
  const navigate = useNavigate();

  //Dealer lead Crud Object
  const [dealerLeadList, setDealerLeadList] = useState<any>([]);
  const [leadDealerData, setLeadDealerData] = useState<any>({});

  // Lead Dealer Model/ Dialog
  const [leadDealerDialog, setLeadDealerDialog] = useState<boolean>(false);

  // Lead dealer id Data Details
  const [leadId, setLeadId] = useState<any>(null);

  // Date Object
  let today = new Date();
  const [dates, setDates] = useState<string | Date | Date[] | any | null>([new Date(today.setDate(today.getDate() - 31)), new Date()]);

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  // use effect method
  useEffect(() => {
    getDealerLeadDataFromAPI();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [dates]);

  // Get Dealer Lead Data from API
  const getDealerLeadDataFromAPI = async () => {
    // call api
    pageService
      .getDealerLeadData()
      .then((response) => {
        // get response
        if (response) {
          const DataList = response;
          if (DataList.length == 0) {
            setDealerLeadList({});
          } else {
            setDealerLeadList(DataList);
          }
          setPageLoad(true);
        } else {
          setPageLoad(false);
          setDealerLeadList({});
        }
      });
  };

  // On Date Change
  const onDateChange = (e: any) => {
    setDates(e.value);
  };

  // call lead dealer api approve dealer
  const leadDealer = async () => {

    setSubmitLoading(true);

    let passwordBuff = Buffer.from('12345678').toString('base64');

    //Request object
    let formRequestData = new FormData();

    formRequestData.append('id', leadId);
    formRequestData.append('password', passwordBuff);

    // call apil
    pageService.approveDealerLeadData(formRequestData).then((result) => {
      setLeadDealerDialog(false);
      setLeadDealerData({ leadDealerData });
      setLoading(true);
      setLeadId('');
      setSubmitLoading(false);
      toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Dealer Data Leaded Successfully.' });
      getDealerLeadDataFromAPI();
    }).catch(error => {
      setLeadDealerDialog(false);
      setLeadDealerData({ leadDealerData });
      setSubmitLoading(false);
      setLoading(true);
      toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Dealer Data not Leaded, Please try again.', });
    }
    );
  };

  // open lead dialog
  const confirmLeadDealer = (leadID: any, mobile: any) => {
    setLeadId(leadID);
    setLeadDealerData({
      id: leadID,
      mobile: mobile
    });
    setLeadDealerDialog(true);
  };

  //close delete dialog
  const hideLeadDealerDialog = () => {
    setLeadId('');
    setLeadDealerDialog(false);
    setLoading(false);
  };

  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // right part of toolbar
  const rightToolbarTemplate = () => {
    return (
      <>
        <Calendar
          value={dates}
          dateFormat="dd/mm/yy"
          onChange={(e) => onDateChange(e)}
          selectionMode="range"
          showIcon
        />
      </>
    );
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {

    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          tooltip='View'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-primary mt-2 mr-3 mb-2"
          onClick={() => navigate('/user/dealer/view-lead-dealer', { state: { id: rowData.id, type: "lead" } })}
        />
        <Button
          icon="pi pi-arrow-up-right"
          tooltip='Approve'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-success mt-2 mb-2"
          onClick={() => confirmLeadDealer(rowData.id, rowData.mobile)}
        />
      </div>
    );
  };

  // for column image
  const imageBodyTemplate = (rowData: any) => {
    {console.log(rowData)}
    if (rowData.photo) {
      return (
        <img
          className="image"
          src={rowData.photo}
          width="50"
          height="50"
          alt="NO Image"
        />
      );
    } else {
      return (
        <img
          className="image"
          src="/assets/images/no_data_img.png"
          width="32"
          height="32"
          alt="NO Image"
        />
      );
    }
  };

  // for column name
  const leadedAddedUserTemplate = (rowData: any) => {
    return (
      <>
        <div>
          {rowData?.lead_added_user_name?.first_name + " " + rowData?.lead_added_user_name?.last_name}
        </div>
      </>
    );
  };

  const createdAtTemplate = (rowData: any) => {
    return (<>
      <div>{formatDateTime(rowData?.created_at)}</div>
    </>);
  };
  
  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <h4>Dealer Lead List</h4>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              // right={rightToolbarTemplate}
            ></Toolbar>
            {/* Datatable */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive"
                  value={dealerLeadList}
                  paginator={dealerLeadList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Dealer Lead Data Found"
                >
                  {DealerLeadColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'created_at') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={createdAtTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else if (col.field === 'lead_added_user_name') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={leadedAddedUserTemplate} />
                      );
                    } else if (col.field === 'photo') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={imageBodyTemplate}
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {DealerLeadColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </div>

      {/* approve Dialog start*/}
      <Dialog
        visible={leadDealerDialog}
        style={{ width: '450px' }}
        header="Lead Dealer"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-secondary"
              onClick={hideLeadDealerDialog}
            />
            <Button
              label="Approve"
              icon="pi pi-arrow-up-right"
              className="p-button-success"
              onClick={leadDealer}
              loading={submitLoading}
            />
          </>
        }
        onHide={hideLeadDealerDialog}
      >
        <div className="flex align-items-center justify-content-start">
          <i
            className="pi pi-exclamation-triangle mr-3 delete-triangle"
            style={{ fontSize: '2rem' }}
          />
          <span className="delete-dialog-note">
            Default password will be set on approving dealer lead.
          </span>
        </div>
        <br />
        {/* <div className="formgrid grid">
            <div className="field col-2">
              <label htmlFor="email">Email</label>
              </div>
              <div className="field col-10">
              <InputText
                style={{ marginLeft: '16px' }}
                value={leadDealerData.email}
                name="email"
                autoComplete="off"
                readOnly
              />
            </div>
            <div className="field col-2">
              <label htmlFor="password">Password</label>
              </div>
              <div className="field col-10">
              <Password
                style={{ marginLeft: '16px' }}
                value={leadDealerData.password}
                name="password"
                autoComplete="off"
                onChange={(e) => onInputChange(e, 'password')}
                className={errors['password'] && 'p-invalid'}
              />
              <small className="p-invalid-txt">{errors['password']}</small>
            </div>
          </div> */}
      </Dialog>
      {/* approve Dialog End*/}
    </>
  );
};
