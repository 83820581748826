import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { InputSwitch } from 'primereact/inputswitch';

const AppBreadcrumb = (props: any) => {


    const location = useLocation();

    const activeRoute = props.routes.filter((route: any) => {
        return route.label.replace(/\s/g, '').toLowerCase() === location.pathname.toLowerCase().replace(/\s/g, '').slice(1);
    });

    let items;

    if (location.pathname === '/dashboard') {
        items = [{ label: 'Dashboard' }, { label: 'Sales Dashboard' }];
    } else if (!activeRoute.length) {
        items = [{ label: '' }, { label: '' }];
    } else {
        items = [{ label: activeRoute[0].parent }, { label: activeRoute[0].label }];
    }

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    return (
        <>

            <div className="layout-breadcrumb-container">
                <div className="layout-breadcrumb-left-items">
                    {/* {isStatic() && (
                        <button className="menu-button p-link" onClick={props.onMenuButtonClick}>
                            <i className="pi pi-bars"></i>
                        </button>
                    )}

                    <BreadCrumb model={items} className="layout-breadcrumb" /> */}
                </div>
                <div className="layout-breadcrumb-right-items Colorscheme">
                    <label htmlFor="theme1">Light</label>
                    <InputSwitch className='theme-input' checked={props.colorScheme == "dark"} onChange={(e) => props.changeColorScheme(e.value)} />
                    <label htmlFor="theme2">Dark</label>
                </div>
            </div>
        </>
    );
};

export default AppBreadcrumb;
