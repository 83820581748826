import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
import { useNavigate } from 'react-router-dom';


const AppInlineMenu = (props: any) => {

    const menuRef = useRef(null);

    //Navigate Another Route
    const navigate = useNavigate();

    const isSlim = () => {
        return props.menuMode === 'slim';
    };

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("assigned_actions");
        localStorage.removeItem("userkey");
        localStorage.removeItem("role");
        window.location.href = "/";
    }

    let name = localStorage.getItem("name")

    return (
        <>

            {!isMobile() && (isStatic() || isSlim() || isSidebar()) && (
                <div className={classNames('layout-inline-menu', { 'layout-inline-menu-active': props.activeInlineProfile })}>
                    <button className="layout-inline-menu-action p-link" onClick={props.onChangeActiveInlineMenu}>
                        <img src="/assets/images/avatar.png" alt="avatar" style={{ width: '44px', height: '44px' }} />
                        <span className="layout-inline-menu-text">{name}</span>
                        {/* <Avatar icon="pi pi-user" size="large" shape="circle">{name}</Avatar> */}
                        <i className="layout-inline-menu-icon pi pi-angle-down"></i>
                    </button>
                    <CSSTransition nodeRef={menuRef} classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.activeInlineProfile} unmountOnExit>
                        <ul ref={menuRef} className="layout-inline-menu-action-panel">
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={logout}>
                                    <i className="pi pi-power-off pi-fw"></i>
                                    <span>Logout</span>
                                </button>
                            </li>
                            <li className="layout-inline-menu-action-item">
                                <button className="p-link" onClick={() => navigate('/change-password')}>
                                    <i className="pi pi-unlock pi-fw"></i>
                                    <span>Change Password</span>
                                </button>
                            </li>
                        </ul>
                    </CSSTransition>
                </div>
            )}
        </>
    );
};

export default AppInlineMenu;
