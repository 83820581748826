import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';

import { defaultPageRowOptions, defaultRowOptions, orderTransferTypeDropdown, paginatorLinks, showingEntries, SkeletonbodyTemplate, Skeletonitems, } from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { formatDateTime } from '../../appconfig/AppHelper';
import { orderTransferColumns } from '../../appconfig/DatatableSetting';
import { OrderTransferApprove } from '../../config/Validate';

export const OrderTransfer = () => {
  document.title = 'Sales Transfer/Return | Swarnim Farms';
  const [ordersList, setOrdersList] = useState<any>([]);

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);
  const [pageLoad, setPageLoad] = useState(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [transferApproveDialog, setTransferApproveDialog] =
    useState<boolean>(false);
  const [transferData, setTransferData] = useState<any>({});
  const [receivedData, setReceivedData] = useState<any>({});

  const [receiveQtyDialog, setReceiveQtyDialog] =
    useState<boolean>(false);

  const [type, setType] = useState<any>({ name: 'All', code: 'All' });
  const [loading, setLoading] = useState<boolean>(false);

  // Error object
  const [isError, setIsError] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});

  const pageService = new PageService();

  //Navigate Another Route
  const navigate = useNavigate();

  // use effect method
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getordersDataFromAPI();
  }, []);

  useEffect(() => {
    getordersDataFromAPI();
  }, [type]);

  // Get orders Data from API
  const getordersDataFromAPI = async () => {

    pageService.orderTransferList(type.code).then((response) => {
      setPageLoad(false);
      // get response
      if (response) {
        const DataList = response;
        if (DataList.length == 0) {
          setOrdersList([]);
        } else {
          setOrdersList(DataList);
        }
        setPageLoad(true);
      } else {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong, Please try again.',
        });
      }
    });
  }

  const onAmountChange = (e: any) => {
    setTransferData({ ...transferData, "amount_per_packet": e })
  }

  // open view orders data dialog
  const approveTransfer = (rowData: any) => {
    setSpinner(true);
    setTransferApproveDialog(true);
    setTransferData(rowData);
    setSpinner(false);
  };

  // close view orders data dialog
  const hideTransferDialog = () => {
    setTransferApproveDialog(false);
    setSpinner(false);
  };

  // add transfer approve api call
  const onTransferAprove = async () => {

    const { errors, isError } = OrderTransferApprove(transferData);
    setErrors(errors);
    setIsError(isError);

    //Check Error if no errors then call API
    if (!isError) {
      setLoading(true);

      let formData = new FormData();

      formData.append('order_transfer_id', transferData.id);
      formData.append('type', transferData.type);

      if (!window.cn(transferData) && transferData.type == 'Dealer') {
        formData.append('amount_per_packet', transferData.amount_per_packet);
        formData.append('order_transfer_to', transferData.order_transfer_to);
      }


      // Api call
      pageService.orderTransferApprove(formData).then((response) => {
        // Get Response
        setLoading(false);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
        });
        setTransferApproveDialog(false);
        getordersDataFromAPI();
      }).catch(error => {
        setLoading(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error,
        });
      });
    }
  };

  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // for right toolbar
  const rightToolbarTemplate = () => {
    return (
      <>
        Type :
        <Dropdown
          value={type}
          onChange={(e) => setType(e.value)}
          options={orderTransferTypeDropdown}
          optionLabel="name"
          placeholder="Transfer Type"
        ></Dropdown>
        <div style={{ marginLeft: '15px' }}></div>
      </>
    );
  };

  // for column action
  const receivedQtyTemplate = (rowData: any) => {
    return (
      <div className="actions action-btn">
        <Button
          icon="pi pi-plus"
          tooltip='Add'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-success mt-2 mb-2 ml-5"
          onClick={() => onAddRecevedQtyDialog(rowData)}
          disabled={rowData.status === 0 || rowData.type == 'Dealer' || rowData.received_qty != null}
        />
      </div>
    )
  }

  const onDataChange = (e: any) => {
    setTransferData({ ...receivedData, "received_qty": e })
  }

  // add received qty api call
  const onDataAdd = async () => {

    // const { errors, isError } = OrderTransferApprove(transferData);
    // setErrors(errors);
    // setIsError(isError);

    // //Check Error if no errors then call API
    // if (!isError) {
    setLoading(true);

    let formData = new FormData();

    formData.append('order_transfer_id', transferData.id);
    formData.append('received_qty', transferData.received_qty);

    // Api call
    pageService.orderReturnQty(formData).then((response) => {
      // Get Response
      setLoading(false);
      toast.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: response.message,
      });
      setReceiveQtyDialog(false);
      getordersDataFromAPI();
    }).catch(error => {
      setLoading(false);
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: error.response.data.error,
      });
    });
    // }
  };

  // open view orders data dialog
  const onAddRecevedQtyDialog = (rowData: any) => {
    setSpinner(true);
    setReceiveQtyDialog(true);
    setReceivedData(rowData);
    setSpinner(false);
  };

  // close view orders data dialog
  const hideReceivedQtyDialog = () => {
    setReceiveQtyDialog(false);
    setSpinner(false);
  };

  // for column action
  const statusBodyTemplate = (rowData: any) => {
    return (
      <div className="actions action-btn">
        <Button
          icon="pi pi-arrow-up-right"
          tooltip='Approve'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-success ml-3 mt-2 mb-2 "
          onClick={() => approveTransfer(rowData)}
          disabled={rowData.status === 1}
        />
      </div>)
  }

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions action-btn">
        <Button
          icon="pi pi-file-pdf"
          tooltip='Sales Pdf'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-primary mt-2 mb-2 mr-3"
          onClick={() => window.open(rowData.swarnim_sales_transfer_pdf, '_blank')}
          disabled={rowData.swarnim_sales_transfer_pdf == null ? true : false}
        />
        <Button
          icon="pi pi-file-pdf"
          tooltip='Dealer Pdf'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-primary mt-2 mb-2 mr-3"
          onClick={() => window.open(rowData.dealer_sales_transfer_pdf, '_blank')}
          disabled={rowData.dealer_sales_transfer_pdf == null ? true : false}
        />
      </div>
    );
  };

  const cropBodyTemplate = (rowData: any) => {
    return (<>
      <div>{rowData?.crop_obj?.name}</div>
    </>);
  };

  const varietyBodyTemplate = (rowData: any) => {
    return (<>
      <div>{rowData?.variety_obj?.name}</div>
    </>);
  };

  const orderDateTemplate = (rowData: any) => {
    return (<>
      <div>{formatDateTime(rowData?.created_at)}</div>
    </>);
  };

  const orderTransferedBy = (rowData: any) => {
    return (<>
      <div>{!window.cn(rowData.transfer_by) ? rowData.transfer_by.full_name : "-"}</div>
    </>);
  };

  const orderTransferedTo = (rowData: any) => {
    return (<>
      <div>{!window.cn(rowData.transfer_to) ? rowData.transfer_to.full_name : "-"}</div>
    </>);
  };

  const orderType = (rowData: any) => {
    return (<>
      <div>{!window.cn(rowData.type) && rowData.type == 'Dealer' ? <Badge severity="warning" value="Sales Transfer"></Badge> :
        <Badge severity="success" value="Sales Return"></Badge>}</div>
    </>);
  };

  return (
    <>
      <div className="grid crud-demo main-div-sec ">
        <div className="col-12">
          <div className="card">
            <div className='main-header'>
              <h4 className='header'>Sales Transfer/Return List </h4>

            </div>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            {/* Datatable */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive p-datatable-sm"
                  value={ordersList}
                  paginator={ordersList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Transfer/Return Found"
                >
                  {orderTransferColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'received_qty') {
                      return <Column key={col.field} field={col.field} header={col.header} body={receivedQtyTemplate} />
                    } else if (col.field === 'status') {
                      return <Column key={col.field} field={col.field} header={col.header} body={statusBodyTemplate} />
                    } else if (col.field === 'sr_no') {
                      return <Column key={col.field} header={col.header} body={(_, { rowIndex }) => rowIndex + 1} />
                    } else if (col.field === 'created_at') {
                      return <Column key={col.field} field={col.field} header={col.header} body={orderDateTemplate} />
                    } else if (col.field === 'order_transfered_by') {
                      return <Column key={col.field} field={col.field} header={col.header} body={orderTransferedBy} />
                    } else if (col.field === 'order_transfer_to') {
                      return <Column key={col.field} field={col.field} header={col.header} body={orderTransferedTo} />
                    } else if (col.field === 'crop') {
                      return <Column key={col.field} field={col.field} header={col.header} body={cropBodyTemplate} />;
                    } else if (col.field === 'variety') {
                      return <Column key={col.field} field={col.field} header={col.header} body={varietyBodyTemplate} />;
                    } else if (col.field === 'type') {
                      return <Column key={col.field} field={col.field} header={col.header} body={orderType} />
                    } else {
                      return <Column key={col.field} field={col.field} header={col.header} />
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {orderTransferColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </div>

      {/* approve Dialog start*/}
      <Dialog
        visible={transferApproveDialog}
        style={{ width: '450px' }}
        header="Order Transfer"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-secondary"
              onClick={hideTransferDialog}
            />
            <Button
              label="Approve"
              icon="pi pi-arrow-up-right"
              className="p-button-success"
              onClick={onTransferAprove}
              loading={loading}
            />
          </>
        }
        onHide={hideTransferDialog}
      >
        <div className="formgrid grid">
          <div className="field col-12 md:col-4">
            <label htmlFor="crop">Transfered From :&nbsp; </label>
            <span>{!window.cn(transferData) ? transferData?.transfer_by?.full_name : ""}</span>
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="crop">Transferred To :&nbsp; </label>
            <span>{!window.cn(transferData.transfer_to) ? transferData.transfer_to.full_name : "Swarnim"}</span>
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="crop">Data Added By :&nbsp; </label>
            <span>{!window.cn(transferData) ? transferData?.data_added_by?.full_name : ""}</span>
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="crop">Crop :&nbsp; </label>
            <span>{!window.cn(transferData) ? transferData?.crop_obj?.name : ""}</span>
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="crop">Variety :&nbsp; </label>
            <span>{!window.cn(transferData) ? transferData?.variety_obj?.name : ""}</span>
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="crop">Lot No. :&nbsp; </label>
            <span>{!window.cn(transferData) ? transferData.lot_no : ""}</span>
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="packing_size">Packing Size :&nbsp;</label>
            <span>{!window.cn(transferData) ? transferData.packing_size : ""}</span>
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="no_of_bag">No. Of Bags :&nbsp;</label>
            <span>{!window.cn(transferData) ? transferData.no_of_bag : ""}</span>
          </div>
          {!window.cn(transferData) && transferData.type == 'Dealer' ?

            <><div className="field col-12 md:col-4">
              <label htmlFor="amount_per_packet">Amount </label>
              <InputText
                value={!window.cn(transferData) ? transferData.amount_per_packet : ""}
                name="amount_per_packet"
                onChange={(e) => onAmountChange(e.target.value)}
              />
              <small className="p-invalid-txt">{errors['amount']}</small>
            </div></>
            : <div className="field col-12 md:col-4">
              <label htmlFor="no_of_bag">Total Amount &nbsp;</label>
              <span>{!window.cn(transferData) ? transferData.amount : ""}</span>
            </div>}

          <div className="field col-12">
            <label htmlFor="transport_type">Transport Type : &nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;</label>
            <span>{!window.cn(transferData) ? transferData.transport_type : ""}</span>
          </div>
          {!window.cn(transferData) && transferData.transport_type == 'Part Load' ?
            <>
              <div className="field col-12 md:col-4">
                <label htmlFor="no_of_bag">Lr No. :&nbsp;</label>
                <span>{!window.cn(transferData) ? transferData.lr_no : ""}</span>
              </div>

              <div className="field col-12 md:col-4">
                <label htmlFor="transport_name">Transport Name :&nbsp;</label>
                <span>{!window.cn(transferData) ? transferData.transport_name : ""}</span>
              </div>

              <div className="field col-12 md:col-4">
                <label htmlFor="transport_amount">Transport Amount :&nbsp;</label>
                <span>{!window.cn(transferData) ? transferData.transport_amount : ""}</span>
              </div>

              <div className="field col-12 md:col-4">
                <label htmlFor="date_of_supply">Date Of Supply :&nbsp;</label>
                <span>{!window.cn(transferData) ? transferData.date_of_supply : ""}</span>
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="lr_image">Lr Image :&nbsp;</label>
                <Button
                  icon="pi pi-eye"
                  className="p-button-rounded p-button-primary ml-3"
                  style={{ marginRight: '17px' }}
                  onClick={() => window.open(transferData.lr_image, '_blank')}
                />
              </div>
            </>
            : !window.cn(transferData) && transferData.transport_type == 'Full Load' ?
              <>
                <div className="field col-12 md:col-4">
                  <label htmlFor="no_of_bag">Lr No. :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.lr_no : ""}</span>
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="transport_name">Transport Name :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.transport_name : ""}</span>
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="transport_amount">Transport Amount :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.transport_amount : ""}</span>
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="car_no">Vehicle No. :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.car_no : ""}</span>
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="driver_name">Driver Name :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.driver_name : ""}</span>
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="driver_mobile">Driver Mobile :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.driver_mobile : ""}</span>
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="license_no">License No. :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.license_no : ""}</span>
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="license_state">License State :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.license_state : ""}</span>
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="driver_image">Driver Image :&nbsp;</label>
                  <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-primary ml-3"
                    style={{ marginRight: '17px' }}
                    onClick={() => window.open(transferData.driver_image, '_blank')}
                  />
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="lr_image">Lr Image :&nbsp;</label>
                  <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-primary ml-3"
                    style={{ marginRight: '17px' }}
                    onClick={() => window.open(transferData.lr_image, '_blank')}
                  />
                </div>
              </>
              : <>
                <div className="field col-12 md:col-4">
                  <label htmlFor="transport_name">Transport Name :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.transport_name : ""}</span>
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="transport_amount">Transport Amount :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.transport_amount : ""}</span>
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="car_no">Vehicle No. :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.car_no : ""}</span>
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="driver_mobile">Driver Mobile :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.driver_mobile : ""}</span>
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="from_location">From Location :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.from_location : ""}</span>
                </div>
                <div className="field col-12 md:col-4">
                  <label htmlFor="to_location">To Loaction :&nbsp;</label>
                  <span>{!window.cn(transferData) ? transferData.to_location : ""}</span>
                </div>
              </>}
          <div className="field col-12 md:col-4">
            <label htmlFor="from_location">Return Request:&nbsp;</label>
            <Button
              icon="pi pi-eye"
              className="p-button-rounded p-button-primary ml-3"
              style={{ marginRight: '17px' }}
              onClick={() => window.open(transferData.return_request_image, '_blank')}
            />
          </div>

        </div>
        <br />
      </Dialog>
      {/* approve Dialog End*/}

      {/* RECEIVED QTY Dialog start*/}
      <Dialog
        visible={receiveQtyDialog}
        style={{ width: '450px' }}
        header="Received Return Quantity"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-secondary"
              onClick={hideReceivedQtyDialog}
            />
            <Button
              label="Add"
              icon="pi pi-arrow-up-right"
              className="p-button-success"
              onClick={onDataAdd}
              loading={loading}
            />
          </>
        }
        onHide={hideReceivedQtyDialog}
      >
        <div className="formgrid grid">

          <div className="field col-12">
            <label htmlFor="received_qty">Received Qty. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </label>
            <InputText
              value={!window.cn(receivedData) ? receivedData.received_qty : ""}
              name="received_qty"
              onChange={(e) => onDataChange(e.target.value)}
              placeholder='Enter Received Quantity'
            />
            <small className="p-invalid-txt">{errors['received_qty']}</small>
          </div>

        </div>
        <br />
      </Dialog>
      {/* RECEIVED QTY Dialog End*/}
    </>
  );
};
