import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { formatDateTime } from '../../appconfig/AppHelper';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';

//Services
import PageService from '../../service/PageService';
import { Loader } from '../../components/Loader/Loader';

export const ViewLeadDealer = () => {

    //Navigate Another Route
    const navigate = useNavigate();

    const pageService = new PageService();
    const location = useLocation();

    const [pageLoad, setPageLoad] = useState(false);
    const [dealerData, setDealerData] = useState<any>(null);

    useEffect(() => {
        if (location.state) {
            const state = location.state;
            getDealerDataFromApi(state);
        }
    }, []);

    // Get Dispatch Data from API
    const getDealerDataFromApi = async (state: any) => {
        setPageLoad(true);
        // call api
        pageService
            .getLeadDealerDataById(state.id, state.type)
            .then((response) => {
                // get response
                if (response) {
                    const DataList = response;
                    if (DataList.length == 0) {
                        setDealerData(null);
                    } else {
                        setDealerData(DataList);
                        setPageLoad(false);
                    }
                } else {
                    setDealerData(null);
                }
            });
    };

    return (<>
        <h3> <Button
            //label="Back"
            icon="pi pi-arrow-left"
            className="p-button-secondary mr-2"
            onClick={() => navigate('/user/dealer/dealer-lead')}
        />    Dealer Details</h3>

        <div className='card' style={{ position: "relative" }}>
            <div className="field col">
                <div className="grid">
                    <div className="field col-4 flex flex-column">
                        <p> <b>Firm Name</b> : {!window.cn(dealerData) ? dealerData.firm_name : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Full Name</b> : {!window.cn(dealerData) ? dealerData.full_name : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Firm Type</b> : {!window.cn(dealerData) ? dealerData.firm_type : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Email</b> : {!window.cn(dealerData) ? dealerData.email : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Mobile</b> : {!window.cn(dealerData) ? dealerData.mobile : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Alternative Mobile</b> : {!window.cn(dealerData) ? dealerData.alt_mobile_no : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Business Address</b> : {!window.cn(dealerData) ? dealerData.business_address : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Residence Address</b> : {!window.cn(dealerData) ? dealerData.residence_address : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>State</b> : {!window.cn(dealerData) ? dealerData.state?.name : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>City</b> : {!window.cn(dealerData) ? dealerData.city?.name : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>District</b> : {!window.cn(dealerData) ? dealerData.district : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Taluka</b> : {!window.cn(dealerData) ? dealerData.taluka : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>PinCode</b> : {!window.cn(dealerData) ? dealerData.pincode : ""}</p>
                    </div>
                    <div className="field col-8 flex flex-column">
                        <p> <b>Date</b> : {!window.cn(dealerData) ? formatDateTime(dealerData.created_at) : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>GSTIN No.</b> : {!window.cn(dealerData) ? dealerData.gst_no : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>PAN No.</b> : {!window.cn(dealerData) ? dealerData.pan_no : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>AADHAR No.</b> : {!window.cn(dealerData) ? dealerData.aadhar_no : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Seed License No.</b> : {!window.cn(dealerData) ? dealerData.license_no : ""}</p>
                    </div>
                    <div className="field col-8 flex flex-column">
                        <p> <b>License Valid Upto</b> : {!window.cn(dealerData) ? dealerData.valid_upto : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Account No</b> : {!window.cn(dealerData) ? dealerData.payment?.account_no : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Bank Name</b> : {!window.cn(dealerData) ? dealerData.payment?.bank_name : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>IFSC Code</b> : {!window.cn(dealerData) ? dealerData.payment?.ifsc_code : ""}</p>
                    </div>
                    <div className="field col-12 flex flex-column">
                        <p> <b>Lead Added User</b> : {!window.cn(dealerData) ? dealerData.lead_added_user_name?.first_name + " " + dealerData?.lead_added_user_name?.last_name : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>GSTIN Reg. Copy</b> : {
                            !window.cn(dealerData) && dealerData.gstin_reg_copy ? <Button
                                icon="pi pi-eye"
                                className="p-button-rounded p-button-primary ml-3"
                                style={{ marginRight: '17px' }}
                                onClick={() => window.open(dealerData.gstin_reg_copy, '_blank')}
                            /> : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Seed License Copy</b> : {!window.cn(dealerData) && dealerData.seed_license_copy? <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary ml-3"
                            style={{ marginRight: '17px' }}
                            onClick={() => window.open(dealerData.seed_license_copy, '_blank')}
                        /> : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>PAN Card</b> : {!window.cn(dealerData) && dealerData.pancard ? <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary ml-3"
                            style={{ marginRight: '17px' }}
                            onClick={() => window.open(dealerData.pancard, '_blank')}
                        /> : ""}</p>
                    </div>
                    <div className="field col-4 flex flex-column">
                        <p> <b>Aadhar Card</b> : {!window.cn(dealerData) && dealerData.aadharcard ? <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-primary ml-3"
                            style={{ marginRight: '17px' }}
                            onClick={() => window.open(dealerData.aadharcard, '_blank')}
                        /> : ""}</p>
                    </div>
                    <div className="field col-2 flex flex-column">
                        <p> <b>Profile Picture</b> :</p>
                    </div> 
                    <div className="field col-3 flex flex-column">
                        {!window.cn(dealerData) && dealerData.photo ?
                                <img src={dealerData.photo}
                                    onClick={() => window.open(dealerData.photo, '_blank')}
                                    width={150} height={100}></img>
                            : ""}
                    </div>
                </div>
            </div>
        </div>

        {
            pageLoad && <Loader />
        }
    </>);
}