import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppMenu from './AppMenu';
import { classNames } from 'primereact/utils';

const AppTopbar = (props: any) => {
    const onTopbarSubItemClick = (event: any) => {
        event.preventDefault();
    };

    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("assigned_actions");
        localStorage.removeItem("role");
        localStorage.removeItem("userkey");
        window.location.href = "/";
    }

    return (
        <>
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>

                    <button className="logo p-link" onClick={() => navigate('/dashboard')}>
                        <img src="/assets/images/logo.png" className="logo-img" />
                    </button>
                </div>

                <AppMenu
                    model={props.items}
                    menuMode={props.menuMode}
                    colorScheme={props.colorScheme}
                    menuActive={props.menuActive}
                    activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    toggleMenu={props.onToggleMenu}
                    onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMenuItemClick={props.onMenuItemClick}
                />

                <div className="layout-topbar-right">
                    <ul className="layout-topbar-right-items">
                        <li id="profile" className={classNames('profile-item', { 'active-topmenuitem': props.topbarMenuActive })}>

                            {
                                !window.cn(localStorage.getItem("name")) &&
                                <span className="mr-1" onClick={props.onTopbarItemClick} >{localStorage.getItem("name")}</span>
                            }
                            <button className="p-link " onClick={props.onTopbarItemClick} >
                                <img src="/assets/images/avatar.png" style={{ borderRadius: "50%" }} alt="profile" />

                            </button>

                            <ul className="fadeInDown">

                                <li role="menuitem">
                                    <button className="p-link" onClick={() => navigate('/change-password')}>
                                        <i className="pi pi-unlock pi-fw"></i>
                                        <span>Change Password</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link" onClick={logout}>
                                        <i className="pi pi-fw pi-sign-out"></i>
                                        <span>Logout</span>
                                    </button>
                                </li>
                            </ul>
                        </li>


                    </ul>
                </div>
            </div>
        </>
    );
};

export default AppTopbar;
