import { useEffect, useRef, useState } from 'react';

//Prime React Component Inbuilt
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { formatDateTime } from '../../appconfig/AppHelper';
import { RadioButton } from 'primereact/radiobutton';

//Buffer Storage
import { Buffer } from 'buffer';

// Column
import { EmployeeColumns } from '../../appconfig/DatatableSetting';

//App Helper 
import { imageTemplate } from '../../appconfig/AppHelper';

// Data table
import {
  defaultPageRowOptions,
  defaultRowOptions,
  paginatorLinks,
  showingEntries,
  SkeletonbodyTemplate,
  Skeletonitems,
} from '../../appconfig/Settings';

//Services
import PageService from '../../service/PageService';

//File Uplaod Components
import {
  chooseOptions,
  emptyTemplate,
  headerTemplate,
} from '../../components/ImageUploadComponent/ImageUploadSetting';

// Validatetion
import { EmployeeDataValidate } from '../../config/Validate';

export const Employee = () => {
  document.title = 'Employee | Swarnim Farms';

  //Employee Crud Object
  const [EmployeeList, setEmployeeList] = useState<any>([]);
  const [EmployeeData, setEmployeeData] = useState<any>({});

  // File Upload Details
  const fileUploadRef = useRef(null);
  const [image, setImage] = useState<any>();
  const removeFile = useRef(null);

  // Error object
  const [isError, setIsError] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});

  // Date Object
  const [dates, setDates] = useState<string | Date | Date[] | any | null>(null);

  //Set Toast/ Filter Properties
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const toast = useRef<any>(null);

  // Edit and Delete Model/ Dialog
  const [EmployeeDialog, setEmployeeDialog] = useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

  // Edit and Delete Data Details
  const [editId, setEditId] = useState<any>(null);
  const [deleteId, setDeleteId] = useState<any>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  // Page service
  const pageService = new PageService();

  //Loading/Page Loading
  const [pageLoad, setPageLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // state and city
  const [stateData, setStateData] = useState<any>([]);
  const [selectedStateId, setSelectedStateId] = useState<any>(null);
  const [selectedCityId, setSelectedCityId] = useState<any>(null);
  const [cityData, setCityData] = useState<any>([]);
  const [roleList, setRoleList] = useState<any>([]);
  const [selectedRolesValue, setSelectedRolesValue] = useState<any>([]);
  const [role, setRole] = useState<any>(null);

  // use effect method
  useEffect(() => {
    getEmployeeDataFromAPI();
    getStateDataFromAPI();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [dates]);

  const onCheckboxChange = (e: any) => {
    let selectedValue: any[] = [...selectedRolesValue];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    setSelectedRolesValue(selectedValue);
  };

  // Handle Select state
  const handleSeleteState = (value: any) => {
    if (!window.cn(value)) {
      setSelectedStateId(value);
      getCityDataFromAPI(value);
    } else {
      setSelectedStateId(null);
      setCityData([]);
    }
  };

  // Get States
  const getStateDataFromAPI = async () => {
    try {
      const stateData: any = [];

      pageService.getState().then((response) => {
        if (response) {
          const stateDataList = response;
          for (let i = 0; i < stateDataList.length; i++) {
            const userObj: any = {};
            userObj['name'] = stateDataList[i].name;
            userObj['code'] = stateDataList[i].id;
            stateData.push(userObj);
          }
          setStateData(stateData);
        }
      });

    } catch (error) { }
  };

  //Get City
  const getCityDataFromAPI = async (selectedStateId: any) => {
    try {
      let stateId = !window.cn(selectedStateId) ? selectedStateId.code : '';

      const cityData: any = [];

      pageService.getCity(stateId).then((response) => {
        if (response) {
          const cityDataList = response;

          for (let i = 0; i < cityDataList.length; i++) {
            const userObj: any = {};
            userObj['name'] = cityDataList[i].name;
            userObj['code'] = cityDataList[i].id;
            cityData.push(userObj);
          }
          setCityData(cityData);
        }
      });
    } catch (error) { }
  };

  // Get Employee Data from API
  const getEmployeeDataFromAPI = async () => {
    // api call
    pageService
      .userList()
      .then((response) => {
        // get response
        if (response) {
          const DataList = response;
          if (DataList.length == 0) {
            setEmployeeList([]);
          } else {
            setEmployeeList(DataList);
          }
          setPageLoad(true);
        } else {
          setPageLoad(false);
          setEmployeeList([]);
        }
      });
  };

  // open add dealer dialog
  const addDialog = () => {
    setEmployeeDialog(true);
    getRolesApiCall();
  };

  // Get States
  const getRolesApiCall = async () => {
    try {
      pageService.getAllRoles().then((response) => {
        if (response) {
          setRoleList(response);
        }
      });
    } catch (error) { }
  };

  // close add dealer dialog
  const hideAddDialog = () => {
    setSelectedRolesValue([]);
    setEmployeeDialog(false);
    setEmployeeData({});
    setEditId(null);
    setErrors({});
    setCityData([]);
    setSelectedCityId(null);
    setSelectedStateId(null);
    setRole(null);
    setLoading(false);
    {
      !window.cn(removeFile.current) && onTemplateRemove(removeFile?.current);
    }
  };

  //onChange Dealer Data
  const onInputChange = (e: any, name: string) => {

    let val = (e.target && e.target.value) || '';
    if (name == 'email' || name == 'password') {
      val = (e.target && e.target.value) || '';
    } else if (name == 'login_for_both') {
      val = e;
    } else {
      val = (e.target && e.target.value) || '';
      val = val.toUpperCase();
    }
    setEmployeeData({ ...EmployeeData, [name]: val });
  };

  // for adding dealer data
  const onSubmit = async () => {
    const { errors, isError } = EmployeeDataValidate(editId, EmployeeData, image, selectedStateId, selectedCityId, role);
    setErrors(errors);

    //Check Error if no errors then call API
    if (!isError) {
      setLoading(true);

      //Request object
      let formRequestData = new FormData();

      formRequestData.append('first_name', EmployeeData.first_name);
      formRequestData.append('last_name', EmployeeData.last_name);
      formRequestData.append('email', EmployeeData.email);
      formRequestData.append('mobile', EmployeeData.mobile);
      formRequestData.append('role_id', role?.code);
      formRequestData.append('state', selectedStateId?.code);
      formRequestData.append('city', selectedCityId?.code);
      formRequestData.append('login_for_both', EmployeeData.login_for_both);

      if (image && image.objectURL) {
        formRequestData.append('photo', image);
      }

      if (editId !== undefined && editId !== null) {
        formRequestData.append('id', editId);
      } else {
        let passwordBuff = Buffer.from(EmployeeData.password).toString('base64');
        formRequestData.append('password', passwordBuff);
      }

      if (selectedRolesValue.length > 0) {
        formRequestData.append('assigned_actions', selectedRolesValue);
      }

      // api call
      pageService.addEmployee(formRequestData).then(result => {
        // Work here with the result
        setEmployeeDialog(false);
        setLoading(false);
        getEmployeeDataFromAPI();
        setEmployeeData({});
        setSelectedStateId(null);
        setSelectedCityId(null);
        setSelectedRolesValue([]);
        setRole(null);
        toast.current?.show({ severity: 'success', summary: 'Success', detail: result.message });
      }).catch(error => {
        // Error Handling here
        if (error) {
          //Spinner False and Set Toast
          setLoading(false);
          toast.current?.show({ severity: 'error', summary: 'Error', detail: error.response.data.error });
        }
      })
    }
  };

  //open edit dialog
  const editEmployee = async (Employee: any) => {
    setEditId(Employee.id);
    getRolesApiCall();

    let EmployeeData = Employee;

    // set data in edit form
    setEmployeeData({
      first_name:
        EmployeeData && EmployeeData.first_name ? EmployeeData.first_name : '',
      last_name:
        EmployeeData && EmployeeData.last_name ? EmployeeData.last_name : '',
      email: EmployeeData && EmployeeData.email ? EmployeeData.email : '',
      mobile: EmployeeData && EmployeeData.mobile ? EmployeeData.mobile : '',
      login_for_both: EmployeeData && EmployeeData.login_for_both ? EmployeeData.login_for_both : '',
    });

    setImage(EmployeeData.photo);

    let state: any = {
      'name': EmployeeData?.state,
      'code': EmployeeData?.state_id
    }

    let city: any = {
      'name': EmployeeData?.city,
      'code': EmployeeData?.city_id
    }

    let role: any = {
      'name': EmployeeData?.role,
      'code': EmployeeData?.role_id
    }

    setRole(role);
    setSelectedStateId(state);
    getCityDataFromAPI(state);
    setSelectedCityId(city);
    setEmployeeDialog(true);
    setSelectedRolesValue(EmployeeData?.assigned_actions.split(","));
  };

  // open delete dialog
  const confirmDeleteEmployee = (deleteID: any) => {
    setDeleteId(deleteID);
    setDeleteDialog(true);
  };

  //close delete dialog
  const hideDeleteDialog = () => {
    setDeleteDialog(false);
    setDeleteId(null);
  };

  // call delete api
  const deleteEmployee = async () => {
    setSubmitLoading(true);

    // call api
    pageService.deleteEmployee(deleteId).then((response) => {
        setSubmitLoading(false);
        setDeleteId(null);
        setDeleteDialog(false);
        getEmployeeDataFromAPI();
        setPageLoad(true);
        toast.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
        });
      }).catch(error => {
        setPageLoad(false);
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response.data.error,
        });
        setPageLoad(false);
        setSubmitLoading(false);
      });
  }

  // left part of toolbar
  const leftToolbarTemplate = () => {
    return (
      <>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.currentTarget.value)}
            placeholder="Search..."
          />
        </span>
      </>
    );
  };

  // for column action
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          tooltipOptions={{ position: 'bottom' }}
          tooltip='Edit'
          className="p-button-rounded p-button-success mr-3 mt-2 mb-2"
          onClick={() => editEmployee(rowData)}
        />
        {localStorage.hasOwnProperty('role') && localStorage.getItem('role') == '1' ?
        <Button
          icon="pi pi-trash"
          tooltip='Delete'
          tooltipOptions={{ position: 'bottom' }}
          className="p-button-rounded p-button-danger mt-2 mb-2"
          onClick={() => confirmDeleteEmployee(rowData.id)}
        />:''}
      </div>
    );
  };

  // for column name
  const roleBodyTemplate = (rowData: any) => {
    return (
      <>
        <div>
          {rowData?.role?.role}
        </div>
      </>
    );
  };

  // Template for image upload
  const itemImageTemplate = (file: any, props: any) => {
    setImage(file);
    removeFile.current = props.onRemove;
    return (
      <>
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img
              alt={file.name}
              role="presentation"
              src={file.objectURL}
              width={100}
            />
            <span className="flex flex-column text-left ml-3">{file.name}</span>
            <Button
              type="button"
              icon="pi pi-times"
              className="p-button-outlined p-button-rounded p-button-danger ml-auto"
              onClick={() => onTemplateRemove(props.onRemove)}
            />
          </div>
        </div>
      </>
    );
  };

  // remove image template
  const onTemplateRemove = (callback: any) => {
    setImage({});
    callback();
  };

  const createdAtTemplate = (rowData: any) => {
    return (<>
      <div>{formatDateTime(rowData?.created_at)}</div>
    </>);
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='main-header'>
              <h4 className='header'> Employee List </h4>
              <Button
                label="Add New Employee"
                icon="pi pi-plus"
                className="p-button-success mr-2"
                onClick={addDialog}
              />
            </div>
            <Toast ref={toast} />
            <Toolbar
              className="mb-4"
              left={leftToolbarTemplate}
            ></Toolbar>
            {/* Datatable */}
            {pageLoad ? (
              <>
                <DataTable
                  className="datatable-responsive"
                  value={EmployeeList}
                  paginator={EmployeeList.length > 0 && true}
                  globalFilter={globalFilter}
                  rows={defaultRowOptions}
                  rowsPerPageOptions={defaultPageRowOptions}
                  paginatorTemplate={paginatorLinks}
                  currentPageReportTemplate={showingEntries}
                  emptyMessage="No Employee Data Found"
                >
                  {EmployeeColumns.map((col, i) => {
                    if (col.field === 'action') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={actionBodyTemplate}
                        />
                      );
                    } else if (col.field === 'created_at') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={createdAtTemplate}
                        />
                      );
                    } else if (col.field === 'sr_no') {
                      return (
                        <Column
                          key={col.field}
                          header={col.header}
                          body={(_, { rowIndex }) => rowIndex + 1} />
                      );
                    } else if (col.field === 'photo') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={imageTemplate}
                        />
                      );
                    } else if (col.field === 'role_id') {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          body={roleBodyTemplate}
                        />
                      );
                    } else {
                      return (
                        <Column
                          key={col.field}
                          field={col.field}
                          header={col.header}
                          sortable
                          filter
                        />
                      );
                    }
                  })}
                </DataTable>
              </>
            ) : (
              <>
                {/* Skeleton Data table */}
                <DataTable value={Skeletonitems}>
                  {EmployeeColumns.map((col, i) => (
                    <Column
                      key={col.field}
                      field={col.field}
                      header={col.header}
                      body={SkeletonbodyTemplate}
                    />
                  ))}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Add Dialog Start*/}
      <Dialog
        visible={EmployeeDialog}
        header={editId !== null ? "Edit Employee Details" : "Add Employee Details"}
        className="p-fluid"
        onHide={hideAddDialog}
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideAddDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              className="p-button-text"
              onClick={onSubmit}
              loading={loading}
            />
          </>
        }
      >
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="name">First Name *</label>
            <InputText
              value={EmployeeData.first_name}
              name="first_name"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'first_name')}
              className={errors['first_name'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['first_name']}</small>
          </div>

          <div className="field col">
            <label htmlFor="name">Last Name *</label>
            <InputText
              value={EmployeeData.last_name}
              name="last_name"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'last_name')}
              className={errors['last_name'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['last_name']}</small>
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="email">Email *</label>
            <InputText
              value={EmployeeData.email}
              name="email"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'email')}
              className={errors['email'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['email']}</small>
          </div>
          <div className="field col">
            <label htmlFor="mobile">Mobile *</label>
            <InputText
              value={EmployeeData.mobile}
              name="mobile"
              autoComplete="off"
              onChange={(e) => onInputChange(e, 'mobile')}
              className={errors['mobile'] && 'p-invalid'}
            />
            <small className="p-invalid-txt">{errors['mobile']}</small>
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="state">State *</label>
            <Dropdown
              filter
              value={selectedStateId}
              onChange={(e) => handleSeleteState(e.value)}
              options={stateData}
              optionLabel="name"
              placeholder="Select State"
              name="state"
              className={errors['state'] && 'p-invalid'}
            ></Dropdown>
            <small className="p-invalid-txt">{errors['state']}</small>
          </div>

          <div className="field col">
            <label htmlFor="city">City *</label>
            <Dropdown
              filter
              value={selectedCityId}
              onChange={(e) => setSelectedCityId(e.value)}
              options={cityData}
              optionLabel="name"
              placeholder="Select City"
              name="city"
              className={errors['city'] && 'p-invalid'}
            ></Dropdown>
            <small className="p-invalid-txt">{errors['city']}</small>
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="role">Role *</label>
            <Dropdown
              filter
              value={role}
              onChange={(e) => setRole(e.value)}
              options={roleList}
              optionLabel="name"
              name="role"
              placeholder="Select Role"
              className={errors['role'] && 'p-invalid'}
            ></Dropdown>
            <small className="p-invalid-txt">{errors['role']}</small>
          </div>
          {editId === null ? (
            <div className="field col">
              <label htmlFor="password">Password *</label>
              <Password
                name="password"
                autoComplete="off"
                onChange={(e) => onInputChange(e, 'password')}
                className={errors['password'] && 'p-invalid'}
              />
              <small className="p-invalid-txt">{errors['password']}</small>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className="formgrid grid">
          <div className="col-12 md:col-4 mb-3">
            <label htmlFor="is_advance">Login For Both Web & App *  <small className="p-invalid-txt">{errors['login_for_both']}</small></label>
          </div>
          <div className="col-12 md:col-4 lg:col-4">
            <div className="field-radiobutton">
              <RadioButton inputId="option1" name="option" value="Yes" checked={EmployeeData.login_for_both == 'Yes'} onChange={(e) => onInputChange(e.value, "login_for_both")} />
              <label htmlFor="option1">Yes</label>
            </div>
          </div>
          <div className="col-12 md:col-4 lg:col-4">
            <div className="field-radiobutton">
              <RadioButton inputId="option2" name="option" value="No" checked={EmployeeData.login_for_both == 'No'} onChange={(e) => onInputChange(e.value, "login_for_both")} />
              <label htmlFor="option2">No</label>
            </div>
          </div>

        </div>

        <label>Assign action to User * <small className="p-invalid-txt">{errors['action']}</small></label>  <br /><br />
        <div className="form grid">
          <div className="col-12 md:col-2"><label><b>Users</b></label></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Dealer" checked={selectedRolesValue.indexOf('Dealer') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Dealer</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Lead Dealer" checked={selectedRolesValue.indexOf('Lead Dealer') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Lead Dealer</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Farmer" checked={selectedRolesValue.indexOf('Farmer') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Farmer</label>
            </div>
          </div>
        </div>
        <div className="form grid">
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Employee" checked={selectedRolesValue.indexOf('Employee') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Employee</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Organizer" checked={selectedRolesValue.indexOf('Organizer') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Organizer</label>
            </div>
          </div>
        </div>
        <div className="form grid">
          <div className="col-12 md:col-2"><label><b>Manage Product</b></label></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Production" checked={selectedRolesValue.indexOf('Production') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Production</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Raw Material" checked={selectedRolesValue.indexOf('Raw Material') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Raw Material</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Testing" checked={selectedRolesValue.indexOf('Testing') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Testing</label>
            </div>
          </div>
        </div>
        <div className="form grid">
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Processing" checked={selectedRolesValue.indexOf('Processing') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Processing</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Packaging" checked={selectedRolesValue.indexOf('Packaging') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Packaging</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Dispatch" checked={selectedRolesValue.indexOf('Dispatch') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Dispatch</label>
            </div>
          </div>
        </div>
        <div className="form grid">
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Seed Remenant" checked={selectedRolesValue.indexOf('Seed Remenant') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Seed Remenant</label>
            </div>
          </div>
        </div>
        <div className="form grid">
          <div className="col-12 md:col-2"><label><b>Stock</b></label></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Global Crops" checked={selectedRolesValue.indexOf('Global Crops') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Global Crops</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Crop" checked={selectedRolesValue.indexOf('Crop') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Crop</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Lot No Stock" checked={selectedRolesValue.indexOf('Lot No Stock') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Lot No Stock</label>
            </div>
          </div>

        </div>
        <div className="form grid">
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Packaging Material" checked={selectedRolesValue.indexOf('Packaging Material') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Packaging Material</label>
            </div>
          </div>
          <div className="col-12 md:col-5">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Cropwise Packaging Material" checked={selectedRolesValue.indexOf('Cropwise Packaging Material') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Cropwise Packaging Material</label>
            </div>
          </div>
        </div>
        <div className="form grid">
          <div className="col-12 md:col-2"><label><b>Others</b></label></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Dashboard" checked={selectedRolesValue.indexOf('Dashboard') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Dashboard</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Order" checked={selectedRolesValue.indexOf('Order') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Order</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Sales Transfer/Return" checked={selectedRolesValue.indexOf('Sales Transfer/Return') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Sales Transfer/Return</label>
            </div>
          </div>
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="GTM" checked={selectedRolesValue.indexOf('GTM') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">GTM</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Expense" checked={selectedRolesValue.indexOf('Expense') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Expense</label>
            </div>
          </div>
        </div>
        <div className="form grid">
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Attendance" checked={selectedRolesValue.indexOf('Attendance') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Attendance</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Leave Management" checked={selectedRolesValue.indexOf('Leave Management') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Leave Management</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Location Tracker" checked={selectedRolesValue.indexOf('Location Tracker') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Location Tracker</label>
            </div>
          </div>
        </div>
        <div className="form grid">
          <div className="col-12 md:col-2"><label><b>Reports</b></label></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Grower" checked={selectedRolesValue.indexOf('Grower') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Grower</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Sampling" checked={selectedRolesValue.indexOf('Sampling') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Sampling</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Inspection" checked={selectedRolesValue.indexOf('Inspection') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Inspection</label>
            </div>
          </div>
        </div>
        <div className="form grid">
          <div className="col-12 md:col-2"></div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Testing" checked={selectedRolesValue.indexOf('Testing') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Testing</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Loading/Unloading" checked={selectedRolesValue.indexOf('Loading/Unloading') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Loading/Unloading</label>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field-checkbox">
              <Checkbox inputId="checkOption1" name="option" value="Packing Material" checked={selectedRolesValue.indexOf('Packing Material') !== -1} onChange={onCheckboxChange} />
              <label htmlFor="checkOption1">Packing Material</label>
            </div>
          </div>
        </div>
        <div className="formgrid grid mt-3">
          <div className="field col-6">
            <label htmlFor="photo">Photo</label>
            <FileUpload
              ref={fileUploadRef}
              accept="image/*"
              name="photo[]"
              className="imageupload"
              chooseOptions={chooseOptions}
              emptyTemplate={emptyTemplate}
              headerTemplate={headerTemplate}
              itemTemplate={itemImageTemplate}
            ></FileUpload>
            <small className="p-invalid-txt">{errors['photo']}</small>
          </div>

          {editId != null && (
            <>
              <div className="field col-6 editImage">
                {typeof (image) === 'string' ? (
                  <>  <label htmlFor="photo">Old Image</label>
                    <img src={image}></img></>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </Dialog>
      {/* Add Dialog End*/}

      {/* deleteDialog start*/}
      <Dialog
        visible={deleteDialog}
        style={{ width: '450px' }}
        header="Delete Employee"
        modal
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-button-secondary"
              onClick={hideDeleteDialog}
            />
            <Button
              label="Delete"
              icon="pi pi-trash"
              className="p-button-danger"
              onClick={deleteEmployee}
              loading={submitLoading}
            />
          </>
        }
        onHide={hideDeleteDialog}
      >
        <div className="flex align-items-center justify-content-start">
          <i
            className="pi pi-exclamation-triangle mr-3 delete-triangle"
            style={{ fontSize: '2rem' }}
          />
          <span className="delete-dialog-note">
            Are you sure you want to delete ?
          </span>
        </div>
      </Dialog>
      {/* deleteDialog End*/}
    </>
  );
};
